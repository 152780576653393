<template>
	<svg viewBox="0 0 25.12 17.24" class="phonehangupSVG">
	<path d="M1.22 9.88C1.04 9.7.83 9.42.6 9.06c-.19-.29-.35-.59-.49-.91-.2-.48-.14-1.26.16-2.33.29-.99.75-1.79 1.38-2.42.18-.18.37-.35.57-.5.23-.17.47-.32.71-.46.21-.12.42-.23.63-.34.14-.07.4-.19.77-.36.37-.17.6-.27.68-.32.84-.41 1.72-.72 2.63-.94C9.07.17 10.7 0 12.56 0c1.86 0 3.49.17 4.89.5.91.22 1.79.53 2.63.94.23.11.45.21.68.32.26.12.52.24.77.36.21.11.43.22.63.34.25.14.49.29.71.46.2.15.39.32.57.5.62.62 1.08 1.43 1.38 2.42.31 1.07.36 1.84.16 2.33-.1.24-.26.55-.48.91-.23.36-.43.64-.62.82-.09.09-.18.16-.24.18-.16.08-.6 0-1.32-.23-.2-.05-.49-.14-.86-.24-.37-.11-.7-.21-1-.29-.3-.09-.59-.16-.86-.23-.05 0-.19-.03-.43-.08s-.44-.09-.58-.14a.815.815 0 0 1-.36-.22c-.14-.14-.21-.4-.22-.8-.01-.4.01-.8.07-1.19.06-.39.09-.78.09-1.17 0-.38-.06-.64-.18-.76a.839.839 0 0 0-.28-.18c-.13-.06-.22-.1-.3-.12-.07-.02-.2-.06-.39-.1-.19-.04-.29-.07-.31-.08-1.45-.41-2.84-.62-4.17-.62s-2.72.21-4.17.62c-.02 0-.12.03-.31.08-.19.04-.32.08-.39.1-.07.02-.17.06-.3.12-.1.04-.2.1-.28.18-.12.12-.18.38-.18.76 0 .39.03.78.09 1.17.06.4.08.8.07 1.19-.01.4-.08.67-.22.8-.1.1-.22.17-.36.22-.15.05-.34.1-.58.14-.24.04-.39.07-.43.08-.27.07-.56.15-.86.23-.3.08-.64.18-1 .29-.37.11-.65.19-.86.24-.71.24-1.15.32-1.32.23a.888.888 0 0 1-.24-.18Z" fill="red"/>
	<path d="m16.99 11.19.52.54c.11.11.16.24.16.4s-.05.29-.16.4l-4.55 4.55c-.11.11-.24.16-.4.16s-.29-.05-.4-.16l-4.55-4.55c-.11-.11-.16-.24-.16-.4s.05-.29.16-.4l.52-.54c.11-.11.24-.16.4-.15s.29.07.4.18l2.7 2.81V7.3c0-.16.05-.29.16-.4s.24-.16.4-.16h.75c.16 0 .29.05.4.16s.16.24.16.4v6.73l2.7-2.81c.11-.11.24-.17.4-.18s.29.04.4.15Z" fill="red"/>
	</svg>
</template>

<script>
export default {
   name: 'phonehangupSVG',
}
</script>