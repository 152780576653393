<template>
  <div id="blacklist">
    <UIBox classbox="hello">
      <template v-slot:title>
        <span class="bold">Blacklist</span>
      </template>
      <template>
        <div id="blacklist-search">
          <div class="search-container">
            <div :class="`search-box${role ? role.toLowerCase() : ''}`">
              <input type="text" placeholder="Search by Company Name, Owner Name, Phone, Email" v-model="searchQuery" />
              <button :class="`search-icon${role ? role.toLowerCase() : ''}`" @click="fetchBlacklist">
                <i class="fa fa-search" style="color: #FFF !important" />
              </button>
            </div>

            <button class="btn solid manual-entry" @click="showModal = true">
              <i class="fas fa-plus"></i>
              Manual Blacklist Entry
            </button>
          </div>
        </div>
        <div class="container-body" id="reportContainer">
          <div class="container-body" id="reportContainer">
            <div class="table-fullwidth">
              <table class="table call-backs-table" aria-controls="tagstable">
                <thead>
                  <tr>
                    <th style="width: 3%;">#</th>
                    <th style="width: 22%;">Label</th>
                    <th style="width: 15%;">Phone 1</th>
                    <th style="width: 15%;">Phone 2</th>
                    <th style="width: 15%;">Email 1</th>
                    <th style="width: 15%;">Email 2</th>
                    <th style="width: 10%;">Added</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in blacklist" :data-sales-rep-id="row.id">
                    <td>{{ row.id }}</td>
                    <td>{{ row.label }}</td>
                    <td>{{ formatPhoneNumber(row.phone) }}</td>
                    <td>{{ formatPhoneNumber(row.phone2) }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.email2 }}</td>
                    <td>{{ formatDate(row.time) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <!-- Bottom Input -->
      <div class="bottom-input">
        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchBlacklist">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
              aria-controls="tagstable" @change="handlePagination">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>

        <b-modal v-model="showModal" title="Add to Blacklist" hide-footer>
          <div class="form-container">
            <div class="row">
              <div class="col-md-6">
                <input v-model="newBlacklist.label" type="text" placeholder="Label" class="custom-input">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input v-model="newBlacklist.phone" type="tel" placeholder="Phone 1" class="custom-input">
              </div>
              <div class="col-md-6">
                <input v-model="newBlacklist.phone2" type="tel" placeholder="Phone 2" class="custom-input">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input v-model="newBlacklist.email" type="email" placeholder="Email 1" class="custom-input">
              </div>
              <div class="col-md-6">
                <input v-model="newBlacklist.email2" type="email" placeholder="Email 2" class="custom-input">
              </div>
            </div>
            <div class="button-group">
              <button class="btn outline btn-cancel" @click="showModal = false">Cancel</button>
              <button class="btn solid btn-save" type="button" @click="addBlacklist">Save</button>
            </div>
          </div>
        </b-modal>

        <b-modal v-model="notificationModal" title="Added to Blacklist" hide-footer>
          <div class="form-container">
            <div class="row notification-text">
              Your information has been saved to the blacklist.
            </div>
            <div class="button-group">
              <button class="btn-save" type="button" @click="notificationModal = false">OK</button>
            </div>
          </div>
        </b-modal>
      </div>
    </UIBox>
  </div>
</template>

<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import UIBox from '../components/uibox.vue';
import AuthService from '../_services/AuthService.js';
import PaginleftdoubleSVG from '../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../components/SVG-icons/paginrightSVG.vue';

export default {
  components: {
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    menutoogle: false,
  },

  data() {
    return {
      role: '',
      userId: '',
      rowsPerPage: 50,
      currentPage: 1,
      searchQuery: '',
      blacklist: [],
      showModal: false,
      newBlacklist: {
        label: '',
        phone: '',
        phone2: '',
        email: '',
        email2: '',
      },
      notificationModal: false
    };
  },
  computed: {},
  methods: {
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
    async fetchBlacklist() {
      let target = 'https://123avatars.com/v2/blacklist';
      target += `?rowsPerPage=${this.rowsPerPage}&currentPage=${this.currentPage}&searchQuery=${this.searchQuery}`;
      const response = await axios.get(target);
      this.blacklist = response.data.data;
      this.totalRows = response.data.totalRows;
    },
    clearInputs() {
      this.newBlacklist = {
        label: '',
        phone: '',
        phone2: '',
        email: '',
        email2: '',
      }
    },
    async addBlacklist() {
      let target = 'https://123avatars.com/v2/blacklist';
      const userId = localStorage.getItem('userId');
      await axios.post(target, {
        userId,
        label: this.newBlacklist.label,
        phone: this.newBlacklist.phone,
        phone2: this.newBlacklist.phone2,
        email: this.newBlacklist.email,
        email2: this.newBlacklist.email2
      });
      this.notificationModal = true;
      this.showModal = false;
      this.clearInputs();
    },
    handlePagination() {
      this.fetchBlacklist();
    },
    formatPhoneNumber(phoneNumber) {
      if (phoneNumber && phoneNumber.trim().length > 0) {
        phoneNumber = phoneNumber.toString();
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
        }
        const areaCode = phoneNumber.slice(0, 3);
        const centralOfficeCode = phoneNumber.slice(3, 6);
        const stationCode = phoneNumber.slice(6, 10);
        const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        return formattedPhoneNumber;
      }
      return '';
    },
    formatDate(unformattedDate) {
      return DateTime.fromISO(unformattedDate).toFormat('M/d/yy h:mm a').toLowerCase();
    },
  },
  async mounted() {
    await this.initUser();
    //this.fetchBlacklist();
  },
};
</script>
<style scoped>
#blacklist-search {
  width: 100%;
  padding: 0 16px;
}

#reportContainer .table {
  width: 100%;
  table-layout: fixed;
}

#reportContainer th,
#reportContainer td {
  text-align: left;
}

#reportContainer h3 {
  background-color: #B6E7C3;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.search-box, .search-boxadmins, .search-boxmanagers, .search-boxoperators {
  position: relative;
  width: 390px;
}

.search-box input,  .search-boxadmins input, .search-boxmanagers input, .search-boxoperators input {
  width: 100%;
  padding: 8px 40px 8px 16px;
  border-radius: 8px;
}

.search-box input {
  border: 1px solid #46A57C !important;
}

.search-boxadmins input {
  border: 1px solid #A73A18 !important;
}

.search-boxmanagers input {
  border: 1px solid #2653C7 !important;
}

.search-boxoperators input {
  border: 1px solid #46A57C !important;
}

.search-box {
  border-color: #46A57C !important;
}

.search-boxadmins {
  border-color: #A73A18 !important;
}

.search-boxmanagers {
  border-color: #2653C7 !important;
}

.search-boxoperators {
  border-color: #46A57C !important;
}

.search-box input:focus {
  outline: none;
  border: 1px solid #46A57C !important;
}

.search-boxmanagers input:focus {
  outline: none;
  border: 1px solid #2653C7 !important;
}

.search-boxoperators input:focus {
  outline: none;
  border: 1px solid #46A57C !important;
}

.search-boxadmins input:focus {
  outline: none;
  border: 1px solid #A73A18 !important;
}

.manual-entry {
  margin-left: auto;
}

.search-icon, .search-iconadmins, .search-iconmanagers, .search-iconoperators {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  border-style: none;
}

.search-icon {
  background-color: #46A57C !important;
  border-color: #46A57C !important;
}

.search-iconadmins {
  background-color: #A73A18 !important;
  border-color: #A73A18 !important;
}

.search-iconmanagers {
  background-color: #2653C7 !important;
  border-color: #2653C7 !important;
}

.search-iconoperators {
  background-color: #46A57C !important;
  border-color: #46A57C !important;
}

.manual-entry {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.custom-input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.input-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

}

.row {
  margin-bottom: 1rem;
}

.phone-row {
  display: flex;
  gap: 1rem;
}

.phone-row .custom-input {
  width: 50%;
}

.form-container {
  width: 100%;
}

.notification-text {
  text-align: center;
  font-size: 1.5em;
  padding: 10px;
}
</style>