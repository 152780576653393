<template>
  <div class="fixed inset-0 bg-light overflow-y-auto h-full w-full d-flex align-items-center justify-content-center">
    <div class="relative p-5 border w-100 md:w-75 lg:w-50 xl:w-66 shadow-lg rounded bg-white">
      <button @click="closeModal" class="absolute top-0 right-0 mt-2 mr-2 text-dark bg-transparent border-none cursor-pointer">
        &times;
      </button>
      <div class="mt-3 text-center">
        <h3 class="text-lg font-medium text-dark">
          ROI Calculation Prompt
        </h3>
        <div class="mt-2 p-3">
          <textarea
            v-model="localPrompt"
            placeholder="Enter your prompt"
            class="mt-2 mb-4 w-100 p-2 bg-white border rounded shadow-sm focus-outline-none h-64 md:h-80 lg:h-96 xl:h-112 overflow-y-auto"
            style="resize: vertical;"
          ></textarea>
        </div>
        <div class="px-4 py-3">
          <button
            @click="saveData"
            :class="{'btn btn-primary w-100': true, 'animate-pulse': isSaving}"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initialPrompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localPrompt: this.initialPrompt,
      isSaving: false
    };
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    async fetchData() {
      try {
        const response = await axios.get('/api/prompt');
        this.localPrompt = response.data.prompt;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$toasted.show("Error fetching data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      }
    },
    async saveData() {
      this.isSaving = true;
      try {
        let data = { prompt: this.localPrompt };
        let response = await axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
          headers: {
            'Origin': 'http://localhost:8080',
            'Content-Type': 'application/json'
          }
        });
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 5000,
        });
        this.$emit('update:initialPrompt', this.localPrompt);
        this.$emit('close-modal');
      } catch (error) {
        console.error('Error saving data:', error);
        this.$toasted.show("Error saving data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      } finally {
        this.isSaving = false;
      }
    }
  },
  mounted() {
    // this.fetchData();
  }
};
</script>

<style>
.animate-pulse {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>