<template>
    <svg viewBox="0 0 21 20" class="penSVG">
      <path d="M12.28 3.98044L16.823 8.30968C17.0599 8.53538 17.0599 8.76107 16.823 8.98677L5.77775 19.5124L1.1271 19.9843C0.933327 20.0253 0.73955 19.9843 0.545773 19.8817C0.351996 19.7791 0.222811 19.656 0.115157 19.4713C0.00750339 19.2867 -0.014028 19.102 0.00750272 18.9174L0.502711 14.4855L11.5695 3.98044C11.8064 3.75475 12.0432 3.75475 12.28 3.98044ZM20.4402 2.87248C20.8062 3.26232 21 3.71371 21 4.22665C21 4.7396 20.8062 5.19099 20.4402 5.58082L18.6316 7.26328C18.3948 7.48897 18.1579 7.48897 17.9211 7.26328L13.3781 2.93404C13.1413 2.70834 13.1413 2.48265 13.3781 2.25695L15.1867 0.574497C15.5527 0.18466 16.0049 0 16.5431 0C17.0814 0 17.5551 0.18466 17.9642 0.574497L20.4402 2.87248Z"/>
    </svg>
</template>

<script>
export default {
   name: 'penSVG',
}
</script>