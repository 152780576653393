import {
  CognitoUserPool,
  CognitoUser,
} from "amazon-cognito-identity-js";

// Configuration for your Cognito User Pool
const poolData = {
  UserPoolId: "us-west-1_Xb5sM57hy", // Replace with your actual user pool id
  ClientId: "459g3alqohqvrvrln2ccla7r0v", // Replace with your actual client id
};

// Initialize the Cognito User Pool
const userPool = new CognitoUserPool(poolData);

class CognitoService {
  getCognitoSub() {
    return new Promise((resolve, reject) => {
      const overrideState = JSON.parse(localStorage.getItem("overrideState"));
      if (overrideState && overrideState.userId) {
        return resolve(overrideState.userId);
      }

      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error("Error retrieving session:", err);
            return reject(null);
          }

          if (session && session.isValid()) {
            const payload = session.getIdToken().payload;
            resolve(payload.sub || null);
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  getUserRole() {
    return new Promise((resolve, reject) => {
      const overrideState = JSON.parse(localStorage.getItem("overrideState"));
      if (overrideState && overrideState.role) {
        return resolve(overrideState.role);
      }

      const cognitoUser = userPool.getCurrentUser();
      
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error("Error retrieving session:", err);
            return reject(null);
          }

          if (session && session.isValid()) {
            const payload = session.getIdToken().payload;
            const groups = payload["cognito:groups"];
            if (!groups || groups.length === 0) {
              resolve(null); // No groups found
            } else {
              resolve(groups[0]); // Resolve the first group as the role
            }
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("overrideState");

      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.signOut();
        console.log("User signed out successfully");
        resolve(true);
      } else {
        console.error("No user found to sign out");
        resolve(false);
      }
    });
  }

  /**
   * Set override state for "login as another user" functionality.
   * @param {string} userId - The ID of the user to override with.
   * @param {string} role - The role of the user to override with.
   */
  setOverrideState(userId, role) {
    const overrideState = {
      userId,
      role,
    };
    localStorage.setItem("overrideState", JSON.stringify(overrideState));
    localStorage.removeItem('currentView');
    console.log("Override state set:", overrideState);

    // Reload the page to apply the new user view
    window.location.reload();
  }

  /**
   * Clear override state to return to the original admin view.
   */
  clearOverrideState() {
    localStorage.removeItem("overrideState");
    console.log("Override state cleared");
    localStorage.removeItem('currentView');
    // Reload the page to return to the admin view
    window.location.reload();
  }

  /**
   * Check if the user state is currently overridden.
   * @returns {boolean} - True if overridden, false otherwise.
   */
  isOverridden() {
    const overrideState = JSON.parse(localStorage.getItem("overrideState"));
    return !!(overrideState && overrideState.userId && overrideState.role);
  }
}

export default new CognitoService();