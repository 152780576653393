<template>
	<svg viewBox="0 0 12 12" class="tooltipSVG">
		 <circle cx="6" cy="6" r="5.5"/>
		 <path d="M5.48295 8.99993V4.6363H6.69318V8.99993H5.48295ZM6.09091 4.0738C5.91098 4.0738 5.75663 4.01414 5.62784 3.89482C5.50095 3.77361 5.4375 3.62872 5.4375 3.46016C5.4375 3.29349 5.50095 3.1505 5.62784 3.03118C5.75663 2.90997 5.91098 2.84937 6.09091 2.84937C6.27083 2.84937 6.42424 2.90997 6.55114 3.03118C6.67992 3.1505 6.74432 3.29349 6.74432 3.46016C6.74432 3.62872 6.67992 3.77361 6.55114 3.89482C6.42424 4.01414 6.27083 4.0738 6.09091 4.0738Z"/>
	   </svg>
</template>

<script>
export default {
   name: 'tooltipSVG',
}
</script>