<template>
	<svg viewBox="0 0 16 16" class="extlinkSVG">
		 <path d="M13.6498 9.375c.1375 0 .25.05.3375.1375.0875.0875.1375.2.1375.3375v3.75c0 .3875-.1375.725-.4125 1-.275.275-.6.4125-1 .4125H2.3998c-.3875 0-.725-.1375-1-.4125-.275-.275-.4125-.6-.4125-1V3.275c0-.3875.1375-.725.4125-1 .275-.275.6-.4125 1-.4125h4.68751c.13749 0 .24999.05.33749.1375.0875.0875.1375.2.1375.3375v.9375c0 .1375-.05.25-.1375.3375-.0875.0875-.2.1375-.33749.1375H2.8748v9.375h9.375V9.85c0-.1375.05-.25.1375-.3375.0875-.0875.2-.1375.3375-.1375h.9375-.0125ZM15.2998 0c.2 0 .3625.0625.5.2s.2.3.2.5v3.75c0 .3125-.15.525-.4375.65s-.55.0625-.7625-.15l-1.05-1.05-7.125 7.15c-.13749.1375-.3.2-.5.2s-.3625-.0625-.5-.2l-.675-.675c-.1375-.1375-.2-.3-.2-.5s.0625-.3625.2-.5l7.15-7.125-1.05-1.05c-.2125-.2125-.2625-.475-.15-.7625.1125-.2875.3375-.4375.65-.4375h3.75Z"/>
	   </svg>
</template>

<script>
export default {
   name: 'extlinkSVG',
}
</script>