<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="card shadow-lg">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="text-center w-100">The Problem & The Solution</h3>
        <button @click="$emit('close-modal')" class="btn btn-light" aria-label="Close" style="font-size: 1.5rem; line-height: 1;">&times;</button>
      </div>
      <div class="card-body">
        <div v-if="alertMessage" :class="`alert ${alertType}`" role="alert">
          {{ alertMessage }}
        </div>
        <div class="mb-3">
          <label for="prompt" class="form-label">Prompt</label>
          <textarea id="prompt" v-model="localPrompt" class="form-control" rows="4" placeholder="Prompt"></textarea>
        </div>
        <div class="mb-3">
          <label for="problem" class="form-label">The Problem</label>
          <textarea id="problem" v-model="localProblem" class="form-control" rows="4" placeholder="The Problem"></textarea>
        </div>
        <div class="mb-3">
          <label for="solution" class="form-label">The Solution</label>
          <textarea id="solution" v-model="localSolution" class="form-control" rows="4" placeholder="The Solution"></textarea>
        </div>
        <div class="mb-3">
          <label for="questions" class="form-label">Questions to Find Their Problem</label>
          <textarea id="questions" v-model="localQuestions" class="form-control" rows="4" placeholder="Questions to Find Their Problem"></textarea>
        </div>
      </div>
      <div class="card-footer">
        <button @click="saveData" class="btn btn-primary w-100">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    problem: {
      type: String,
      default: ''
    },
    solution: {
      type: String,
      default: ''
    },
    questions: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localProblem: this.problem,
      localSolution: this.solution,
      localQuestions: this.questions,
      localPrompt: this.prompt,
      alertMessage: '',
      alertType: ''
    };
  },
  watch: {
    problem(newVal) {
      this.localProblem = newVal;
    },
    solution(newVal) {
      this.localSolution = newVal;
    },
    questions(newVal) {
      this.localQuestions = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  },
  methods: {
    saveData() {
      const data = {
        promptType: "Script-Problem_Solution",
        problem: this.localProblem,
        solution: this.localSolution,
        questions: this.localQuestions,
        prompt: this.localPrompt
      };

      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Origin': 'http://localhost:8080',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.alertMessage = 'Data saved successfully!';
          this.alertType = 'alert-success';
          console.log('Data saved:', response.data);
          setTimeout(() => {
            this.$emit('close-modal');
          }, 3000);
        })
        .catch(error => {
          this.alertMessage = 'Error saving data.';
          this.alertType = 'alert-danger';
          console.error('Error saving data:', error);
        });
    }
  }
};
</script>

<style scoped>
.vh-100 {
  height: 100vh;
}
textarea {
  max-height: 200px;
  overflow-y: auto;
}
</style>