<template>
  <div class="modal fade show d-block" tabindex="-1" style="background: rgba(0,0,0,0.5);" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button type="button" class="close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea
            placeholder="Enter your prompt"
            class="form-control mb-2"
            v-model="prompt"
            rows="5"
          ></textarea>
          <textarea
            placeholder="The Closing Script"
            class="form-control"
            v-model="closingScript"
            rows="5"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button
            @click="saveData"
            :class="{ 'pulse-animation': isSaving }"
            class="btn btn-primary w-100"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    modalTitle: {
      type: String,
      default: 'The Closing Script'
    },
    initialClosingScript: {
      type: String,
      default: ''
    },
    initialPrompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      closingScript: this.initialClosingScript,
      prompt: this.initialPrompt,
      isSaving: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    saveData() {
      if (!this.prompt || !this.closingScript) {
        this.$toasted.show("Please fill in the details", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
        return;
      }

      let data = {
        promptType: "Script-Close",
        script: this.closingScript,
        prompt: this.prompt,
      };

      this.isSaving = true;
      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.isSaving = false;
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 5000,
        });
        console.log('Data saved successfully:', response);
        this.closeModal();
      })
      .catch(error => {
        this.isSaving = false;
        this.$toasted.show("Error saving data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "error",
          duration: 5000,
        });
        console.error('Error saving data:', error);
      });
    },
  }
};
</script>

<style scoped>
.pulse-animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>