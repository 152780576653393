<template>
	<svg viewBox="0 0 21 22" class="pauseSVG">
		<path d="M8.84454 0H0.703613V22H8.84454V0Z" />
		<path d="M20.2964 0H12.1555V22H20.2964V0Z" />
	</svg>
</template>

<script>
export default {
   name: 'pauseSVG',
}
</script>