<template>
    <div style="width:90%; margin-left:10%;">
      <div class="dashboard d-flex justify-content-between text-center p-3 mb-4 bg-white shadow-sm rounded">
        <div>Total Active Caller IDs: <a href="#" @click="showTotalModal('active')"><span>{{ activeCallerIds }}</span></a></div>
        <div>Total No Outbound Caller IDs: <a href="#" @click="showTotalModal('noOutbound')"><span>{{ noOutboundCallerIds }}</span></a></div>
        <div>Total Caller IDs: <a href="#" @click="showTotalModal('total')"><span>{{ totalCallerIds }}</span></a></div>
      </div>
  
      <div class="table-container p-3 mb-4 bg-white shadow-sm rounded">
        <div class="table-title text-center text-success mb-3">Area Codes and Caller ID's</div>
        <div class="add-caller-id row mb-3 justify-content-center">
          <div class="col-auto">
            <label for="areaCodeSelect" class="form-label">Select Area Code:</label>
            <select id="areaCodeSelect" class="form-select" v-model="selectedAreaCode">
              <option v-for="code in allAreaCodes" :key="code.code" :value="code.code">{{ code.code }} - {{ code.state }}</option>
            </select>
          </div>
          <div class="col-auto">
            <label for="callerIdInput" class="form-label">Caller ID:</label>
            <input type="text" id="callerIdInput" class="form-control" v-model="callerIdInput" placeholder="Enter Caller ID (e.g., 546-8787)">
          </div>
          <div class="col-auto align-self-end">
            <button class="btn btn-success ms-2" @click="addCallerId">Add Caller ID</button>
          </div>
        </div>
        <div class="add-backup-id row mb-3 justify-content-center">
          <div class="col-auto">
            <label for="backupCallerIdInput" class="form-label">Backup Caller ID:</label>
            <input type="text" id="backupCallerIdInput" class="form-control" v-model="backupCallerId" placeholder="Enter Backup Caller ID (e.g., (949) 444-5999)">
          </div>
          <div class="col-auto align-self-end">
            <button class="btn btn-success ms-2" @click="saveBackupCallerId">Save Backup Caller ID</button>
          </div>
        </div>
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>State</th>
      <th>Area Code</th>
      <th>Caller ID</th>
      <th>Age (Days)</th>
      <th>Date of Last Call</th>
      <th>Calls</th>
      <th>Inbounds</th>
      <th>Outbounds</th>
      <th>Current Status</th>
      <th>Actions</th>
      <th>Connected to Leads</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="(area, areaCode) in areaCodes">
      <tr :key="areaCode + '-no-caller-id'" v-if="area.callerIds.length === 0">
        <td>{{ area.state }}</td>
        <td>{{ areaCode }}</td>
        <td>No Caller ID</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ area.connectedLeads }}</td>
      </tr>
      <tr v-for="callerId in area.callerIds" :key="callerId.callerId">
        <td>{{ area.state }}</td>
        <td>{{ areaCode }}</td>
        <td>{{ formatCallerId(callerId.callerId, areaCode) }}</td>
        <td>{{ getAgeInDays(callerId.datePurchased) }}</td>
        <td>{{ formatDate(callerId.dateLastCall) }}</td>
        <td>{{ callerId.calls }}</td>
        <td>{{ callerId.inbounds }}</td>
        <td>{{ callerId.outbounds }}</td>
        <td>
          <select class="form-select" v-model="callerId.status" @change="updateCallerIdStatus(areaCode, callerId.callerId, callerId.status)">
            <option value="Active">Active</option>
            <option value="No Outbound">No Outbound</option>
          </select>
        </td>
        <td>
          <span class="delete-icon text-danger" @click="archiveCallerId(areaCode, callerId.callerId)">&times;</span>
          <button class="btn btn-success" @click="showSpamLog(callerId.callerId)">Spam Log</button>
        </td>
        <td>{{ area.connectedLeads }}</td>
      </tr>
    </template>
  </tbody>
</table>
      </div>
  
      <div class="table-container p-3 mb-4 bg-white shadow-sm rounded">
        <div class="table-title text-center text-success mb-3">Archived Caller ID's</div>
        <div class="search-bar text-center mb-3">
          <input type="text" id="archivedSearchInput" class="form-control w-75 d-inline-block" v-model="archivedSearchInput" placeholder="Search archived caller IDs..." @input="searchArchivedCallerIds">
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>State</th>
              <th>Area Code</th>
              <th>Caller ID</th>
              <th>Age (Days)</th>
              <th>Date of Last Call</th>
              <th>Calls</th>
              <th>Inbounds</th>
              <th>Outbounds</th>
              <th>Current Status</th>
              <th>Archive Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="callerId in filteredArchivedCallerIds" :key="callerId.callerId">
              <td>{{ callerId.state }}</td>
              <td>{{ callerId.areaCode }}</td>
              <td>{{ formatCallerId(callerId.callerId, callerId.areaCode) }}</td>
              <td>{{ getAgeInDays(callerId.datePurchased) }}</td>
              <td>{{ formatDate(callerId.dateLastCall) }}</td>
              <td>{{ callerId.calls }}</td>
              <td>{{ callerId.inbounds }}</td>
              <td>{{ callerId.outbounds }}</td>
              <td>{{ callerId.status }}</td>
              <td>{{ callerId.archiveDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="modal fade" id="spamModal" tabindex="-1" aria-labelledby="spamModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="spamModalLabel">Spam Log</h5>
              <button type="button" class="btn-close" @click="closeSpamModal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in spamLogs" :key="log.date + log.time">
                    <td>{{ formatDate(log.date) }}</td>
                    <td>{{ log.time }}</td>
                    <td>{{ log.details }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeSpamModal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        allAreaCodes: [],
        areaCodes: {},
        archivedCallerIds: [],
        backupCallerId: '',
        selectedAreaCode: '',
        callerIdInput: '',
        backupCallerIdInput: '',
        archivedSearchInput: '',
        spamLogs: [],
        activeCallerIds: 0,
        noOutboundCallerIds: 0,
        totalCallerIds: 0
      };
    },
    computed: {
      filteredArchivedCallerIds() {
        return this.archivedCallerIds.filter(callerId => {
          return (
            callerId.callerId.toLowerCase().includes(this.archivedSearchInput.toLowerCase()) ||
            callerId.state.toLowerCase().includes(this.archivedSearchInput.toLowerCase()) ||
            callerId.areaCode.toLowerCase().includes(this.archivedSearchInput.toLowerCase())
          );
        });
      }
    },
    methods: {
    searchArchivedCallerIds() {

    },
      formatDate(date) {
        let result = '-';
        if(date) {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            result = `${month}-${day}-${year}`;
        }
        return result;
      },
      getAgeInDays(date) {
        const currentDate = new Date();
        const pastDate = new Date(date);
        const diffInTime = currentDate.getTime() - pastDate.getTime();
        return Math.floor(diffInTime / (1000 * 3600 * 24));
      },
      formatCallerId(callerId, areaCode) {
        if(areaCode == 1) {
            areaCode = '949';
        }
        return `(${areaCode}) ${callerId.substring(3, 6)}-${callerId.substring(6)}`;
      },
      getStateByAreaCode(areaCode) {
        const areaCodeObj = this.allAreaCodes.find(code => code.code === areaCode);
        return areaCodeObj ? areaCodeObj.state : '';
      },
      updateDashboard() {
        let totalActive = 0;
        let totalNoOutbound = 0;
        let totalCallerIds = 0;
  
        Object.keys(this.areaCodes).forEach(areaCode => {
          const callerIds = this.areaCodes[areaCode].callerIds;
          let activeCount = 0;
          let noOutboundCount = 0;
  
          callerIds.forEach(callerId => {
            if (callerId.status === 'Active') {
              activeCount++;
            } else if (callerId.status === 'No Outbound') {
              noOutboundCount++;
            }
          });
  
          totalActive += activeCount;
          totalNoOutbound += noOutboundCount;
          totalCallerIds += callerIds.length;
        });
  
        this.activeCallerIds = totalActive;
        this.noOutboundCallerIds = totalNoOutbound;
        this.totalCallerIds = totalCallerIds;
      },
      populateTable() {
        fetch('https://123avatars.com/v2/caller-ids')
          .then(response => response.json())
          .then(data => {
            this.areaCodes = data.reduce((acc, callerId) => {
              const areaCode = callerId.areaCode;
              if(areaCode == 1) {
                this.backupCallerId = callerId.callerId;
              }
              if (!acc[areaCode]) {
                acc[areaCode] = { state: this.getStateByAreaCode(areaCode), callerIds: [], connectedLeads: 0 };
              }
              acc[areaCode].callerIds.push(callerId);
              return acc;
            }, {});
            this.updateDashboard();
          });
      },
      populateArchivedTable() {
        fetch('https://123avatars.com/v2/archived-caller-ids')
          .then(response => response.json())
          .then(data => {
            this.archivedCallerIds = data.map(callerId => ({
              ...callerId,
              ageInDays: this.getAgeInDays(callerId.datePurchased),
              dateLastCallFormatted: this.formatDate(callerId.dateLastCall)
            }));
          });
      },
      addCallerId() {
        if (!this.callerIdInput || !/^\d{3}-\d{4}$/.test(this.callerIdInput)) {
          alert('Please enter a valid Caller ID in the format 546-8787.');
          return;
        }
  
        const callerId = this.callerIdInput.replace('-', '');
        const isDuplicate = Object.keys(this.areaCodes).some(ac =>
          this.areaCodes[ac].callerIds.some(id => id.callerId === callerId)
        );
  
        if (isDuplicate) {
          alert('This Caller ID already exists.');
          return;
        }
  
        const currentDate = new Date();
        const newCallerId = {
          areaCode: this.selectedAreaCode,
          callerId,
          datePurchased: currentDate,
          dateLastCall: currentDate,
          calls: 0,
          inbounds: 0,
          outbounds: 0,
          status: 'Active'
        };
  
        fetch('https://123avatars.com/v2/caller-ids', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newCallerId)
        })
          .then(response => response.json())
          .then(data => {
            newCallerId.id = data.id;
            this.areaCodes[this.selectedAreaCode].callerIds.push(newCallerId);
            this.callerIdInput = ''; // Clear the input field
            this.populateTable();
            this.updateDashboard();
          });
      },
      saveBackupCallerId() {
        if (!this.backupCallerIdInput || !/^\(\d{3}\) \d{3}-\d{4}$/.test(this.backupCallerIdInput)) {
          alert('Please enter a valid Backup Caller ID in the format (949) 444-5999.');
          return;
        }
  
        this.backupCallerId = this.backupCallerIdInput;
      },
      archiveCallerId(areaCode, callerId) {
        const callerIds = this.areaCodes[areaCode].callerIds;
        const index = callerIds.findIndex(id => id.callerId === callerId);
        if (index > -1) {
          const archivedCallerId = {
            ...callerIds[index],
            state: this.areaCodes[areaCode].state,
            areaCode,
            archiveDate: this.formatDate(new Date())
          };
  
          fetch('https://123avatars.com/v2/archived-caller-ids', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(archivedCallerId)
          })
            .then(response => response.json())
            .then(data => {
              archivedCallerId.id = data.id;
              this.archivedCallerIds.push(archivedCallerId);
              callerIds.splice(index, 1);
              this.populateTable();
              this.populateArchivedTable();
              this.updateDashboard();
            });
        }
      },
      updateCallerIdStatus(areaCode, callerId, status) {
        const callerIds = this.areaCodes[areaCode].callerIds;
        const index = callerIds.findIndex(id => id.callerId === callerId);
        if (index > -1) {
        // Update the status locally
        callerIds[index].status = status;
        this.updateDashboard();

        // Send a PUT request to update the status and archive the caller ID on the backend
        fetch('https://123avatars.com/v2/caller-ids/status', {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ areaCode, callerId, status })
        })
        .then(response => response.json())
        .then(data => {
            console.log('Status updated and caller ID archived successfully:', data);
        })
        .catch(error => {
            console.error('Error updating status and archiving caller ID:', error);
        });
        }
    },
      showSpamLog(callerId) {
        const spamLogs = {
          '2011234567': [
            { date: '2023-01-01', time: '10:00 AM', details: 'Spam call reported' },
            { date: '2023-02-15', time: '11:30 AM', details: 'Multiple spam reports' },
            { date: '2023-03-20', time: '02:45 PM', details: 'User blocked the number' }
          ],
          '2021234567': [
            { date: '2023-01-05', time: '09:00 AM', details: 'Spam call reported' },
            { date: '2023-04-10', time: '12:00 PM', details: 'Spam call reported' }
          ],
          '2031234567': [
            { date: '2023-03-10', time: '08:30 AM', details: 'User blocked the number' }
          ],
          '2051234567': [
            { date: '2023-01-20', time: '01:15 PM', details: 'Spam call reported' },
            { date: '2023-02-25', time: '03:20 PM', details: 'Multiple spam reports' }
          ],
          '2061234567': [
            { date: '2023-02-10', time: '04:50 PM', details: 'Spam call reported' },
            { date: '2023-05-15', time: '11:00 AM', details: 'User blocked the number' }
          ]
        };
  
        this.spamLogs = spamLogs[callerId] || [];
        const modal = new bootstrap.Modal(document.getElementById('spamModal'));
        modal.show();
      },
      closeSpamModal() {
        const modal = bootstrap.Modal.getInstance(document.getElementById('spamModal'));
        modal.hide();
      },
      populateAreaCodeSelect() {
        fetch('https://123avatars.com/v2/area-codes')
          .then(response => response.json())
          .then(data => {
            this.allAreaCodes = data;
            this.areaCodes = data.reduce((acc, code) => {
              acc[code.code] = { state: code.state, callerIds: [], connectedLeads: 0 };
              return acc;
            }, {});
          });
      }
    },
    mounted() {
      this.populateAreaCodeSelect();
      this.populateTable();
      this.populateArchivedTable();
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Arial', sans-serif;
    background-color: #e0f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .dashboard {
    width: 90%;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .table-container {
    width: 90%;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .table-title {
    font-size: 24px;
    color: #004d40;
    margin-bottom: 10px;
  }
  
  .delete-icon {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }
  
  .search-bar input {
    width: 80%;
  }
  
  .modal-content {
    border-radius: 25px;
    text-align: center;
  }
  </style>