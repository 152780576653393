<template>

  <div id="leadfarm" class="leadfarm-style" :data-role="role ? role.toLowerCase() : ''">

    <!-- Manager Chat -->
    <div id="manager-chat">
      <b-collapse id="managerchat" class="messanger-pop" ref="chatRef">
        <b-card group>
          <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span>
          <Chat :type="role" :salesRepId="currentSalesRepId" :managerId="managerId" :headerTitle="`Chat with ${chatName}`" @new_message="handleMessageNotification" v-if="chatVisible" @close-modal="closeChat" @call-manager="callManager" />
        </b-card>
      </b-collapse>
      <div class="messanger-icon" align-self="center">
        <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span>
        <a v-b-toggle id="chat-link" class="align-items-center" href="#managerchat" @click=openChat() v-if="role === null || role === 'Operators'">
          <chatSVG :size="40"/>
        </a>
        <a v-b-toggle id="chat-link" class="align-items-center" href="#managerchat" v-else>
          <chatSVG />
        </a>
      </div>
    </div>

    <!-- <b-collapse id="chat-collapse" class="messanger-pop" v-if="currentSalesRepId">
    <b-card group>1
      <Chat :type="'REP'" :salesRepId="currentSalesRepId" :managerId="managerId" :headerTitle="`Chat with ${managerName}`" @new_message="handleMessageNotification" />
    </b-card>
    </b-collapse> -->

    <Notifications v-if="showNotification" :notifications="notifications" 
      @answer-call="answerCall"
      @deny-call="denyCall" />

    <!-- Callbacks Form -->
    <Callbacks :type="'NEW'" :prospect="company" :userId="userId" @callbacks-closed="handleCallbacksClosed" v-if="showCallbacks" />

    <!-- New Dialer + Header (dummy) -->
    <header id="dialer">
      <div class="logo">
        <div class="logo-inner">
          <img src="../../assets/images/logo.svg">
        </div>
      </div>

      <!-- UI in middle header v-if="role !== 'Roaming_Closer'" -->
      <div class="dialer-ui">
        <!-- Live Transfers Toggle -->
        <b-button
          type="button"
          id="live-transfer"
          class="btn outline"
          :class="{ 'btn-primary': liveTransfersEnabled, 'btn-outline-secondary': !liveTransfersEnabled }"
          @click="toggleLiveTransfers"
          v-if="role === 'Roaming_Closer' && remainingCredits > pricePerTransfer"
        >
          {{ liveTransfersEnabled ? 'Live Transfers On' : 'Live Transfers Off' }}
        </b-button>

        <button
          id="industry-popover"
          type="button"
          class="btn outline"
          @click="handlePopoverToggle"
          v-if="role !== 'Managers' && role !== 'Recruiters' && role !== 'Recruiter_Managers' && role !== 'Roaming_Closer'"
        >
          {{ dialerText }}
        </button>

        <!-- Dialer Status + controls -->
        <div class="dot-outer" v-if="role !== 'Managers' && role !== 'Recruiters' && role !== 'Recruiter_Managers' && role !== 'Roaming_Closer'">
          <div class="dot-inner off">
            {{ dialerStatus }}
          </div>
        </div>
        <div class="dot-outer" v-if="role == 'Roaming_Closer' && call">
          <div class="dot-inner off">
            {{ dialerStatus }}
          </div>
        </div>
        <div
          class="dialer-timer disabled"
          :class="{ opacity: dialerStatus === 'Connected' }"
          v-if="role !== 'Admins' && role !== 'Managers' && role !== 'Recruiters' && role !== 'Recruiter_Managers' && role !== 'Roaming_Closer'"
        >
          {{ connectedCallTime() }}
        </div>
        <div
          class="dialer-timer disabled"
          :class="{ opacity: dialerStatus === 'Connected' }"
          v-if="role == 'Roaming_Closer' && call"
        >
          {{ connectedCallTime() }}
        </div>

        <!-- Next Call -->
        <button
          type="button"
          class="btn outline icon"
          @click="nextCallButtonRoot"
          :class="{ opacity: dialerStatus === 'Connected' }"
          v-if="role !== 'Admins' && role !== 'Managers' && role !== 'Recruiters' && role !== 'Recruiter_Managers' && role !== 'Roaming_Closer'"
        >
          <nextSVG />
        </button>

        <!-- Mute icon -->
        <b-button
          type="button"
          ref="muteButton"
          class="outline icon mute"
          v-if="role === 'Roaming_Closer' && call"
          @click="toggleMuted()"
        >
          <MuteASVG />
        </b-button>

        <b-button
          type="button"
          ref="closeButton"
          class="outline icon mute"
          v-if="role === 'Roaming_Closer' && call"
          @click="closeTransferNotification"
        >
          <PhoneHangUpSVG />
        </b-button>
      </div>

      <!-- UI on right side of header -->
      <div class="end-ui">
        <div class="time-since" v-if="role !== 'Admins' && role !== 'Managers' && role !== 'Recruiters' && role !== 'Recruiter_Managers' && role !== 'Roaming_Closer'">
          <span class="bold">Time Since Last Call</span>
          <br>{{ formatTime(timeSinceLastCall(lastCallEndTime)) }}
        </div>

        <walletIcon v-if="role === 'Roaming_Closer'" :width="80" :height="65" :badge-count="remainingCredits" />

        <!-- Dial-pad button -->
        <button id="popoverpad" class="btn outline icon">
          <PhoneSVG />
        </button>

        <!-- Missed call button -->
        <!-- <b-button v-b-modal.missedcallModal  class="outline icon">
      <MissedcallSVG />
    </b-button> -->

        <!-- Voicemail button -->
        <button type="button" class="btn outline icon" @click="callIntoVoicemail">
          <voicemailSVG />
          <span class="badge" v-if="voicemails != 0">{{ voicemails }}</span>
        </button>

      </div>
    </header>

    <!-- Missed Call Modal -->
    <b-modal id="missedcallModal" hide-footer size="lg" body-class="modalTable">
      <template #modal-header="{ close }" class="header">
        <div>Missed Calls</div>
        <button class="btn" @click="close()">
          <closeSVG />
        </button>
      </template>
      <b-table :items="missedcalls_items" :fields="missedcalls_fields" class="missedcalls" :per-page="perPageMisssedcalls" :current-page="currentPageMissedcalls" aria-controls="missedcalls">
        <template #cell(newmissedcall)="newmissedcallicon">
          <template v-if="newmissedcallicon.item.newmissedcall === true">
            <div class="new icon"></div>
          </template>
          <template v-else-if="newmissedcallicon.item.newmissedcall === false">
            <div class="icon"></div>
          </template>
        </template>
      </b-table>
      <div class="bottom-input single">
        <template>
          <div class="pagin">
            <b-pagination v-model="currentPageMissedcalls" :total-rows="rowsMissedcalls" :per-page="perPageMisssedcalls" aria-controls="missedcalls">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
        <div class="current-page">Current Page: {{ currentPageMissedcalls }}</div>
      </div>
    </b-modal>

    <!-- Industry popover -->
    <b-popover ref="industryPopover" target="industry-popover" :triggers="popoverTrigger" placement="bottom" custom-class="industry-popover">
      <b-table hover :items="industries_items" :fields="industries_fields" thead-class="hidden_header">
        <template #cell(industry)="industry">
          <div @click="startDialer(industry.value)">
            {{ industry.value }}
          </div>
        </template>
        <template #cell(leads)="leads">
          {{ leads.value }} Leads
        </template>
      </b-table>
    </b-popover>

    <!-- Callback Popover target="nextcall-popover" -->
    <b-popover v-if="nextCallPopover" triggers="click" placement="bottom" custom-class="nextcall-popover">
      <button type="button" class="btn solid" @click="scheduleCallback">Schedule Callback</button>
      <button type="button" class="btn solid" @click="nextCallButton">Next Call</button>
    </b-popover>

    <!-- Live Transfer Notification -->
    <b-popover ref="liveTransfer" target="live-transfer" placement="bottom" trigger="manual" custom-class="incoming-popover">
      <div class="content bg-light rounded d-flex flex-column justify-content-between align-items-center" style="position: relative;">
        <div class="header text-center py-2 w-100">
          <h5 class="title mb-0" id="dialer_modal_label">Incoming Transfer</h5>
        </div>

        <div class="d-flex flex-column w-100 p-4">
          <div class="d-flex flex-column text-center popover-content mb-4">
            <h2 class="m-0">#{{ liveTransfer?.count }}</h2>
            <span>of the day</span>
          </div>

          <div class="d-flex popover-btns">
            <button class="btn answer-btn" @click="answerTransfer">
              <CheckSVG :size="20" fill="#fff" />
            </button>

            <button 
              class="btn btn-danger"
              @click="closeTransferPopUp()">
              <CloseSVG :size="20" fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    </b-popover>


    <!-- Number Pad Popover -->
    <b-popover ref="popoverPad" target="popoverpad" triggers="click" placement="bottom" custom-class="dialer-popover">

      <div class="content">
        <div class="header">
          <div class="header-left">
            <h3 class="title" id="dialer_modal_label">Dialer</h3>
            <!-- if number matches existing entry, show launch in dialer button -->
            <button class="btn solid icon" @click="fetchLeadIdAndLoad" v-if="phoneNumber.length >= 10">Load Profile</button>
          </div>
            <button class="btn solid icon" @click="closeDialPad()">
              <CloseSVG />
            </button>
        </div>
        <div class="body">
          <table id="dialer_table">
            <tr>
              <td id="dialer_input_td" colspan="3"><input type="text" placeholder="3212345678" class="pad noSelect" v-model="phoneNumber"></td>
            </tr>
            <tr class="dialer_num_tr">
              <td class="dialer_num" @click="dialerClick('dial', 1)">1</td>
              <td class="dialer_num" @click="dialerClick('dial', 2)">2</td>
              <td class="dialer_num" @click="dialerClick('dial', 3)">3</td>
            </tr>
            <tr class="dialer_num_tr">
              <td class="dialer_num" @click="dialerClick('dial', 4)">4</td>
              <td class="dialer_num" @click="dialerClick('dial', 5)">5</td>
              <td class="dialer_num" @click="dialerClick('dial', 6)">6</td>
            </tr>
            <tr class="dialer_num_tr">
              <td class="dialer_num" @click="dialerClick('dial', 7)">7</td>
              <td class="dialer_num" @click="dialerClick('dial', 8)">8</td>
              <td class="dialer_num" @click="dialerClick('dial', 9)">9</td>
            </tr>
            <tr class="dialer_num_tr">
              <td class="dialer_num" @click="dialerClick('dial', 0)">#</td>
              <td class="dialer_num" @click="dialerClick('dial', 0)">0</td>
              <td class="dialer_num" @click="dialerClick('dial', 0)">*</td>
            </tr>
            <tr class="dialer_num_tr">
              <td align-self="center" class="dialer_del_td bottom-buttons" id="dialer_clear_btn_td" @click="dialerClick('delete', 'delete')">
                <EraseSVG />
              </td>
              <td class="dialer_call_td bottom-buttons" id="dialer_call_btn_td" @click="makeDirectCall" v-if="!call">
                <PhoneSVG  />
              </td>
              <td class="dialer_call_td bottom-buttons" id="dialer_call_btn_td" @click="hangupCall()" v-if="call">
                <PhoneHangUpSVG />
              </td>
              <td class="dialer_del_td bottom-buttons" id="dialer_del_btn_td" @click="dialerClick('clear', 'clear')">
                <CloseSVG />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-popover>

    <!-- Start of page content -->
    <main id="page-content">

      <!-- Left Column -->
      <div class="left-nav" :class="{hide: leftnav}">

        <div class="nav-toggle" @click="leftnav = !leftnav">
          <div id="nav-icon" :class="{open: !leftnav} ">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="nav-content">
          <div class="info">
            <div class="welcome">
              Welcome<br> {{ userName }}
            </div>
            <div class="number">
              {{ callbackNum }}
            </div>
            <div class="timezone">
              <a href="#" @click.prevent>
                <TimezoneSVG />{{ timeZone }}
                <EditSVG class="mini" />
              </a>
            </div>
            <!--<div class="filterSelect select" v-if="role === 'Admins' && selectedRole != ''">
              <label >Role Override</label>
              <select id="managerFilter" v-model="selectedRole" class="form-control" @change="changeRole">
                <option v-for="role in roles">
                  {{ role }}
                </option>
              </select>
            </div>-->
            <div class="logout" style="margin-top:1%;">
              <a href="#" @click="logout">
                <logoutSVG />Log out
              </a>
            </div>
            <div class="logout" v-if="isUserOverride == true" style="margin-top:1%;">
              <a href="#" @click="logoutUser">
                <logoutSVG />Log Out Of User
              </a>
            </div>
          </div>
          <div class="navitem" v-if="role === 'Admins' || role === 'Managers' || role === 'Operators' || role === 'Roaming_Closer' || role === null">
            <a href="#" class="side-nav" :class="{ active: currentView === 'search' }" @click="updateView('search')" @click.prevent>
              <SearchSVG />Search
            </a>
          </div>
          <div class="navitem" v-if="role === null || role === 'Operators' || role === 'Managers' || role === 'Roaming_Closer'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'dialer' }" @click="updateView('dialer')" @click.prevent>
              <PhoneSVG />Dialer
            </a>
          </div>
          <!-- v-if="role === 'Managers'" -->
          <div class="navitem" v-if="role === 'Managers' || role === 'Admins' || role === 'Recruiter_Managers' || role === 'Recruiters'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'panel' }" @click="updateView('panel')" @click.prevent>
              <ThreeLinesSVG :size="20" />Panel
            </a>
          </div>
          <div class="navitem" v-if="role === 'Managers' || role === 'Recruiters' || role === 'Admins' || role === 'Recruiter_Managers'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'scoreboard' }" @click="updateView('scoreboard')" @click.prevent>
              <ScoreboardSVG :size="20" />Scoreboard
            </a>
          </div>
          <div class="navitem" v-if="role === 'Managers' || role === 'Recruiters' || role === 'Admins' || role === 'Recruiter_Managers'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'users' }" @click="updateView('users')" @click.prevent>
              <UsersSVG :size="20" />Users
            </a>
          </div>
          <div class="sub navitem" v-if="role === 'Admins'">
            <a href="#" :class="{ active: currentView === 'admin-funnels' }" @click="updateView('admin-funnels')" @click.prevent><FunnelsSVG :size="20" />Funnels</a>
          </div>
          <!-- <div class="navitem" v-if="role === 'Admins'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'stats' }" @click="updateView('stats')" @click.prevent>
              Stats
            </a>
          </div> -->
          <div class="navitem" v-if="role === 'Admins'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'schedules' }" @click="updateView('schedules')" @click.prevent>
              <SchedulesSVG :size="20" />Schedules
            </a>
          </div>
          <div class="navitem" v-if="role === 'Admins' || role === 'Managers'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'blacklist' }" @click="updateView('blacklist')" @click.prevent>
              <BlacklistSVG :size="20" />Blacklist
            </a>
          </div>
          <div class="navitem" v-if="role === 'Admins' || role === 'Managers' || role === 'Roaming_Closer'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'inbox' }" @click="updateView('inbox')" @click.prevent>
              <EmailSVG :size="20" />
              <div class="inbox-count-container">
                Inbox
                <span v-show="inboxUnreadCount > 0" class="badge-inbox-count">{{ inboxUnreadCount }}</span>
              </div>
            </a>
          </div>
          <div class="navitem" v-if="role === 'Admins'">
            <a href="#" class="side-nav" :class="{ active: currentView === 'prompt' }" @click="updateView('prompt')" @click.prevent>
              <ThreeGearsSVG :size="20" />Prompts
            </a>
          </div>
          <div class="sub navitem" v-if="role === 'Admins'">
            <a href="#" :class="{ active: currentView === 'admin-operatorQuotaPolicy' }" @click="updateView('admin-operatorQuotaPolicy')" @click.prevent><QuotaSVG :size="20" />Operator Quota Policy</a>
          </div>
          <div class="sub navitem" v-if="role === 'Admins' || role === 'Roaming_Closer'">
            <a href="#" :class="{ active: currentView === 'broadcast' }" @click="updateView('broadcast')" @click.prevent><chatSVG :size="20" />Broadcast</a>
          </div>
          <div class="navitem accordian">
            <a class="align-items-center main" href="#" @click.prevent="toggleAccordion('nav-reports')">
              <NavcaretSVG />
              Reports
            </a>
            <b-collapse v-model="accordion['nav-reports']" id="nav-reports">
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === 'Roaming_Closer'">
                <a href="#" class="side-nav" :class="{ active: currentView === 'pipeline' }" @click="updateView('pipeline')" @click.prevent>
                  Pipeline
                </a>
              </div>
              <div class="sub navitem">
                <a href="#" class="side-nav" :class="{ active: currentView === 'tags' }" @click="updateView('tags')" @click.prevent>
                  Tags
                </a>
              </div>
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === null || role === 'Operators'">
                <a href="#" :class="{ active: currentView === 'reports-TOReports' }" @click="updateView('reports-TOReports')" @click.prevent>TO QC Report</a>
              </div>
              <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'reports-callsreports' }" @click="updateView('reports-callsreports')" @click.prevent>Calls Report</a>
              </div>
              <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'reports-callbacks' }" @click="updateView('reports-callbacks')" @click.prevent>Call Backs</a>
              </div>
              <!-- <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === 'Operators'">
                <a href="#" :class="{ active: currentView === 'operator-leaderboard' }" @click="updateView('operator-leaderboard')" @click.prevent>Operator Leaderboard</a>
              </div> -->
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers'">
                <a href="#" :class="{ active: currentView === 'reports-operatorTOTrackingReports' }" @click="updateView('reports-operatorTOTrackingReports')" @click.prevent>Operator TO Tracking Report</a>
              </div>
              <!-- <div class="sub navitem" v-if="false">
                <a href="#" :class="{ active: currentView === 'reports-NAAOReport' }" @click="updateView('reports-NAAOReport')" @click.prevent>Newley Added Active Operators</a>
              </div> -->
            </b-collapse>
          </div>
          <div class="navitem accordian">
            <a class="align-items-center main" href="#" @click.prevent="toggleAccordion('nav-training')">
              <NavcaretSVG />Training
            </a>
            <b-collapse v-model="accordion['nav-training']" id="nav-training">
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === null || role === 'Operators'">
                <a href="#" :class="{ active: currentView === 'training-dayone' }" @click="updateView('training-dayone')" @click.prevent>Day One Training</a>
              </div>
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === null || role === 'Operators'">
                <a href="#" :class="{ active: currentView === 'training-recorded' }" @click="updateView('training-recorded')" @click.prevent>Recorded Sales Calls</a>
              </div>
              <!-- <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'training-salesvideos' }" @click="updateView('training-salesvideos')" @click.prevent>Sales Video on Youtube</a>
              </div> -->
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers' || role === 'Roaming_Closer'">
                <a href="#" :class="{ active: currentView === 'training-services' }" @click="updateView('training-services')" @click.prevent>Services Training</a>
              </div>
              <div class="sub navitem" v-if="role === 'Admins' || role === 'Managers'">
                <a href="#" :class="{ active: currentView === 'training-test' }" @click="updateView('training-test')" @click.prevent>The Test</a>
              </div>
            </b-collapse>
          </div>
          <div class="navitem accordian" v-if="role !== 'Roaming_Closer'">
            <a class="align-items-center main" href="#" @click.prevent="toggleAccordion('nav-resources')">
              <NavcaretSVG />Resources
            </a>
            <b-collapse v-model="accordion['nav-resources']" id="nav-resources">
              <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'resources-askai' }" @click="updateView('resources-askai')" @click.prevent>Ask our AI</a>
              </div>
              <!-- <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'resources-paperwork' }" @click="updateView('resources-paperwork')" @click.prevent>Paperwork to get Paid</a>
              </div>
              <div class="sub navitem">
                <a href="#" :class="{ active: currentView === 'resources-incomecalc' }" @click="updateView('resources-incomecalc')" @click.prevent>Income Calculator</a>
              </div>
              <div class="sub navitem doubleline">
                <a href="#">
                  <span class="bold">
                    Daily Q&A
                    <ExtlinkSVG />
                  </span>
                  <span class="normal">12 pm PST</span></a>
              </div> -->
            </b-collapse>
          </div>
        </div>
      </div>

      <!-- Middle Column -->
      <div class="middle-content">
        
        <!-- Dailer Page -->
        <div v-if="currentView === 'dialer'" class="page">

          <!-- Button Header -->
          <div class="button-bar">

            <!-- prospect search -->
            <vue-typeahead-bootstrap class="search" inputClass="search-icon" :autoClose="false" v-model="temp" :data="searchResults ? searchResults : []" @input="fetchSuggestions" placeholder="Search for company, prospect, phone, or email..." />

            <div class="group">
              
              <!-- Feel free to call from JS, HTMl just for testing. Slot for error or success. Default slot is the text -->
              <ErrorModal><template v-slot:error> Error Text Goes Here</template></ErrorModal>
              
              <!-- Just for Testing -->
              <!-- <b-button v-b-modal.alert>Show Error Modal</b-button> -->

              
              <button type="button" class="btn solid icon" @click="openSignUpPage">Sign Up Prospect
                <extlinkSVG />
              </button>
              <b-button type v-b-modal.callbackModal class="btn solid" @click="scheduleCallback">Schedule Callback</b-button>
              <button type="button" class="btn solid red" @click="blacklist">Add to DNC</button>
            </div>
          </div>

          <div class="cont-right-bar">
            <div class="right-bar-body">

              <!-- Box 1 Script -->
              <UIBox ref="uiBox" classcontent="nopad">
                <template v-slot:title>
                  <span class="bold">Script</span>
                </template>
                <template #default>

                  <FormulateForm>

                    <!-- Step 1: Ask for Name -->
                    <transitionGroup name="fade">
                      <div v-if="currentStep === 1" class="step step-one" :key="step-one">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Hi, how are you today?</span> <span class="response">(Prospect: Good...)</span></p>
                            <p>My name is {{ currentSalesRepName || '' }} and the reason I am calling is because I’ve been working with local {{ company.industry || '' }} businesses like yours, helping them boost their sales.</p>
                            <p><span class="bold">Do you have the capacity to take on more clients?</span><span class="response">(Prospect: Yes)</span></p>
                            <p>I’m offering free marketing services to qualified businesses.</p>
                            <p><span class="bold">Would you like to see if you qualify?</span> <span class="response">(Prospect: Yes)</span></p>
                            <p><span class="bold">Ok and What’s Your First Name?</span></p>
                            <FormulateInput type="text" placeholder="Type your answer here..." name="name" validation="required" v-model="formData.name" class="answer-input" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>


                      <!-- Step 2: Confirmation Question -->
                      <div v-if="currentStep === 2" class="step step-two" :key="step-two">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Ok, I have your Business Name as {{ company.company_name || '' }}, is that correct?</span></p>
                            <FormulateInput type="radio" name="confirmation" label="Yes" value="yes" v-model="formData.confirmation" />
                            <FormulateInput type="radio" name="confirmation" label="No" value="no" v-model="formData.confirmation" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 3: Ask for Business Name -->
                      <div v-if="currentStep === 3" class="step step-three" :key="step-three">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">OK, I'll Update Your Profile in Our System. What's your Business Name?</span></p>
                            <FormulateInput type="text" placeholder="Type your answer here..." name="businessName" validation="required" v-model="formData.businessName" class="answer-input" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 4: How long have you been in business -->
                      <div v-if="currentStep === 4" class="step step-four" :key="step-four">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">How long have you been in business?</span></p>
                            <FormulateInput type="radio" name="businessDuration" label="Still Opening" value="Still Opening" v-model="formData.businessDuration" />
                            <FormulateInput type="radio" name="businessDuration" label="0–6 Months" value="0-6 Months" v-model="formData.businessDuration" />
                            <FormulateInput type="radio" name="businessDuration" label="6–12 Months" value="6-12 Months" v-model="formData.businessDuration" />
                            <FormulateInput type="radio" name="businessDuration" label="1–2 Years" value="1-2 Years" v-model="formData.businessDuration" />
                            <FormulateInput type="radio" name="businessDuration" label="2–5 Years" value="2-5 Years" v-model="formData.businessDuration" />
                            <FormulateInput type="radio" name="businessDuration" label="5+ Years" value="5+ Years" v-model="formData.businessDuration" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 5: Do you have a website -->
                      <div v-if="currentStep === 5" class="step step-five" :key="step-five">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Do you have a website?</span></p>
                            <FormulateInput type="radio" name="hasWebsite" label="Yes" value="yes" v-model="formData.hasWebsite" />
                            <FormulateInput type="radio" name="hasWebsite" label="No" value="no" v-model="formData.hasWebsite" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 6: What's Your Website URL -->
                      <div v-if="currentStep === 6" class="step step-six" :key="step-six">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">What's Your Website URL</span></p>
                            <!-- Input for Website URL -->
                            <FormulateInput type="text" placeholder="Type your answer here..." name="websiteURL" validation="required|url" v-model="formData.websiteURL" class="answer-input" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 7: Have you ever done Online Marketing before -->
                      <div v-if="currentStep === 7" class="step step-seven" :key="step-seven">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Have you ever done Online Marketing before?</span></p>

                            <FormulateInput type="radio" name="hasDoneOnlineMarketing" label="Yes" value="yes" v-model="formData.hasDoneOnlineMarketing" />
                            <FormulateInput type="radio" name="hasDoneOnlineMarketing" label="No" value="no" v-model="formData.hasDoneOnlineMarketing" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 8: Where do you get most of your clients from -->
                      <div v-if="currentStep === 8" class="step step-eight" :key="step-eight">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Where do you get most of your clients from?</span></p>
                            <p class="small-text">You can choose more than One...</p>
                            <div class="inputgroup doublecolumn">
                              <FormulateInput type="checkbox" name="clientsFrom" label="Word of Mouth" value="Word of Mouth" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Door Hangers" value="Door Hangers" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Yellow Pages" value="Yellow Pages" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Flyers" value="Flyers" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Business Cards" value="Business Cards" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Magazines" value="Magazines" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Bus Stop Bench" value="Bus Stop Bench" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="BillBoards" value="BillBoards" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="BBB" value="BBB" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Yelp" value="Yelp" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Walk ins" value="Walk ins" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="TV" value="TV" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Radio" value="Radio" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Craigslist" value="Craigslist" v-model="formData.clientsFrom" />
                              <FormulateInput type="checkbox" name="clientsFrom" label="Other" value="Other" v-model="formData.clientsFrom" />
                            </div>
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 9: How much are you currently investing in Advertising and Marketing -->
                      <div v-if="currentStep === 9" class="step step-nine" :key="step-nine">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">How much are you currently investing per month on your <span class="highlight">Advertising and Marketing</span>?</span></p>
                            <p class="small-text">Choose as many as you like</p>
                            <div class="inputgroup doublecolumn">
                              <FormulateInput type="checkbox" name="advertisingBudget" label="0" value="0" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="0-$99" value="0-$99" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$100-$300" value="$100-$300" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$300-$500" value="$300-$500" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$500-$1,000" value="$500-$1,000" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$1,000-$2,000" value="$1,000-$2,000" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$2,000-$5,000" value="$2,000-$5,000" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$5,000-$10,000" value="$5,000-$10,000" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$10,000+" value="$10,000+" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$25,000+" value="$25,000+" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$50,000+" value="$50,000+" v-model="formData.advertisingBudget" />
                              <FormulateInput type="checkbox" name="advertisingBudget" label="$100,000+" value="$100,000+" v-model="formData.advertisingBudget" />
                            </div>
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 10: Do you WANT more business -->
                      <div v-if="currentStep === 10" class="step step-ten" :key="step-ten">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Do you WANT more business?</span></p>
                            <FormulateInput type="radio" name="wantMoreBusiness" label="Yes" value="yes" v-model="formData.wantMoreBusiness" />
                            <FormulateInput type="radio" name="wantMoreBusiness" label="No" value="no" v-model="formData.wantMoreBusiness" />
                          </template>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="nextStep">Next</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 11: Would you like to see how our free marketing services can boost your online presence -->
                      <div v-if="currentStep === 11" class="step step-eleven" :key="step-eleven">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Are you open to hearing about more ways to get more customers?</span></p>

                            <FormulateInput type="radio" name="boostOnlinePresence" label="Yes" value="yes" v-model="formData.boostOnlinePresence" />
                            <FormulateInput type="radio" name="boostOnlinePresence" label="No" value="no" v-model="formData.boostOnlinePresence" />
                          </template>
                          <p><span class="bold">I have my partner {{ managerName }} here, he is a marketing specialist and will finish the process with you</span></p>
                          <template #buttons>
                            <ScriptButton buttonStyle="outline" @click="prevStep">Previous</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="submitForm()" v-if="isBarging">Submit TO</ScriptButton>
                            <ScriptButton buttonStyle="solid" @click="requestTO()" v-if="!isBarging">Request TO</ScriptButton>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 12: Thank you -->
                      <div v-if="currentStep === 12" class="step step-twelve" :key="step-twelve">
                        <ScriptPage>
                          <template #question v-if="isBarging">
                            <p><span class="bold">Thanks for submitting!</span></p>
                          </template>
                          <template #question v-if="!isBarging">
                            <p><span class="bold">Please engage the prospect until the TO!</span></p>
                            <p>Time left: {{ formattedScriptTimer }}</p>
                            <ScriptButton buttonStyle="solid" @click="submitForm()" v-if="scriptTimer <= 0">Submit TO</ScriptButton>
                          </template>
                          <template #question v-else>
                            <p><span class="bold">Manager is currently barging.</span></p>
                          </template>
                        </ScriptPage>
                      </div>

                      <!-- Step 13: Thank you -->
                      <div v-if="currentStep === 13" class="step step-twelve" :key="step-twelve">
                        <ScriptPage>
                          <template #question>
                            <p><span class="bold">Thanks for submitting!</span></p>
                          </template>
                        </ScriptPage>
                      </div>
                    </transitionGroup>
                  </FormulateForm>
                </template>
              </UIBox>

              <!-- Box 2 Profile -->
              <UIBox classbox="prospectsA" classcontent="columns">
                <template v-slot:title>
                  <div class="star" @click="onClick" :class="{ toggled: isToggled }">
                    <StarSVG class="icon" />
                  </div>
                  Company Profile for <span class="bold">{{ companyName }}</span>
                  <div v-if="company.leadClaimed">
                    <h6 color="red">CLAIMED</h6>
                  </div>
                </template>
                <template v-slot:button>
                  <button type="button" class="btn solid google" @click="searchLead">
                    Search on
                    <GoogleSVG />
                  </button>
                </template>
                <template v-slot:leftcol>
                  <b-table :items="profile_1_a_items" :fields="profile_fields" class="separator nopad-first nopad-sides" thead-class="hidden_header"></b-table>
                  <b-table :items="profile_1_b_items" :fields="profile_fields" class="separator nopad-first nopad-sides" thead-class="hidden_header"></b-table>
                  <b-table :items="profile_1_c_items" :fields="profile_fields" class="nopad-first nopad-last nopad-sides" thead-class="hidden_header"></b-table>
                </template>
                <template v-slot:rightcol>

                  <!-- Box 2 Profile: Interested In -->
                  <b-table :items="profile_2_a_items" :fields="profile_fields" class="separator nopad-first nopad-sides" thead-class="hidden_header"></b-table>
                  <div class="separator">

                    <!-- Box 2 Profile: Phone Numbers -->
                    <b-table :items="profile_2_b_items" :fields="profile_fields_numbers" class="nopad-first numbers nopad-sides" thead-class="hidden_header"></b-table>
                    <!-- Box 2 Profile: Phone Numbers / Add fields (these only appear when you click the (+) -->
                    <div class="add-fields">
                      <b-form-input v-model="text" placeholder="Label"></b-form-input>
                      <b-form-input v-model="text" placeholder="Number"></b-form-input>
                      <b-button class="solid icon add " @click="saveNote">
                        <SaveSVG />
                      </b-button>
                    </div>
                    <!-- Box 2 Profile: Phone Numbers / Add Button (+) -->
                    <div class="add-button">
                      <b-button class="solid add icon">
                        <AddSVG />
                      </b-button>
                    </div>
                  </div>

                  <!-- Box 2 Profile: Tags -->
                  <b-table :items="profile_2_c_items" :fields="profile_fields" class="nopad-first nopad-last nopad-sides" thead-class="hidden_header"></b-table>
                </template>
              </UIBox>


              <!-- Box 3 Prospects -->
              <UIBox classbox="prospectsB" classcontent="columns">
                <template v-slot:title>
                  <div class="title-container">
                    <span class="bold">Prospects Marketing Information</span>
                  </div>
                </template>
                <template v-slot:leftcol>
                  <b-table :items="prospects_1_items" :fields="prospects_fields" thead-class="hidden_header" class="nopad-first nopad-last nopad-sides">
                    <template #cell(label)="popover">
                      <div>{{ popover.item.label }}</div>
                      <a v-b-popover.hover.top="popover.item.tooltip" class="info-hover" v-if="popover.item.tooltip">
                        <TooltipSVG />
                      </a>
                    </template>
                  </b-table>
                </template>
                <template v-slot:rightcol>
                  <b-table :items="prospects_2_items" :fields="prospects_fields" thead-class="hidden_header" class="nopad-first nopad-last nopad-sides">
                    <template #cell(label)="popover">
                      <div>{{ popover.item.label }}</div>
                      <a v-b-popover.hover.top="popover.item.tooltip" class="info-hover" v-if="popover.item.tooltip">
                        <TooltipSVG />
                      </a>
                    </template>
                  </b-table>
                </template>
              </UIBox>

              <!-- Box 4 Notes -->
              <UIBox>
                <template v-slot:title>
                  <span class="bold">Notes</span>
                </template>
                <template #default>
                  <template v-if="!note_list_items || !note_list_items.length">
                    <div class="empty-message">Click the New Note button to add a new note to this prospect</div>
                  </template>
                  <b-table v-else
                    :items="note_list_items" 
                    :fields="note_list_fields" 
                    class="notes nopad-sides" 
                    :per-page="perPageNotes" 
                    :current-page="currentPageNotes" 
                    aria-controls="notes"
                  >
                    <template #cell(delete)="row">
                      <b-button 
                        @click="deleteNote(row.index, row.item.id)" 
                        class="red icon float-right"
                      >
                        <CloseSVG />
                      </b-button>
                    </template>
                  </b-table>
                  <div class="bottom-input">
                    <b-popover ref="newNotePopover" target="newnotepopover" triggers="click blur" placement="top" custom-class="note-popover">
                      <b-form-textarea id="textarea-no-auto-shrink" placeholder="Enter Note" no-auto-shrink no-resize class="noSelect" v-model="newNoteText"></b-form-textarea>
                      <div class="buttons">
                        <b-button class="btn solid icon save" @click="saveNote">
                          <SaveSVG />
                        </b-button>
                      </div>
                    </b-popover>
                    <button class="btn solid icon newnote" id="newnotepopover">
                      <PlusSVG />New Note
                    </button>
                    <template>
                      <template>
                        <div class="pagin">
                          <b-pagination v-model="currentPageNotes" :total-rows="rowsNotes" :per-page="perPageNotes" aria-controls="notes">
                            <template #first-text>
                              <PaginleftdoubleSVG />
                            </template>
                            <template #prev-text>
                              <PaginleftSVG />
                            </template>
                            <template #next-text>
                              <PaginrightSVG />
                            </template>
                            <template #last-text>
                              <PaginrightdoubleSVG />
                            </template>
                          </b-pagination>
                        </div>
                      </template>
                      <div class="current-page">Current Page: {{ currentPageNotes }}</div>
                    </template>
                  </div>
                </template>
              </UIBox>

              <!-- Box 5 Prospect Calls -->
              <UIBox>
                <template v-slot:title>
                  <span class="bold">Prospect Calls</span>
                </template>
                <template #default>
                  <template v-if="!call_list_items || !call_list_items.length">
                    <div class="empty-message">Archived calls with prospect will populate here</div>
                  </template>
                  <template v-else v-for="call_list_item in call_list_items">
                    <b-table :items="call_list_items" :fields="call_list_fields" class="calls nopad-sides" :per-page="perPageCalls" :current-page="currentPageCalls" aria-controls="calls">
                      <template #cell(play)="row">
                        <b-button @click="" class="icon">
                          <PlaySVG />
                        </b-button>
                      </template>
                    </b-table>
                    <div class="bottom-input single">
                      <template>
                        <div class="pagin">
                          <b-pagination v-model="currentPageCalls" :total-rows="rowsCall" :per-page="perPageCalls" aria-controls="calls">
                            <template #first-text>
                              <PaginleftdoubleSVG />
                            </template>
                            <template #prev-text>
                              <PaginleftSVG />
                            </template>
                            <template #next-text>
                              <PaginrightSVG />
                            </template>
                            <template #last-text>
                              <PaginrightdoubleSVG />
                            </template>
                          </b-pagination>
                        </div>
                      </template>
                      <div class="current-page">Current Page: {{ currentPageCalls }}</div>
                    </div>
                  </template>
                </template>
              </UIBox>

              <!-- Box 6 Messages -->
              <div class="box">
  <div class="header">
    <div class="title">
      <span class="bold">Messages</span>
    </div>
  </div>
  <div class="conversation">
    <template v-if="!messages || !messages.length">
      <div class="empty-message">Emails, texts, and calls with prospect will populate here</div>
    </template>

    <!-- Messages -->
    <div
      v-for="(message, index) in messages"
      :key="message.id"
      :class="{
        'bubble-row right': isFromSalesRep(message),
        'bubble-row left': !isFromSalesRep(message)
      }"
    >
      <div class="bubble-container">
        <!-- Icon and Badge for Call, Event, or Message -->
        <template v-if="message.type === 5">
          <!-- Call Event -->
          <div class="badge">
            <svg v-if="message.inbound === '1'" class="icon"><CallIncomingSVG /></svg>
            <svg v-else class="icon"><CallOutgoingSVG /></svg>
            Call started at {{ formatDateTimezone(message.time) }}
          </div>
        </template>
        <template v-else-if="message.type === 3">
          <!-- Click Event -->
          <div class="badge">
            <svg class="icon"><ClickEventSVG /></svg>
            Clicked on: {{ message.text }}
          </div>
        </template>
        <template v-else-if="message.type === 4">
          <!-- Sequence Trigger Event -->
          <div class="badge">
            <svg class="icon"><SequenceTriggerSVG /></svg>
            Sequence triggered: {{ message.text }}
          </div>
        </template>
        <template v-else>
          <!-- Default Message -->
          <div class="bubble">
            <span>{{ message.text }}</span>
            <svg class="tail" :viewBox="'0 0 27 25'">
              <path
                v-if="isFromSalesRep(message)"
                d="M26.5 24.5L12 0.5L0.5 15.5L26.5 24.5Z"
              />
              <path
                v-else
                d="M0 24.5L14.5 0.5L26 15.5L0 24.5Z"
              />
            </svg>
          </div>
        </template>

        <!-- Sender Information -->
        <div class="subtitle bold">{{ getSenderName(message) }}</div>
        <div class="subtitle">{{ formatDateTimezone(message.time) }}</div>
      </div>
    </div>
  </div>

  <div class="chat-input">
    <input
      type="text"
      v-model="messageInput"
      placeholder="Type your message..."
      @keyup.enter="sendMessage"
    />
    <button @click="sendMessage">Send</button>
  </div>
</div>

            </div>

            <div class="right-bar">

              <!-- Right Column -->
              <div class="right-content">

                <!-- Box 7 Calls  -->
                <UIBox>
                  <template v-slot:title>
                    <span class="bold">Recent Calls</span>
                  </template>
                  <template #default>
                    <template v-if="!recentcalls_fields_next || !recentcalls_fields_next.length">
                      <div class="empty-message">No recent calls</div>
                    </template>
                    <template v-else v-for="call in recentcalls_fields_next">
                      <b-table :items="recentcalls_fields_next" :fields="recentcalls_fields" class="stats nopad-last">
                        <template #head(name)="headname">
                          Next
                        </template>
                        <template #cell(name)="compname">
                          <span class="bold">{{ compname.value }}</span>
                        </template>
                      </b-table>
                      <b-table :items="recentcalls_fields_previus" :fields="recentcalls_fields" class="stats nopad-last">
                        <template #head(name)="headname">
                          Previous
                        </template>
                        <template #cell(name)="compname">
                          <span class="bold">{{ compname.value }}</span>
                        </template>
                      </b-table>
                      <b-button type v-b-modal.recentcallsModal class="btn solid fullwidth" @click="scheduleCallback">View More</b-button>
                    </template>
                  </template>
                </UIBox>

                <!-- Box 8 Callbacks  -->
                <UIBox id="callbacks-right">
                  <template v-slot:title>
                    <span class="bold">Call Backs</span>
                    <!-- <div class="countdown">3:13</div> -->
                  </template>
                  <template #default>
                    <template v-if="!callback_items || !callback_items.length">
                      <div class="empty-message">No call backs scheduled</div>
                    </template>
                    <template v-else v-for="callback_item in callback_items">
                      <b-table :items="callback_items" :fields="callback_fields" class="stats nopad-sides">
                        <!-- Phone Column -->
                        <template #cell(phone)="data">
                          <button class="btn outline icon" @click="openCallProspect({ phone: data.item.cleanPhone, leadId: data.item.leadId })">
                            <phoneSVG />
                          </button>
                        </template>
                        
                        <!-- Name Column -->
                        <template #cell(name)="data">
                          <span class="bold">{{ data.item.name }}</span>
                        </template>

                        <!-- Time Column -->
                        <template #cell(time)="data">
                          <svg viewBox="0 0 13 13" class="arrow">
                            <path d="M0.0795898 13L0.0795898 0L12.1783 6.5L0.0795898 13Z" />
                          </svg>
                          {{ data.item.time }}
                        </template>
                      </b-table>
                    </template>
                  </template>
                </UIBox>


                <!-- Box 9 Daily SPIFFs -->
                <UIBox classcontent="nopad" v-if="false">
                  <template v-slot:title>
                    <span class="bold">Daily SPIFs</span>
                  </template>
                  <template #default>
                    <SpifWidget :totalTOsCompleted="stats.today.qualifiedTos" />
                  </template>
                </UIBox>

                <!-- Box 10 Stats -->
                <UIBox>
                  <template v-slot:title>
                    <span class="bold">Stats</span>
                  </template>
                  <template #default>
                    <b-table :items="stat_items" :fields="stat_fields" class="stats nopad-last nopad-sides">
                      <template #head(title)="title_head">
                        {{ title_head.label.emptyText }}
                      </template>
                      <template #cell(title)="title">
                        <span class="bold">{{ title.value }}</span>
                      </template>
                    </b-table>
                  </template>
                </UIBox>

                <!-- Box 11 Sales -->
                <!-- <UIBox>
                  <template v-slot:title>
                    <span class="bold">Sales</span>
                  </template>
                  <template #default>
                    <b-table :items="sales_items" :fields="sales_fields" class="stats nopad-last nopad-sides">
                      <template #head(title)="title_head">
                        {{ title_head.label.emptyText }}
                      </template>
                      <template #cell(title)="title">
                        <span class="bold">{{ title.value }}</span>
                      </template>
                    </b-table>
                  </template>
                </UIBox> -->

                <!-- Box 12 Objections -->
                <UIBox>
                  <template v-slot:title>
                    <span class="bold">Objections</span>
                  </template>
                  <template>
                    <div class="objections" role="tablist">
                      <div v-for="(objection, index) in objection_items" class="items">
                        <div v-b-toggle="'collapse-' + index" class="title">
                          {{ objection.text1 }}
                          <accordiancaretSVG />
                        </div>
                        <b-collapse :id="'collapse-' + index" :accordion="'objections'" role="tabpanel" class="copy">
                          <div>{{ objection.text2 }}</div>
                        </b-collapse>
                      </div>
                    </div>
                  </template>
                </UIBox>

              </div>

            </div>
          </div>
        </div>

        <!-- Prompt Page -->
        <div v-if="currentView === 'prompt'" class="page single">
          <Prompt />
        </div>

        <!-- Search Page -->
        <div v-if="currentView === 'search'" class="page single">
          <Search  @open-call-prospect="openCallProspect" />
        </div>

        <!-- Tags Page -->
        <div v-if="currentView === 'tags'" class="page single">
          <Tags @open-call-prospect="openCallProspect" />
        </div>

        <div v-if="currentView === 'blacklist'" class="page single">
          <Blacklist @open-call-prospect="openCallProspect" />
        </div>

        <div v-if="currentView === 'schedules'" class="page single">
          <Schedules @open-call-prospect="openCallProspect" />
        </div>

        <!-- Admin Accordion -->
        <div v-if="currentView === 'admin-operatorQuotaPolicy'" class="page single">
          <OperatorQuotaPolicy></OperatorQuotaPolicy>
        </div>

        <!-- Reports CallBacks Page -->
        <div v-if="currentView === 'reports-callbacks'" class="page single">
          <CallbackReport @open-call-prospect="openCallProspect"></CallbackReport>
        </div>

        <!-- Call Reports Page -->
        <div v-if="currentView === 'reports-callsreports'" class="page single">
          <CallsReport @open-call-prospect="openCallProspect"></CallsReport>
        </div>

        <!-- TO Reports Page -->
        <div v-if="currentView === 'reports-TOReports'" class="page single">
          <TOReport></TOReport>
        </div>
        
        <!-- Operator TO Tracking Reports Page -->
        <div v-if="currentView === 'reports-operatorTOTrackingReports'" class="page single">
          <OperatorTOTrackingReport></OperatorTOTrackingReport>
        </div>

        <!-- Broadcast -->
        <div v-if="currentView === 'broadcast'" class="page single">
          <Broadcast @broadcast-sent="broadcastSent"></Broadcast>
        </div>

        <!-- Broadcast -->
        <div v-if="currentView === 'broadcast-sent'" class="page single">
          <BroadcastSent></BroadcastSent>
        </div>

        <!-- Inbox -->
        <div v-if="currentView === 'inbox'" class="page single">
          <Inbox @open-call-prospect="openCallProspect" @make-call="makeDirectCallFromInbox"></Inbox>
        </div>

        <!-- Pipeline -->
        <div v-if="currentView === 'pipeline'" class="page single">
          <Pipeline @open-call-prospect="openCallProspect" :userTimezone="timeZone"></Pipeline>
        </div>
        
        <!-- NAAO Reports Page -->
        <div v-if="currentView === 'reports-NAAOReport'" class="page single">
          <NAAOReport />
        </div>

        <!-- TRAINING -->
        <!-- Day One Training Page -->
        <div v-if="currentView === 'training-dayone'" class="page single" :key="currentView">
          <UIBox classcontent="nopad">
            <template v-slot:title>
              <span class="bold">Day One Training</span>
            </template>
            <template #default>
              <div id="typeform-container" data-tf-live="01JBCSKH3Q8FZ8YT2RVS905GTB"></div>
            </template>
          </UIBox>
        </div>

        <!-- Recorded Training Page -->
        <div v-if="currentView === 'training-recorded'" class="page single" :key="currentView">
          <TrainingRecorded />
        </div>

        <!-- Salves Videos Training Page -->
        <div v-if="currentView === 'training-salesvideos'" class="page single" :key="currentView">
          <UIBox classcontent="nopad" classbox="recorded">
            <template v-slot:title>
              <span class="bold">Sales Videos on Youtube</span>
            </template>
            <template>
              <div id="typeform-container" data-tf-live="01J3V5CXAMADA317WRE60DBYXQ"></div>
            </template>
          </UIBox>
        </div>

        <!-- Services Training Page -->
        <div v-if="currentView === 'training-services'" class="page single" :key="currentView">
          <UIBox classcontent="nopad">
            <template v-slot:title>
              <span class="bold">Services Training</span>
            </template>
            <template #default>
              <div id="typeform-container" data-tf-live="01JBG4H9NM3BR7DC1417RAPNDY"></div>
            </template>
          </UIBox>
        </div>

        <!-- Test Training Page -->
        <div v-if="currentView === 'training-test'" class="page single" :key="currentView">
          <UIBox classcontent="nopad">
            <template v-slot:title>
              <span class="bold">The Test</span>
            </template>
            <template #default>
              <div id="typeform-container" data-tf-live="01JBG4E0MV19VNG2J260B9G1X1"></div>
            </template>
          </UIBox>
        </div>

        <!-- Paperwork Resources Page -->
        <div v-if="currentView === 'resources-paperwork'">
          <GetPaid></GetPaid>
        </div>

        <!-- Income Calc Resources Page -->
        <div v-if="currentView === 'resources-incomecalc'">
          <IncomeCalculator></IncomeCalculator>
        </div>

        <!-- Ask AI Resources Page -->
        <div v-if="currentView === 'resources-askai'">
          <div id="customgpt_chat"></div>
        </div>

        <!-- Panel Page -->
        <div v-if="currentView === 'panel'" class="page">

              <Panel @init-call="initCall" @open-chat="openChat"></Panel>
        </div>

        <!-- Scoreboard Page -->
        <div v-if="currentView == 'scoreboard'" class="page">
          <Scoreboard @missed-tos="openMissedTOs"></Scoreboard>
        </div>

        <!-- Users Page -->
        <div v-if="currentView === 'users'" class="page">
          <Users></Users>
        </div>

        <!-- Funnels Page -->
        <div v-if="currentView === 'admin-funnels'" class="page">
          <Funnels></Funnels>
        </div>

        <!-- Stats Page -->
        <div v-if="currentView === 'stats'" class="page">
          <AdvancedStats></AdvancedStats>
        </div>

        <!-- Calls Report Page -->
        <div v-else-if="currentView === 'calls-report'" class="page">
          <CallsReport :salesRepId="salesRepIdCallsFilter" :toCallsFilter="toCallsFilter" @open-call-prospect="openCallProspect"></CallsReport>
        </div>

        <!-- Call Back Report Page -->
        <div v-else-if="currentView === 'callback-report'" class="page">
          <CallbackReport @open-call-prospect="openCallProspect"></CallbackReport>
        </div>

        <!-- Operator Leaderboard Report Page -->
        <div v-else-if="currentView === 'operator-leaderboard'" class="page">
          <callOperatorLeaderboard @open-call-prospect="openCallProspect"></callOperatorLeaderboard>
        </div>

        <!-- Daily QA -->
        <div v-else-if="currentView === 'daily-qa'" class="page">
          <QALink></QALink>
        </div>

      </div>
    </main>
    <audio id="telnyx-audio" autoplay></audio>
    <audio id="notification-audio" style="visibility:hidden;"></audio>
    <audio id="ringback-audio" autoplay controls loop style="visibility:hidden;"></audio>

    <!-- Recent Calls Modal -->
    <b-modal id="recentcallsModal" hide-footer size="lg" body-class="modalTable">
      <template #modal-header="{ close }" class="header">
        <div>Recent Calls</div>
        <button class="btn" @click="close()">
          <closeSVG />
        </button>
      </template>
      <b-table :items="recentcallslist_items" :fields="recentcallslist_fields" class="missedcalls" :per-page="perPageRecentcalls" :current-page="currentPageRecentcalls" aria-controls="missedcalls">
      </b-table>
      <div class="bottom-input single">
        <template>
          <div class="pagin">
            <b-pagination v-model="currentPageRecentcalls" :total-rows="rowsRecentcalls" :per-page="perPageRecentcalls" aria-controls="missedcalls">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
        <div class="current-page">Current Page: {{ currentPageRecentcalls }}</div>
      </div>
    </b-modal>

  </div>

</template>

<script>
import axios from 'axios';
import { TelnyxRTC } from '@telnyx/webrtc';
import  CallsReport  from "./CallReports.vue";
import  OperatorTOTrackingReport  from "./OperatorTOTrackingReport.vue";
import  OperatorQuotaPolicy  from "./OperatorQuotaPolicy.vue";
import Chat from "../../components/sales/Chat.vue";
import GetPaid from "./GetPaid.vue";
import Callbacks from "./Callbacks.vue";
import CallbackReport from "../../components/CallbacksReport.vue";
import callOperatorLeaderboard from "../../components/callOperatorLeaderboard.vue";
import IncomeCalculator from "../../components/TOincomecalc.vue";
import Multiselect from "vue-multiselect";
import AuthService from '@/_services/AuthService';
import SocketService from '../../components/sales/socketService.js';
import { VBTogglePlugin } from 'bootstrap-vue';
import moment from 'moment';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { FormWizard, TabContent } from 'vue-form-wizard';
import  SpifWidget  from './SpifWidget.vue';
import  SalesScript  from '../../components/salesscript.vue';

import Funnels from "./Funnels.vue";
import Users from "./Users.vue";
import Panel from "../../components/sales/Panel.vue";
import Scoreboard from "../../components/sales/scoreboard.vue";
import AdvancedStats from '../../components/advancedStats.vue';
// import { VueSound } from 'vue-sound';

import UIBox from '../../components/uibox.vue';
import Notifications from '../../components/notifications.vue';
import Search from '../../components/search.vue';
import TOReport from '../../components/TOreport.vue';
import ScriptPage from '../../components/scriptpage.vue';
import ScriptButton from '../../components/scriptbutton.vue';
import TrainingRecorded from '../../components/trainingrecorded.vue';
import NAAOReport from '../../components/NAAOReport.vue';
import ErrorModal from '../../components/errorModal.vue';
import Tags from '../../components/Tags.vue';
import Blacklist from '../../components/Blacklist.vue';
import Schedules from '../../components/Schedules.vue';
import Prompt from '../../components/Prompt.vue';

// SVG Icons
import PauseSVG from '../../components/SVG-icons/pauseSVG.vue';
import NextSVG from '../../components/SVG-icons/nextSVG.vue';
import PhoneSVG from '../../components/SVG-icons/phoneSVG.vue';
import ThreeLinesSVG from '../../components/SVG-icons/threeLinesSVG.vue';
import ThreeGearsSVG from '../../components/SVG-icons/threeGearsSVG.vue';
import QuotaSVG from '../../components/SVG-icons/quotaSVG.vue';
import ScoreboardSVG from '../../components/SVG-icons/scoreboardSVG.vue';
import BlacklistSVG from '../../components/SVG-icons/blacklistSVG.vue';
import SchedulesSVG from '../../components/SVG-icons/schedulesSVG.vue';
import FunnelsSVG from '../../components/SVG-icons/funnelsSVG.vue';
import UsersSVG from '../../components/SVG-icons/usersSVG.vue';
import VoicemailSVG from '../../components/SVG-icons/voicemailSVG.vue';
import EmailSVG from '../../components/SVG-icons/emailSVG.vue';
import CloseSVG from '../../components/SVG-icons/closeSVG.vue';
import EraseSVG from '../../components/SVG-icons/eraseSVG.vue';
import TimezoneSVG from '../../components/SVG-icons/timezoneSVG.vue';
import EditSVG from '../../components/SVG-icons/editSVG.vue';
import ChatSVG from '../../components/SVG-icons/chatSVG.vue';
import CheckSVG from '../../components/SVG-icons/checkSVG.vue';
import LogoutSVG from '../../components/SVG-icons/logoutSVG.vue';
import SearchSVG from '../../components/SVG-icons/searchSVG.vue';
import TagsSVG from '../../components/SVG-icons/tagsSVG.vue';
import NavcaretSVG from '../../components/SVG-icons/navcaretSVG.vue';
import ExtlinkSVG from '../../components/SVG-icons/extlinkSVG.vue';
import StarSVG from '../../components/SVG-icons/starSVG.vue';
import TooltipSVG from '../../components/SVG-icons/tooltipSVG.vue';
import GoogleSVG from '../../components/SVG-icons/googleSVG.vue';
import PlusSVG from '../../components/SVG-icons/plusSVG.vue';
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';
import PlaySVG from '../../components/SVG-icons/playSVG.vue';
import AccordiancaretSVG from '../../components/SVG-icons/accordiancaretSVG.vue';
import SaveSVG from '../../components/SVG-icons/saveSVG.vue';
import MissedcallSVG from '../../components/SVG-icons/missedcallSVG.vue';
import CallIncomingSVG from '../../components/SVG-icons/callincomingSVG.vue';
import CallOutgoingSVG from '../../components/SVG-icons/calloutgoingSVG.vue';
import CallFunnelInSVG from '../../components/SVG-icons/callfunnelinSVG.vue';
import CallFunnelOutSVG from '../../components/SVG-icons/callfunneloutSVG.vue';
import PhoneHangUpSVG from '../../components/SVG-icons/phonehangupSVG.vue';
import MuteASVG from '../../components/SVG-icons/muteASVG.vue';
import MuteBSVG from '../../components/SVG-icons/muteBSVG.vue';
import AddSVG from '../../components/SVG-icons/addSVG.vue';
import LaunchindialerSVG from '../../components/SVG-icons/launchindialerSVG.vue';
import Broadcast from '../../components/Broadcast.vue';
import Inbox from '../../components/inbox/Inbox.vue';
import Pipeline from '../../components/pipeline/Pipeline.vue';
import BroadcastSent from '../../components/broadcast/BroadcastSent.vue';
import walletIcon from '../../components/walletIcon.vue';

import { flushEvents } from 'aws-amplify/analytics';

export default {

  created: function() {
  this.moment = moment;
  },
  components: { 'CallsReport': CallsReport, 'Chat': Chat, 'GetPaid': GetPaid, 'Callbacks': Callbacks, 'CallbackReport': CallbackReport, 'callOperatorLeaderboard': callOperatorLeaderboard, 'IncomeCalculator': IncomeCalculator, Multiselect, VueTypeaheadBootstrap, UIBox, Notifications, FormWizard, TabContent, 'SpifWidget': SpifWidget, Search, SalesScript, TOReport, ScriptPage, ScriptButton, TrainingRecorded, NAAOReport, OperatorTOTrackingReport, OperatorQuotaPolicy, ErrorModal,

  'Panel': Panel, 'Scoreboard': Scoreboard, 'Users': Users, 'Funnels': Funnels, 'AdvancedStats': AdvancedStats, 'Tags': Tags, 'Blacklist': Blacklist, 'Schedules': Schedules, 'Prompt': Prompt, 'Broadcast': Broadcast, 'Inbox': Inbox, 'Pipeline': Pipeline,
  'BroadcastSent': BroadcastSent, 

  PauseSVG, NextSVG, PhoneSVG, VoicemailSVG, EmailSVG, ThreeLinesSVG, ThreeGearsSVG, QuotaSVG, ScoreboardSVG, BlacklistSVG, SchedulesSVG, FunnelsSVG, UsersSVG, CloseSVG, EraseSVG, TimezoneSVG, EditSVG, ChatSVG, LogoutSVG, SearchSVG, TagsSVG, NavcaretSVG, ExtlinkSVG, StarSVG, TooltipSVG, GoogleSVG, PlusSVG, PaginleftdoubleSVG, PaginleftSVG, PaginrightdoubleSVG, PaginrightSVG, PlaySVG, AccordiancaretSVG, SaveSVG, MissedcallSVG, CallIncomingSVG, CallOutgoingSVG, CallFunnelInSVG, CallFunnelOutSVG, PhoneHangUpSVG, MuteASVG, MuteBSVG, AddSVG, LaunchindialerSVG, BroadcastSent, CheckSVG,

  'walletIcon': walletIcon},
  data() {
  return {
    remainingCredits: 0,
    pricePerTransfer: 7,
    inboxUnreadCount: 0,
    accordion: {
      'nav-reports': false,
      'nav-training': false,
      'nav-resources': false,
    },
    // SCRIPT FORM
    formData: { name: '', email: '' },
    currentStep: 10,
    currentStep: 1,
    formData: {
      name: '', 
      confirmation: '',
      businessName: '',
      businessDuration: '',
      hasWebsite: '',
      hasDoneOnlineMarketing: '',
      clientsFrom: [],
      advertisingBudget: [],
      wantMoreBusiness: '',
      boostOnlinePresence: ''
    },
    isBarging: false,
    now: 0, // Set initial time in seconds (2 minutes)
    intervalId: null, // To hold the interval ID for cleanup
    intervalId: null,
    countdownSeconds: 10, // initial countdown time in seconds
    futureTimestamp: null,
    scriptTimer: 120,
    toRequested: false,
    
    
    leftnav: '',
    
    // industries list
    industries_fields: [
    {
      key: 'industry',
      variant: 'bold',
    },
    {
      key: 'leads',
      variant: 'text-right'
    },
    ],
    industries_items: [],
    
    // recent calls table
    recentcallslist_fields: [
    {
      key: 'prospect',
    },
    {
      key: 'phone',
    },
    {
      key: 'time',
    },
    {
      key: 'date',
    },
    ],
    
    recentcallslist_items: [
    { prospect: 'Dickerson', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Larsen', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Geneva', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    ],
    perPageRecentcalls: 10,
    currentPageRecentcalls: 1,
    
    // missed calls table
    missedcalls_fields: [
    {
      key: 'newmissedcall',
      class: 'icon',
      label: ''
    },
    {
      key: 'prospect',
    },
    {
      key: 'phone',
    },
    {
      key: 'time',
    },
    {
      key: 'date',
    },
    ],
    
    missedcalls_items: [
    { newmissedcall: true, prospect: 'Dickerson', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: true, prospect: 'Larsen', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: true, prospect: 'Geneva', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    { newmissedcall: false, prospect: 'Jami', phone: 1234561234, time: '12:12pm', date: 'September 8th, 2024' },
    ],
    perPageMisssedcalls: 10,
    currentPageMissedcalls: 1,
    
    // Box 1 Prospects
    prospects_fields: [
    {
      key: 'label',
      variant: 'bold',
      class: 'info-popover'
    },
    {
      key: 'data',
      variant: 'text-right'
    },
    ],
    
    prospects_1_items: [
    {
      label: 'Click Date',
      data: '-',
      tooltip: 'The date when the prospect clicked on a link to learn more from one of our text messages.'
    },
    {
      label: 'Lead Shelflife',
      data: '-',
      tooltip: 'The duration the lead has been managed, from the first call, text, or email to the current day.'
    },
    {
      label: 'Texts',
      data: '-',
      tooltip: 'The total number of inbound and outbound text messages exchanged with the prospect.'
    },
    {
      label: 'Emails',
      data: '-',
      tooltip: 'The total number of inbound and outbound emails exchanged with the prospect.'
    }
    ],

    prospects_2_items: [
    {
      label: 'Talk Time',
      data: '-',
      tooltip: 'The cumulative time spent talking with the prospect on all calls.'
    },
    {
      label: 'Calls',
      data: '-',
      tooltip: 'The total number of inbound and outbound calls with the prospect.'
    },
    {
      label: 'Voicemails',
      data: '-',
      tooltip: 'The total number of voicemails left for the prospect.'
    },
    {
      label: 'Calls with Humans',
      data: '-',
      tooltip: 'The total number of calls where the prospect answered or spoke with a representative for more than 2min. '
    }
    ],
    
    // Box 2 Profile
    profile_fields: [
    {
      key: 'label',
      variant: 'bold',
    },
    {
      key: 'data',
      variant: 'text-right'
    }
    ],
    // Box 2 Profile Numbers
    profile_fields_numbers: [
    {
      key: 'label',
    },
    {
      key: 'data',
      variant: 'text-right'
    }
    ],
    
    profile_1_a_items: [
    {
      label: 'Owner',
      data: '-'
    },
    {
      label: "Owner’s Email",
      data: '-'
    }
    ],

    profile_1_b_items: [
    {
      label: 'Address',
      data:   '-'
    },
    {
      label: 'City',
      data: '-'
    },
    {
      label: 'State',
      data: '-'
    },
    {
      label: 'Zip',
      data: '-'
    }
    ],

    profile_1_c_items: [
    {
      label: 'URL',
      data: '-'
    },
    {
      label: 'Google Rating',
      data: '-'
    }
    ],

    profile_2_a_items: [
    {
      label: 'Interested in',
      data: '-'
    }
    ],

    profile_2_b_items: [
    {
      label: 'Phone Numbers',
      data: '' // this stays blank
    },
    {
      label: "Owner’s Cell",
      data: '-'
    },
    {
      label: 'Business Phone',
      data: '-'
    }
    ],

    profile_2_c_items: [
    {
      label: 'Tags',
      data: '-'
    }
    ],

    // Box 4 Notes
    note_list_fields: [
    {
      key: 'date'
    },
    {
      key: 'time'
    },
    {
      key: 'note'
    },
    {
      key: 'user',
      sortable: true
    },
    {
      key: 'delete'
    }
    ],
    perPageNotes: 5,
    currentPageNotes: 1,
    note_list_items: [],

    // Box 5 Prospect Calls
    call_list_fields: ['play', 'date', 'time', 'length', 'number'],
    perPageCalls: 5,
    currentPageCalls: 1,
    call_list_items: [],
    
    //Box 7 Call 
    recentcalls_fields: [{
      key: 'name',
      label: 'Company',
    },
    {
      key: 'length',
      label: 'Call Length',
      variant: 'text-right'
    }
    ],
    recentcalls_fields_next: [],
    recentcalls_fields_previus: [],

    
    // Box 8 Callbacks
    callback_fields: [
    {
      key: 'phone',
      label: ''
    },  
    {
      key: 'name',
      label: 'Company',
    },
    {
      key: 'time',
      label: 'Time (PST)',
      variant: 'text-right'
    }
    ],
    callback_items: [],
    
    // Box 8 Stats
    stat_fields: [{
      key: 'title',
    },
    {
      key: 'today',
      label: 'Today',
      variant: 'text-right'
    },
    {
      key: 'this_week',
      label: 'This Week',
      variant: 'text-right'
    }
    ],
    
    stat_items: [
    {
      title: 'Calls',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Talk Time',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Managed Barge Time',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Calls Per Paid TO',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Finished Scripts',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Finished Scripts Asked All Questions',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Missed TOs (No Manager)',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Paid TOs',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Team TOs',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Commission',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Hot Leads Created',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Scheduled Call Backs',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Completed Call Backs',
      today: '-',
      this_week: '-'
    }
    ],

    // Box 10 Sales
    sales_fields: [{
      key: 'title',
    },
    {
      key: 'today',
      label: 'Today',
      variant: 'text-right'
    },
    {
      key: 'this_week',
      label: 'This Week',
      variant: 'text-right'
    }
    ],
    sales_items: [{
      title: 'My Sales',
      today: '-',
      this_week: '-'
    },
    {
      title: 'Team Sales',
      today: '-',
      this_week: '-'
    }
    ],

    // Box 11 Objections
    objection_items: {},

    // DATA

    currentView: 'search', // change back to 'dialer'
    

    // NOTIFICATIONS DATA
    salesNumber: localStorage.getItem('salesNumber') || 0,
    showNotification: false,
    notifications: [
				{ title: 'Team TOs', number: this.salesNumber, text: 'Just came in', alert: false }
			],
    
    // USER DATA
    role: '',
    userId: '',
    userName: '',
    timeZone: 'PST',
    isUserOverride: false,
    selectedRole: '',
    roles: ['Operators', 'Sales Rep', 'Managers', 'Admins'],
    salesRepId: '',
    timezone: '',
    sipDid: '',
    username: '',
    password: '',
    currentSalesRepId: '',
    currentSalesRepName: '',
    managerId: '',
    managerName: '',
    managerDid: '',
    missedCalls: '',
    voicemails: '',
    companyId: '',
    userId: '',
    numUnread: 0,
    lastCallEndTime: '',
    callbackNum: '',
    scriptLink: '',
    signupLink: '',
    qaLink: '',
    trainingLockerLink: '',

    // DIALER DATA
    dialerMode: 'By Industry',
    industries: [],
    industry: '',
    dialerStatus: 'Dialer Off',
    dialerText: 'Start Dialer',
    nextCallPopover: false,
    showDialPad: false,
    interval: null,
    now: null,
    popoverTrigger: 'manual',
    temp: '',
    callStartTime: null,
    incomingCall: false,

    // LEAD DATA
    companyName: '-',
    isToggled: false,
    company: {
    company_name: '-',
    phone: '-',
    address: '-',
    city: '-',
    state: '-',
    zipcode: '-',
    owner: '-',
    owner_cell: '-',
    owner_email: '-'
    },

    // LIVE TRANSFER DATA
    liveTransfer: null,
    liveTransfersEnabled: true,
    localAudioTrack: null,
    isLocalMuted: true,
    transferNum: 0,
    autoMute: false,


    // NOTES DATA
    noteText: '',

    // CALLS DATA
    calls: [],
    totalRows: 0,
    totalCount: 0,

    // MESSAGING DATA
    messageInput: '',
    chatVisible: false,
    chatName: '',

    // SEARCH
    searchResults: [],
    temp: '',
    
    stats: {
    today: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
    thisWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
    lastWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
    sales: { talkTime: 0, totalCalls: 0, inboundCalls: 0, outboundCalls: 0 }
    },
    searchQuery: '',
    dateFilter: 'All',
    typeFilter: 'All',
    milestoneFilter: 'All',
    timezoneFilter: 'All',
    salesRepFilter: 'All',
    shelfLifeFilter: 'All',
    hoursFilter: 'Show Only During Hours',
    customStartDate: '',
    customEndDate: '',
    rowsPerPage: 50,
    currentPage: 1,
    leads: [],
    totalRows: 0,
    sortDirection: 'asc',
    showOverlay: false,
    showNotesPopup: false,
    showMessagesPopup: false,
    notesCompanyName: '',
    messagesCompanyName: '',
    newNoteText: '',
    notesData: {},
    filteredRows: [],
    currentLeadId: null,
    totalCount: 0,
    scriptLink: '',
    signupLink: '',
    qaLink: '',
    trainingLockerLink: '',
    dialerActive: 'OFF',
    dialerText: 'Start Dialer',
    playingCallId: null,
    audio: null,
    outboundHuman: false,
    callDestination: '',
    includeVideo: false,
    enableDebugging: false,
    authentication: 'credential',
    sipUsername: 'cmharrisiii22686',
    password: '5khcqfTE',
    callerIdName: '',
    callerIdNumber: '',
    clientState: '',
    telnyxRTC: null,
    call: null,
    localAudioTrack: null,
    isMuted: false,
    dialpad: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#']
    ],
    username: null,
    password: null,
    timer: null,
    offTimer: null,
    connectedTimer: null,
    currentCallTimer: null,
    elapsedTime: 0,
    offElapsedTime: 0,
    connectedElapsedTime: 0,
    currentCallElapsedTime: 0,
    status: 'OFF',
    ignore: false,
    currentProspectNumber: '',
    loggedTime: 0,
    showTimeModal: false,
    notes: [],
    prospect: null,
    company: {
    company_name: '-',
    phone: '-',
    address: '-',
    city: '-',
    state: '-',
    zipcode: '-',
    owner: '-',
    owner_cell: '-',
    owner_email: '-'
    },
    showDialerModal: false,
    phoneNumber: '',
    sipDid: '',
    paused: false,
    messages: [],
    calls: [],
    currentSalesRepId: '',
    managerId: '',
    currentSalesRepName: '',
    isListen: false,
    bargeCall: '',
    localStream: null,
    outboundStream: null,
    listeningCall: null,
    showCallbacks: false,
    context: null,
    frequencies: {
    '1': [697, 1209],
    '2': [697, 1336],
    '3': [697, 1477],
    '4': [770, 1209],
    '5': [770, 1336],
    '6': [770, 1477],
    '7': [852, 1209],
    '8': [852, 1336],
    '9': [852, 1477],
    '*': [941, 1209],
    '0': [941, 1336],
    '#': [941, 1477],
    },
    bargeContext: null,
    microphoneNode: null,
    microphoneStream: null,
    prospectNode: null,
    prospectStream: null,
    managerStream: null,
    missedCalls: 0,
    voicemails: 0,
    objections: null,
    activePanel: null,
    upcomingCallback: null,
    lastCompany: null,
    lastConnectedDuration: 0,
    sender: null,
    showNextButtons: false,
    isDropdownOpen: false,
    selectedOption: 'Previously Showed Interest',
    industrySearch: '',
    industries: [],
    textMessage: '',
    selectedInterestType: {
      name: 'By Industry'
    },
    industrySearch: '',
    dialerModes: [
    // {
    //   name: 'Previously Showed Interest'
    // },
    {
      name: 'By Industry'
    }
    ],
    selectedIndustry: '',
    userId: '',
    userTimezone: '',
    numUnread: 0,
    dialerOK: true
  };
  },
  watch: {
    salesRepId(newValue) {
      
    },
    now(value) {
      if (value === 0) {
        if(this.intervalId) {
          let data = {
            event: 'request-to',
            salesRepId: this.salesRepId,
            managerId: this.managerId,
            callId: this.company.callId
          };
         // SocketService.emit(event, data);
        }
        clearInterval(this.intervalId); // Stop timer when reaching 0
      }
    },
    currentView(newView) {
      localStorage.setItem('currentView', newView);
    },
    salesNumber(value) {
      localStorage.setItem('salesNumber', value);
    },
    role(newRole) {
      document.body.setAttribute('data-role', newRole.toLowerCase());
    }
  },
computed: {
  formattedScriptTimer() {
    console.log('scriptTiemr: ', this.scriptTimer);
    const hours = Math.floor(this.scriptTimer / 3600);
    const minutes = Math.floor((this.scriptTimer % 3600) / 60);
    const seconds = this.scriptTimer % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  },
  rowsMissedcalls() {
    return this.missedcalls_items.length
  },
  rowsRecentcalls() {
    return this.recentcallslist_items.length
  },
  rowsNotes() {
    return this.note_list_items.length
  },
  rowsCall() {
    return this.call_list_items.length
  },
  loggedFormattedTime() {
    const hours = Math.floor(this.elapsedTime / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((this.elapsedTime % 3600) / 60).toString().padStart(2, '0');
    const seconds = (this.elapsedTime % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  },
  loggedOffFormattedTime() {
    const hours = Math.floor(this.offElapsedTime / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((this.offElapsedTime % 3600) / 60).toString().padStart(2, '0');
    const seconds = (this.offElapsedTime % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  },
  connectedFormattedTime() {
    const hours = Math.floor(this.connectedElapsedTime / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((this.connectedElapsedTime % 3600) / 60).toString().padStart(2, '0');
    const seconds = (this.connectedElapsedTime % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  },
  connectedCallFormattedTime() {
    const hours = Math.floor(this.currentCallElapsedTime / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((this.currentCallElapsedTime % 3600) / 60).toString().padStart(2, '0');
    const seconds = (this.currentCallElapsedTime % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  },
  // filteredIndustries() {
  //   return this.industries.filter(industry => 
  //     industry.toLowerCase().includes(this.industrySearch.toLowerCase())
  //   );
  // }
  },
  methods: {
    processLeadClaimed(data) {
      //if(data.managerId != this.userId) {
        this.$refs.liveTransfer.$emit('close');
      //}
    },
    _toggleMute() {

    },
    async toggleMuted() {
      this.isMuted = !this.isMuted;
      let muteButton = this.$refs.muteButton;
      console.log('toggleMute - button: ', muteButton);
      console.log('toggleMuted: - isMuted: ', this.isMuted);

      if (muteButton) {
        const timestamp = new Date().toISOString();
        if (this.isMuted) {
          this.localAudioTrack.enabled = false;
          console.log('toggleMute: - isMuted = true');
          muteButton.classList.add('muted');
          console.log('liveTransfer: ', this.liveTransfer);
          const response = await axios.post('https://123avatars.com/v2/log-barge-event', {
                          call_id: this.liveTransfer.callId,
                          manager_id: this.userId,
                          rep_id: this.liveTransfer.salesRepId,
                          timestamp: timestamp,
                          event_type: 'mute'
                      });
                      console.log(response.data);
        } else {
          this.localAudioTrack.enabled = true;
          console.log('toggleMute: - isMuted = false');
          muteButton.classList.remove('muted');
          const response = await axios.post('https://123avatars.com/v2/log-barge-event', {
                          call_id: this.liveTransfer.callId,
                          manager_id: this.userId,
                          rep_id: this.liveTransfer.salesRepId,
                          timestamp: timestamp,
                          event_type: 'unmute'
                      });
                      console.log(response.data);
        }
        console.log('toggleMute - audio track enabled: ', this.localAudioTrack.enabled);
        console.log('toggleMute - button class list after apply: ', muteButton.classList);
      } else {
        console.error('Mute button element not found');
      }
    },
    async toggleLiveTransfers() {
      this.liveTransfersEnabled = !this.liveTransfersEnabled;
      console.log("Live Transfers toggled:", this.liveTransfersEnabled);

      const timestamp = new Date().toISOString();
      const callId = -1; // this.call?.callId; // Replace with the correct call ID source
      const managerId = this.managerId; // Replace with the actual manager ID
      const repId = this.userId; // Replace with the actual rep ID

      try {
          if (this.liveTransfersEnabled) {
              // Log live transfer activation
              // const response = await axios.post('https://123avatars.com/v2/log-live-transfer-event', {
              //     call_id: callId,
              //     manager_id: managerId,
              //     rep_id: repId,
              //     timestamp: timestamp,
              //     event_type: 'active'
              // });
              console.log(response.data);
          } else {
              // Log live transfer deactivation
              // const response = await axios.post('https://123avatars.com/v2/log-live-transfer-event', {
              //     call_id: callId,
              //     manager_id: managerId,
              //     rep_id: repId,
              //     timestamp: timestamp,
              //     event_type: 'inactive'
              // });
              console.log(response.data);
          }
      } catch (error) {
          console.error('Error logging live transfer event:', error);
      }
  },
  async closeTransferPopUp() {
    this.$refs.liveTransfer.$emit('close');
    // this.liveTransfer = null;
  },
    async closeTransferNotification() {
      this.$refs.liveTransfer.$emit('close');
      // this.liveTransfer = null;
      if(this.call) {
        await this.call.hangup();
        this.localAudioTrack = null;
        this.isMuted = false;
      }
    },
    answerTransfer() {
      this.autoMute = false;
      this._bargeCall();
    },
    _bargeCall() {
      let webTarget = 'https://123avatars.com';
      if(this.liveTransfer?.isAI) {
        webTarget = 'https://ap.voice.theleadfarm.co';
      }
      axios.get(`${webTarget}/v2/transfer-call?id=${this.liveTransfer.salesRepId}&managerId=${this.userId}&callId=${this.liveTransfer.callId}&managerNum=${encodeURIComponent(this.sipDid)}&isAI=${this.liveTransfer?.isAI}`)
          .then(async (resp) => {
          if(resp.data.message) {
              alert(resp.data.message);
              //this.closeBarge(call);
          } else {
              this.makeCall(this.sipDid, this.sipDid, null);
              this.loadProspect(await this.fetchLead(this.liveTransfer.salesRepId, this.liveTransfer.leadId));
              this.currentView = 'dialer';
              this.remainingCredits = parseInt(this.remainingCredits) - this.pricePerTransfer;
              this.company.leadClaimed = true;
              this.transferNum = resp.data.count;
              let data = {
                  event: 'update-barge',
                  salesRepId: this.liveTransfer.salesRepId,
                  managerId: this.userId,
                  managerName: this.userName,
                  callId: this.liveTransfer.callId,
                  status: 'open'
              };
              SocketService.emit('update-barge', data);
              const timestamp = new Date().toISOString();
              // const response = await axios.post('https://123avatars.com/v2/log-barge-event', {
              //             call_id: this.liveTransfer.callId,
              //             manager_id: this.userId,
              //             rep_id: this.liveTransfer.salesRepId,
              //             timestamp: timestamp,
              //             event_type: 'unmute'
              //         });
              // console.log('unuteREsposne: ', response);
              //alert('Successfully claimed lead.')
          }
          }).catch((err) => {
          console.error("error barging call: ", err);
          });
    },
    async notifyTORequest(data) {
      if(this.liveTransfersEnabled) {
        this.liveTransfer = data;
        // {
        //   event: 'request-to',
        //   salesRepId: this.salesRepId,
        //   managerId: this.managerId,
        //   callId: this.company.callId
        // }
        this.$refs.liveTransfer.$emit('open');
        try {
          const audioElement = document.getElementById('notification-audio');
          audioElement.src = 'https://123avatars.com/uploads/bbm_tone.mp3';
          await audioElement.play();
        } catch (error) {
          console.error('error playing audio: ', error);
        }
      }
    }, 
    async fetchRemainingCredits() {
      console.log('userId: ', this.userId);
      let target = `https://123avatars.com/v2/sales-reps/${this.userId}`;
      let response = await axios.get(target);
      this.remainingCredits = response.data[0].credits;
      this.pricePerTransfer = response.data[0].price_per_transfer;
    },
    async getInboxUnreadCount() {
      const cRole =  await AuthService.getUserRole();
        const cUserId = await AuthService.getCognitoSub();
        const newUrl = `https://123avatars.com/v2/inbox/unopened-total?id=${cUserId}&role=${cRole}`
        console.log('NEW ROLE', newUrl);
        axios.get(newUrl)
        .then(result => {
          console.log('FETCHING RESULT', result.data.count);
          this.inboxUnreadCount = result.data.count;
        })
        .catch(error => {
          console.log('FETCHING ERROR');
          console.error('Error fetching unread count:', error);
        });  
      
      setInterval(async () => {
        const cRole =  await AuthService.getUserRole();
        const cUserId = await AuthService.getCognitoSub();
        const newUrl = `https://123avatars.com/v2/inbox/unopened-total?id=${cUserId}&role=${cRole}`
        console.log('NEW ROLE', newUrl);
        axios.get(newUrl)
        .then(result => {
          console.log('FETCHING RESULT', result.data.count);
          this.inboxUnreadCount = result.data.count;
        })
        .catch(error => {
          console.log('FETCHING ERROR');
          console.error('Error fetching unread count:', error);
        });  
      }, 30000);
    },
    broadcastSent() {
      this.fetchRemainingCredits();
      this.updateView('broadcast-sent');
    },
    toggleAccordion(id) {
      Object.keys(this.accordion).forEach(key => {
        if (key !== id) {
          this.$set(this.accordion, key, false);
        }
      });
      this.$set(this.accordion, id, !this.accordion[id]);
    },
    changeRole() {
      let role = this.selectedRole;
      if (role === 'Sales Rep') {
        role = 'Roaming_Closer';
      }
      AuthService.setOverrideState(this.userId, role);
    },
    closeAccordion() {
     // uiBox.value.toggleAccordion();
    },
    answerCall() {

    },
    denyCall() {

    },
    startScriptTimer() {
      this.futureTimestamp = Date.now() + this.countdownSeconds * 1000; // Set future timestamp 120 seconds from now
      this.intervalId = setInterval(() => {
        this.updateTimer();
      }, 1000);
    },

  updateTimer() {
    const currentTime = Date.now();
    const remainingTime = Math.floor((this.futureTimestamp - currentTime) / 1000);

    if (remainingTime <= 0) {
      this.scriptTimer = 0;
      this.triggerEvent();
      clearInterval(this.intervalId); // Stop timer when reaching 0
    } else {
      this.scriptTimer = remainingTime;
    }
  },

  triggerEvent() {
    if (this.intervalId) {
      const data = {
        event: 'request-to',
        salesRepId: this.salesRepId,
        managerId: this.managerId,
        callId: this.company.callId,
        leadId: this.company.leadId
      };
     // SocketService.emit('request-to', data);
    }
  },
  async openCallProspect(data) {
    if (!('switchProfile' in data) || data.switchProfile !== false) this.currentView = 'dialer';
    this.phoneNumber = data.phone
    let localSalesRepId = this.salesRepId;
    if(data.salesRepId) {
      localSalesRepId = data.salesRepId;
    }

    console.log('data: ', data);
    this.loadProspect(await this.fetchLead(localSalesRepId, data.leadId, data.fromInbox));
    if(data.makeCall) {
      this.$refs.popoverPad.$emit('open');
      this.makeDirectCall();
    }
  },
  async fetchLead(salesRepId, leadId, fromInbox = false) {
    try {
    const response = await axios.get('https://123avatars.com/v2/lead', {
      params: {
      salesRepId: salesRepId,
      leadId: leadId,
      ...(fromInbox && { fromInbox })
      }
    });
    return response.data;  // The lead data from the response
    } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
    }
  },
  async initCall(number) {
    console.log('number: ', number);
    this.phoneNumber = number;
    this.$refs.popoverPad.$emit('open');
    this.makeDirectCall();
  },
  openMissedTOs(salesRepId) {
    console.log('salesRepId: ', salesRepId);
    this.salesRepIdCallsFilter = salesRepId;
    this.toCallsFilter = 'Missed';
    this.updateView('calls-report');
    },
  async generateMessage(company) {
    try {
    company = this.comapny;
    const payload = {
      type: 'SMS', // or 'email' or 'call' based on your requirement
      interactionId: company.leadId, // Assuming leadId is the interaction identifier
      userId: company.owner_email, // Adjust this to match the correct userId field
      companyId: company.company_name,
      campaignId: company.campaignId,
      leadId: company.leadId,
      phone: company.phone,
      email: company.owner_email
    };

    const response = await axios.post('https://123avatars.com/v2/omni-message/generate', payload);
    console.log('Generated message:', response.data);
    this.messageInput = response.data.nextMessage; // Set generated message as input
    } catch (error) {
    console.error('Error generating message:', error);
    }
  },
  // async sendMessage(company) {
  //   try {
  //   company = this.company;
  //   const payload = {
  //     type: 'SMS', // or 'email' or 'call' based on your requirement
  //     message: this.messageInput, // Use the message from the input
  //     recipient: company.phone, // Use phone or email based on the channel
  //     userId: company.owner_email, // Adjust this to match the correct userId field
  //     companyId: company.company_name,
  //     campaignId: company.campaignId,
  //     leadId: company.leadId,
  //     phone: company.phone,
  //     email: company.owner_email
  //   };

  //   const response = await axios.post('https://123avatars.com/v2/omni-message/send', payload);
  //   this.messages.push({
  //     text: payload.message,
  //     inbound: "0",
  //     time: new Date()
  //   });
  //   this.messageInput = '';
  //   console.log('Message sent:', response.data);
  //   } catch (error) {
  //   console.error('Error sending message:', error);
  //   }
  // },
  async sendMessage() {
    try {
      await axios.post(`https://123avatars.com/v2/inbox/reply-to-message`, {
        leadId: this.company.leadId,
        azureContactId: this.company.azureContactId,
        to: `${this.company.phone.trim()}`,
        company: this.company.company_name,
        text: this.messageInput
      });

      this.messages.push({
        text: this.messageInput,
        inbound: "0",
        time: new Date()
      });
      this.messageInput = '';
    } catch (error) {
    }
  },
  async fetchSuggestions() {
    try {
    if (this.temp.length > 2) { // Trigger search when input length is greater than 2
      this.searchResults = null;
      let response = await axios
      .get(`https://123avatars.com/v2/search`, { params: { query: this.temp } });
      this.searchResults = response.data.results.map(res => res.company_name);
      console.log('searchResults: ', this.searchResults);
    }          
    } catch(error) {
      console.error('Error fetching search results:', error);  
    }
  },
  async callManager() {
    console.log('callManager');
    this.phoneNumber = this.managerDid;
    this.closeChat();
    this.$refs.popoverPad.$emit('open');
    this.makeDirectCall();
  },
  closeChat() {
    const chatElement = document.getElementById('managerchat');
    if(chatElement.classList.contains('show')) {
    const linkElement = document.getElementById('chat-link');
    linkElement.click();
    }
     this.chatVisible = false;
},
  async hangupCall() {
    if(this.call) {
      await this.call.hangup();
      this.call = null;
      this.localAudioTrack = null;
      this.isMuted = false;
    }
  },
  toggleMute() {
    this.isMuted = !this.isMuted;
    if(this.localAudioTrack) {
      this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
    }
  },
  async onClick() {
    try {
    // Toggle the star state in the UI
    this.isToggled = !this.isToggled;

    // Prepare the data for the backend
    const data = {
      milestone: 'Starred'
    };

    // Make a PUT request to the backend to update the milestone
    await axios.put(`https://123avatars.com/v2/leads/${this.company.leadId}/star`, data);

    // Optionally handle the response here (success notification, etc.)
    console.log('Milestone updated successfully');
    } catch (error) {
    // Handle any errors from the API call
    console.error('Error updating milestone:', error);
    // Optionally, revert the toggle in the UI if the request fails
    this.isToggled = !this.isToggled;
    }
  },
  closeDialPad() {
    // if(this.call) {
    //   this.call.hangup();
    //   this.call = null;
    //   this.localAudioTrack = null;
    //   this.isMuted = false;
    // }
    // this.updateDialerStatus('Dialer Off');
    this.$refs.popoverPad.$emit('close');
  },
  openChat(rep) {
    console.log('rep: ', rep);
    if(rep) {
      this.currentSalesRepId = rep.id;
      this.currentSalesRepName = rep.repName;
      this.chatName = rep.repName
      console.log('chatName: ', this.chatName);
      this.managerId = rep.managerId;
      const chatElement = document.getElementById('managerchat');
      if(!chatElement.classList.contains('show')) {
        const linkElement = document.getElementById('chat-link');
        linkElement.click();
      }
    } else {
      this.chatName  = this.managerName;
    }
    this.$refs.chatRef.$emit('open');
    this.chatVisible = true;
  },
  async submitForm() {
    try {

      // Make POST request to backend to mark to_submitted in the Phone_Calls table
      const response = await axios.post('https://123avatars.com/v2/submit-to', {
        callId: this.company.callId,
        leadId: this.company.leadId,
        firstName: this.formData.name != null && this.formData.name != '' ? true : false,
        responses: this.formData
      });

      console.log('Form submitted successfully:', response.data);

      if(this.isBarging) {
        this.currentStep += 2;
      } else {
        this.currentStep++;
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  },
  async requestTO() {
    try {
    console.log('formDataSubmitted: ', this.formData);
    const event = 'request-to';
    try {
      // Emit script-update update to the SocketService
      const event = 'request-to';
      const data = {
        event: 'request-to',
        salesRepId: this.salesRepId,
        managerId: this.managerId,
        callId: this.company.callId,
        leadId: this.company.leadId
      };
      this.toRequested = true;

      // Emit script-update event to the SocketService
      if (!this.isBarging) {
        //SocketService.emit(event, data);
        console.log(`Emitted request-to event: ${event}`, data);
      }

      if(!this.isBarging) {
        this.startScriptTimer();
      }
      this.currentStep++;
      console.log(`Emitted request-to event: ${event}`, data);

      // const response = await axios.post('https://123avatars.com/v2/submit-to', {
      //   callId: this.company.callId,
      //   firstName: this.formData.name != null && this.formData.name != '' ? true : false
      // });
      // this.toRequested = true;
      
    } catch (error) {
      console.error(`Failed to request-to event: ${error.message}`);
    }
    //const response = await axios.post('https://your-backend-endpoint.com/api/submit', this.formData);
     // console.log('Form submitted successfully:', response.data);
    } catch (error) {
    console.error('Error submitting form:', error);
    }
  },
  async nextStep() {
    console.log('formData: ', this.formData);
    this.formData.industry = this.company?.industry;
    this.formData.campaignId = this.company?.campaignId;
    this.formData.leadId = this.comapny?.leadId;
    this.formData.isNew = false;
    let payload = {
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    data: this.formData,
    callId: this.company?.callId
    };
    this.sendScriptUpdate(payload);

    if(this.currentStep == 7) {
      const event = 'request-to';
      const data = {
        event: 'request-to',
        salesRepId: this.salesRepId,
        salesRepName: this.salesRepName,
        managerId: this.managerId,
        callId: this.company.callId,
        leadId: this.company.leadId
      };
      this.toRequested = true;
      const response = axios.post('https://123avatars.com/v2/submit-to', {
        callId: this.company.callId,
        leadId: this.company.leadId,
        firstName: this.formData.name != null && this.formData.name != '' ? true : false,
        responses: this.formData
      });
      this.toRequested = true;

      // Emit script-update event to the SocketService
      if (!this.isBarging) {
        SocketService.emit(event, data);
        console.log(`Emitted request-to event: ${event}`, data);
      }
    }

    if (this.currentStep < 12) {
    if(this.currentStep == 2) {
      if(this.formData.confirmation == 'no') {
      this.currentStep = 3;
      } else {
      this.currentStep = 4;
      }
    } else if(this.currentStep == 5) {
      if(this.formData.hasWebsite == 'no') {
      this.currentStep = 7;
      } else {
      this.currentStep = 6;
      }
    } else {
      this.currentStep++;
    }
    }
  },
  prevStep() {
    if (this.currentStep > 1) {
    this.currentStep--;
    }
  },
  handleClickOutside(event) {
    if (!this.$refs.dropdownContainer?.contains(event.target)) {
    this.industries = []; // Clear the dropdown
    this.industrySearch = ''; // Reset the search field
    }
  },
  selectIndustry(industry) {
    this.selectedIndustry = industry;
    this.industries = [];
    this.industrySearch = '';
  },
  selectInterestType(type) {
    this.selectedInterestType = type;
  },
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  },
  setSearchOption(option) {
    this.selectedOption = option;
  },

  togglePanel(index) {
    this.activePanel = this.activePanel === index ? null : index;
  },
  isActive(index) {
    return this.activePanel === index;
  },
  // Function to change the first input stream
  setMicrophoneStream(newStream) {
    if (this.microphoneStream) {
    this.microphoneStream.disconnect();
    }
    this.microphoneStream = this.bargeContext.createMediaStreamSource(newStream);
    this.microphoneStream.connect(this.microphoneNode);
    console.log("Microphone stream set and connected:", this.microphoneStream);
    console.log("Barge context initialized:", this.bargeContext);
    console.log("Manager stream:", this.managerStream.stream);
    // this.listeningCall.peer.instance.removeTrack(this.sender);

    this.managerStream.stream.getTracks().forEach((track) => {
    console.log("Adding track to peer connection:", track);
     // this.listeningCall.peer.instance.addTrack(track, this.managerStream.stream);
    });
  },
  setProspectStream(newStream) {
    if (this.prospectStream) {
    this.prospectStream.disconnect();
    }
    this.prospectStream = this.bargeContext.createMediaStreamSource(newStream);
    this.prospectStream.connect(this.prospectNode);
    console.log("Prospect stream set and connected:", this.prospectStream);
    console.log("Barge context initialized:", this.bargeContext);
    console.log("Manager stream:", this.managerStream.stream);
    console.log("microphoneNode: ", this.microphoneNode);
    console.log("prospectNode: ", this.prospectNode);
    console.log("listeningPeer: ", this.listeningCall);

    // this.listeningCall.peer.options.localStream.stop();
    // this.listeningCall.peer.instance.removeStream(this.listeningCall.peer.options.localStream);
   
    // this.listeningCall.peer.options.localStream = this.managerStream;
    // this.listeningCall.peer.createOffer();


    this.managerStream.stream.getTracks().forEach((track) => {
    console.log("Adding track to peer connection:", track);
    console.log("instance: ", this.listeningCall.peer);
    track.stop();
     // this.listeningCall.peer.instance.replaceTrack(track, this.managerStream.stream);
    });

    this.managerStream.stream.getTracks().forEach((track) => {
    console.log("Adding track to peer connection:", track);
    console.log("instance: ", this.listeningCall.peer);
    this.listeningCall.peer.instance.addTrack(track, this.managerStream.stream);
    });
  },
  async openBarge() {
    this.bargeContext = new (window.AudioContext || window.webkitAudioContext)();
    this.microphoneNode = this.bargeContext.createGain();
    this.prospectNode = this.bargeContext.createGain();
    this.managerStream = this.bargeContext.createMediaStreamDestination();

    // Connect nodes to the manager stream
    this.microphoneNode.connect(this.managerStream);
    this.prospectNode.connect(this.managerStream);

    // Set microphone stream
    if (this.localStream) {
    this.setMicrophoneStream(this.localStream);
    }

    // Set prospect stream if available
    if (this.call && this.call.remoteStream) {
     // this.setProspectStream(this.call.remoteStream);
    }


    // this.attachStreamToAudioElement(this.managerStream.stream);

    try {

    this.listeningCall.peer.instance.onnegotiationneeded = (event) => {
      console.log("event: ", event)
    };

    console.log("listeningPeer: ", this.listeningCall.peer);
    this.listeningCall.peer.options.localStream.getTracks().forEach((track) => {
      track.stop();
    });

    this.listeningCall.peer.options.localStream.getTracks().forEach((track) => {
      console.log("track: ", track);
    });
    

    this.managerStream.stream.getTracks().forEach((track) => {
      console.log("Adding track to peer connection:", track);
     // this.sender = this.listeningCall.peer.instance.addTrack(track, this.managerStream.stream);
    });

    try {
      this.attachStreamToAudioElement(this.managerStream.stream);
      this.listeningCall.peer.options.remoteStream = this.managerStream.stream;
    } catch (err) {
      console.error("error init stream: ", err);
    }

    console.log("listeningPeerTwo: ", this.listeningCall.peer);
    try {
     let offer = await this.listeningCall.peer.instance.createOffer();
     let result = await this.listeningCall.peer.instance.setLocalDescription(offer);
    } catch (err) {
      console.error("error making offer: ", err);
    }

    try {
      console.log("instance: ", this.listeningCall.peer.instance);
      setTimeout(async () => {
      //let resp = await this.listeningCall.peer.instance.createOffer();
      console.log("resp: ", resp);
      this.listeningCall.peer.options.localStream.getTracks().forEach((track) => {
        console.log("newTracK: ", track);
        //track.start();
      });
      }, 5000);
    } catch (err) {
      console.error("error making offer2: ", err);
    }
    
    } catch (err) {
    console.error("error modifying stream.", err);
    }


    console.log("Barge context initialized:", this.bargeContext);
    console.log("Manager stream:", this.managerStream.stream);
    

    // Add tracks to the peer connection
    // this.managerStream.stream.getTracks().forEach((track) => {
    //   console.log("Adding track to peer connection:", track);
    //   this.sender = this.listeningCall.peer.instance.addTrack(track, this.managerStream.stream);
    // });
  },
  async scheduleCallback() {
    console.log("company: ", this.company);
    if(this.company.isHotLead == 'Hot Lead') {
    alert('You cannot set callbacks for existing hot leads.');
    } else {
    this.showCallbacks = true;
    }
  },
  async handleCallbacksClosed() {
    this.showCallbacks = false;
 //     this.showNextButtons = false;
  },
  async dialNumber(phone) {
    this.phoneNumber = phone;
    this.showDialerModal = true;
  },
  async getUserMedia() {
    try {
    console.log("devices: ", await navigator.mediaDevices.enumerateDevices());
    this.localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
     // console.log("localStream: ", localStream);
    console.log("localStream123: ", this.localStream);
    } catch (err) {
    console.error('Error getting user media:', err);
    }
  },
  searchLead() {
    const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(this.company.phone))}`;
    window.open(url, '_blank');
  },
  async blacklist() {
    try {

    this.company = {
    company_name: this.prospect.company_name,
    phone: this.prospect.phone,
    address: this.prospect.Company_Street_Address,
    city: this.prospect.Company_City,
    state: this.prospect.Company_State,
    zipcode: this.prospect.Company_Zipcode,
    owner: this.prospect.Owner_Name,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    owner_email: this.prospect.Owner_Email
    };

    let email = this.company.owner_email ? this.company.owner_email : '';
    let response = await axios
    .get(
      "https://123avatars.com/backend/setAvatarBlacklist.php?u=" + this.userId + "&no=" + this.company.phone + "&label=" + '1'+ "&no2=" + '' + "&mail=" + email + "&mail2=" + this.mail2
    );
    if(response.status == 200) {
      this.$toasted.show(" Added to blacklist successfully ", {
      theme: "outline",
      position: "top-right",
      type: "success",
      icon: "check",
      duration: 2000,
      });
    } else {
      this.$toasted.show("Failed to add to blacklist.", {
      theme: "outline",
      position: "top-right",
      type: "danger",
      icon: "cross",
      duration: 2000,
      });
    }
    this.nextCallButton();
    } catch (err) {
    console.error("error adding company to blacklist: ", err);
    }
  },
  // async sendMessage() {
  //   try {
  //     let result = await axios.post('https://123avatars.com/v2/send-sms-message', {
  //       text: this.textMessage,
  //       lead_id: this.company.leadId,
  //       campaign_id: this.company.campaignId
  //     });
  //     this.textMessage = '';
  //     setTimeout(() => {
  //       this.appendMessages(this.prospect);
      
  //     }, 1000);
  //   } catch (err) {
  //     console.error("error sending message: ", err);
  //   }
  // },
  openLastProspect() {

  },
  isFromSalesRep(message) {
    return message.inbound === "0"; // Assuming inbound "0" means from sales rep
  },
  getSenderName(message) {
    return this.isFromSalesRep(message) ? 'You' : this.company.company_name || ''; // Replace 'ABC Plumbing' with appropriate logic
  },
  formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  },
  formatDateTimezone(dateString) {
    console.log(dateString);

    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true,
      timeZone: 'UTC'
    };

    return new Date(dateString).toLocaleDateString('en-US', options);
  },
  getTimezone() {
    // Get the local timezone
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // List of US timezones
    const usTimezones = [
    'America/New_York',    // EST/EDT
    'America/Chicago',     // CST/CDT
    'America/Denver',      // MST/MDT
    'America/Phoenix',     // MST (no DST)
    'America/Los_Angeles', // PST/PDT
    'America/Anchorage',   // AKST/AKDT
    'America/Adak',        // HST/HDT
    'Pacific/Honolulu',    // HST (no DST)
    ];

    // Check if the local timezone is a US-based timezone
    if (usTimezones.includes(localTimezone)) {
    return localTimezone;
    }

    // If not a US timezone, return PST/PDT
    return 'America/Los_Angeles';
  },
  appendMessages(prospect) {
    this.messagesCompanyName = `Messages with ${prospect.company_name}`;
    this.showOverlay = true;
    this.showMessagesPopup = true;
    //this.messages = []; // Reset messages array

    const avatarId = prospect.avatar_id;
    const leadId = prospect.lead_id;

    console.log("url: ", `https://123avatars.com/v2/lead-conversation?avatar_id=${prospect.avatar_id}&lead_id=${prospect.lead_id}&user_id=${this.userId}`);
    axios.get(`https://123avatars.com/v2/lead-conversation?avatar_id=${prospect.avatar_id}&lead_id=${prospect.lead_id}&user_id=${this.userId}`, {
      params: {
        avatar_id: avatarId,
        lead_id: leadId
      }
    })
    .then(response => {
    //this.messages = response.data;
    this.messages.push(response.data[response.data.length-1]);
    this.scrollToBottom();
    console.log("response.data: ", response.data);
    console.log("messages: ", this.messages);
    })
    .catch(error => {
    console.error('Error fetching messages:', error);
    });
  },
  scrollToBottom() {
    this.$nextTick(() => {
    const container = this.$refs.conversationContainer;
    container.scrollTop = container.scrollHeight;
    });
  },
  pauseButton() {
    this.paused = !this.paused;

    const pauseButtonElement = document.getElementById('pause-button');
    
    if (this.paused) {
    // Add 'paused-div' class to the button when paused
    pauseButtonElement.classList.add('paused-div');
    
    this.startPausedTimer();
    this.sendCallStatusUpdate({
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      status: 'PAUSED'
    });
    this.sendUserStatusUpdate({
      userId: this.userId,
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      timestamp: new Date().toISOString(),
      loginLogout: 0,
      duration: this.elapsedTime,
      connectedDuration: this.connectedElapsedTime,
      date: new Date().toISOString().split('T')[0],
      status: 'PAUSED'
    });
    } else {
    // Remove 'paused-div' class when resumed
    pauseButtonElement.classList.remove('paused-div');

    this.stopPausedTimer();
    }
  },
  callIntoVoicemail() {
    this.phoneNumber = '*98';
    this.makeDirectCall();
    this.showDialerModal = true;
    setTimeout(() => {
    this.call.dtmf('1234#');
    setTimeout(() => {
      //this.call.dtmf('1');
    }, 1000);
    }, 12000);
  },
  async playTone(digit) {
    if (!this.context) {
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    }
    const oscillator1 = this.context.createOscillator();
    const oscillator2 = this.context.createOscillator();
    const gainNode = this.context.createGain();

    gainNode.gain.value = 0.1; // Set volume

    oscillator1.frequency.setValueAtTime(this.frequencies[digit][0], this.context.currentTime);
    oscillator2.frequency.setValueAtTime(this.frequencies[digit][1], this.context.currentTime);

    oscillator1.connect(gainNode);
    oscillator2.connect(gainNode);
    gainNode.connect(this.context.destination);

    oscillator1.start();
    oscillator2.start();

    setTimeout(() => {
    oscillator1.stop();
    oscillator2.stop();
    }, 300); // Play the tone for 300ms
  },
  async dialerClick(type, digit) {
    console.log('type: ', type);
    console.log('digit: ', digit);
    switch(type) {
      case 'dial': 
        console.log('this.status: ', this.status);
        if(this.dialerStatus == 'Connected') {
          this.phoneNumber += digit.toString();
          console.log('call: ', this.call);
          console.log('digit: ', this.call.dtmf); 
          this.playTone(digit);
          let result = await this.call.dtmf(digit.toString());
          console.log('result: ', result);
        } else {
          this.phoneNumber += digit.toString();
          this.playTone(digit);
        }
//          this.call.dtmf(digit);
        if(this.phoneNumber.length >= 10) {
          // await this.fetchLeadIdAndLoad();
        }

        break;
      case 'delete':
        this.deleteLastDigit();
        break;
      case 'clear':
        this.clearPhoneNumber();
        break;
      default:
        console.error("Unknown keypad action.");
    }
  },

  async fetchLeadIdAndLoad() {
    try {
      let response = await axios.get(`https://123avatars.com/v2/search?id=${this.salesRepId}&role=${this.role}&query=${encodeURIComponent(this.phoneNumber)}`);
      console.log('response: ', response);
      if(response.data.results[0]) {
        let leadId = response.data.results[0].lead_id;
        this.loadProspect(await this.fetchLead(this.salesRepId, leadId));
      }
    } catch (err) {
      console.error('error loading lead: ', err);
    }
  },
  clearPhoneNumber() {
    this.phoneNumber = '';
  },
  deleteLastDigit() {
    this.phoneNumber = this.phoneNumber.slice(0, -1);
  },
  async makeDirectCallFromInbox(toPhoneNumber, fromPhoneNumber) {
    console.log('toPhoneNumber:', toPhoneNumber);
    console.log('fromPhoneNumber:', fromPhoneNumber);
    this.makeDirectCall(toPhoneNumber, fromPhoneNumber, this.userId, true);
  },
  async makeDirectCall(toPhoneNumber = null, fromPhoneNumber = null, salesRepId = null, fromInbox) {

    console.log('toPhoneNumber:', toPhoneNumber);
    console.log('fromPhoneNumber:', fromPhoneNumber);
    console.log('salesRepId:', salesRepId);
    if (toPhoneNumber) {
      //this.connect();
    }
    const telnyxRTC = this.telnyxRTC;
    console.log("telnyxRTC: ", telnyxRTC);
    if (telnyxRTC) {
      let toNum = this.phoneNumber;
      if (toNum.length === 10) {
        toNum = '+1' + toNum;
      }
      console.log("Dialing number:", toNum);

      if (fromInbox && toPhoneNumber) {
        toNum = toPhoneNumber;
      }

      if (salesRepId) {
       this.salesRepId  = salesRepId;
      }

      if (fromPhoneNumber) {
       this.sipDid  = fromPhoneNumber;
      }

     console.log('fromNum: ', this.sipDid);
     console.log('toNum: ', toNum);

      try {
        let response = await axios.post('https://123avatars.com/v2/add-outbound-call', {
          fromNum: this.sipDid,
          toNum: toNum,
          salesRepId: await AuthService.getCognitoSub(),
          callControlId: ''
        });

        let webHook = 'https://ap.voice.theleadfarm.co/v2/telnyx-callback-dialer';
        
        this.call = telnyxRTC.newCall({
          destinationNumber: toNum,
          callerNumber: this.sipDid,
          record: "record-from-answer",
          record_format: "mp3",
          record_channels: "single",
          webhook_event_url: 'https://123avatars.com/v2/telnyx-callback-dialer'
        });
        console.log('call: ', this.call);
        this.startCurrentCallTimer();
        this.sendCallStatusUpdate({
          salesRepId: this.salesRepId,
          managerId: this.managerId,
          status: 'DIALING'
        });

      
        console.log("call: ", this.call);
      } catch (err) {
        console.error("error initing call: ", err);
      }
    } else {
      console.error("TelnyxRTC is not initialized.");
    }
  },
  updateDialPad() {
    if(this.showDialPad) {
      if(this.call) {
        this.call.hangup();
        this.call = null;
        this.localAudioTrack = null;
        this.isMuted = false;
      }
    }
    this.showDialPad = !this.showDialPad;
    console.log("showDialPad: ", this.showDialPad);
  },
  updateView(currentView) {
    this.currentView = currentView;
    if(this.currentView == 'training-salesvideos' || this.currentView == 'training-recorded' || this.currentView == 'recorded-calls' || this.currentView == 'recorded-videos' || this.currentView == 'training-dayone'|| this.currentView == 'training-services'|| this.currentView == 'training-test') {
      setTimeout(() => {
        this.loadTypeformScript();
      }, 100);
    }
    if(this.currentView == 'ask-ai') {
      setTimeout(() => {
        this.loadAskAIScript()
      }, 100);
    }
  },
  updateQueryParam(key, value) {
    let url = new URL(window.location.href);
    console.log('key: ', key);
    if(value != null && value != 'null' && value != undefined && value != 'undefined') {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
    // // Update the browser's URL without reloading the page
    window.history.replaceState(null, '', url.toString());
  },
  removeElementById(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.parentNode.removeChild(element);
    }
  },
  loadTypeformScript() {
    if (!document.getElementById('typeform-embed-script')) {
      let script = document.createElement('script');
      script.src = 'https://embed.typeform.com/next/embed.js';
      script.id = 'typeform-embed-script';
      script.async = true;
      script.onload = () => {
        this.checkTypeformEmbedReady();
      };
      document.head.appendChild(script);
    } else {
      let script = document.getElementById('typeform-embed-script');
      document.head.removeChild(script);
      this.loadTypeformScript();
    }
  },
  // Check if Typeform SDK is ready
  checkTypeformEmbedReady(retries = 10, delay = 200) {
    // console.log('TF: ', window.tf);
    // if (window.tf) {
    //   // If the embed is ready, initialize it
    //   this.initializeTypeform();
    // } else if (retries > 0) {
    //   // Retry checking after a delay
    //   setTimeout(() => {
    //     this.checkTypeformEmbedReady(retries - 1, delay);
    //   }, delay);
    // } else {
    //   console.error('Typeform Embed SDK failed to load.');
    // }
  },
  initializeTypeform() {
    let url = 'https://123websites.typeform.com/to/ISIDY4M1'; // Replace with your Typeform URL

    const form = window.tf.createWidget('ISIDY4M1', {
      container: document.getElementById('typeform-container'),
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
      source: 'http://localhost:8080',
      onQuestionChanged: (event) => {
        console.log('questionChangedEvent: ', event);
        console.log('response: ', event.response);
        console.log('almostIframe: ', document.getElementById('typeform-container').children[0].children[0]);
      },
      onSubmit: (event) => {
        console.log('onSubmit: ', event);
      },
      onStarted: (event) => {
        console.log('onStarted: ', event);
      }
    });

    // Initialize the question counter
    let questionCount = 0;
  },
  sendProgressUpdate(questionCount) {
    // Replace with your actual webhook or backend API URL
    const webhookUrl = 'https://your-backend-api-url.com';

    fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: `Question ${questionCount} completed`,
        questionIndex: questionCount, // The current question number
      }),
    })
    .then((response) => {
      if (response.ok) {
        console.log('Progress update sent successfully!');
      } else {
        console.error('Error sending progress update:', response.statusText);
      }
    })
    .catch((error) => {
      console.error('Error sending progress update:', error);
    });
  },
  async fetchSchedule() {
    try {
      const response = await axios.get('https://123avatars.com/v2/get-schedule', {
        params: {
          userId: '123009',
          type: 'Wakeups'
        }
      });

      const schedule = response.data;
      const currentTime = new Date();

      // Convert openTime and closeTime to local time
      const openTime = new Date(currentTime);
      const [openHours, openMinutes] = schedule.openTime.split(':');
      openTime.setUTCHours(openHours, openMinutes, 0, 0);

      const closeTime = new Date(currentTime);
      const [closeHours, closeMinutes] = schedule.closeTime.split(':');
      closeTime.setUTCHours(closeHours, closeMinutes, 0, 0);

      console.log("openTime: ", openTime);
      console.log("closeTime: ", closeTime);

      // Adjust for cases where closeTime is past midnight
      if (closeTime < openTime) {
        closeTime.setDate(closeTime.getDate() + 1);
      }

      // Check if current time is within the open and close times
      if (currentTime >= openTime && currentTime <= closeTime) {
        this.showTimeModal = false;
        this.dialerOK = true;
      } else {
        this.showTimeModal = false;
        this.dialerOK = true;
      }

      console.log("showTimeModal: ", this.showTimeModal);

    } catch (error) {
      console.error('Error fetching schedule:', error);
    }
  },
  startScheduleCheck() {
    // Call fetchSchedule immediately
    this.fetchSchedule();

    // Set interval to call fetchSchedule every minute
    setInterval(this.fetchSchedule, 60000);
  },
  async loadExternalScript() {
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://cdn.customgpt.ai/js/chat.js')
    // document.head.appendChild(recaptchaScript)
    // recaptchaScript.onload = async () => {
    //   await CustomGPT.init({ p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
    //   CustomGPT.hide();
    // }
  },
  async loadAskAIScript() {
    // try {
    //   await CustomGPT.init({ div_id: "customgpt_chat", p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
    // } catch (err) {
    //   console.error("error loading new GPT: ", err);
    // }
    // (function() {
    //   let embeddedAIScript = document.createElement('script');
    //   embeddedAIScript.setAttribute('src', 'https://cdn.customgpt.ai/js/embed.js');
    //   embeddedAIScript.setAttribute('div_id', 'customgpt_chat');
    //   embeddedAIScript.setAttribute('div_id', '36587');
    //   embeddedAIScript.setAttribute('div_id', '876cdea81afe212c597fe461d006f0a2');
    //   document.head.appendChild(embeddedAIScript);
    //   embeddedAIScript.onload = async () => {
    //     // Ensure that getDomain is not already declared in this scope
    //     if (typeof getDomain === 'undefined') {
    //       function getDomain() {
    //         // Function implementation
    //       }
    //     }
    //     // await CustomGPT.init({ div_id: "customgpt_chat", p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
    //   };
    // })();
  },
  async loadEmbeddedExternalScript() {
    let embeddedScript = document.createElement('script')
    embeddedScript.setAttribute('src', 'https://embed.typeform.com/next/embed.js')
    document.head.appendChild(embeddedScript)
    embeddedScript.onload =  () => {
      console.log("embeddedScriptLoaded");
    }
  },
  convertToLocalTime(utcDateTime) {
    var local = new Date(utcDateTime);
    return local;
  },
  convertToLocalTimeFormatted(utcDateTime) {
    const local = new Date(utcDateTime);
    let hours = local.getHours();
    const minutes = local.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? 12 : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  },
  getTimeDifferenceInSeconds(timeString) {
    // Parse the input time string into a Date object
    console.log("timeString: ", timeString);
    const pastDate = new Date(timeString);

    console.log("pastDate: ", pastDate);

    // Extract the UTC time components for the past date
    const pastTime = Date.UTC(
      pastDate.getUTCFullYear(),
      pastDate.getUTCMonth(),
      pastDate.getUTCDate(),
      pastDate.getUTCHours(),
      pastDate.getUTCMinutes(),
      pastDate.getUTCSeconds(),
      pastDate.getUTCMilliseconds()
    );

    // Get the current date and time in UTC
    const currentDate = new Date();
    const currentTime = Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds(),
      currentDate.getUTCMilliseconds()
    );

    // Calculate the difference in milliseconds

    const timeDifferenceInMillis = Math.abs(currentTime - pastTime);

    console.log("timeDiffMili: ", timeDifferenceInMillis);

    // Convert the difference from milliseconds to seconds
    const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;

    return timeDifferenceInSeconds;
  },
  async loadLastLoggedTime() {
    try {
      const salesRepId = await AuthService.getCognitoSub();

      const response = await fetch(`https://123avatars.com/v2/loadLastLoggedTime?salesRepId=${salesRepId}&userId=${this.userId}`);

      if (response.ok) {
        const data = await response.json();
        let elapsedOffTime = this.getTimeDifferenceInSeconds(data.startTime);
        console.log("elapsedOffTime: ", elapsedOffTime);
        this.startOffTimer(elapsedOffTime);
      } else {
        console.error('Error loading last logged time: ', await response.text());
      }
    } catch (err) {
      console.error('Error loading last logged time: ', err);
    }
  },
  async autoNext(toNum) {
    console.log("autoNext");
    if (this.call) {
      console.log("this.call: ", this.call);
      if (this.currentProspectNumber == toNum && this.dialerActive != 'CONNECTED') {
        console.log("preNextCall");
        setTimeout(async () => {
          this.nextCall();
        }, 1000);
      }
    }
  },
  async nextProspect() {
    try {
      let userId = '123009';
      let dialerId = await AuthService.getCognitoSub();
      const response = await axios.post('https://123avatars.com/v2/next-prospect', {
        user_id: userId,
        id: dialerId
      });
    } catch (err) {
      console.error("Error calling next prospect.", err);
    }
  },
  toggleAudio(row) {
    if (this.playingCallId === row.id) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio = null;
      this.playingCallId = null;
    } else {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      let url = row.recordingUrl;
      if (url) {
        this.audio = new Audio(url);
        this.audio.play();
        this.playingCallId = row.id;
        this.audio.onended = () => {
          this.audio = null;
          this.playingCallId = null;
        };
      } else {
        alert("No audio URL available");
      }
    }
  },
  convertSecondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min${minutes !== 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''}`;
  },
  async nextCallButtonRoot() {
    this.stopRingback();
    // this.showNextButtons = true;
    if(this.connectedElapsedTime > 120) {
      this.nextCallPopover = true;
    } else {
      this.nextCallButton();
    }
  },
  async nextCallButton() {
    this.ignore = true;
    this.nextCallPopover = false;
    this.nextCall();
  },
  async nextCall() {
    try {
      if (this.dialerStatus != 'Dialer Off') {
        if(!this.dialerOK) {
          if (this.call) {
            await this.call.hangup();
            this.call = null;
            this.localAudioTrack = null;
            this.isMuted = false;
          }
          await this.startDialer();
          return;
        }
        if(!this.paused) {
          if (this.call) {
            await this.call.hangup();
            this.call = null;
            this.localAudioTrack = null;
            this.isMuted = false;
          }
          this.updateDialerStatus('Dialing');
          let userId = '123009';
          this.resetScript();
          this.toRequested = false;
          let payload = {
            salesRepId: this.salesRepId,
            managerId: this.managerId,
            data: { isNew: true }
          };
          this.sendScriptUpdate(payload);
          let response = await axios.post('https://123avatars.com/v2/next-call?id=' + this.salesRepId,
            {
              user_id: userId,
              id: this.salesRepId,
              managerId: this.managerId,
              timezone: this.timezone,
              dialerMode: this.dialerMode,
              industry: this.industry
            }
          );
          if(response.data.flag) {
            this.startDialer();
            alert(response.data.message);
          } else {
            this.loadProspect(response.data, true);
          }
        }
      }

    } catch (err) {
      console.error("Error calling next prospect.", err);
    }
  },
  async startDialer() {
    try {
      console.log("this.dialerActive: ", this.dialerActive);
      if(!this.dialerOK) {
        alert('It is outside of scheduled calling hours.');
        return;
      }
      if (this.dialerActive != 'OFF') {
        this.ignore = true;
        this.dialerActive = 'OFF';
        this.status = 'OFF';
        this.dialerText = 'Start Dialer';
        this.showNextButtons = false;
        this.stopRingback();
        let userId = '123009';
        let dialerId = this.salesRepId;
        let timezone = this.getTimezone();
        if (this.call) {
          await this.call.hangup();
          this.call = null;
          this.localAudioTrack = null;
          this.isMuted = false;
          this.dialerActive = 'OFF';
        }
        this.dialerActive = 'OFF';
        const response = await axios.post('https://123avatars.com/v2/logout-dialer', {
          user_id: userId,
          id: dialerId,
          timezone: timezone
        });
        this.startOffTimer(0);
        this.stopTimer();
        this.stopConnectedTimer();
        this.sendUserStatusUpdate({
          userId: this.userId,
          salesRepId: this.salesRepId,
          managerId: this.managerId,
          timestamp: new Date().toISOString(),
          loginLogout: 0,
          duration: this.elapsedTime,
          connectedDuration: this.connectedElapsedTime,
          date: new Date().toISOString().split('T')[0],
          status: 'OFFLINE'
        });
      } else {
        console.log('dialerMode: ', this.selectedInterestType.name);
        console.log('selectedIndustry: ', this.selectedIndustry);
        if(this.selectedInterestType.name == 'By Industry' && this.selectedIndustry == '') {
          console.log('here');
          alert('You must select an industry to start the dialer.');
        } else {
          // await this.markLoggedInTime();
          this.dialerText = 'Stop Dialer';
          let userId = '123009';
          let dialerId = this.salesRepId; //await AuthService.getCognitoSub();
          let timezone = this.getTimezone();
          if (!userId) {
            throw new Error('User ID not found in localStorage');
          }
          this.dialerActive = 'DIAL';
          const response = await axios.post('https://123avatars.com/v2/login-dialer', {
            user_id: userId,
            id: dialerId,
            managerId: this.managerId,
            timezone: timezone,
            dialerMode: this.selectedInterestType.name,
            industry: this.selectedIndustry.industries
          });
          this.dialerActive = 'ON';
          this.startTimer();
          this.sendUserStatusUpdate({
            userId: this.userId,
            salesRepId: this.salesRepId,
            managerId: this.managerId,
            timestamp: new Date().toISOString(),
            loginLogout: 1,
            date: new Date().toISOString().split('T')[0],
            timezone: this.timezone,
            status: 'ACTIVE'
          });
          
          if(response.data.flag) {
            alert(response.data.message);
          } else {
            this.loadProspect(response.data, true);
          }
        }
      }
    } catch (error) {
      this.dialerActive = 'OFF';
      console.error('Error logging in to dialer:', error)
    }
  },
  updateCompanyQueryParams() {
    this.updateQueryParam('sales_rep', this.currentSalesRepName.split(' ')[0]);
    this.updateQueryParam('industry', this.company.industry);
    this.updateQueryParam('company_name', this.company.company_name);
    this.updateQueryParam('customer_name', this.company.owner);
    this.updateQueryParam('website', this.company.website);
    this.updateQueryParam('lead_id', this.company.leadId);
    this.updateQueryParam('sales_rep_id', this.salesRepId);
    this.updateQueryParam('manager_id', this.managerId);
    this.updateQueryParam('call_id', this.company.callId);
    if(window.tf) {
      window.tf.load();
    } else {
      setTimeout(() => {
        window.tf.load();
      }, 1000);
    }
    //this.removeElementById('typeform-container');
    setTimeout(() => {
      this.updateDataTfHiddenWithUrlParams('typeform-container');
      }, 100);
  },
  updateDataTfHiddenWithUrlParams(containerId) {
    const container = document.getElementById(containerId);
    if (container) {
      const urlParams = new URLSearchParams(window.location.search);
      let hiddenFields = [];

      // Construct the hidden fields string from URL parameters
      urlParams.forEach((value, key) => {
        hiddenFields.push(`${key}=${value}`);
      });

      // Join the fields with a comma and update the data-tf-hidden attribute
      const hiddenFieldsString = hiddenFields.join(',');
      console.log('hiddenFieldsString: ', hiddenFieldsString);
      container.setAttribute('data-tf-hidden', hiddenFieldsString);

      // Find the iframe inside the container
      const iframe = container.querySelector('iframe');

      console.log('iframe: ', iframe);

      if (iframe) {
        const iframeUrl = new URL(iframe.src);

        // Clear existing search params in the iframe src
        iframeUrl.search = '';

        // Append current URL params to the iframe src
        urlParams.forEach((value, key) => {
          iframeUrl.searchParams.set(key, value);
        });

        // Update the iframe src to reload it with new params
        iframe.src = iframeUrl.toString();
      }

      // Optional: Reload the container to apply changes
      const clone = container.cloneNode(true);
      container.parentNode.replaceChild(clone, container);
    }
  },
  autoLogout() {
    this.logout();
  },
  async logout() {
    await AuthService.signOut();
    localStorage.clear();
    window.location.reload();
  },
  logoutUser() {
    AuthService.clearOverrideState();
  },
  getTimeUntil8PMInPST() {
    const now = new Date();
    const date = new Date();
    date.setHours(20, 0, 0, 0); // Set the time to 8:00:00.000 PM
    const utcOffset = date.getTimezoneOffset() / 60; // Get the local timezone offset in hours
    const pstOffset = 8; // PST is UTC-8

    // Adjust the time to PST
    const timeDiff = utcOffset - pstOffset;
    date.setHours(date.getHours() - timeDiff);

    console.log(date); 
    
    let timeUntil8PM = date - now;
    
    if (timeUntil8PM < 0) {
    timeUntil8PM += 24 * 60 * 60 * 1000; // Add 24 hours
    }
    console.log('timeUntil8PMPST: ', timeUntil8PM);
    return timeUntil8PM;
  },
  isDaylightSavingTime(date = new Date()) {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(january, july) !== date.getTimezoneOffset();
  },
  scheduleFunctionFor8PM() {
    const timeUntil8PM = this.getTimeUntil8PMInPST();
    
    setTimeout(() => {
    // The function you want to trigger at 8 PM PST
    this.autoLogout();

    // Set an interval to trigger the function every 24 hours after the first execution
    setInterval(this.autoLogout(), 24 * 60 * 60 * 1000);
    }, timeUntil8PM);
  },
  startPausedTimer() {
    this.stopPausedTimer();
    this.elapsedPausedTime = 0;
    this.elapsedPausedTime = 0;
    this.pausedTimer = setInterval(() => {
    this.elapsedPausedTime++;
    if(this.elapsedPausedTime > 180) {
      this.autoLogout();
    }
    }, 1000);
  },
  stopPausedTimer() {
    if (this.pausedTimer) {
    clearInterval(this.pausedTimer);
    this.pausedTimer = null;
    this.elapsedPausedTime = 0;
    }
  },
  startOffTimer(offElapsedTime) {
    this.stopOffTimer();
    console.log("offElapsedTime: ", offElapsedTime);
    this.offElapsedTime = offElapsedTime;
    this.offTimer = setInterval(() => {
    this.offElapsedTime++;
    }, 1000);
  },
  async startCurrentCallTimer() {
    this.stopCurrentCallTimer();
    this.currentCallElapsedTime = 0;
    this.currentCallTimer = setInterval(() => {
    if(this.status == 'CONNECTED') {
      this.currentCallElapsedTime++;
    }
    }, 1000);
  },
  stopCurrentCallTimer() {
    if (this.currentCallTimer) {
    this.lastConnectedDuration = this.connectedCallFormattedTime;
    clearInterval(this.currentCallTimer);
    this.currentCallTimer = null;
    this.currentCallElapsedTime = 0;
    }
  },
  startConnectedTimer() {
    this.stopConnectedTimer();
    this.connectedElapsedTime = 0;
    this.connectedTimer = setInterval(() => {
    if(this.dialerStatus == 'Connected') {
      this.connectedElapsedTime++;
    } else {
      this.stopConnectedTimer();
    }
    }, 1000);
  },
  stopConnectedTimer() {
    this.currentCallElapsedTime = 0;
    this.connectedElapsedTime = 0;
    if (this.connectedTimer) {
    clearInterval(this.connectedTimer);
    this.connectedTimer = null;
    this.connectedElapsedTime = 0;
    }
  },
  stopTimer() {
    if (this.timer) {
    clearInterval(this.timer);
    this.timer = null;
    this.elapsedTime = 0;
    }
  },
  startTimer() {
    this.stopTimer();
    this.elapsedTime = 0;
    this.elapsedOffTime = 0;
    this.timer = setInterval(() => {
    this.elapsedTime++;
    }, 1000);
  },
  stopOffTimer() {
    if (this.offTimer) {
    clearInterval(this.offTimer);
    this.offTimer = null;
    this.offElapsedTime = 0;
    }
  },
  formatPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.toString();
    if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
    }
    const areaCode = phoneNumber.slice(0, 3);
    const centralOfficeCode = phoneNumber.slice(3, 6);
    const stationCode = phoneNumber.slice(6, 10);
    const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
    return formattedPhoneNumber;
  },
  searchProspectCompany(company_name, state, phone) {
    const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(phone))}`;
    window.open(url, '_blank');
  },
  async fetchLeads(id = null) {
    const params = {
    search: this.searchQuery,
    date: this.dateFilter,
    type: this.typeFilter,
    milestone: this.milestoneFilter,
    timezone: this.timezoneFilter,
    salesRep: this.salesRepFilter,
    shelfLife: this.shelfLifeFilter,
    hours: this.hoursFilter,
    startDate: this.customStartDate,
    endDate: this.customEndDate,
    rowsPerPage: this.rowsPerPage,
    currentPage: this.currentPage,
    };
    console.log('salesRepId: ', this.salesRepId);
    id = this.salesRepId; //await AuthService.getCognitoSub();
    if (id) {
    params.id = id;
    if(this.role == 'Admins' || this.role == 'Managers') {
      params.isOverride = true;
    }
    
    axios.get('https://123avatars.com/v2/leads', { params })
      .then(response => {
      if (this.username == null) {
        this.sipDid = response.data.sip_did
        this.username = response.data.username;
        this.password = response.data.password;
        this.currentSalesRepId = response.data.sales_rep_id;
        this.managerId = response.data.manager_sub;
        this.managerName = response.data.manager_name;
        this.currentSalesRepName = response.data.sales_rep;
        this.calls = response.data.leads;
        this.missedCalls = response.data.missed_calls;
        this.voicemails = response.data.voicemails;21
        //this.userId = response.data.userId;
        if(this.role == 'Admins' || this.role == 'Managers') {
        this.setCompanyData();
        } else {
        this.connect();
        }

        
      }
      this.scriptLink = response.data.scriptLink;
      this.signupLink = response.data.signupLink;
      this.qaLink = response.data.qaLink;
      this.trainingLockerLink = response.data.trainingLockerLink;
      console.log('currentSalesRepId: ', this.currentSalesRepId);
      console.log('managerId: ', this.managerId);
      })
      .catch(error => {
      console.error('Error fetching leads:', error);
      });
    }
  },
  setCompanyData() {
    this.prospect = this.calls[0];
    this.prospect.localDid = this.prospect.from_num;
    console.log("prospect: ", this.prospect);
    this.company = {
    company_name: this.prospect.company_name,
    phone: this.prospect.phone,
    state: this.prospect.state,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    timezone: this.prospect.ttimezone,
    leadId: this.prospect.lead_id,
    campaignId: this.prospect.campaign_id,
    isHotLead: this.prospect.isHotLead ? 'Hot Lead' : 'Clicked',

    address: this.prospect.Company_Street_Address,
    city: this.prospect.Company_City,
    state: this.prospect.Company_State,
    zipcode: this.prospect.Company_Zipcode,
    owner: this.prospect.Owner_Name,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    owner_email: this.prospect.Owner_Email,
    industry: this.prospect.Industry,

    callId: this.prospect.call_id
    };
    let _prospect = this.calls[1];
    _prospect.localDid = this.prospect.from_num;
    this.lastCompany = {
    company_name: _prospect.company_name,
    phone: _prospect.phone,
    state: _prospect.state,
    owner_cell: this.formatPhoneNumber(_prospect.phone),
    timezone: _prospect.ttimezone,
    leadId: _prospect.lead_id,
    campaignId: _prospect.campaign_id,
    isHotLead: _prospect.isHotLead ? 'Hot Lead' : 'Clicked',

    address: _prospect.Company_Street_Address,
    city: _prospect.Company_City,
    state: _prospect.Company_State,
    zipcode: _prospect.Company_Zipcode,
    owner: _prospect.Owner_Name,
    owner_cell: this.formatPhoneNumber(_prospect.phone),
    owner_email: _prospect.Owner_Email,
    industry: _prospect.Industry,

    callId: _prospect.call_id
    };
    this.updateCompanyQueryParams();
    this.fetchNotes(this.prospect);
    this.fetchMessages(this.prospect);
  },
  

  searchTable() {
    //this.fetchLeads();
  },
  filterTable() {
    //this.fetchLeads();
  },
  filterByMilestone(milestone) {
    this.milestoneFilter = milestone;
    // this.fetchLeads();
  },
  applyCustomDateRange() {
    // this.fetchLeads();
  },
  changePage(page) {
    if (page > 0 && page <= this.totalPages) {
    this.currentPage = page;
    //this.fetchLeads();
    }
  },
  updateRowsPerPage() {
    this.currentPage = 1;
    // this.fetchLeads();
  },
  sortTable(property) {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.leads.sort((a, b) => {
    let x = a[property];
    let y = b[property];
    if (property === 'date') {
      x = new Date(a.start_time);
      y = new Date(b.start_time);
    }
    if (direction === 'asc') {
      return x > y ? 1 : x < y ? -1 : 0;
    } else {
      return x < y ? 1 : x > y ? -1 : 0;
    }
    });
    this.sortDirection = direction;
    this.filteredRows = this._filteredRows();
  },
  matchSearchQuery(lead) {
    const query = this.searchQuery.toLowerCase();
    return (
    lead.phone.toLowerCase().includes(query) ||
    lead.company_name.toLowerCase().includes(query) ||
    lead.state.toLowerCase().includes(query) ||
    (lead.tags || []).some(tag => tag.toLowerCase().includes(query))
    );
  },
  matchDateFilter(date) {
    const d = new Date(date);
    const today = new Date();
    switch (this.dateFilter) {
    case 'All':
      return false;
    case 'Today':
      return this.isSameDay(today, d);
    case 'Yesterday':
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      return this.isSameDay(yesterday, d);
    case 'This Week':
      return this.isSameWeek(today, d);
    case 'Last Week':
      const lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      return this.isSameWeek(lastWeek, d);
    case 'This Month':
      return this.isSameMonth(today, d);
    case 'Last Month':
      const lastMonth = new Date();
      lastMonth.setMonth(today.getMonth() - 1);
      return this.isSameMonth(lastMonth, d);
    case 'Last 7 Days':
      const last7Days = new Date();
      last7Days.setDate(today.getDate() - 7);
      return d >= last7Days && d <= today;
    case 'Last 30 Days':
      const last30Days = new Date();
      last30Days.setDate(today.getDate() - 30);
      return d >= last30Days && d <= today;
    case 'Last 90 Days':
      const last90Days = new Date();
      last90Days.setDate(today.getDate() - 90);
      return d >= last90Days && d <= today;
    case 'YTD':
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      return d >= startOfYear && d <= today;
    case 'Custom':
      const startDate = new Date(this.customStartDate);
      const endDate = new Date(this.customEndDate);
      return d >= startDate && d <= endDate;
    default:
      return true;
    }
  },
  isSameDay(date1, date2) {
    return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
    );
  },
  isSameWeek(date1, date2) {
    const startOfWeek1 = new Date(date1);
    startOfWeek1.setDate(date1.getDate() - date1.getDay());
    startOfWeek1.setHours(0, 0, 0, 0);
    const startOfWeek2 = new Date(date2);
    startOfWeek2.setDate(date2.getDate() - date2.getDay());
    startOfWeek2.setHours(0, 0, 0, 0);
    return startOfWeek1.getTime() === startOfWeek2.getTime();
  },
  isSameMonth(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
  },
  matchShelfLifeFilter(shelfLife) {
    switch (this.shelfLifeFilter) {
    case '1 Day':
      return shelfLife === 1;
    case 'Under 30 Days':
      return shelfLife < 30;
    case 'Over 30 Days':
      return shelfLife > 30;
    case 'Over 90 Days':
      return shelfLife > 90;
    case 'Over 180 Days':
      return shelfLife > 180;
    case 'Over 365 Days':
      return shelfLife > 365;
    default:
      return true;
    }
  },
  isDuringBusinessHours(timeZone) {
    const timeZoneMapping = {
    EST: 'America/New_York',
    PST: 'America/Los_Angeles',
    CST: 'America/Chicago',
    MST: 'America/Denver',
    HST: 'Pacific/Honolulu',
    AKST: 'America/Anchorage',
    };
    const ianaTimeZone = timeZoneMapping[timeZone];
    if (!ianaTimeZone) {
    throw new Error(`Unrecognized timezone abbreviation: ${timeZone}`);
    }
    const now = new Date();
    const options = {
    timeZone: ianaTimeZone,
    hour: '2-digit',
    hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(now);
    const hour = parseInt(parts.find(p => p.type === 'hour').value, 10);
    return hour >= 9 && hour < 17;
  },
  getLocalTime(date, timeZone) {
    const timeZoneMapping = {
    EST: 'America/New_York',
    PST: 'America/Los_Angeles',
    CST: 'America/Chicago',
    MST: 'America/Denver',
    HST: 'Pacific/Honolulu',
    AKST: 'America/Anchorage',
    };
    const ianaTimeZone = timeZoneMapping[timeZone];
    if (!ianaTimeZone) {
    return new Date(date).toLocaleTimeString();
    }
    return new Date(date).toLocaleTimeString('en-US', { timeZone: ianaTimeZone });
  },
  updateLead(row) {
    axios.put(`https://123avatars.com/v2/leads/${row.sales_lead_id}`, row)
    .then(response => {
      console.log('Lead updated:', response.data);
    })
    .catch(error => {
      console.error('Error updating lead:', error);
    });
  },
  openNotesPopup(companyName, leadId) {
    this.notesCompanyName = `Notes for ${companyName}`;
    this.currentLeadId = leadId;
    this.showOverlay = true;
    this.showNotesPopup = true;
    this.fetchNotes();
  },
  closePopup() {
    this.showOverlay = false;
    this.showNotesPopup = false;
    this.showMessagesPopup = false;
    this.currentLeadId = null;
    this.fetchNotes();
  },

  addTag(rowIndex) {
    const newTag = this.filteredRows[rowIndex].newTag.trim();
    if (newTag) {
    this.filteredRows[rowIndex].tags.push(newTag);
    this.filteredRows[rowIndex].newTag = '';
    this.updateLead(this.filteredRows[rowIndex]);
    }
  },
  removeTag(rowIndex, tagIndex) {
    this.filteredRows[rowIndex].tags.splice(tagIndex, 1);
    this.updateLead(this.filteredRows[rowIndex]);
  },
  async connect() {
    console.log('did: ', this.callerIdName);
    this.telnyxRTC = new TelnyxRTC({
    login: this.username,
    password: this.password,
    callerIdName: this.callerIdName,
    callerIdNumber: this.callerIdNumber,
    debug: this.enableDebugging,
    logLeveL: 'debug'
    });

    this.telnyxRTC.on('telnyx.notification', async (notification) => {
      

    // try {
    //   const call = notification.call;
    //   if (notification.type === 'callUpdate' && call.state === 'ringing') {
    //   if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
    //     call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
    //   ) {

    //   }
    //   }
    // } catch (err) {
    //   console.log("Error answering call: ", err);
    // }

    // if(true) {
    //   console.log('direction: ', notification.call);
    //   console.log("inboundNotification: ", notification);
    //   this.incomingCall = true;
    //   console.log('incomingCall: ', notification.call);
    // }
    
    if(notification.call?.direction == 'outbound' && this.showDialerModal) {
      if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
      this.attachStreamToAudioElement(this.call.remoteStream);
      this.updateDialerStatus('Dialing');
      }
      if (notification.type === 'callUpdate' && this.call.state === 'early') {
      if (this.call) {
        this.attachStreamToAudioElement(this.call.remoteStream);
        this.updateDialerStatus('Dialing');
      }
      }
      if (notification.type === 'callUpdate' && this.call.state === 'active') {
       this.processConnectedCall();
        this.localAudioTrack = this.call.localStream.getAudioTracks()[0];
        //this.localAudioTrack.enabled = false;\
        console.log('callConnectedONE: ', this.localAudioTrack);
        if(this.role == 'Roaming_Closer') {
          console.log('callConnected: ', this.localAudioTrack);
         // this.localAudioTrack.enabled = false;
        //  this.toggleMuted(true);
        }
      }
      if (notification.type === 'callUpdate' && this.call.state === 'destroy') {
      if (this.call) {
        this.call = null;
        this.localAudioTrack = null;
        this.isMuted = false;
        if (this.dialerActive != 'OFF') {
        //this.dialerActive = 'DIAL';
        }
      }
      }
      if (notification.type === 'callUpdate' && this.call?.state === 'hangup') {
      if(notification.call.direction == 'outbound') {
        let prospectNumber = '';
        if(this.prospect) {
        prospectNumber  = '+1' + this.prospect.phone;
        } else {
        prospectNumber = this.phoneNumber;
        if(prospectNumber.length == 10) {
          prospectNumber = '+1' + prospectNumber;
        }
        }
        console.log('prospectNumber: ', prospectNumber);
        if(notification.call.options.destinationNumber == prospectNumber) {
        this.handleCallHangup(this.call.cause);
        }
      }
      }
    }

    if(notification.call?.direction == 'outbound' && !this.showDialerModal) {
      this.call = notification.call;
      console.log("outboundNotification: ", notification);
      if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
      this.attachStreamToAudioElement(this.call.remoteStream);
      this.updateDialerStatus('Dialing');
      }
      if (notification.type === 'callUpdate' && this.call.state === 'trying') {
      this.playRingback();
      if (this.call) {
        this.updateDialerStatus('Dialing');
      }
      }
      if (notification.type === 'callUpdate' && this.call.state === 'early') {
      if (this.call) {
        this.attachStreamToAudioElement(this.call.remoteStream);
        this.updateDialerStatus('Dialing');
      }
      }
      if (notification.type === 'callUpdate' && this.call.state === 'active') {
        this.processConnectedCall();
        console.log('callConnectedTWO: ', this.localAudioTrack);
        this.localAudioTrack = this.call.localStream.getAudioTracks()[0];
        if(this.role == 'Roaming_Closer') {
          console.log('callConnected: ', this.localAudioTrack);
          console.log('autoMute: ', this.autoMute);
          if(this.autoMute) {
            this.localAudioTrack.enabled = false;
            this.toggleMuted();
            this.autoMute = false;
          }
        }
        
      }
      if (notification.type === 'callUpdate' && this.call.state === 'destroy') {
      if (this.call) {
        this.call = null;
        this.localAudioTrack = null;
        this.isMuted = false;
        if (this.dialerActive != 'OFF') {

        }
      }
      }
      if (notification.type === 'callUpdate' && this.call?.state === 'hangup') {
      if(notification.call.direction == 'outbound') {
        let prospectNumber = '';
        if(this.prospect) {
        prospectNumber  = '+1' + this.prospect.phone;
        } else {
        prospectNumber = this.phoneNumber;
        if(prospectNumber.length == 10) {
          prospectNumber = '+1' + prospectNumber;
        }
        }
        console.log('prospectNumber: ', prospectNumber);
        if(notification.call.options.destinationNumber == prospectNumber) {
        this.handleCallHangup(this.call.cause);
        }
      }
      }
    }
    });
    await this.telnyxRTC.connect();
  },
  processConnectedCall() {
    this.attachStreamToAudioElement(this.call.remoteStream);
    // MUTE
    // this.localAudioTrack = this.call.localStream.getAudioTracks()[0];
    // this.localAudioTrack.enabled = false;
    //if(this.role != 'Roaming_Closer') {
      this.startConnectedTimer();
      this.updateDialerStatus('Connected');
      this.sendCallStatusUpdate({
        salesRepId: this.salesRepId,
        managerId: this.managerId,
        status: 'ACTIVE',
        callId: this.company.callId
      });
    //}
  },
  handleCallHangup(cause) {
    console.log('ignore: ', this.ignore);
    this.stopRingback();
    let isNew = !this.isBarging;
    console.log('isBarging:Hangup: ', this.isBarging);
    console.log('isBarging:Hangup: ', isNew);
    let payload = {
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    data: { isNew: true }
    };
    this.sendScriptUpdate(payload);
    if (cause === 'NORMAL_CLEARING') {
    this.updateDialerStatus('Hung Up');
    if (!this.ignore) {
      
      // setTimeout(async () => {
      //   await this.nextCall();
      // }, 1000);
    } else {
      this.ignore = false;
    }
    } else if (cause === 'USER_BUSY') {
    this.status = 'USER BUSY';
    } else if (cause === 'CALL_REJECTED') {
    this.status = 'CALL REJECTED';
    } else if (cause === 'DECLINE') {
    this.status = 'DECLINED';
    } else {
    console.log('The call was ended with cause:', cause);
    }
    this.sendCallStatusUpdate({
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    status: 'INACTIVE',
    callDuration: this.connectedElapsedTime,
    callEndTime: new Date().toISOString(),
    });
    this.stopConnectedTimer();
    
  },
  attachStreamToAudioElement(stream) {

    try {
    if(stream) {
      this.stopRingback();
    }
    const audioElement = document.getElementById('telnyx-audio');
    audioElement.srcObject = stream;

    } catch (err) {
    console.error("error merging streams: ", err);
    }
  },
  stopRingback() {
    const ringbackElement = document.getElementById('ringback-audio');
    ringbackElement.pause();
    ringbackElement.currentTime = 0;
  },
  async playRingback() {
    const audioElement = document.getElementById('ringback-audio');
    audioElement.src = 'https://123avatars.com/uploads/US_ringback_tone.ogg';
    await audioElement.play();
    console.log('audioElement: ', audioElement);
  },
  dialPadPress(key) {
    this.callDestination += key;
  },
  makeCall(fromNum, toNum, fromInbox = null) {
    if (fromInbox) {
      //this.connect();
    }
    console.log('this.telnycRTC', this.telnyxRTC);
    if (this.telnyxRTC) {
    console.log("toNum: ", toNum);
    if (toNum.length == 10) {
      toNum = '+1' + toNum;
    }

    console.log('LIVE TRANSFER: ', this.liveTransfer);
    let webHook = 'https://ap.voice.theleadfarm.co/v2/telnyx-callback-dialer';
    if(this.liveTransfer?.isAI) {
      webHook = 'https://ap.voice.theleadfarm.co/v2/telnyx-callback-dialer';
    }
    console.log('LIVE TRANSFER: ', webHook);

    this.call = this.telnyxRTC.newCall({
      destinationNumber: toNum,
      callerNumber: fromNum
    });

    this.startCurrentCallTimer();
    this.sendCallStatusUpdate({
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      status: 'DIALING',
      callId: this.company.callId
    }); 

    this.currentProspectNumber = toNum;
    }
  },

  // initialize user
  async initUser() {
    this.role =  await AuthService.getUserRole();
    this.userId = await AuthService.getCognitoSub();
    console.log('initUser: ', this.userId);
    this.isUserOverride = AuthService.isOverridden();
    this.selectedRole = this.role;

    console.log('role: ', this.role);
    console.log('userId: ', this.userId);

    const savedView = localStorage.getItem('currentView');
    if (savedView) {
      this.currentView = savedView;
      if(this.role == null || this. role == 'Operators') {
        this.salesRepId = this.userId;
        this.currentSalesRepId = this.userId;
      } else if (this.role == 'Roaming_Closer') {
        this.salesRepId = this.userId;
        this.currentSalesRepId = this.userId;
      }
    } else {
      if(this.role == 'Recruiter_Managers') {
        this.currentView = 'users';
      } else if(this.role == 'Recruiters') {
      this.currentView = 'users';
      } else if(this.role == 'Managers') {
      this.currentView = 'panel';
      } else if(this.role == 'Admins') {
      this.currentView = 'scoreboard';
      } else {
        this.currentView = 'dialer'; //reports-TOReports'; // change back to dialer
        this.salesRepId = this.userId;
        this.currentSalesRepId = this.userId;
      }
    }
    this.timezone = this.getTimezone();
    console.log(this.timezone);
  },
  getTimezone() {
    // Get the local timezone
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // List of US timezones
    const usTimezones = [
    'America/New_York',    // EST/EDT
    'America/Chicago',     // CST/CDT
    'America/Denver',      // MST/MDT
    'America/Phoenix',     // MST (no DST)
    'America/Los_Angeles', // PST/PDT
    'America/Anchorage',   // AKST/AKDT
    'America/Adak',        // HST/HDT
    'Pacific/Honolulu',    // HST (no DST)
    ];

    // Check if the local timezone is a US-based timezone
    if (usTimezones.includes(localTimezone)) {
    return localTimezone;
    }

    // If not a US timezone, return PST/PDT
    return 'America/Los_Angeles';
  },
  async fetchUserData() {
    try {
      console.log('this.role: ', this.role);
    
    switch(this.role) {
      case null: 
      case 'Operators':
      case 'Roaming_Closer':
        await this.fetchRepData();
        break;      
      default: 
       await this.fetchManagerData();
    }
    } catch (err) {
    console.error('error fetching user data.', err);
    }
  },
  async fetchManagerData() {
    try {
      console.log('managerId: ', this.managerId);
      // Make the GET request to fetch the manager data
      const response = await axios.get(`https://123avatars.com/v2/manager?id=${this.userId}`);

      // Check if the response contains data
      if (response && response.data) {
      this.manager = response.data; // Set manager data to component's state
      this.userName = response.data.firstName + ' ' + response.data.lastName;
      this.username = response.data.sip_username;
      this.callbackNum = '(949) 658-0444';
      this.password = response.data.sip_password;
      this.sipDid = response.data.sip_did;
      this.callerIdName = response.data.sip_did;
      this.callerIdNumber = response.data.sip_did;
      this.timeZone = response.data.timezone;
      await this.connect();
      } else {
      this.error = 'No data found for the given manager ID';
      }
    } catch (err) {
      // Handle any errors
      console.error('Error loading manager:', err);
      this.error = 'An error occurred while fetching manager details';
    }
  },
  async fetchRepData() {
    try {
    const params = {
      search: this.searchQuery,
      date: this.dateFilter,
      type: this.typeFilter,
      milestone: this.milestoneFilter,
      timezone: this.timezoneFilter,
      salesRep: this.salesRepFilter,
      shelfLife: this.shelfLifeFilter,
      hours: this.hoursFilter,
      startDate: this.customStartDate,
      endDate: this.customEndDate,
      rowsPerPage: this.rowsPerPage,
      currentPage: this.currentPage,
      id: this.userId
    };

    
    let response = await axios.get('https://123avatars.com/v2/rep-login', { params });
    if(response.status == 200) {
      this.sipDid = response.data.sip_did;
      this.callerIdNum = response.data.sip_id;
      this.callerIdName = response.data.sip_did;
      this.username = response.data.username;
      this.password = response.data.password;
      // this.currentSalesRepId = response.data.sales_rep_id;
      this.currentSalesRepName = response.data.sales_rep;
      this.userName = response.data.sales_rep;
      this.managerId = response.data.manager_sub;
      this.managerName = response.data.manager_name;
      this.managerDid = response.data.manager_did;
      this.missedCalls = response.data.missed_calls;
      this.voicemails = response.data.voicemails;
      this.numUnread = response.data.unread_count;
      this.credits = response.data.credits;
      // this.lastCallEndTime = response.data.last_call_end_time;
      this.callbackNum = '(949) 658-0444'; //response.data.callback_num;
      this.companyId = response.data.user_id;
      //this.userId = response.data.user_id;
      this.scriptLink = response.data.scriptLink;
      this.signupLink = response.data.signupLink;
      this.qaLink = response.data.qaLink;
      this.trainingLockerLink = response.data.trainingLockerLink;
      this.timeZone = response.data.timezone;
      if(this.role == 'Admins' || this.role == 'Managers') {
      this.setCompanyData();
      } else {
        await this.connect();
      }
    } else {
      console.error("Error fetching user: ", response.status, response.data);
    }

    } catch (err) {
    console.error("Error fetching user: ", err);
    }
  },
  configureSockets() {
    SocketService.connect();
    SocketService.register(this.salesRepId);
    SocketService.on('data-update', this.updateLeadData);
    SocketService.on('update-barge', this.updateBarge);
    if(this.role !== 'Managers') {
      SocketService.on('new_message', this.handleMessageNotification);
    }
    if(this.role == 'Roaming_Closer') {
      SocketService.register(this.userId);
      SocketService.on('request-to', this.notifyTORequest);
      SocketService.on('lead-claimed', this.processLeadClaimed);
    }
  },
  updateBarge(data) {
    console.log('isBarging: ', this.isBarging);
    if(data.status === 'open') {
      this.isBarging = true;
      this.toRequested = false;
    }
    if(data.status === 'close') {
      this.isBarging = false;
    }
  },
  sendScriptUpdate(data) {
    const event = 'script-update';
    try {
    // Emit script-update update to the SocketService
    SocketService.emit(event, data);
    console.log(`Emitted script-update event: ${event}`, data);
    } catch (error) {
    console.error(`Failed to script-update event: ${error.message}`);
    }
  },
  sendUserStatusUpdate(data) {
    const event = 'user-status';
    try {
    // Emit user-status update to the SocketService
    SocketService.emit(event, data);
    console.log(`Emitted user-status event: ${event}`, data);
    } catch (error) {
    console.error(`Failed to emit user-status event: ${error.message}`);
    }
  },
  // Function to send call-status updates with data passed in
  sendCallStatusUpdate(data) {
    const event = 'call-status';
    try {
    // Emit call-status update to the SocketService
    SocketService.emit(event, data);
    console.log(`Emitted call-status event: ${event}`, data);
    } catch (error) {
    console.error(`Failed to emit call-status event: ${error.message}`);
    }
  },
  async sendHeartbeat() {
    try {
    let isOnCall = this.call && this.dialerStatus == 'Connected';
    const payload = {
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      loggedInTime: this.elapsedTime,
      talkTime: this.connectedElapsedTime,
      currentCallTime: this.currentCallElapsedTime,
      onCall: isOnCall ? true : false,
      callId: isOnCall ? this.company.callId : null,
      toRequested: this.toRequested ? true : false,
      callStartTime: this.callStartTime
    };

    // Emit heartbeat data via WebSocket
    const event = 'heartbeat';
    SocketService.emit(event, payload);
    } catch (err) {
    console.error("Error sending heartbeat via socket.", err);
    }
  },
  startHeartbeat() {
    // Send an initial heartbeat
    this.sendHeartbeat();

    // Set up a recurring heartbeat every 2 seconds (2000 milliseconds)
    if (!this.heartbeatInterval) {
    this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), 4000);
    }
  },
  stopHeartbeat() {
    if (this.heartbeatInterval) {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = null;
    }
  },
  updateLeadData(data) {
    try {
    if(this.company.leadId == data.leadId) {
      this.company.company_name = data.companyName;
      this.company.website = data.website;
      this.company.owner = data.ownerName;
    }
    } catch (err) {
    console.error("error updating prospect data: ", err);
    }
  },
  async fetchCallbacks() {
    const params = { id: this.salesRepId };
    axios.get('https://123avatars.com/v2/callbacks', { params })
    .then(response => {
      this.mapInputDataToCallbackItems(response.data);
    })
    .catch(error => {
      console.error('Error fetching stats:', error);
    });
  },
  async fetchStats() {
    const params = { uuid: this.salesRepId };
    axios.get('https://123avatars.com/v2/stats', { params })
    .then(response => {
      this.lastCallEndTime = response.data.user.lastCallEndTime;
      this.mapInputDataToStatItems(response.data);
      //this.mapInputDataToSalesItems(response.data);
      console.log('lastCallEndTime: ', this.lastCallEndTime);
      //this.stats.today = response.data.today;
      //this.stats.thisWeek = response.data.thisWeek;
      if (this.salesNumber != response.data.today.teamTOs) {
        this.salesNumber = response.data.today.teamTOs;
        this.notifications = [
				{ title: 'Team TOs', number: this.salesNumber, text: 'Just came in', alert: false }
			];
        this.showNotification = true;
        setTimeout(async () => {
          this.showNotification = false;
        }, 10000);
      }
      if(this.salesNumber == 0 && response.data.today.teamTOs == 0) {

      }
      this.upcomingCallback = response.data.upcomingCallback;
    })
    .catch(error => {
      console.error('Error fetching stats:', error);
    });
  },

  // initialize data
  fetchIndustries() {
    axios.get(`https://123avatars.com/v2/industries`)
    .then(response => {
      this.industries = [];
      response.data.forEach((industry) => {
      // industry.displayName = industry.industries; // + '(' + industry.count + ')';
      // this.industries.push(industry.displayName);
      this.industries.push(industry);
      });
      this.industries_items = this.industries;
      console.log('industries: ', this.industries_items);
    })
    .catch(error => {
      console.error("There was an error fetching the industries!", error);
    });
  },
  async fetchObjections() {
    try {
    const response = await axios.get('https://123avatars.com/v2/objections');
    this.mapInputDataToObjectionItems(response.data);
    console.log('objections: ', this.objection_items);
    } catch (error) {
    console.error('Error fetching objections:', error);
    }
  },

  // external links
  async openSignUpPage() {
    let signupLink = this.signupLink;
    signupLink += `?rep_id=${this.salesRepId}&campaign_id=${this.company.campaignId}&lead_id=${this.company.leadId}`;
    window.open(signupLink, '_blank');
  },
  openScriptPage() {
    window.open(this.scriptLink, '_blank');
  },
  openQAPage() {
    window.open(this.qaLink, '_blank');
  },
  openTrainingLockerPage() {
    window.open(this.trainingLockerLink, '_blank');
  },

  // prospect operations
  async blacklist() {
    try {
    this.company = {
    company_name: this.prospect.company_name,
    phone: this.prospect.phone,
    address: this.prospect.Company_Street_Address,
    city: this.prospect.Company_City,
    state: this.prospect.Company_State,
    zipcode: this.prospect.Company_Zipcode,
    owner: this.prospect.Owner_Name,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    owner_email: this.prospect.Owner_Email
    };

    let email = this.company.owner_email ? this.company.owner_email : '';
    let response = await axios
    .get(
    "https://123avatars.com/backend/setAvatarBlacklist.php?u=" + this.userId + "&no=" + this.company.phone + "&label=" + '1'+ "&no2=" + '' + "&mail=" + email + "&mail2=" + this.mail2
    );
    if(response.status == 200) {
    this.$toasted.show(" Added to blacklist successfully ", {
      theme: "outline",
      position: "top-right",
      type: "success",
      icon: "check",
      duration: 2000,
    });
    } else {
    this.$toasted.show("Failed to add to blacklist.", {
      theme: "outline",
      position: "top-right",
      type: "danger",
      icon: "cross",
      duration: 2000,
    });
    }
    this.nextCallButton();
    } catch (err) {
    console.error("error adding company to blacklist: ", err);
    }
  },

  // chat operations
  handleMessageNotification(event) {
    console.log('event: ', event);
    // Select the element
    const chatElement = document.getElementById('managerchat');
    if(!chatElement.classList.contains('show')) {
    const linkElement = document.getElementById('chat-link');
    linkElement.click();
    }
  },

  // dialer operations
  async handlePopoverToggle() {
    // Check if the variable allows the popover to open
    if (this.dialerText == 'Start Dialer') {
    // If the popover is currently hidden, show it
    //await this.fetchIndustries();
    this.$refs.industryPopover.$emit('open');
    } else {
    console.log("popoverToggle: ");
    // Otherwise, hide it just to ensure it's closed
    this.$refs.industryPopover.$emit('close');
    this.ignore = true;
    this.dialerText = 'Start Dialer';
    this.updateDialerStatus('Dialer Off');
    this.stopRingback();
        let payload = {
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    data: { isNew: true }
    };
    this.sendScriptUpdate(payload);
    let userId = '123009';
    if (this.call) {
      await this.call.hangup();
      this.call = null;
      this.localAudioTrack = null;
      this.isMuted = false;
      this.dialerActive = 'OFF';
    }
    this.dialerActive = 'OFF';
    const response = await axios.post('https://123avatars.com/v2/logout-dialer', {
      user_id: userId,
      id: this.salesRepId,
      timezone: timezone
    });
    this.startOffTimer(0);
    this.stopTimer();
    this.stopConnectedTimer();
    this.sendUserStatusUpdate({
      userId: this.userId,
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      timestamp: new Date().toISOString(),
      loginLogout: 0,
      duration: this.elapsedTime,
      connectedDuration: this.connectedElapsedTime,
      date: new Date().toISOString().split('T')[0],
      status: 'OFFLINE'
    });
    }
  },
  resetScript() {
    this.formData = {
    name: '',
    confirmation: '',
    businessName: '',
    businessDuration: '',
    hasWebsite: '',
    hasDoneOnlineMarketing: '',
    clientsFrom: [],
    advertisingBudget: [],
    wantMoreBusiness: '',
    boostOnlinePresence: ''
    };
    this.countdownSeconds = 120; // initial countdown time in seconds
    this.futureTimestamp = null;
    this.scriptTimer = 120;
    this.currentStep = 1;
    let payload = {
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    data: this.formData
    };
    this.sendScriptUpdate(payload);
  },
  async startDialer(industry) {
    if(this.dialerText == 'Start Dialer') {
    this.industry = industry;
    this.$refs.industryPopover.$emit('close');
    if(this.industry == '') {
      alert('You must select an industry to start the dialer.');
    } else {
      this.dialerText = 'Stop Dialer';
      let userId = '123009';
      this.updateDialerStatus('Dialing');
      this.resetScript();
      const response = await axios.post('https://123avatars.com/v2/login-dialer', {
      user_id: userId,
      id: this.salesRepId,
      managerId: this.managerId,
      timezone: this.timezone,
      dialerMode: this.dialerMode,
      industry: this.industry
      });
      this.startTimer();
      this.sendUserStatusUpdate({
      userId: userId,
      salesRepId: this.salesRepId,
      managerId: this.managerId,
      timestamp: new Date().toISOString(),
      loginLogout: 1,
      date: new Date().toISOString().split('T')[0],
      timezone: this.timezone,
      status: 'ACTIVE'
      });
      
      if(response.data.flag) {
      alert(response.data.message);
      } else {
      this.loadProspect(response.data, true);
      }
    }
    } else {
//        this.$refs.industryPopover.$emit('close');
    
    }
  },
  loadProspect(prospect, initCall) {
    this.prospect = prospect;
    this.mapProspectData(prospect);

    // if(this.company) {
    //   this.lastCompany = this.company;
    //   this.lastCompany.lastConnectedDuration = this.lastConnectedDuration;
    // }

    this.company = {
    company_name: this.prospect.company_name,
    phone: this.prospect.phone,
    state: this.prospect.state,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    timezone: this.prospect.ttimezone,
    leadId: this.prospect.lead_id,
    azureContactId: this.prospect.azure_contact_id,
    campaignId: this.prospect.campaign_id,
    isHotLead: this.prospect.isHotLead ? 'Hot Lead' : 'Clicked',
    milestone: this.prospect.milestone,

    address: this.prospect.Company_Street_Address,
    city: this.prospect.Company_City,
    state: this.prospect.Company_State,
    zipcode: this.prospect.Company_Zipcode,
    owner: this.prospect.Owner_Name,
    owner_cell: this.formatPhoneNumber(this.prospect.phone),
    owner_email: this.prospect.Owner_Email,
    industry: this.prospect.Industry,
    website: this.prospect.Website,

    callId: this.prospect.call_id
    };
    this.formData.isNew = false;
    this.formData.name = this.company.owner;
    if(this.company.website) {
    this.formData.hasWebsite = true;
    this.formData.website = this.company.website;
    }
    this.formData.state = this.company.state;
    this.formData.industry = this.company.industry;
    this.formData.businessName = this.company.company_name;
    let payload = {
    salesRepId: this.salesRepId,
    managerId: this.managerId,
    data: this.formData,
    callId: this.company?.callId
    };
    this.sendScriptUpdate(payload);
    this.updateCompanyQueryParams();
    this.fetchNotes(prospect);
    this.fetchCalls(prospect);
    this.fetchMessages(prospect);
    console.log('prospect: ', this.prospect);
    if(initCall) {
    this.makeCall(this.prospect.localDid, this.prospect.phone);
    }
  },
  fetchNotes() {
    this.notes = [];
    const userId = '123009';
    const leadId = this.prospect.lead_id;
    axios.get(`https://123avatars.com/v2/notes`, { params: { leadId: leadId, userId } })
    .then(response => {
      this.notes = response.data.notes;
      this.mapNotesToListItems(this.notes);
    })
    .catch(error => {
      console.error('Error fetching notes:', error);
      this.notes = [];
    });
  },
  saveNote() {
    console.log('savenotes');
    if (!this.newNoteText.trim()) return;
    const newNote = {
    userId: localStorage.getItem("userId"),
    date: new Date().toLocaleDateString(),
    time: new Date().toLocaleTimeString(),
    text: this.newNoteText.trim(),
    sales_rep: this.currentSalesRepName
    };
    axios.post(`https://123avatars.com/v2/leads/${this.company.leadId}/notes`, newNote)
    .then(response => {
      this.newNoteText = '';
      this.fetchNotes();
      //this.fetchLeads();
      this.$refs.newNotePopover.$emit('close');
    })
    .catch(error => {
      console.error('Error saving note:', error);
    });
  },
  deleteNote(index, noteId) {
    axios.delete(`https://123avatars.com/v2/leads/${this.company.leadId}/notes/${noteId}`)
    .then(response => {
      const itemIndex = this.note_list_items.findIndex(item => item.id === noteId);
      if (itemIndex !== -1) {
        this.note_list_items.splice(itemIndex, 1);
      }
      //this.fetchLeads();
    })
    .catch(error => {
      console.error('Error deleting note:', error);
    });
  },
  fetchCalls() {
    this.calls = [];
    const userId = localStorage.getItem('userId');
    const leadId = this.prospect.lead_id;
    let params = {
      // id: this.salesRepId,
      leadId: leadId,
      search: this.searchQuery,
      date: this.dateFilter,
      type: this.typeFilter,
      milestone: this.milestoneFilter,
      timezone: this.timezoneFilter,
      salesRep: this.salesRepFilter,
      salesRepId: this.salesRepId,
      shelfLife: this.shelfLifeFilter,
      hours: this.hoursFilter,
      startDate: this.customStartDate,
      endDate: this.customEndDate,
      rowsPerPage: this.rowsPerPage,
      currentPage: this.currentPage,
      toFilter: this.toFilter,
      leadType: 'two-min',
      isOverride: true
    };
    axios.get('https://123avatars.com/v2/leads', { params })
    .then(response => {
    this.calls = response.data.leads;
    this.totalRows = response.data.total;
    this.totalCount = response.data.total;
    this.mapCallsToCallList(this.calls);
    })
    .catch(error => {
    console.error('Error fetching calls:', error);
    });
  },
  fetchMessages(prospect) {
    this.messagesCompanyName = `Messages with ${prospect.company_name}`;
    this.showOverlay = true;
    this.showMessagesPopup = true;
    this.messages = []; // Reset messages array

    const avatarId = prospect.avatar_id;
    const leadId = prospect.lead_id;

    axios.get(`https://123avatars.com/v2/lead-conversation`, {
    params: {
      avatar_id: 108,
      lead_id: leadId,
      user_id: this.userId
    }
    })
    .then(response => {
    this.messages = response.data;
    })
    .catch(error => {
    console.error('Error fetching messages:', error);
    });
  },

  // 
  timeSinceLastCall(lastCallEndTime) {
    const end = this.lastCallEndTime || new Date();
    const timeDifference = this.now - new Date(end); // Difference in milliseconds
    let timeDiff = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
    return timeDiff;
  },
  connectedCallTime() {
    if (this.dialerStatus == 'Connected') {
    const timeDifference = this.now - this.callStartTime; // Corrected the subtraction
    let timeDiff = Math.floor(timeDifference / 1000); // Time difference in seconds
    console.log('timeDiff: ', timeDiff);
    if(timeDiff > 60) {
      const dotInner = document.querySelector('.dialer-ui .dot-inner');
      dotInner.classList.remove('off');
      dotInner.classList.remove('dialing');
      dotInner.classList.remove('on');
      dotInner.classList.add('oneMin');
    }
    
    const hours = Math.floor(timeDiff / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((timeDiff % 3600) / 60).toString().padStart(2, '0');
    const seconds = (timeDiff % 60).toString().padStart(2, '0');
    if(hours == -1 && minutes == -1 && seconds == -1) {
      return `00:00:00`;
    } else {
      return `${hours}:${minutes}:${seconds}`;
    }
    } else {
    return `00:00:00`;
    }
  },
  mapInputDataToObjectionItems(inputData) {
    inputData.forEach((item, index) => {
    // Create a key for the current objection (e.g., 'objection1', 'objection2', etc.)
    const objectionKey = `objection${index + 1}`;

    if (this.objection_items[objectionKey]) {
      // Update text1 and text2 based on the input data
      this.objection_items[objectionKey].text1 = item.objection;
      this.objection_items[objectionKey].text2 = item.rebuttal;
    } else {
      this.objection_items[objectionKey] = {};
      this.objection_items[objectionKey].text1 = item.objection;
      this.objection_items[objectionKey].text2 = item.rebuttal;
    }
    });
  },
  mapInputDataToCallbackItems(inputData) {
    // Get today's date in the format 'YYYY-MM-DD'
    const today = new Date().toISOString().split('T')[0];
    
    // Find the data entry for today
    const todayData = inputData.find(item => item.date === today);

    console.log('todayData: ', todayData);
    
    // Clear the existing callback items
    this.callback_items.length = 0;
    
    // If there is data for today, map it to the callback_items array
    if (todayData) {
    let upcomingCallback = false;
    todayData.data.forEach(callback => {
      this.callback_items.push({
      name: callback.company,
      cleanPhone: callback.contactPhone,
      phone: this.formatPhoneNumber(callback.contactPhone),
      time: new Date(callback.callbackTime).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }),
      leadId: callback.leadId
      });
      upcomingCallback = this.isWithinNextTenMinutes(callback.callbackTime);
    });
    const callbackElement = document.getElementById('callbacks-right');
    if(upcomingCallback) {
      if(!callbackElement.classList.contains('notice')) {
        callbackElement.classList.add('notice');
      }
    } else {
      if(callbackElement.classList.contains('notice')) {
        callbackElement.classList.remove('notice');
      }
    }
    }
  },
  isWithinNextTenMinutes(callbackTime) {
    const currentTime = new Date().getTime(); // Get current time in milliseconds
    const targetTime = new Date(callbackTime).getTime(); // Get callback time in milliseconds

    const tenMinutesInMillis = 10 * 60 * 1000; // 10 minutes in milliseconds

    // Check if the target time is within the next 10 minutes
    return (targetTime - currentTime) <= tenMinutesInMillis && targetTime > currentTime;
  },
  mapInputDataToSalesItems(inputData) {
    // Map the input data to the appropriate sales items
    this.sales_items.forEach(item => {
    switch (item.title) {
      case 'My Sales':
      item.today = inputData.today.mySales ? inputData.today.mySales.toString() : '0';
      item.this_week = inputData.thisWeek.mySales ? inputData.thisWeek.mySales.toString() : '0';
      break;
      case 'Team Sales':
      // Assuming team sales can be derived similarly, you can adapt this based on actual data structure
      item.today = inputData.today.sales.toString(); // Replace with correct team sales data if available
      item.this_week = inputData.thisWeek.sales.toString(); // Replace with correct team sales data if available
      break;
      default:
      console.warn(`Unknown sales item title: ${item.title}`);
      break;
    }
    });
  },
  mapInputDataToStatItems(inputData) {
    // Map the input data to the appropriate stat items
    this.stat_items.forEach(item => {
    switch (item.title) {
      case 'Calls':
      item.today = inputData.today.totalCalls.toString();
      item.this_week = inputData.thisWeek.totalCalls.toString();
      break;
      case 'Talk Time':
      item.today = inputData.today.talkTime;
      item.this_week = inputData.thisWeek.talkTime;
      break;
      case 'Managed Barge Time':
      item.today = inputData.today.managedBargeTime;
      item.this_week = inputData.thisWeek.managedBargeTime;
      break;
      case 'Calls Per Paid TO':
      item.today = inputData.today.qualifiedTos ? 
          (Math.round((inputData.today.totalCalls / inputData.today.qualifiedTos) * 10) / 10).toString() : '0';
      item.this_week = inputData.thisWeek.qualifiedTos ? 
          (Math.round((inputData.thisWeek.totalCalls / inputData.thisWeek.qualifiedTos) * 10) / 10).toString() : '0';
      break;
      case 'Finished Scripts':
      item.today = inputData.today.finishedScripts;
      item.this_week = inputData.thisWeek.finishedScripts;
      break;
      case 'Finished Scripts Asked All Questions':
      item.today = inputData.today.finishedScriptsAllQuestions;
      item.this_week = inputData.thisWeek.finishedScriptsAllQuestions;
      break;
      case 'Missed TOs (No Manager)':
      item.today = inputData.today.missedTOs ? inputData.today.missedTOs.toString() : '0';
      item.this_week = inputData.thisWeek.missedTOs ? inputData.thisWeek.missedTOs.toString() : '0';
      break;
      case 'Paid TOs':
      item.today = inputData.today.qualifiedTos ? inputData.today.qualifiedTos.toString() : '0';
      item.this_week = inputData.thisWeek.qualifiedTos ? inputData.thisWeek.qualifiedTos.toString() : '0';
      break;
      case 'Team TOs':
      item.today = inputData.today.teamTos ? inputData.today.teamTOs.toString() : '0';
      item.this_week = inputData.thisWeek.teamTos ? inputData.thisWeek.teamTOs.toString() : '0';
      break;
      case 'Commission':
      item.today = '$'+(inputData.today.commission ? inputData.today.commission.toString() : '0');
      item.this_week = '$'+(inputData.thisWeek.comission ? inputData.thisWeek.commission.toString() : '0');
      break;
      case 'Hot Leads Created':
      item.today = inputData.today.hotLeads.toString();
      item.this_week = inputData.thisWeek.hotLeads.toString();
      break;
      case 'Scheduled Call Backs':
      item.today = inputData.today.scheduledCallbacks.toString();
      item.this_week = inputData.thisWeek.scheduledCallbacks.toString();
      break;
      case 'Completed Call Backs':
      item.today = inputData.today.completedCallbacks.toString();
      item.this_week = inputData.thisWeek.completedCallbacks.toString();
      break;
      default:
      console.warn(`Unknown stat item title: ${item.title}`);
      break;
    }
    });
  },
  mapCallsToCallList(inputData) {
    this.call_list_items = inputData.map((call, index) => {
    return {
      id: index + 1, // sequential ID starting from 1
      date: moment(call.datetime).format('MM/DD/YYYY'), // format the datetime field to MM/DD/YYYY
      time: moment(call.datetime).format('h:mm a'), // format the time to 12-hour format with am/pm
      length: `${Math.floor(call.duration / 60)}min ${call.duration % 60}sec`, // convert duration to minutes and seconds
      number: `(${call.phone.slice(0, 3)}) ${call.phone.slice(3, 6)}-${call.phone.slice(6)}` // format phone number to (XXX) XXX-XXXX
    };
    });
    console.log('callListItems: ', this.call_list_items);
  },
  mapProspectData(inputData) {
    // Prospects 1 items
    this.prospect = inputData;
    this.companyName = inputData.company_name;
    this.prospects_1_items = [
      {
      label: 'Click Date',
      data: inputData.click_date ? moment(inputData.click_date).format('MM/DD/YYYY') : '-',
      tooltip: 'The date when the prospect clicked on a link to learn more from one of our text messages.'
      },
      {
      label: 'Lead Shelflife',
      data: inputData.shelf_life ? `${inputData.shelf_life} Days` : '0 Days',
      tooltip: 'The duration the lead has been managed, from the first call, text, or email to the current day.'
      },
      {
      label: 'Texts',
      data: inputData.texts ? inputData.texts.toString() : '0',
      tooltip: 'The total number of inbound and outbound text messages exchanged with the prospect.'
      },
      {
      label: 'Emails',
      data: inputData.total_out ? inputData.total_out.toString() : '0',
      tooltip: 'The total number of inbound and outbound emails exchanged with the prospect.'
      }
    ];

    // Prospects 2 items
    this.prospects_2_items = [
      {
      label: 'Talk Time',
      data: this.formatTime(inputData.total_talk_time ? parseInt(inputData.total_talk_time) : 0),
      tooltip: 'The cumulative time spent talking with the prospect on all calls.'
      },
      {
      label: 'Calls',
      data: inputData.calls ? inputData.calls.toString() : '0',
      tooltip: 'The total number of inbound and outbound calls with the prospect.'
      },
      {
      label: 'Voicemails',
      data: inputData.voicemail ? inputData.voicemail.toString() : '0',
      tooltip: 'The total number of voicemails left for the prospect.'
      },
      {
      label: 'Calls with Humans',
      data: inputData.human_answer ? inputData.human_answer.toString() : '0',
      tooltip: 'The total number of calls where the prospect answered or spoke with a representative for more than 2min. '
      }
    ];

    // Profile fields
    this.profile_fields = [
      {
      key: 'label',
      variant: 'bold',
      },
      {
      key: 'data',
      variant: 'text-right'
      }
    ];

    // Profile 1A items
    this.profile_1_a_items = [
      {
      label: 'Owner',
      data: inputData.Owners_Name || '-'
      },
      {
      label: "Owner’s Email",
      data: inputData.Company_Email || '-'
      }
    ];

    // Profile 1B items
    this.profile_1_b_items = [
      {
      label: 'Address',
      data: `${inputData.Company_Street_Address || '-'}`
      },
      {
      label: 'City',
      data: inputData.Company_City || '-'
      },
      {
      label: 'State',
      data: inputData.Company_State || '-'
      },
      {
      label: 'Zip',
      data: inputData.Company_Zipcode || '-'
      }
    ];

    // Profile 1C items
    this.profile_1_c_items = [
      {
      label: 'URL',
      data: inputData.Website || '-'
      },
      {
      label: 'Google Rating',
      data: inputData.Google_Maps_Rating ? inputData.Google_Maps_Rating.toString() : '-'
      }
    ];

    // Profile 2A items
    this.profile_2_a_items = [
      {
      label: 'Interested in',
      data: inputData.Keywords ? inputData.Keywords.join(', ') + ' [edit]' : '-'
      }
    ];

    // Profile 2B items
    this.profile_2_b_items = [
      {
      label: 'Phone Numbers',
      data: inputData.phone ? this.formatPhoneNumber(inputData.phone) : '-'
      },
      {
      label: "Owner’s Cell",
      data: inputData.Owner_Cell_Phone ? this.formatPhoneNumber(inputData.Owner_Cell_Phone.toString()) : '-'
      },
      {
      label: 'Business Phone',
      data: inputData.Company_Phone ? this.formatPhoneNumber(inputData.Company_Phone) : '-'
      },
      {
      label: '',
      data: '[plus]'
      }
    ];

    // Profile 2C items
    this.profile_2_c_items = [
      {
      label: 'Tags',
      data: '[edit]'
      }
    ];
  },
  mapNotesToListItems(notesData) {
  // Define the note list fields
  this.note_list_fields = ['date', 'time', 'note', 'user', 'delete'];

  // Set perPage and currentPageNotes
  this.perPage = 5;
  this.currentPageNotes = 1;

  // Map the notes data to note_list_items
  this.note_list_items = notesData.map(note => {
    return {
      id: note.id,
      date: moment(note.date).format('MM/DD/YYYY'),
      time: moment(note.time, 'HH:mm:ss').format('h:mm a'), // Convert time from 24-hour to 12-hour format
      note: note.text,
      user: note.sales_rep, // Assuming a function to get the user's name
    };
  });
},
  updateDialerStatus(dialerStatus) {
    this.dialerStatus = dialerStatus;
    const dotInner = document.querySelector('.dialer-ui .dot-inner');
    switch(dialerStatus) {
    case 'Connected':
      dotInner.classList.remove('off');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('on');
      this.callStartTime = new Date();
    break;
    case 'Dialer Off':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('off');
      this.callStartTime = null;
    break;
    case 'Dialing':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
      this.lastCallEndTime = null;
    break;
    case 'Hung Up':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
    break;
    case 'Paused':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
    break;
    }
  },
  formatTime(seconds) {
    if (seconds < 0) {
    return '-';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    let formattedTime = '';
    if (hours > 0) {
    formattedTime += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes}m `;
    }
    formattedTime += `${secs}sec`;
    return formattedTime.trim();
  },
  formatPhoneNumber(phoneNumber) {
    let formattedPhoneNumber = '-';
    if(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
    }
    return formattedPhoneNumber;
  },
  onOpen() {
    this.$refs.popover.$emit('open')
  },
  onClose() {
    this.$refs.popover.$emit('close')
  }
  },
  created() {
    // Load the currentView from localStorage if available
    document.body.setAttribute('data-role', this.role.toLowerCase());
  },
  async mounted() {
  // init user data
  await this.initUser();
  await this.fetchUserData();
  this.getInboxUnreadCount();
  this.fetchRemainingCredits();
  // init sockets
  this.configureSockets();
  this.startHeartbeat();

  if(this.role !== 'Managers') {
    // init widgets/stats
    this.fetchCallbacks();
    setInterval(() => {
    this.fetchCallbacks();
    }, 120000);
    this.fetchStats();
    this.fetchObjections();
    this.interval = setInterval(() => {
    this.now = new Date();
    }, 1000);
    setTimeout(() => {
      setInterval(() => {
        this.fetchStats();
      }, 3000000);
    }, 5000);
    
    // configure schedules
    this.startScheduleCheck();
    this.scheduleFunctionFor8PM();

    // load scripts
    this.loadLastLoggedTime();
    this.loadExternalScript();
    this.loadTypeformScript();

    // load dialer data
    this.fetchIndustries();
  }
  
  document.addEventListener('click', this.handleClickOutside);

  // console.log('salesRepId: ', this.currentSalesRepId);
  // console.log('salesRepId 1: ', this.userId);

  setTimeout(() => {
    console.log('ABC salesRepId: ',  this.currentSalesRepId);
    console.log('ABC managerId: ', this.managerId);
    // console.log('typeform: ', document.getElementById('typeform-container'));
    // document.getElementById('typeform-container').addEventListener('onQuestionChanged', (event) => { 
    //   console.log('questionChanged: ', event);
    // });
    // const form = window.tf.createWidget('01J3XSP3R70BREBHAD27B9DN3W', {
    //   container: document.getElementById('typeform-container'),
    //   hideFooter: true,
    //   hideHeaders: true,
    //   opacity: 0,
    // });

    // Initialize the question counter
    // let questionCount = 0;

    //  console.log('form: ', form);
    
    console.log('formWizard: ', document.getElementById('formWizard'));
    
  }, 5000);

   //this.loadProspect(await this.fetchLead(this.salesRepId, 518879045));
   console.log('currentView: ', this.currentView);
  },
  beforeDestroy() {
  document.removeEventListener('click', this.handleClickOutside);
  // Clear the interval when the component is destroyed to avoid memory leaks
  clearInterval(this.interval);
  },
  
};




</script>

<style scoped>

.inbox-count-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.badge-inbox-count {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  background-color: #ff0000;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.title-container {
  overflow: hidden; /* Clear the floats */
}

.bold {
  font-weight: bold;
}
.enter-text {
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.highlight {
  font-weight: bold;
}

body main#page-content .left-nav .navitem a {
  line-height: 1;
  align-items: center;
}
</style>
ageNotes = 1;

  // Map the notes data to note_list_items
  this.note_list_items = notesData.map(note => {
    return {
      id: note.id,
      date: moment(note.date).format('MM/DD/YYYY'),
      time: moment(note.time, 'HH:mm:ss').format('h:mm a'), // Convert time from 24-hour to 12-hour format
      note: note.text,
      user: note.sales_rep, // Assuming a function to get the user's name
    };
  });
},
  updateDialerStatus(dialerStatus) {
    this.dialerStatus = dialerStatus;
    const dotInner = document.querySelector('.dialer-ui .dot-inner');
    switch(dialerStatus) {
    case 'Connected':
      dotInner.classList.remove('off');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('on');
      this.callStartTime = new Date();
    break;
    case 'Dialer Off':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('off');
      this.callStartTime = null;
    break;
    case 'Dialing':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
      this.lastCallEndTime = null;
    break;
    case 'Hung Up':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
    break;
    case 'Paused':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
    break;
    }
  },
  formatTime(seconds) {
    if (seconds < 0) {
    return '-';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    let formattedTime = '';
    if (hours > 0) {
    formattedTime += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes}m `;
    }
    formattedTime += `${secs}sec`;
    return formattedTime.trim();
  },
  formatPhoneNumber(phoneNumber) {
    let formattedPhoneNumber = '-';
    if(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
    }
    return formattedPhoneNumber;
  },
  onOpen() {
    this.$refs.popover.$emit('open')
  },
  onClose() {
    this.$refs.popover.$emit('close')
  }
  },
  created() {
    // Load the currentView from localStorage if available

  },
  async mounted() {
  // init user data
  await this.initUser();
  await this.fetchUserData();

  // init sockets
  this.configureSockets();
  this.startHeartbeat();

  if(this.role !== 'Managers') {
    // init widgets/stats
    this.fetchCallbacks();
    setInterval(() => {
    this.fetchCallbacks();
    }, 10000);
    this.fetchStats();
    this.fetchObjections();
    this.interval = setInterval(() => {
    this.now = new Date();
    }, 1000);
    setInterval(() => {
    this.fetchStats();
    }, 60000);
    
    // configure schedules
    this.startScheduleCheck();
    this.scheduleFunctionFor8PM();

    // load scripts
    this.loadLastLoggedTime();
    this.loadExternalScript();
    this.loadTypeformScript();

    // load dialer data
    this.fetchIndustries();
    setInterval(() => {
      this.fetchIndustries();
    }, 60000);
  }
  
  document.addEventListener('click', this.handleClickOutside);

  // console.log('salesRepId: ', this.currentSalesRepId);
  // console.log('salesRepId 1: ', this.userId);

  setTimeout(() => {
    console.log('ABC salesRepId: ',  this.currentSalesRepId);
    console.log('ABC managerId: ', this.managerId);
    // console.log('typeform: ', document.getElementById('typeform-container'));
    // document.getElementById('typeform-container').addEventListener('onQuestionChanged', (event) => { 
    //   console.log('questionChanged: ', event);
    // });
    // const form = window.tf.createWidget('01J3XSP3R70BREBHAD27B9DN3W', {
    //   container: document.getElementById('typeform-container'),
    //   hideFooter: true,
    //   hideHeaders: true,
    //   opacity: 0,
    // });

    // Initialize the question counter
    // let questionCount = 0;

    //  console.log('form: ', form);
    
    console.log('formWizard: ', document.getElementById('formWizard'));
    
  }, 5000);

   //this.loadProspect(await this.fetchLead(this.salesRepId, 518879045));
   console.log('currentView: ', this.currentView);

  },
  beforeDestroy() {
  document.removeEventListener('click', this.handleClickOutside);
  // Clear the interval when the component is destroyed to avoid memory leaks
  clearInterval(this.interval);
  },
  
};




</script>

<style scoped>

.title-container {
  overflow: hidden; /* Clear the floats */
}

.bold {
  font-weight: bold;
}
.enter-text {
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.highlight {
  font-weight: bold;
}
</style>
geNotes
  this.perPage = 5;
  this.currentPageNotes = 1;

  // Map the notes data to note_list_items
  this.note_list_items = notesData.map(note => {
    return {
      id: note.id,
      date: moment(note.date).format('MM/DD/YYYY'),
      time: moment(note.time, 'HH:mm:ss').format('h:mm a'), // Convert time from 24-hour to 12-hour format
      note: note.text,
      user: note.sales_rep, // Assuming a function to get the user's name
    };
  });
},
  updateDialerStatus(dialerStatus) {
    this.dialerStatus = dialerStatus;
    const dotInner = document.querySelector('.dialer-ui .dot-inner');
    switch(dialerStatus) {
    case 'Connected':
      dotInner.classList.remove('off');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('on');
      this.callStartTime = new Date();
    break;
    case 'Dialer Off':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('dialing');
      dotInner.classList.add('off');
      this.callStartTime = null;
    break;
    case 'Dialing':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
      this.lastCallEndTime = null;
    break;
    case 'Hung Up':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
      this.callStartTime = null;
    break;
    case 'Paused':
      dotInner.classList.remove('oneMin');
      dotInner.classList.remove('on');
      dotInner.classList.remove('off');
      dotInner.classList.add('dialing');
    break;
    }
  },
  formatTime(seconds) {
    if (seconds < 0) {
    return '-';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    let formattedTime = '';
    if (hours > 0) {
    formattedTime += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes}m `;
    }
    formattedTime += `${secs}sec`;
    return formattedTime.trim();
  },
  formatPhoneNumber(phoneNumber) {
    let formattedPhoneNumber = '-';
    if(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
    }
    return formattedPhoneNumber;
  },
  onOpen() {
    this.$refs.popover.$emit('open')
  },
  onClose() {
    this.$refs.popover.$emit('close')
  }
  },
  created() {
    // Load the currentView from localStorage if available

  },
  async mounted() {
  // init user data
  await this.initUser();
  await this.fetchUserData();

  // init sockets
  this.configureSockets();
  this.startHeartbeat();

  if(this.role !== 'Managers') {
    // init widgets/stats
    this.fetchCallbacks();
    setInterval(() => {
    this.fetchCallbacks();
    }, 10000);
    this.fetchStats();
    this.fetchObjections();
    this.interval = setInterval(() => {
    this.now = new Date();
    }, 1000);
    setInterval(() => {
    this.fetchStats();
    }, 10000);
    
    // configure schedules
    this.startScheduleCheck();
    this.scheduleFunctionFor8PM();

    // load scripts
    this.loadLastLoggedTime();
    this.loadExternalScript();
    this.loadTypeformScript();

    // load dialer data
    this.fetchIndustries();

