<template>
  <li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        src="../../assets/images/dashboard/profile.jpg"
        alt=""
      />
      <div class="media-body">
        <span>{{ companyName }}</span>
        <p class="mb-0 font-roboto">
          {{ username }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <a href="#"><feather type="user"></feather><span>Account </span></a>
      </li>
      <li>
        <a href="#"><feather type="settings"></feather><span>Settings</span></a>
      </li>
      <li>
        <a @click="logout"
          ><feather type="log-in"></feather><span>Log out</span></a
        >
      </li>
      <li class="dropdown">
        <a href="#" @click="toggleCompanies"
          ><feather type="briefcase"></feather><span>Companies</span
          ><i :class="{'fa-angle-down': !showCompanies, 'fa-angle-up': showCompanies}" class="fa ml-2"></i></a
        >
        <ul v-if="showCompanies" class="company-dropdown">
          <li v-for="company in companies" :key="company.userId">
            <a href="#" @click="selectCompany(company.userId)">{{ company.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script>
import firebase from "firebase";
import UserAuth from "../../auth/js/index";

export default {
  name: "Profile",
  data() {
    return {
      username: localStorage.getItem("username"),
      showCompanies: false,
      companies: [
        { name: "123 Websites", userId: 123007 },
        { name: "180 Ads", userId: 123009 },
        { name: "Test", userId: 123011 }
      ],
    };
  },
  computed: {
    companyName() {
      const userId = localStorage.getItem("userId");
      const company = this.companies.find(company => company.userId === Number(userId));
      return company ? company.name : 'No Company Selected';
    }
  },
  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          UserAuth.Logout();
          this.$router.replace("/auth/login");
        });
    },
    toggleCompanies: function (event) {
      event.preventDefault();
      this.showCompanies = !this.showCompanies;
    },
    selectCompany: function (userId) {
      localStorage.setItem("userId", userId);
      this.showCompanies = false;
      location.reload();
    },
  },
};
</script>

<style scoped>
.company-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}

.company-dropdown li {
  padding: 5px 10px;
}

.company-dropdown li a {
  color: #333;
  text-decoration: none;
}

.company-dropdown li a:hover {
  text-decoration: underline;
}
</style>