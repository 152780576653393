<template>
	<div id="NAAOReport">
		
		<b-modal id="modal-scoped">
			<template #modal-header="{ close }">
			  Modal Header
			</template>
		
			<template #default>
		  		<b-table :items="naao_modal_items" :fields="naao_modal_fields" class="nopad-last nopad-sides">
				</b-table>
			</template>	
		
			<template #modal-footer="{ ok }">
			  <button class="btn solid" @click="ok()">
				OK
			  </button>
			</template>
		  </b-modal>
		
		<UIBox classcontent="">
			<template v-slot:title>
				<span class="bold">Newley Added Active Operators Report</span><span>Week of 10/28/24</span>
			</template>
			<template>
				<b-table :items="naao_items" :fields="naao_fields" class="nopad-last nopad-sides">
					<template #cell(totalactive)="totalactive">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ totalactive.value }}</span></a>
					</template>
					<template #cell(mon)="mon">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ mon.value }}</span></a>
					</template>
					<template #cell(tue)="tue">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ tue.value }}</span></a>
					</template>
					<template #cell(wed)="wed">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ wed.value }}</span></a>
					</template>
					<template #cell(thu)="thu">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ thu.value }}</span></a>
					</template>
					<template #cell(fri)="fri">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ fri.value }}</span></a>
					</template>
					<template #cell(sat)="sat">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ sat.value }}</span></a>
					</template>
					<template #cell(sun)="sun">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ sun.value }}</span></a>
					</template>
					<template #cell(thisweek)="thisweek">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ thisweek.value }}</span></a>
					</template>
					<template #cell(lastweek)="lastweek">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ lastweek.value }}</span></a>
					</template>
					<template #cell(thismonth)="thismonth">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ thismonth.value }}</span></a>
					</template>
					<template #cell(lastmonth)="lastmonth">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ lastmonth.value }}</span></a>
					</template>
					<template #cell(ytd)="ytd">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ ytd.value }}</span></a>
					</template>
					<template #cell(all)="all">
						<a href="#" @click.prevent @click="$bvModal.show('modal-scoped')">{{ all.value }}</span></a>
					</template>
				</b-table>
			</template>
		</UIBox>
	</div>
</template>

<script setup>
import { ref } from 'vue';


import UIBox from './uibox.vue';

const naao_modal_fields = ref([
	{ key: 'number', label: '' },
	{ key: 'recruiter', label: 'Recruiter' },
	{ key: 'operator', label: 'Name of Operator' },
	{ key: 'daysactive', label: 'Days to Active' },
]);

const naao_modal_items = ref([
{
	number: '1',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '2',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '3',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '4',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '5',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '6',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
{
	number: '7',
	recruiter: 'Ash',
	operator: 10,
	daysactive: 10
},
]);
const naao_fields = ref([
	{ key: 'totalactive', label: 'Total Active Users' },
	{ key: 'recruiter', label: 'Recruiter Name' },
	{ key: 'mon', label: 'Mon.' },
	{ key: 'tue', label: 'Tue.' },
	{ key: 'wed', label: 'Wed.' },
	{ key: 'thu', label: 'Thurs.' },
	{ key: 'fri', label: 'Fri.' },
	{ key: 'sat', label: 'Sat.' },
	{ key: 'sun', label: 'Sun.' },
	{ key: 'thisweek', label: 'This Week' },
	{ key: 'lastweek', label: 'Last Week' },
	{ key: 'thismonth', label: 'This Month' },
	{ key: 'lastmonth', label: 'Last Month' },
	{ key: 'ytd', label: 'YTD' },
	{ key: 'all', label: 'All' },
	
]);

const naao_items = ref([
{
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
}, {
	totalactive: '34',
	recruiter: 'Ash',
	mon: 10,
	tue: 10,
	thisweek: 20,
	thismonth: 20,
	ytd: 20,
	all: 20
},

{
	totalactive: '480',
	recruiter: '',
	mon: 44,
	tue: 58,
	_rowVariant: 'total'
}, 	
]);


</script>