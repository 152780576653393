<template>
  <div class="wallet-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 120" :width="width" :height="height">
      <!-- Background -->
      <rect x="10" y="20" width="100" height="80" rx="10" fill="#fff"/>
      
      <!-- Credit card layers effect -->
      <rect x="20" y="35" width="80" height="15" rx="4" fill="#3994C3"/>
      <rect x="20" y="55" width="80" height="15" rx="4" fill="#F4A601"/>
      <rect x="20" y="75" width="80" height="15" rx="4" fill="#4BB73A"/>
      
      <!-- Wallet icon (simplified) -->
      <path d="M45 40 L75 40" stroke="white" stroke-width="2"/>
      <path d="M45 45 L65 45" stroke="white" stroke-width="2"/>
      
      <!-- Flexible Notification Badge -->
      <template v-if="showBadge">
        <!-- Dynamic width background -->
        <rect 
          :x="95 - (getBadgeWidth() / 2)"
          y="10"
          :width="getBadgeWidth()"
          height="30"
          rx="15"
          fill="#FF3B30"
        />
        <!-- Badge text -->
        <text 
          x="95" 
          y="25" 
          font-family="Arial, sans-serif" 
          font-size="22" 
          fill="white" 
          text-anchor="middle" 
          dominant-baseline="middle"
        >
          {{ displayNumber }}
        </text>
      </template>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WalletIcon',
  props: {
    badgeCount: {
      type: Number,
      default: 0
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: '100%'
    }
  },
  computed: {
    showBadge() {
      return this.badgeCount > 0;
    },
    displayNumber() {
      return this.badgeCount;
    }
  },
  methods: {
    getBadgeWidth() {
      // Calculate width based on number of digits
      const textLength = String(this.displayNumber).length;
      const baseWidth = 50; // Minimum width for single digit
      const digitWidth = 10; // Additional width per digit
      return baseWidth + (Math.max(0, textLength - 1) * digitWidth);
    }
  }
}
</script>

<style scoped>
.wallet-icon {
  display: flex;
}
</style>