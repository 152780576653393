<template>
  <div id="app" class="container mt-4" style="width:90%; height:85%;">
    <div class="header">
      <h1>Automatic Sales</h1>
      <p>Configure the automatic sales settings below</p>
    </div>
    <div class="form-card">
      <form @submit.prevent="saveSettings">
        <div class="form-group">
          <label for="start-time">Start Time (first sale):</label>
          <div class="input-group">
            <input type="time" id="start-time" v-model="startTime" required>
            <div class="timezone-group">
              <label for="start-timezone">Timezone:</label>
              <select id="start-timezone" v-model="startTimezone" required>
                <option v-for="timezone in usTimezones" :value="timezone">{{ timezone }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="end-time">End Time:</label>
          <div class="input-group">
            <input type="time" id="end-time" v-model="endTime" required>
            <div class="timezone-group">
              <label for="end-timezone">Timezone:</label>
              <select id="end-timezone" v-model="endTimezone" required>
                <option v-for="timezone in usTimezones" :value="timezone">{{ timezone }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="interval">Interval (minutes):</label>
          <input type="number" id="interval" v-model="interval" min="1" required>
        </div>
        <div class="form-group">
          <button type="submit">Save Settings</button>
        </div>
      </form>
      <div v-if="totalHours !== null">
        <p>Total Hours: {{ totalHours.toFixed(1) }} hours</p>
        <p>Sales will go out at the following times in PST:</p>
        <ul>
          <li v-for="(time, index) in salesTimes" :key="index">{{ time }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startTime: '',
      endTime: '',
      interval: '',
      startTimezone: 'PST',
      endTimezone: 'PST',
      usTimezones: ["PST", "MST", "CST", "EST"],
      totalHours: null,
      salesTimes: []
    };
  },
  created() {
    this.loadSettings();
  },
  watch: {
    startTime: 'calculateTotalHours',
    endTime: 'calculateTotalHours',
    interval: 'calculateTotalHours'
  },
  methods: {
    loadSettings() {
      fetch('https://123avatars.com/v2/get-auto-sale-settings')
        .then(response => response.json())
        .then(data => {
          this.startTime = data.startTime;
          this.endTime = data.endTime;
          this.interval = data.intervalMinutes;
          this.startTimezone = data.startTimezone;
          this.endTimezone = data.endTimezone;
        })
        .catch(error => {
          console.error('Error loading settings:', error);
        });
    },
    saveSettings() {
      const settings = {
        startTime: this.startTime,
        endTime: this.endTime,
        intervalMinutes: this.interval,
        startTimezone: this.startTimezone,
        endTimezone: this.endTimezone
      };

      fetch('https://123avatars.com/v2/save-auto-sale-settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          alert('Settings saved successfully!');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    calculateTotalHours() {
      if (this.startTime && this.endTime && this.interval) {
        const [startHours, startMinutes] = this.startTime.split(':').map(Number);
        const [endHours, endMinutes] = this.endTime.split(':').map(Number);

        const start = new Date();
        start.setHours(startHours);
        start.setMinutes(startMinutes);

        const end = new Date();
        end.setHours(endHours);
        end.setMinutes(endMinutes);

        const diffMs = end - start;
        const diffHours = diffMs / (1000 * 60 * 60);

        this.totalHours = diffHours;

        this.calculateSalesTimes(start, diffHours);
      } else {
        this.totalHours = null;
        this.salesTimes = [];
      }
    },
    calculateSalesTimes(start, totalHours) {
      this.salesTimes = [];
      const intervalMinutes = Number(this.interval);
      const totalSales = Math.floor(totalHours * 60 / intervalMinutes);

      for (let i = 0; i <= totalSales; i++) {
        const saleTime = new Date(start.getTime() + i * intervalMinutes * 60000);
        this.salesTimes.push(saleTime.toLocaleTimeString('en-US', { timeZone: 'PST', hour: '2-digit', minute: '2-digit' }));
      }
    }
  }
};
</script>

<style scoped>
body {
  font-family: 'Roboto', sans-serif;
  background: #f5f7fa;
  margin: 0;
  padding: 0;
  color: #333;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.header {
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
}
.form-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.form-group {
  margin: 20px 0;
}
.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}
.input-group {
  display: flex;
  align-items: center;
}
.input-group input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}
.timezone-group {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.timezone-group label {
  margin-bottom: 5px;
  transform: translateY(-10px); /* Move the label up */
}
.input-group select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}
.form-group button {
  background: #6a11cb;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.form-group button:hover {
  background: #2575fc;
}
.form-group input:focus,
.form-group select:focus {
  border-color: #6a11cb;
  outline: none;
  box-shadow: 0 0 5px rgba(106, 17, 203, 0.2);
}
ul {
  padding: 0;
  list-style-type: none;
}
ul li {
  background: #f1f1f1;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
}
</style>