<template>
  <div>
    <Breadcrumbs main="" title="Bulk Templates" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-3 pr-sm-0 box-col-12" v-show="false">
          <div class="file-sidebar">
            <div class="card">
              <div class="card-body">
                <ul>
                  <li>
                    <div class="btn btn-primary">
                      <feather type="folder"></feather>
                      All
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="file-text"></feather>
                      SMS
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="globe"></feather>
                      MMS
                    </div>
                  </li>

                  <li>
                    <div class="btn btn-light">
                      <feather type="trash-2"></feather>
                      Deleted
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-md-12 box-col-12">
          <div class="file-content">
            <div class="card">
              <div class="card-header">
                <div class="media">
                  <!-- <form class="form-inline" action="#" method="get">
                    <div class="form-group mb-0">
                      <i class="fa fa-search"></i>
                      <input
                        class="form-control-plaintext"
                        type="text"
                        placeholder="Search..."
                      />
                    </div>
                  </form> -->
                  <div class="media-body text-right">
                    <form
                      class="d-inline-flex"
                      action="#"
                      method="POST"
                      enctype="multipart/form-data"
                      name="myForm"
                    >
                      <div class="btn btn-primary" @click="bulkModal2 = true">
                        <feather type="plus-square" class="text-top"></feather>
                        Add New Bulk Template
                      </div>

                      <b-modal
                        id="modal-lg"
                        size="lg"
                        title="Dataset"
                        class="theme-modal"
                        hide-footer
                        hide-header
                        v-model="bulkModal2"
                      >
                        <div class="row">
                          <div class="col-12">
                            <label>Bulk Template Name</label>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Rules and data for XYZ"
                              v-model="bulkSelectedDataSet.name"
                            />
                          </div>
                          <div class="col-12 mt-4">
                            <label>Information</label>
                            <b-dropdown
                              text="Add Variables"
                              variant="primary"
                              style="float: right"
                            >
                              <b-dropdown-header id="dropdown-header-label">
                                LeadVariables
                              </b-dropdown-header>
                              <b-dropdown-item
                                @click="addHookVariable('@URL-URL')"
                                >URL</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Owners_Name')"
                                >lead-Owners_Name</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Company_Name')"
                                >lead-Company_Name</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable(
                                    '@lead-Company_Street_Address'
                                  )
                                "
                                >lead-Company_Street_Address</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Company_City')"
                                >lead-Company_City</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-Company_Zipcode')
                                "
                                >lead-Company_Zipcode</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Company_State')"
                                >lead-Company_State</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Website')"
                                >lead-Website</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Industry')"
                                >lead-Industry</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-Year_Established')
                                "
                                >lead-Year_Established</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-Years_in_Business')
                                "
                                >lead-Years_in_Business</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Facebook_Page')"
                                >lead-Facebook_Page</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-Linkedin_Profile')
                                "
                                >lead-Linkedin_Profile</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-Twitter_Profile')
                                "
                                >lead-Twitter_Profile</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="addHookVariable('@lead-Instagram_Page')"
                                >lead-Instagram_Page</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="
                                  addHookVariable('@lead-YouTube_Channel')
                                "
                                >lead-YouTube_Channel</b-dropdown-item
                              >
                              <b-dropdown-header id="dropdown-header-label1">
                                Connected Data
                              </b-dropdown-header>
                              <b-dropdown-item></b-dropdown-item>
                            </b-dropdown>
                            <textarea
                              rows="25"
                              placeholder="Information Goes Here"
                              style="
                                background-color: black;
                                color: white;
                                border-radius: 20px;
                                padding: 10px;
                                width: 100%;
                              "
                              spellcheck="false"
                              v-model="bulkSelectedDataSet.text"
                            ></textarea>
                            <div class="counter" style="float: right">
                              <small>{{
                                bulkSelectedDataSet.text.length || 0
                              }}</small
                              >/160
                            </div>
                          </div>
                          <div class="col-12 mt-4">
                            <button class="btn btn-primary" @click="saveBulk">
                              Save
                            </button>
                            <button
                              class="btn btn-danger ml-4"
                              @click="deleteDataSet"
                              v-if="bulkSelectedDataSet.id > 0"
                            >
                              <feather type="trash-2" size="14"></feather>
                              Delete
                            </button>
                          </div>
                        </div>
                      </b-modal>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-body file-manager">
                <!-- <h4 class="mb-3">All Files</h4> -->
                <h6>Templates</h6>
                <ul class="folder">
                  <li
                    class="folder-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="dataset in bulkData"
                    :key="dataset.id"
                    @click="editDataset(dataset.id)"
                  >
                    <div class="media">
                      <i class="fa fa-file f-36 txt-primary"></i>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">{{ dataset.name }}</h6>
                        <p>{{ dataset.text | truncate(64) }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      bulkData: [],
      bulkModal2: false,
      bulkSelectedDataSet: { name: "", text: "" },
    };
  },
  mounted() {
    this.getConnectedData();
  },
  methods: {
    getConnectedData() {
      axios
        .get("https://123avatars.com/backend/getBulk.php?u=" + this.userId)
        .then((response) => {
          console.log(response.data);
          this.bulkData = response.data;
        });
    },
    editDataset(id) {
      this.bulkModal2 = true;
      let dataSet = this.bulkData.find((item) => item.id === id);
      this.bulkSelectedDataSet = dataSet;
    },
    saveBulk() {
      let id = this.bulkSelectedDataSet.id;
      if (!id) {
        if (!this.bulkSelectedDataSet.name || !this.bulkSelectedDataSet.text) {
          this.$toasted.show("Please fill in the details", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        axios
          .get("https://123avatars.com/backend/newBulk.php", {
            params: {
              u: this.userId,
              dataText: this.bulkSelectedDataSet.text,
              dataName: this.bulkSelectedDataSet.name,
            },
          })
          .then((response) => {
            //new Data Created
            console.log(response);
            this.getConnectedData();
            this.bulkModal2 = false;
            this.$toasted.show(
              this.bulkSelectedDataSet.name + " Dataset Created Successfully",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
            this.bulkSelectedDataSet = { name: "", text: "" };
          });
        return false;
      }
      axios
        .get("https://123avatars.com/backend/editBulk.php", {
          params: {
            id: this.bulkSelectedDataSet.id,
            dataText: this.bulkSelectedDataSet.text,
            dataName: this.bulkSelectedDataSet.name,
          },
        })
        .then((response) => {
          //new Data Created
          console.log(response);
          this.getConnectedData();
          this.bulkModal2 = false;
          this.$toasted.show(
            this.bulkSelectedDataSet.name + " Dataset Updated",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 10000,
            }
          );
          this.bulkSelectedDataSet = { name: "", text: "" };
        });
    },
    deleteDataSet() {
      let id = this.bulkSelectedDataSet.id;
      if (!id) {
        return false;
      }
      axios
        .get("https://123avatars.com/backend/deleteBulk.php?id=" + id)
        .then((response) => {
          this.getConnectedData();
          this.bulkModal2 = false;
          this.$toasted.show(
            this.bulkSelectedDataSet.name + " Dataset Deleted Successfully",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 10000,
            }
          );
          this.bulkSelectedDataSet = { name: "", text: "" };
        });
    },
    addHookVariable(variable) {
      this.bulkSelectedDataSet.text =
        this.bulkSelectedDataSet.text + " " + variable;
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length <= limit) {
        return value;
      } else {
        return value.substring(0, limit) + "...";
      }
    },
  },
};
</script>
