<template>
  <div>
    <Breadcrumbs main="Account" title="Domains" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-6 col-lg-12 xl-50 morning-sec box-col-12">
          <px-card class="profile-greeting" bg="primary-gradient">
            <div slot="with-padding">
              <div class="media">
                <div class="media-body">
                  <div class="greeting-user">
                    <h4 class="f-w-600 font-white" id="greeting">
                      Configure Domains
                    </h4>
                    <p>Add the following <b>DNS Record</b> to your domain</p>
                    <div class="whatsnew-btn">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="copyText('A')"
                        >A</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary ml-2"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="copyText('@')"
                        >@</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary ml-2"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="copyText('13.58.62.42')"
                        >13.58.62.42</a
                      >
                    </div>
                  </div>
                </div>
                <div class="cartoon">
                  <img
                    class="img-fluid"
                    src="../assets/images/domains.png"
                    alt=""
                    style="max-width: 200px"
                  />
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-xl-6 col-lg-12 xl-50 morning-sec box-col-12">
          <px-card class="profile-greeting" bg="primary-gradient">
            <div slot="with-padding">
              <div class="media">
                <div class="media-body">
                  <div class="greeting-user">
                    <h4 class="f-w-600 font-white" id="greeting">
                      Assign NameServers
                    </h4>
                    <p>
                      Assign your domain <b>NameServers</b> to <b>cloudflare</b>
                    </p>
                    <div class="whatsnew-btn">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="copyText('jocelyn.ns.cloudflare.com')"
                        >jocelyn.ns.cloudflare.com</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary ml-2"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="copyText('justin.ns.cloudflare.com')"
                        >justin.ns.cloudflare.com</a
                      >
                    </div>
                  </div>
                </div>
                <div class="cartoon">
                  <img
                    class="img-fluid"
                    src="../assets/images/domains2.png"
                    alt=""
                    style="max-width: 200px"
                  />
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="target"></feather> All Domains
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12"
                                v-for="domain in domains"
                                :key="domain.id"
                              >
                                <div class="project-box">
                                  <span
                                    class="badge"
                                    :class="
                                      domain.penalty_till < now ||
                                      domain.penalty_till == null
                                        ? 'badge-success'
                                        : 'badge-danger'
                                    "
                                    >{{
                                      domain.penalty_till < now ||
                                      domain.penalty_till == null
                                        ? "Active"
                                        : "Blocked Till " + domain.penalty_till
                                    }}</span
                                  >

                                  <div class="row">
                                    <div class="col-6">
                                      <a href="#">
                                        <h6>{{ domain.domain }}</h6>
                                      </a>
                                      <p>
                                        Added: <b>{{ domain.time }}</b>
                                      </p>
                                    </div>
                                    <div class="col-2">
                                      <span>Sent </span>
                                      <div class="text-primary">
                                        {{
                                          parseInt(domain.total_count) ||
                                          0 | formatNumber
                                        }}
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <span>Delivered</span>
                                      <div class="text-success">
                                        {{
                                          parseInt(domain.status_2_count) ||
                                          0 | formatNumber
                                        }}
                                      </div>
                                    </div>
                                    <div class="col-2" style="display: flex">
                                      <div>
                                        <span>Failed</span>
                                        <div class="text-danger">
                                          {{
                                            parseInt(domain.status_4_count) ||
                                            0 | formatNumber
                                          }}
                                        </div>
                                      </div>
                                      <div style="margin-left: 10px">
                                        <span>SPAM</span>
                                        <div class="text-danger">
                                          {{
                                            parseInt(domain.status_3_count) ||
                                            0 | formatNumber
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="col-6 create-project-btn">
                <div
                  class="text-right"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="form-group mb-0 mr-0"></div>
                  <b style="width: 25%">Add New Domain:</b>
                  <input
                    class="form-control ml-2"
                    style="width: 45%"
                    id="new_domain_input"
                    type="text"
                    placeholder="domain.ext"
                    v-model="newDomain"
                  />
                  <div
                    class="btn btn-primary cursor-pointer"
                    @click="addDomain"
                  >
                    <feather type="plus-square"></feather> Add Domain
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      userId: localStorage.getItem("userId"),
      domains: localStorage.getItem("domains")
        ? JSON.parse(localStorage.getItem("domains"))
        : [],
      now: moment().format("YYYY-MM-DD HH:mm:ss"),
      newDomain: "",
    };
  },
  mounted() {
    this.getDomains();
  },
  methods: {
    addDomain() {
      let newDomain = this.newDomain;
      if (newDomain.length > 3) {
        axios
          .post("https://12-3.fun/addDomain", { domain: newDomain })
          .then((response) => {
            console.log(response.data);
            if (response.data == true || response.data == "Already Exists") {
              this.newDomain = "";
              //let's add the domain and refresh
              axios
                .get(
                  "https://123avatars.com/backend/addDomain.php?domain=" +
                    newDomain +
                    "&u=" +
                    this.userId
                )
                .then((response) => {
                  this.getDomains();
                  console.log(response);
                  if (response.data == false) {
                    alert("Domain already exists in your account.");
                  } else {
                    alert("Domain addedd succesfully");
                  }
                });
            } else {
              alert(response.data);
            }
          });
      } else {
        alert("Invalaid domain name: " + newDomain);
      }
    },
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    getDomains() {
      this.$toasted.show(" Hold tight, We're Fetching Domains Data...", {
        theme: "outline",
        position: "top-right",
        type: "warning",
        icon: "info",
        duration: 5000,
      });
      axios
        .get("https://123avatars.com/backend/getDomains.php?u=" + this.userId)
        .then((response) => {
          let domains = response.data.domains;
          this.domains = domains;
          console.log(domains);
          localStorage.setItem("domains", JSON.stringify(domains));
        });
    },
    copyText(text) {
      var aux = document.createElement("input");
      aux.setAttribute("value", text);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      alert("copied");
    },
  },
};
</script>
