<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !show }">
      <div class="loader">
        <span></span>
      </div>
    </div>
    <!-- Loader ends-->
    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>

import AuthService from './_services/AuthService.js';


export default {
  name: "app",
  data() {
    return {
      show: true,
      role: ''
    };
  },
  async mounted() {
    this.timeOut();
    this.role = await AuthService.getUserRole();
  },
  methods: {
    timeOut() {
      var self = this;
      setTimeout(function () {
        self.show = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
