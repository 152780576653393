<template>
  <div class="fixed inset-0 bg-light overflow-y-auto h-full w-full d-flex align-items-center justify-content-center">
    <div class="relative p-5 border border-secondary w-100 md:w-75 lg:w-50 xl:w-67 shadow-lg rounded bg-light">
      <button @click="$emit('close-modal')" class="position-absolute top-0 end-0 m-3 border-0 bg-transparent" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="mt-1 text-center">
        <h3 class="text-lg leading-6 font-medium text-dark">
          Objections & Rebuttals
        </h3>
        <div class="mt-3 px-7 py-3">
          <textarea
            v-model="localPrompt"
            placeholder="Enter your prompt"
            class="mt-2 mb-4 form-control"
            style="resize: vertical; height: 8rem; overflow-y: auto;"
          ></textarea>
          <textarea
            v-model="localObjectionsRebuttals"
            placeholder="Objections & Rebuttals"
            class="mt-2 mb-4 form-control"
            style="resize: vertical; height: 8rem; overflow-y: auto;"
          ></textarea>
        </div>
        <div class="items-center px-4 py-3">
          <button
            id="ok-btn"
            @click="saveData"
            class="btn btn-primary w-100"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    objectionsRebuttals: {
      type: String,
      default: '',
    },
    prompt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localObjectionsRebuttals: this.objectionsRebuttals,
      localPrompt: this.prompt,
    };
  },
  methods: {
    async saveData() {
      const data = {
        promptType: "Script-Objection_Rebuttal",
        script: this.localObjectionsRebuttals,
        prompt: this.localPrompt,
      };
      try {
        let response = await axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
          headers: {
            'Origin': 'http://localhost:8080',
            'Content-Type': 'application/json'
          }
        })
        if (response.status == 200) {
          this.$toasted.show("Data saved successfully", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 5000,
          });
          setTimeout(() => {
            this.$emit('close-modal');
          }, 3000);
        } else {
          this.$toasted.show("Error saving data", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "error",
            duration: 10000,
          });
        }
      } catch (error) {
        this.$toasted.show("Error: " + error.message, {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "error",
          duration: 10000,
        });
      }
    },
  },
  watch: {
    objectionsRebuttals(newVal) {
      this.localObjectionsRebuttals = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    },
  },
};
</script>

<style>
.bg-light {
  background-color: #f8f9fa !important;
}
.text-dark {
  color: #343a40 !important;
}
</style>