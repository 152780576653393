<template>
	<svg viewBox="0 0 19 19" class="timezoneSVG">
	  <g clip-path="url(#clip0_242_1882)">
		<path d="M4.598 9.501c0 .766.038 1.583.115 2.452H.346A9.335 9.335 0 0 1 0 9.501 9.2 9.2 0 0 1 .346 7.05h4.367a27.222 27.222 0 0 0-.115 2.45ZM6.78.384c-.92 1.278-1.558 3.09-1.915 5.44H.727A9.709 9.709 0 0 1 3.16 2.433 9.363 9.363 0 0 1 6.78.384ZM.727 13.18h4.138c.384 2.35 1.022 4.162 1.915 5.44a9.398 9.398 0 0 1-3.62-2.049A9.684 9.684 0 0 1 .727 13.18ZM5.824 9.5c0-.817.038-1.634.114-2.451h7.126a26.392 26.392 0 0 1 0 4.903H5.938A26.368 26.368 0 0 1 5.824 9.5Zm7.047-3.677H6.129c.28-1.735.727-3.14 1.34-4.214C8.082.537 8.758 0 9.5 0c.74 0 1.416.537 2.029 1.61s1.06 2.476 1.34 4.214h.003Zm-6.742 7.355h6.742c-.28 1.738-.727 3.14-1.34 4.214-.613 1.073-1.291 1.61-2.03 1.61-.738 0-1.416-.537-2.029-1.61s-1.06-2.476-1.34-4.214h-.003Zm12.144-7.355h-4.138c-.384-2.348-1.022-4.162-1.915-5.44a9.397 9.397 0 0 1 3.62 2.049 9.684 9.684 0 0 1 2.433 3.39ZM12.22 18.616c.92-1.278 1.558-3.09 1.915-5.44h4.138a9.71 9.71 0 0 1-2.433 3.391 9.362 9.362 0 0 1-3.62 2.049ZM18.654 7.05A9.06 9.06 0 0 1 19 9.5c0 .818-.114 1.635-.346 2.452h-4.367a27.2 27.2 0 0 0 .115-2.452c0-.792-.038-1.61-.114-2.451h4.366Z"/>
	  </g>
	</svg>
</template>

<script>
export default {
   name: 'timezoneSVG',
}
</script>