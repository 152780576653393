<template>
	<div id="IncomeCalc">

		<UIBox classcontent="">
			<template v-slot:title>
				<span class="bold">TO Income Calculator</span>
			</template>
			<template>
				<div class="filter">
					<div class="filterBody">
						<div class="tonumberselect">
							<div class="data-box">
								<div class="headline">
									Company Average
								</div>
								<div class="data">
									<!-- Need these connected to data-->
									10 TO’s Per Sale
								</div>
							</div>
							<div class="data-box">
								<div class="headline">
									Days to 5 Sales
								</div>
								<div class="data">
									<!-- Need these connected to data-->
									10
								</div>
							</div>
						</div>
						<div class="tonumberselect">
							<div class="text">
								Select the number of Daily TO’s<br> to Calculate your income
							</div>
							<div class="form-group select">
								<select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="rowsPerPage">
									<option value="5">5 TOs</option>
									<option value="10">10 TOs</option>
									<option value="15">15 TOs</option>
									<option value="20">20 TOs</option>
								</select>
							</div>
						</div>
						
					</div>
				</div>
				<!-- SPIFF Table-->
				<b-table :items="naao_items" :fields="naao_fields" class="" :per-page="rowsPerPage">
					<template #cell(tonum)="tonum">
						<span class="bold">{{ tonum.value }}</span>
					</template>
					<template #cell(forthat)="forthat">
						&#8369;{{ forthat.value }}
					</template>
					<template #cell(paidtoday)="paidtoday">
						&#8369;{{ paidtoday.value }}
					</template>
					<template #cell(salescomm)="salescomm">
						&#8369;{{ salescomm.value }}
					</template>
					<template #cell(dailycomm)="dailycomm">
						&#8369;{{ dailycomm.value }}
					</template>
					<template #cell(monthly)="monthly">
						&#8369;{{ monthly.value }}
					</template>
				</b-table>

			</template>
		</UIBox>
	</div>
</template>
<script setup>
import { ref } from 'vue';


import UIBox from './uibox.vue';

const naao_fields = ref([
	{ key: 'tonum', label: 'TO#' },
	{ key: 'forthat', label: 'For That TO' },
	{ key: 'paidtoday', label: 'Total Spiffs Paid Today' },
	{ key: 'dailysales', label: 'Daily Sales' },
	{ key: 'salescomm', label: 'Sales Commissions' },
	{ key: 'dailycomm', label: 'Total Daily SPIFF + Commissions' },
	{ key: 'monthly', label: 'Total Monthly Income' },
	
]);

const rowsPerPage = ref(5)
const naao_items = ref([
{
	tonum: 1,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 2,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 3,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 4,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 5,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500,
	_rowVariant: 'green'
},
{
	tonum: 1,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 2,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 3,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 4,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 5,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500,
	_rowVariant: 'green'
}, 
{
	tonum: 1,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 2,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 3,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 4,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 5,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500,
	_rowVariant: 'green'
}, 
{
	tonum: 1,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 2,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 3,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 4,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500
}, {
	tonum: 5,
	forthat: 50,
	paidtoday: 50,
	dailysales: .10,
	salescomm: 275,
	dailycomm: 325,
	monthly: 6500,
	_rowVariant: 'green'
}, 
]);
</script>