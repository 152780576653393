<template>
	<svg viewBox="0 0 28 22" class="nextSVG">
		<path d="M0.19043 21.8334V0.166748L18.8095 11.0001L0.19043 21.8334Z" />
		<path d="M9.19043 21.8334V0.166748L27.8095 11.0001L9.19043 21.8334Z" />
	</svg>
</template>

<script>
export default {
   name: 'nextSVG',
}
</script>