<!-- scriptpage.vue -->
<template>
	<div>
		
		<!-- Scrollable Box -->
		<div class="scroll">
			<div class="question-block">
				<!-- question content -->
				<slot name="question"></slot>
			</div>
		</div>
		
		<!-- button area -->
		<div class="button-bottom">
			<slot name='buttons'></slot>
		</div>
	
	</div>
</template>

<script setup>
	import { useSlots } from 'vue'
	const slots = useSlots()
</script>