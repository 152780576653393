<template>
<svg viewBox="0 0 22 12">
  <path  d="M15.47.97l.54-.52c.11-.11.24-.16.4-.16s.29.05.4.16l4.55,4.55c.11.11.16.24.16.4s-.05.29-.16.4l-4.55,4.55c-.11.11-.24.16-.4.16s-.29-.05-.4-.16l-.54-.52c-.11-.11-.16-.24-.15-.4s.07-.29.18-.4l2.81-2.7h-6.73c-.16,0-.29-.05-.4-.16-.11-.11-.16-.24-.16-.4v-.75c0-.16.05-.29.16-.4s.24-.16.4-.16h6.73l-2.81-2.7c-.11-.11-.17-.24-.18-.4s.04-.29.15-.4c0,0,0,.01,0,0ZM11.44,0C11.69,0,11.86.12,11.96.35c.1.23.05.44-.12.61l-4.34,4.34v6.14c0,.23-.1.4-.3.5-.2.1-.4.09-.59-.04l-1.88-1.31c-.16-.12-.23-.28-.23-.47v-4.83L.17.96C0,.79-.04.59.05.35s.27-.35.52-.35h10.88s-.01,0,0,0Z"/>
</svg>
</template>

<script>
export default {
   name: 'CallFunnelOutSVG',
}
</script>