<style scoped>
.grow-on-hover {
  transition: transform 0.2s;
  /* Adjust the duration as needed */
}

.grow-on-hover:hover {
  transform: scale(1.05);
  /* Adjust the scale factor for desired growth */
}

tr {
  padding: 0px;
}

.chat-box {
  min-height: calc(100% - 30px) !important;

}
@media screen and (max-width: 1660px){
.call-chat-body .chat-box {
    overflow: hidden;
    height: calc(100% - 30px) !important;
}
}

.chat-box .chat-right-aside .chat .chat-msg-box .message {
  width: 80%;
}

.chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background-color: #42a5f0;
  color: white;
}

.chat-box .chat-right-aside .chat .chat-msg-box .other-message .message-data-time {
  color: white;
}

/* .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  background-color: #f5f5f5;
} */

@media only screen and (min-width: 768px) {
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    max-width: 2800px !important;
  }
}
</style>

<template>
  <div>
    <Breadcrumbs :title="'BackOffice : ' + (campaign.name || 'Campaign Name')" />
    <div class="row">
      <div class="col-xl-6 xl-50 col-sm-6">
        <div class="card bg-secondary">
          <div class="card-body">
            <div class="media faq-widgets">
              <div class="media-body">
                <div class="media media-body">
                  <img class="img-20 mr-1 rounded-circle" :src="avatarImage ? getImgUrl('user/' + avatarImage) : 'f1.jpg'
                    " alt="" title="" />

                  <p class="mb-2" v-b-tooltip.hover :title="avatar.phone_number">
                    {{ avatar.name ? avatar.name : "Avatar Name" }}
                    <b><div v-for="(number, index) in avatar.phone_number.split(',')" style="display:inline-block">
                    <div v-if="index < 3" style="display:inline-block" class="badge badge-success ml-1" :title="avatar.phone_number">
                      {{ number.includes("+1") ? number.substring(2) : number | formatPhoneNumber }}
                    </div>

                    <div v-else-if="index === 4" style="display:inline-block" class="badge badge-success ml-1" :title="avatar.phone_number">
                      +{{ avatar.phone_number.split(',').length - 3 }} more
                    </div>
                  </div></b>
                  </p>
                </div>
                <div style="
                    display: flex;
                    justify-content: start;
                    align-items: center;
                  ">
                  <feather type="activity" size="18" style="margin-top: -10px; margin-right: 5px"></feather>
                  <label>Campaign Status: </label>
                  <b-dropdown :text="campaign.status == 1 && campaign?.conv_per_day == 0
                        ? 'Only responding'
                        : campaign.status == 1
                          ? 'Active'
                          : 'Completely Off'
                      " class="mx-2" :class="'btn-round'" variant="success">
                    <b-dropdown-item>Active</b-dropdown-item>
                    <b-dropdown-item>Only Responding</b-dropdown-item>
                    <b-dropdown-item>Stopped</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <feather type="cpu"></feather>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 xl-50 col-sm-6">
        <div class="card bg-primary">
          <div class="card-body">
            <div class="media faq-widgets">
              <div class="media-body">
                <p>
                <div class="loader-box" style="height:auto;justify-content: start;margin-top:-20px;"
                  v-show="liveStatsBlock">
                  <div class="loader-5 mr-2" style="width:10px; height:10px;"></div>
                  Live Stats: <span class="badge badge-warning ml-2">{{ totalOutboundPending | formatNumber }}
                    Pending</span><span class="badge badge-success ml-2">{{ totalOutboundSuccess | formatNumber }}
                    Sent</span><span class="badge badge-danger ml-2">{{ totalOutboundFailed | formatNumber }}
                    Failed</span>
                </div>
                </p>
                <div class="d-flex">
                  <!-- <button class="btn btn-secondary" v-b-modal.modal-edit variant="primary">
                    <feather type="settings" size="14"></feather> Campaign
                    Settings
                  </button> -->
                  <CampaignModal :Btitle="'Campaign Settings'" />
                
                  <button class="ml-2 btn btn-success" v-b-modal.modal-bulk>
                    <feather type="send" size="14"></feather> Schedule Bulk
                  </button>
                  <button class="ml-2 btn btn-info" v-b-modal.modal-blocks>
                    <feather type="grid" size="14"></feather> Blocks
                  </button>
                </div>
              </div>
              <feather type="hard-drive"></feather>
            </div>
            <!-- Bulk Modal -->
            <b-modal id="modal-bulk" size="l" title="Start New Campaign" class="theme-modal" hide-footer hide-header
              v-model="bulkModal">
              <div class="image-container" style="
                                  max-height: 200px;
                                  overflow: hidden;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  border-radius: 20px 20px 20px 20px;
                                ">
                <img :src="getImgUrl('bulk.gif')" style="width: 100%" />
              </div>
              <div class="mt-2 text-left" style="font-size: 18px">
                <b>Send Bulk SMS</b>
              </div>
              <br />

              <div class="btn btn-primary mb-2" @click="bulkModal2 = true"
                style="float:right;display:flex;align-items:center;">
                <feather type="plus-square" class="text-top"></feather>
              </div>

              <br />
            
              <label>Select Template:</label>
              <multiselect v-model="bulkTemplate" tag-placeholder="" placeholder="Select Template" label="name"
                track-by="code" :options="bulkTemplates" :multiple="false"></multiselect>
              <br />
              Text to send:<br/>
              <b>{{bulkTemplate.text}}</b>
              <br/>
              <label>Select List: (Empty will send only to current Lead)</label>
              <multiselect v-model="bulkList" tag-placeholder="" placeholder="Select Leads List" label="name"
                track-by="code" :options="bulkTemplatesList" :multiple="false"></multiselect>

              <button class="btn btn-primary" @click="sendBulk">Send Now</button>
            </b-modal>
            <!-- New Bulk Template Modal -->
              <!-- New Bulk Template Modal -->
              <b-modal id="modal-lg" size="lg" title="Dataset" class="theme-modal" hide-footer hide-header
                v-model="bulkModal2">
                <div class="row">
                  <div class="col-12">
                    <label>Bulk Template Name</label>
                    <input class="form-control" type="text" placeholder="Rules and data for XYZ"
                      v-model="bulkSelectedDataSet.name" />
                  </div>
                  <div class="col-12 mt-4">
                    <label>Information</label>
                    <b-dropdown text="Add Variables" variant="primary" style="float: right">
                      <b-dropdown-header id="dropdown-header-label">
                        system Variables
                      </b-dropdown-header>
                      <b-dropdown-item @click="addHookVariable('@URL-URL')">URL</b-dropdown-item>
                      <b-dropdown-header id="dropdown-header-label">
                        Lead Variables
                      </b-dropdown-header>
                      <b-dropdown-item @click="addHookVariable('@lead-Owners_Name')">lead-Owners_Name</b-dropdown-item>
                      <b-dropdown-item @click="addHookVariable('@lead-Company_Name')">lead-Company_Name</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable(
                          '@lead-Company_Street_Address'
                        )
                        ">lead-Company_Street_Address</b-dropdown-item>
                      <b-dropdown-item @click="addHookVariable('@lead-Company_City')">lead-Company_City</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-Company_Zipcode')
                        ">lead-Company_Zipcode</b-dropdown-item>
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_State')">lead-Company_State</b-dropdown-item>
                      <b-dropdown-item @click="addHookVariable('@lead-Website')">lead-Website</b-dropdown-item>
                      <b-dropdown-item @click="addHookVariable('@lead-Industry')">lead-Industry</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-Year_Established')
                        ">lead-Year_Established</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-Years_in_Business')
                        ">lead-Years_in_Business</b-dropdown-item>
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Facebook_Page')">lead-Facebook_Page</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-Linkedin_Profile')
                        ">lead-Linkedin_Profile</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-Twitter_Profile')
                        ">lead-Twitter_Profile</b-dropdown-item>
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Instagram_Page')">lead-Instagram_Page</b-dropdown-item>
                      <b-dropdown-item @click="
                        addHookVariable('@lead-YouTube_Channel')
                        ">lead-YouTube_Channel</b-dropdown-item>
                      <b-dropdown-header id="dropdown-header-label1">
                        Connected Data
                      </b-dropdown-header>
                      <b-dropdown-item></b-dropdown-item>
                    </b-dropdown>
                    <textarea rows="25" placeholder="Information Goes Here" style="
                                background-color: black;
                                color: white;
                                border-radius: 20px;
                                padding: 10px;
                                width: 100%;
                              " spellcheck="false" v-model="bulkSelectedDataSet.text"></textarea>
                    <div class="counter" style="float: right">
                      <small>{{
                        bulkSelectedDataSet.text.length || 0
                      }}</small>/160
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <button class="btn btn-primary" @click="saveBulk">
                      Create new Bulk Template
                    </button>
                    <button class="btn btn-danger ml-4" @click="deleteDataSet" v-if="bulkSelectedDataSet.id > 0">
                      <feather type="trash-2" size="14"></feather>
                      Delete
                    </button>
                  </div>
                </div>
              </b-modal>
            <!-- Control Block Modal -->
            <b-modal id="modal-blocks" size="lg" title="Display Blocks" class="theme-modal" hide-footer hide-header>
              <h3>Select Blocks to show</h3>
              <!-- <b-form-checkbox name="checkbox1" v-model="block_stats">Stats Table</b-form-checkbox> -->
              <div style="display:flex; justify-content:start; align-items:center;">
                <label class="mr-2"> Stats Table</label>
                <label class="switch">

                  <input type="checkbox" checked="" v-model="block_stats"><span class="switch-state"></span>
                </label>
              </div>
              <div style="display:flex; justify-content:start; align-items:center;">
                <label class="mr-2"> Live Stats</label>
                <label class="switch">

                  <input type="checkbox" checked="" v-model="liveStatsBlock"><span class="switch-state"></span>
                </label>
              </div>
            </b-modal>

          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" v-if="block_stats">
          <advancedStats />
        </div>
      </div>
      <div class="email-wrap">
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="email-left-aside">
              <div class="card">
                <div class="card-body">
                  <div class="email-app-sidebar">
                    <div class="media">
                      <div class="media-size-email" style="display:flex;">
                        <input class="form-control" type="text" placeholder="Search Lead" v-model="search" />
                        <button class="btn btn-primary" @click="searchLeads">
                          <feather type="search" size="14"></feather>
                        </button>
                      </div>
                      <!-- <div class="media-body">
                        <h6 class="f-w-600">MARKJENCO</h6>
                        <p>Markjecno@gmail.com</p>
                      </div> -->
                    </div>
                    <ul class="nav main-menu" role="tablist">
                      <li>
                        <hr />
                      </li>
                      <li @click="type = 'Unread'" class="nav-item">
                        <a class="show" id="pills-darkprofile-tab" data-toggle="pill" href="#pills-darkprofile" role="tab"
                          aria-controls="pills-darkprofile" aria-selected="false"><span class="title" v-b-tooltip.hover
                            title="New Conversation
                            Started Today">
                            <feather type="inbox" size="12"></feather> Unread
                          </span><span class="badge pull-right digits">({{ parseInt(foldersCount.unread) ?
                            parseInt(foldersCount.unread) : 0 | formatNumber }})</span></a>
                      </li>
                       <li @click="type = 'Clicks'" class="nav-item">
                        <a class="show" id="pills-darkprofile-tab" data-toggle="pill" href="#pills-darkprofile" role="tab"
                          aria-controls="pills-darkprofile" aria-selected="false"><span class="title" v-b-tooltip.hover
                            title="Leads Clicked on links">
                            <feather type="check-circle" size="12"></feather> Clicked on link
                          </span><span class="badge pull-right digits">({{ parseInt(totalClicks) ?
                            parseInt(totalClicks) : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Calls'" class="nav-item">
                        <a class="show" id="pills-darkprofile-tab" data-toggle="pill" href="#pills-darkprofile" role="tab"
                          aria-controls="pills-darkprofile" aria-selected="false"><span class="title" v-b-tooltip.hover
                            title="Phone Calls">
                            <feather type="inbox" size="12"></feather> Calls
                          </span><span class="badge pull-right digits">({{ 0 }})</span></a>
                      </li>
                      <li @click="type = 'Calls ( > 2 mins )'" class="nav-item">
                        <a class="show" id="pills-darkprofile-tab" data-toggle="pill" href="#pills-darkprofile" role="tab"
                          aria-controls="pills-darkprofile" aria-selected="false"><span class="title" v-b-tooltip.hover
                            title="Phone Calls">
                            <feather type="inbox" size="12"></feather> Calls ( > 2 mins )
                          </span><span class="badge pull-right digits">({{ 0 }})</span></a>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li @click="type = 'New Today'" class="nav-item">
                        <a class="show" id="pills-darkprofile-tab" data-toggle="pill" href="#pills-darkprofile" role="tab"
                          aria-controls="pills-darkprofile" aria-selected="false"><span class="title" v-b-tooltip.hover
                            title="New Conversation
                            Started Today"><i class="icon-pencil-alt"></i> New Today</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.new_today) ?
                              parseInt(foldersCount.new_today) : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'New Yesterday'" v-b-tooltip.hover title="New Conversation
                            Started Yesterday">
                        <a href="#"><span class="title"><i class="icon-folder"></i> New Yesterday</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.new_yesterday) ?
                              parseInt(foldersCount.new_yesterday) : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Inbox'">
                        <a href="#"><span class="title"><i class="icon-import"></i> Inbox</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.inbox) ?
                              parseInt(foldersCount.inbox) : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Outbox'">
                        <a href="#"><span class="title"><i class="icon-new-window"></i> Outbox</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.outbox) ?
                              parseInt(foldersCount.outbox) : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Reminder Text'">
                        <a href="#"><span class="title"><i class="icon-trash"></i> Reminder Text</span><span
                            class="badge pull-right digits">(0)</span></a>
                      </li>
                      <li @click="type = 'Sent MMS'">
                        <a href="#"><span class="title"><i class="icon-info-alt"></i> Sent MMS</span><span
                            class="badge pull-right digits">(0)</span></a>
                      </li>
                      <li @click="type = 'Sent Email'">
                        <a href="#"><span class="title"><i class="icon-star"></i> Sent Email</span><span
                            class="badge pull-right digits">(0)</span></a>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li @click="type = 'Non Responsive'">
                        <a href="#"><span class="title" v-b-tooltip.hover title="hit Max without Response"><i
                              class="icon-email"></i> Non Responsive</span><span class="badge pull-right digits">({{
                                parseInt(foldersCount.non_responsive) ? parseInt(foldersCount.non_responsive) : 0 |
                              formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Opted Out'">
                        <a href="#"><span class="title"><i class="icon-export"></i> Opted Out</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.stop) ? parseInt(foldersCount.stop)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li>
                        <div class="card-body default-according style-1" style="padding:0px;">

                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="div" class="bg-white" style="color:black;">
                              <h5 class="mb-0">
                                <b-button v-b-toggle.primary_icon_open_close_6 style="color:black;"><i
                                    class="icon-user"></i> Sequences </b-button>
                              </h5>
                            </b-card-header>
                            <b-collapse id="primary_icon_open_close_6" accordion="my-accordion-icon-open-close"
                              role="tabpanel">
                              <b-card-body>

                      <li @click="type = sequence?.name" v-for="(sequence,index) in sequences" :key="sequence.id">
                        <a href="#"><span class="title"><i class="icon-user"></i> {{ sequence?.name }}</span><span
                            class="badge pull-right digits">({{ 0 }})</span></a>
                      </li>

                      <!-- <li @click="type = 'Sentiment1'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 1</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_1) ? parseInt(foldersCount.sentiment_1)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment2'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 2</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_2) ? parseInt(foldersCount.sentiment_2)
                              : 0 | formatNumber }})</span></a>
                      </li> -->
                      </b-card-body>
                      </b-collapse>
                      </b-card>
                  </div>
                  </li>
                      <li>
                        <div class="card-body default-according style-1" style="padding:0px;">

                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="div" class="bg-white" style="color:black;">
                              <h5 class="mb-0">
                                <b-button v-b-toggle.primary_icon_open_close_2 style="color:black;"><i
                                    class="icon-user"></i> SENTIMENT</b-button>
                              </h5>
                            </b-card-header>
                            <b-collapse id="primary_icon_open_close_2" accordion="my-accordion-icon-open-close"
                              role="tabpanel">
                              <b-card-body>
                      <li @click="type = 'Sentiment1'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 1</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_1) ? parseInt(foldersCount.sentiment_1)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment2'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 2</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_2) ? parseInt(foldersCount.sentiment_2)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment3'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 3</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_3) ? parseInt(foldersCount.sentiment_3)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment4'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 4</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_4) ? parseInt(foldersCount.sentiment_4)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment5'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 5</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_5) ? parseInt(foldersCount.sentiment_5)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment6'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 6</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_6) ? parseInt(foldersCount.sentiment_6)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment7'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 7</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_7) ? parseInt(foldersCount.sentiment_7)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment8'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 8</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_8) ? parseInt(foldersCount.sentiment_8)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment9'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 9</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_9) ? parseInt(foldersCount.sentiment_9)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      <li @click="type = 'Sentiment10'">
                        <a href="#"><span class="title"><i class="icon-user"></i> Sentiment 10</span><span
                            class="badge pull-right digits">({{ parseInt(foldersCount.sentiment_10) ? parseInt(foldersCount.sentiment_10)
                              : 0 | formatNumber }})</span></a>
                      </li>
                      </b-card-body>
                      </b-collapse>
                      </b-card>
                  </div>
                  </li>
                  <li>
                    <div class="card-body default-according style-1" style="padding:0px;">

                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="div" class="bg-white" style="color:black;">
                          <h5 class="mb-0">
                            <b-button v-b-toggle.primary_icon_open_close_3 style="color:black;"><i class="icon-import"
                                style="color:black;"></i> Inbound Messages</b-button>
                          </h5>
                        </b-card-header>
                        <b-collapse id="primary_icon_open_close_3" accordion="my-accordion-icon-open-close"
                          role="tabpanel">
                          <b-card-body>
                  <li @click="type = 'Inbound1'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 1 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_1) ? parseInt(foldersCount.inbound_1) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound2'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 2 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_2) ? parseInt(foldersCount.inbound_2) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound3'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 3 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_3) ? parseInt(foldersCount.inbound_3) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound4'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 4 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_4) ? parseInt(foldersCount.inbound_4) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound5'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 5 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_5) ? parseInt(foldersCount.inbound_5) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound6'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 6 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_6) ? parseInt(foldersCount.inbound_6) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound7'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 7 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_7) ? parseInt(foldersCount.inbound_7) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound8'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 8 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_8) ? parseInt(foldersCount.inbound_8) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound9'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 9 Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_9) ? parseInt(foldersCount.inbound_9) : 0
                          | formatNumber }})</span></a>
                  </li>
                  <li @click="type = 'Inbound10'">
                    <a href="#"><span class="title"><i class="icon-import"></i> 10+ Inbound</span><span
                        class="badge pull-right digits">({{ parseInt(foldersCount.inbound_10_plus) ? parseInt(foldersCount.inbound_10_plus) : 0
                          | formatNumber }})</span></a>
                  </li>

                  </b-card-body>
                  </b-collapse>
                  </b-card>


                </div>

                </li>
                <li>
                  <div class="card-body default-according style-1" style="padding:0px;">

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="div" class="bg-white" style="color:black;">
                        <h5 class="mb-0">
                          <b-button v-b-toggle.primary_icon_open_close_4 style="color:black;"><i class="icon-time"
                              style="color:black;"></i> Days Since Last Inbound</b-button>
                        </h5>
                      </b-card-header>
                      <b-collapse id="primary_icon_open_close_4" accordion="my-accordion-icon-open-close" role="tabpanel">
                        <b-card-body>
                <li v-for="days in 30" @click="setType(days,1)">
                  <a href="#">
                    <span class="title">
                      <i class="icon-time"></i> {{ days === 0 ? '0 Days' : days === 30 ? '31+ Days' : days + ' Days' }}
                    </span>
                    <span class="badge pull-right digits">
                      
                      ({{ parseInt(foldersCount['day_since_last_inbound_' + days]) ? parseInt(foldersCount['day_since_last_inbound_' + days]) : 0 | formatNumber }})
                    </span>
                  </a>
                </li>


                </b-card-body>
                </b-collapse>
                </b-card>


              </div>

              </li>
              <li>
                <div class="card-body default-according style-1" style="padding:0px;">

                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="div" class="bg-white" style="color:black;">
                      <h5 class="mb-0">
                        <b-button v-b-toggle.primary_icon_open_close_5 style="color:black;"><i class="icon-time"
                            style="color:black;"></i> Days Since Last Outbound</b-button>
                      </h5>
                    </b-card-header>
                    <b-collapse id="primary_icon_open_close_5" accordion="my-accordion-icon-open-close" role="tabpanel">
                      <b-card-body>
              <li v-for="days in 30" @click="setType(days,0)">
                <a href="#">
                  <span class="title">
                    <i class="icon-time"></i> {{ days === 0 ? '0 Days' : days === 30 ? '31+ Days' : days + ' Days' }}
                  </span>
                  <span class="badge pull-right digits">
                    ({{ parseInt(foldersCount['day_since_last_outbound_' + days]) ? parseInt(foldersCount['day_since_last_outbound_' + days]) : 0 | formatNumber }})
                  </span>
                </a>
              </li>


              </b-card-body>
              </b-collapse>
              </b-card>


            </div>

            </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-md-6">
    <div class="email-right-aside">
      <div class="card email-body">
        <div class="pr-0 b-r-light">
          <div class="email-top">
            <div class="row">
              <div class="col">
                <h5 class="text-capitalize">{{ type }}</h5>
              </div>
              <!-- <div class="col text-right d-flex">
                        <div class="dropdown">
                          <button
                            class="btn bg-transparent dropdown-toggle p-0 text-muted"
                            id="dropdownMenuButton"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-on:click="menutoogle = !menutoogle"
                          >
                            More
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            :class="{ show: menutoogle }"
                          >
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#"
                              >Something else here</a
                            >
                          </div>
                        </div>
                      </div> -->
            </div>
          </div>
          <div class="inbox custom-scrollbar">
            <div style="text-align: center" v-show="contactsLoading">
              <lottie-vue-player :src="`https://lottie.host/419462cd-1640-4aa4-bc8c-b22ef429a870/RaLkrQKqbu.json`"
                :autoplay="true" :loop="true" :player-controls="false" style="width: 50px; height: 50px">
              </lottie-vue-player>
            </div>
            <div class="media active grow-on-hover" style="cursor: pointer; padding: 0px 10px" v-for="contact in contacts"
              :key="'c' + contact.id" @click="getChats(contact)">
              <div class="media-size-email">
                <div class="mr-3 rounded-circle img-fluid img-50 bg-primary" style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 50px;
                            height: 50px;
                            margin-top: 10px;
                            overflow: hidden;
                          " alt="">
                          {{
                              (contact.Company_Name && typeof contact.Company_Name === 'string') 
                                  ? contact.Company_Name.split(" ").map((word) => word[0]).join("") 
                                  : ''
                          }}
                </div>
              </div>
              <div class="media-body">
                <span class="badge badge-info" style="font-size: 8px">{{
                  contact.updated | formatTimeFromNow
                }}
                  Ago</span>
                <h6>
                  <span class="digits" style="font-size: 12px" v-b-tooltip.hover :title="contact.Company_Name">
                  {{
                      (contact.Company_Name && typeof contact.Company_Name === 'string') 
                          ? contact.Company_Name?.slice(0, 16) 
                          : ''
                  }}..</span>
                </h6>
                <p>
                  <feather type="phone-call" size="12"></feather>
                  {{ contact.Company_Phone | formatPhoneNumber }}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-md-12">
    <div class="col call-chat-body" style="padding-left: 0px; border-radius: 0px">
      <div class="card" style="border-radius: 0px 10px 10px 0px; overflow: hidden">
        <div class="card-body p-0">
          <div class="row chat-box">
            <!-- Chat right side start-->
            <div class="col pr-0 chat-right-aside" v-if="chat.length > 0">
              <!-- chat start-->
              <div class="chat">
                <!-- chat-header start-->
                <div class="chat-header clearfix">
                  <div class="media-size-email" style="display: inline-block; float: left">
                    <div class="mr-3 rounded-circle img-fluid img-50 bg-primary" style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 50px;
                                height: 50px;
                              " alt="">
                      {{
                        currentContact.Company_Name.split(" ")
                          .map((word) => word[0])
                          .join("")
                      }}

                      
                    </div>
                  </div>
                  <div class="about">

                    <div class="name">
                      {{ currentContact.Company_Name
                      }}
                      <span class="badge ml-2"
                            :class="currentContact.avgSentiment < 5 ? 'badge-danger' : currentContact.avgSentiment == 5 ? 'badge-primary' : 'badge-success'"
                            v-if="currentContact.inbound == 1">Sentiment Score: {{ currentContact.avgSentiment }}</span>
                    </div>
                    <div class="status digits" v-b-tooltip.hover title="Phone Number (Carrier)">
                      <feather type="phone-call" size="14"></feather>
                      {{
                        currentContact.Company_Phone | formatPhoneNumber
                      }} <small>({{ currentContact.carrier }})</small>
                    </div>
                    <div class="status digits" v-b-tooltip.hover title="Address, City, State, Zipcode">
                      <feather type="home" size="14"></feather>
                      {{ currentContact.Company_Street_Address }}, {{ currentContact.Company_City }},
                      {{ currentContact.Company_State }} , {{ currentContact.Company_Zipcode }}
                    </div>
                    <div class="status digits" v-b-tooltip.hover title="Website">
                      <feather type="globe" size="14"></feather>
                      {{ currentContact.Website }}
                    </div>
                    <div class="status digits" v-b-tooltip.hover title="Days in conversation, Inbounds, Outbound Stats">
                      <feather type="circle" size="14"></feather>
                      Started: <small>{{ chat[0].time | formatToLocalTime  }}</small> ({{ chat[0].time | formatTimeFromNow  }} Ago) , # Inbounds: {{ chat.filter(c => c.inbound == 1).length }}, #Outbounds:
                      {{ chat.filter(c => c.inbound == 0).length }}
                    </div>
                  </div>
                  <ul class="list-inline float-left float-sm-right chat-menu-icons">
                    <li class="list-inline-item grow-on-hover">
                      <feather  type="file-text" v-b-tooltip.hover title="View client details."  @click="openModal('CLIENT_INFO')"></feather>
                    </li>
                    <b-modal id="modal-lg" size="l" title="Add to blacklist" class="theme-modal" hide-footer hide-header
                      v-model="clientInfoModal">
                      <ClientInfo></ClientInfo>
                    </b-modal>
                    <li class="list-inline-item grow-on-hover">
                      <feather  type="phone" v-b-tooltip.hover title="Call the prospect"  @click="callProspect(currentContact)"></feather>
                    </li>
                    <li class="list-inline-item grow-on-hover" @click="openModal('BLACKLIST')">
                      <feather type="shield-off" v-b-tooltip.hover title="Add to blacklist" v-b-modal.modal-lg></feather>
                    </li>
                    <b-modal id="modal-lg" size="l" title="Add to blacklist" class="theme-modal" hide-footer hide-header
                      v-model="blacklistModal">
                      <div class="image-container" style="
                                  max-height: 200px;
                                  overflow: hidden;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  border-radius: 20px 20px 20px 20px;
                                ">
                        <!-- <img :src="getImgUrl('reminder.gif')" style="width: 100%" /> -->
                      </div>
                      <div class="mt-2 text-center" style="font-size: 18px">
                        <b>Add to company blacklist:</b>
                      </div>
                      <br>
                      <button class="btn btn-primary" v-b-modal.modal-close_visit @click="addToBlacklist('COMPANY')" style="margin-right:2%;">
                        Add to company blacklist
                      </button>
                      <button class="btn btn-primary" v-b-modal.modal-close_visit @click="addToBlacklist('MASTER')">
                        Add to master blacklist
                      </button>
                    </b-modal>
                    <li class="list-inline-item grow-on-hover">
                      <feather type="calendar" v-b-tooltip.hover title="Add a Reminder" v-b-modal.modal-lg></feather>
                    </li>
                    <b-modal id="modal-lg" size="l" title="Start New Campaign" class="theme-modal" hide-footer hide-header
                      v-model="reminderModal">
                      <div class="image-container" style="
                                  max-height: 200px;
                                  overflow: hidden;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  border-radius: 20px 20px 20px 20px;
                                ">
                        <img :src="getImgUrl('reminder.gif')" style="width: 100%" />
                      </div>
                      <div class="mt-2 text-center" style="font-size: 18px">
                        <b>Add a Reminder for:</b><br />
                        {{ currentContact.Company_Name }}
                      </div>

                      <div style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                ">
                        <datepicker :inline="true"></datepicker>
                      </div>
                      <label>Reminder Text:</label>
                      <textarea rows="5" placeholder="Your Reminder Text Goes Here" style="
                                  width: 100%;
                                  border-radius: 10px;
                                  border: 1px solid black;
                                  padding: 10px;
                                "></textarea>

                      <button class="btn btn-primary" v-b-modal.modal-close_visit @click="addReminder">
                        Add Reminder
                      </button>
                    </b-modal>
                    <li class="list-inline-item grow-on-hover">
                      <feather type="archive" v-b-tooltip.hover title="Premade Messages" v-b-modal.modal-lg3></feather>
                    </li>
                    <b-modal id="modal-lg3" size="l" title="Start New Campaign" class="theme-modal" hide-footer
                      hide-header v-model="bulkModal3">

                      <div class="mt-2 text-center" style="font-size: 18px">
                        <span>Send Message to:</span><br />
                        <b>{{ currentContact.Company_Name }}</b>
                      </div>
                      <br />

                      <div class="btn btn-primary mb-2" @click="bulkModal2 = true" style="float:right">
                        <feather type="plus-square" class="text-top"></feather>
                      </div>
                      <br />

                      <label>Filter Template Types:</label>
                      <multiselect v-model="singleBulkType" tag-placeholder="" placeholder="Select Template Type" label="name"
                        track-by="code" :options="singleBulkTypeOptions" :multiple="false"></multiselect>
                        <label>Sort Template:</label>
                      <multiselect v-model="singleBulkSort" tag-placeholder="" placeholder="Select Sort Option" label="name"
                        track-by="code" :options="singleBulkSortOptions" :multiple="false"></multiselect>
                      <br />
                      <br />
                      <label>Templates</label>
                      <input class="form-control" type="text" placeholder="Search Templates in real time"
                        v-model="bulkTemplateSearch" />
                      <div class="card card-body bg-primary mt-2" v-for="template in bulkTemplates" :key="template.id"
                        v-b-tooltip.hover :title="template.text"
                        style="display:flex; flex-direction:row; justify-content:start; align-items:start;margin:10px 0px;"
                        v-if="bulkTemplateSearch.length == 0 || template.name.includes(bulkTemplateSearch) || template.text.includes(bulkTemplateSearch)"
                        @click="sendSingleBulk(template.code)">
                        <div>
                          <feather type="smartphone" size="32" class="mr-2"></feather>
                        </div>
                        <div>{{ template.name }}
                          <br />
                          <small>{{ template.text?.slice(0, 32) }}...</small>

                        </div>
                      </div>

                    </b-modal>
                    <li class="list-inline-item toogle-bar" v-on:click="chatmenutoogle = !chatmenutoogle">
                      <a href="#"><i class="icon-menu"></i></a>
                    </li>
                  </ul> 
                </div>
                <!-- chat-header end-->
                <div class="chat-history chat-msg-box custom-scrollbar">
                  <ul>
                    <li v-for="message in chat" :key="message.id" v-bind:class="{ clearfix: message.inbound == 0 }">
                      <div class="message" v-bind:class="{
                        'other-message pull-right':
                          message.inbound == 0,
                        'my-message': message.inbound != 0,
                        'bg-warning text-center': message.type == 3,
                        'bg-success text-center': message.type == 4,
                        'bg-success text-center': message.type == 5
                      }"
                        :style="message.type == 3 ? {'width': '100%', 'padding': '10px', 'line-height': '10px'} : (message.type == 4 || message.type == 5) ? {'width': '100%', 'padding': '10px', 'line-height': '10px'} : {}"
                      >
                        <span class="badge badge-success mr-2" v-if="message.inbound == 0">{{message.from_no?.slice(2) | formatPhoneNumber}}</span><br/>
                                <img class="rounded-circle float-right chat-user-img img-30" alt="" v-if="message.inbound == 0"
                          v-bind:src="getImgUrl('user/f1.jpg')" />
                         
                        <div class="message-data" v-bind:class="{
                          'text-right': message.inbound == 0,
                        }">
                         
                          <span class="badge badge-secondary mr-2" v-if="message.hook_id > 0">Hook</span>
                          <span class="badge badge-info mr-2" v-if="message.bulk_id > 0">BULK</span>
                          <span class="badge badge-warning mr-2" v-if="message.condition_id > 0">Wakeup</span>

                          <span class="message-data-time">{{ message.time | formatToLocalTime }} -
                            {{
                              message.time | formatTimeFromNow
                            }}
                            Ago</span>

                          <span class="badge ml-2"
                            :class="message.sent_score < 5 ? 'badge-danger' : message.sent_score == 5 ? 'badge-primary' : 'badge-success'"
                            v-if="message.inbound == 1">Sentiment score: {{ message.sent_score }}</span>
                        </div>
                        <div v-if="message.type == 3">Click on: https://{{message.URL}}</div>
                        <div v-if="message.type == 4">{{ message.text }}</div>
                        <div v-if="message.type == 5">{{ message.text }}</div>
                        <div v-if="message.type != 3 && message.type != 4 && message.type != 5">{{ message.text }}</div>
                        <div v-if="message.type==5">
                            <vue-audio :file="message.recordingUrl"></vue-audio>
                            <br>
                            <button @click="message.showTranscript = true" class="btn btn-primary" type="button">
                              Open Transcript
                          </button>
                          <br>
                          <br>
                          <p v-if="message.type == 5 && message.showTranscript == true" style="color: black;">
                            {{  message.transcript }}
                          </p>
                          <span class="badge badge-success" style="position: left;"
                          v-if="message.status == 0">Call Pending</span>
                          <span class="badge badge-success" style="position: left;"
                          v-if="message.status == 1 || message.status == 2 || message.status == 3">Call In-Progress</span>
                          <span class="badge badge-info" style="position: left;"
                            v-if="message.status == 4">Call Complete</span>
                        </div>
                        <br />
                        <span class="badge badge-success" style="position: absolute; bottom: -10px"
                          v-if="message.status == 2 && message.inbound == 0">Delivered - {{ message.updated | formatToLocalTime }}</span>
                        <span class="badge badge-info" style="position: absolute; bottom: -10px"
                          v-if="message.status == 1 && message.inbound == 0">Sent Pending Confirmation -
                          {{ message.updated | formatToLocalTime }}</span>
                        <span class="badge badge-danger" style="position: absolute; bottom: -10px"
                          v-if="message.status == 3 && message.inbound == 0">Failed : SPAM -
                          {{ message.updated | formatToLocalTime }}</span>
                        <span class="badge badge-danger" style="position: absolute; bottom: -10px"
                          v-if="message.status == 4 && message.inbound == 0">Failed -
                          {{ message.updated | formatToLocalTime }}</span>
                          <span class="badge badge-danger" style="position: absolute; bottom: -10px"
                          v-if="message.status == 5 && message.inbound == 0">Cancelled -
                          {{ message.updated | formatToLocalTime }}</span>
                          <span class="badge badge-warning" style="position: absolute; bottom: -10px"
                          v-if="message.status == 0 && message.inbound == 0">Scheduled -
                          {{ message.wait }} Mins to send.</span>
                      </div>
                 
                    </li>
                    <div>
                      <div class="image-not-found">
                        <div class="d-block start-conversion">
                          <!-- <img src="../assets/images/start-conversion.jpg" class="img-fluid"> -->
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
                <!-- end chat-history-->
                <div class="chat-message clearfix">
                  <div class="row">
                    <div class="col-xl-12 d-flex">
                      <div class="input-group text-box">
                        <input class="form-control input-txt-bx" id="message-to-send" v-model="text"
                          v-on:keyup.enter="addChat()" type="text" name="message-to-send"
                          placeholder="Type a message......" />
                        <div class="input-group-append">
                          <b-dropdown text="Add Variable" variant="primary" style="float: right">
                      <b-dropdown-header id="dropdown-header-label">
                        system Variables
                      </b-dropdown-header>
                     <b-dropdown-item @click="extractUrl(1)">URL ({{ extractedUrl || 'Generate URL' }})</b-dropdown-item>
                      <b-dropdown-header id="dropdown-header-label">
                        Lead Variables
                      </b-dropdown-header>
                      <b-dropdown-item @click="text = text + currentContact.Owners_Name">Owners Name ({{ currentContact.Owners_Name || "" }})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_Name">Company Name ({{ currentContact.Company_Name}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_State">Company State ({{ currentContact.Company_State}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_City">Company City ({{ currentContact.Company_City}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_Street_Address">Company Address ({{ currentContact.Company_Street_Address}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Country">Company Country ({{ currentContact.Country}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Industry">Company Industry ({{ currentContact.Industry}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Website">Company Website ({{ currentContact.Website}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_Phone">Company Phone ({{ currentContact.Company_Phone}})</b-dropdown-item>
                      <b-dropdown-item @click="text = text + currentContact.Company_Email">Company Email ({{ currentContact.Company_Email}})</b-dropdown-item>
                      
                      
                    </b-dropdown>
                          <button @click="addChat()" class="btn btn-primary" type="button">
                            SEND
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end chat-message-->
                <!-- chat end-->
                <!-- Chat right side ends-->
              </div>
            </div>

            <div style="text-align: center; width: 100%" v-show="chatLoading">
              <lottie-vue-player :src="`https://lottie.host/419462cd-1640-4aa4-bc8c-b22ef429a870/RaLkrQKqbu.json`"
                :autoplay="true" :loop="true" :player-controls="false" style="width: 50px; height: 50px">
              </lottie-vue-player>
            </div>

            <div style="text-align: center; width: 100%" v-show="!chatLoading && chat.length == 0">
              <lottie-vue-player :src="`https://lottie.host/45136e98-771b-4ce7-91f6-56ea43570112/p7TN17ZtKw.json`"
                :autoplay="true" :loop="true" :player-controls="false" style="width: 100%; height: 250px">
              </lottie-vue-player>
              <p>Select a chat from left hand side.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>

  <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from 'moment'; // Import Moment.js at the beginning of your component file

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import CampaignModal from "../components/campaignModal.vue";
import advancedStats from "../components/advancedStats.vue";
import ClientInfo from "../components/memory/ClientInfo.vue";
import VueAudio from "vue-audio";

export default {
  name: "BackOffice",
  components: { ckeditor: CKEditor.component, Multiselect, Datepicker, advancedStats, CampaignModal, 'vue-audio': VueAudio, 'ClientInfo': ClientInfo },

  data() {
    const campaignData = localStorage.getItem("campaign");
    let campaign;
    if (campaignData) {
      try {
        campaign = JSON.parse(campaignData);
        console.log("campaign retreived from LocalStorage");
        console.log(campaign);
      } catch (error) {
        console.error("Error parsing 'campaign' from localStorage:", error);
      }
    } else {
      campaign = [];
    }

    return {
      userId: localStorage.getItem("userId"),
      loading: false,
      contactsLoading: false,
      chatLoading: false,
      format: "MM/dd/yyyy",
      fromDate: moment().subtract(6, 'days').format('MM/DD/YYYY'),
      toDate: moment().add(1, 'days').format('MM/DD/YYYY'),
      dateFormat: [],
      dateOptions: [
        { code: 1, name: "Today" },
        { code: 76, name: "Yesterday" },
        { code: 7, name: "Last 7 Days" },
        { code: 30, name: "Last 30 Days" },
        { code: 77, name: "This Week" },
        { code: 78, name: "Last Week" },
        { code: 88, name: "This Month" },
        { code: 99, name: "Last Month" },
        { code: 100, name: "Year To Date" },
        { code: 101, name: "All" },
      ],
      totalColdMessages: 0,
      totalSMSCost: 0,
      totalAICost: 0,
      totalCost: 0,
      totalSpam: 0,
      totalDelivered: 0,
      totalPending:0,
      totalPositive: 0,
      totalNegative: 0,
      totalResponses: 0,
      totalNonResponsive: 0,
      totalClicks: 0,
      totalGoals: 0,
      totalCPA: 0,
      type: "Unread",
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        config: { height: "300px" },
      },
      menutoogle: false,
      text: "",
      search: "",
      currentchat: [],
      chatmenutoogle: false,

      contacts: [],
      chat: [],
      currentContact: [],
      avatar: localStorage.getItem("avatar")
        ? JSON.parse(localStorage.getItem("avatar"))
        : [],
      avatarImage: "",
      campaign: campaign,
      totalOutboundSuccess: 0,
      totalOutboundFailed: 0,
      totalOutboundPending: 0,
      campaignStep: 9,
      multiValue: [],
      options: [
        { code: 1, name: "1-23.fun" },
        { code: 2, name: "1-2-3w.com" },
        { code: 3, name: "1-23w.com" },
      ],
      uploadLeads: false,
      singledropzoneOptions: {
        url: 'http://localhost:8080',
        thumbnailWidth: 150,
        maxFiles: 1,
        maxFilesize: 2,
        addRemoveLinks: true,
        dictDefaultMessage: '<i class=\'icon-cloud-up\'></i><h6>Select Document To Upload.</h6><span>(Supported Formats: CSV, TXT)</span>'
      },
      industriesValue: [],
      industries: [],
      totalSelectedLeads: 0,
      // conversationsPerDay: 0,
      staticHook: true,

      connectedData: [],
      selectedData: [],
      goals: [],
      goalsValue: [],
      domains: [],
      domainsValue: [],
      createCampaignLoading: false,
      createCampaignLoadingText: "Creating campaign...",
      hookText: "",
      example1: "",
      example2: "",
      example3: "",
      avatarText: "",
      newAvatar: [],
      newCampaign: [],
      block_stats: true,
      liveStatsBlock: true,
      bulkTemplates: [],
      bulkTemplatesList: [
      ],
      bulkTemplate: [],
      bulkList: [],
      bulkModal: false,
      bulkModal3: false,
      reminderModal: false,
      editCampaignModal: false,
      foldersCount: [],

      bulkData: [],
      bulkModal2: false,
      bulkSelectedDataSet: { name: "", text: "" },
      singleBulkType: [{ code: 0, name: "SMS" }],
      singleBulkTypeOptions: [{ code: 0, name: "SMS" }],
      bulkTemplateSearch: "",
      singleBulkSort: [{ code: 3, name: "Older First" }],
      singleBulkSortOptions: [{ code: 0, name: "Newest First" },{ code: 1, name: "A-Z" },{ code: 2, name: "Z-A" },{ code: 3, name: "Older First" }],
      dataModal: false,
      webModal: false,
      fileModal: false,
      SelectedDataSet: [{ name: "", text: "" }],
      newWebsite: "",
      singledropzoneOptions: {
        url:
          "https://123avatars.com/backend/upload.php?u=" +
          localStorage.getItem("userId"),
        thumbnailWidth: 50,
        maxFiles: 4,
        maxFilesize: 5,
        addRemoveLinks: false,
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6><span>(Allowed Formats <strong>PDF, CSV, TXT</strong>, you can upload multiple files at once.)</span>",
      },
      industriesIncludeValue: [],
      industriesExcludeValue: [],
      industriesIncludes: [
        { code: 0, name: 'Owners_Name' },
        { code: 1, name: 'Company_Name' },
        { code: 2, name: 'Company_Street_Address' },
        { code: 3, name: 'Company_City' },
        { code: 4, name: 'Company_Zipcode' },
        { code: 5, name: 'Company_State' },
        { code: 6, name: 'Website' },
        { code: 7, name: 'Industry' },
        { code: 8, name: 'Year_Established' },
        { code: 9, name: 'Years_in_Business' },
        { code: 10, name: 'Facebook_Page' },
      ],
      industriesExcludes: [
        { code: 0, name: 'Owners_Name' },
        { code: 1, name: 'Company_Name' },
        { code: 2, name: 'Company_Street_Address' },
        { code: 3, name: 'Company_City' },
        { code: 4, name: 'Company_Zipcode' },
        { code: 5, name: 'Company_State' },
        { code: 6, name: 'Website' },
        { code: 7, name: 'Industry' },
        { code: 8, name: 'Year_Established' },
        { code: 9, name: 'Years_in_Business' },
        { code: 10, name: 'Facebook_Page' },
      ],
      neverRepliedWakeups:[],
      positiveWakeups:[],
      wakeupModal:false,
      wakeupDays:0,
      wakeupHours:0,
      wakeupMinutes:0,
      wakeupPositive:0,
      wakeupStatic:1,
      wakeupPrompt:"",
      isGettingStats: false,
      isGettingFoldersCount: false,
      //statsData: [],
      extractedUrl: null,
      sequences: [],
      sequenceTypeMap: new Map(),
      blacklistModal: false,
      clientInfoModal: false
    };
  },

  mounted() {
    this.getFoldersCount();
    this.getStats();
    this.fetchContacts();
    this.getBulk();
    this.getSequences();

     setInterval(() => {
      if(this.$route.params.campId && this.$route.params.id){
        this.getStats(1);
        this.getFoldersCount();
      }
    }, 30000);
    // setTimeout(() => {
    //   this.getDomains();
    //   this.fetchLeadIndusties();
    //   this.getConnectedData();
    //   this.getGoals();
    //   this.fetchAvatar();
    // }, 10000)
  },
  watch: {
    dateFormat: {
      handler: "formatStats", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    singleBulkSort:{
      handler: "sortBulkTemplates", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    // fromDate: {
    //   handler: "getStats", // Specify the method to call
    //   deep: true, // Watch for deep changes in the array
    // },
    // toDate: {
    //   handler: "getStats", // Specify the method to call
    //   deep: true, // Watch for deep changes in the array
    // },
    type: {
      handler: "fetchContacts", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
  },
  methods: {
    async callProspect(currentContact) {
      console.log("currentContact: ", currentContact);
      let response = await axios( "https://123avatars.com/backend/newPhoneCall.php?user_id=" + this.userId 
        + "&campaign_id=" + this.$route.params.campId + "&avatar_id=" + this.$route.params.id + "&lead_id=" + currentContact.id + "&from_num=" + "&to_num=" + currentContact.Company_Phone);
      if(response.status == 200) {
          this.$toasted.show("Added phone call queued successfully.", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        } else {
          this.$toasted.show("Failed to queue phone call.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "cross",
            duration: 2000,
          });
        }
    },
    async openModal(type) {
      if(type == 'BLACKLIST') {
        this.reminderModal = false;
        this.bulkModal1 = false;
        this.bulkModal2 = false;
        this.bulkModal3 = false;
        this.clientInfoModal = false;
        this.blacklistModal = true;
      }
      if(type == 'CLIENT_INFO') {
        this.reminderModal = false;
        this.bulkModal1 = false;
        this.bulkModal2 = false;
        this.bulkModal3 = false;
        this.blacklistModal = false;
        this.clientInfoModal = true;
      }
    },
    async addToBlacklist(type) {
      if(type == 'MASTER') {

      }
      if(type == 'COMPANY') {
        console.log("currentContact: ", this.currentContact);
        let email = this.currentContact.Company_Email ? this.currentContact.Company_Email : '';
        let response = await axios
        .get(
          "https://123avatars.com/backend/setAvatarBlacklist.php?u=" + this.userId + "&no=" + this.currentContact.Company_Phone + "&label=" + this.$route.params.campId + "&no2=" + '' + "&mail=" + email + "&mail2=" + this.mail2
        );
        if(response.status == 200) {
          this.$toasted.show(" Added to blacklist successfully ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        } else {
          this.$toasted.show("Failed to add to blacklist.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "cross",
            duration: 2000,
          });
        }
      }
    },
    async getSequences() {
      let response = await axios.get("https://123avatars.com/backend/getFunnels.php?u=" + this.userId);
      this.sequences = response.data;
      if(this.sequences) {
        this.sequences.forEach((sequence) => {
          this.sequenceTypeMap.set(sequence.name, sequence.type);
        });
      }
    },
    saveWakeup(){
      if(this.wakeupPositive == 1){
        let wakeup = {
          id: this.positiveWakeups.length+1,
        mins: parseInt(this.wakeupDays) * 1440 + parseInt(this.wakeupHours) * 60 + parseInt(this.wakeupMinutes),
        static: this.wakeupStatic,
        text: this.wakeupPrompt,
      }
      this.positiveWakeups.push(wakeup);
      }else{
         let wakeup = {
          id: this.neverRepliedWakeups.length+1,
        mins: parseInt(this.wakeupDays) * 1440 + parseInt(this.wakeupHours) * 60 + parseInt(this.wakeupMinutes),
        static: this.wakeupStatic,
        text: this.wakeupPrompt,
      }
      this.neverRepliedWakeups.push(wakeup);

      }
      this.wakeupModal = false;
    },
     addHookVariable(variable){
      this.hookText = this.hookText+" "+variable;
    },
    sortBulkTemplates(){
      if(this.singleBulkSort.code == "0"){
        // Newest First (assuming higher id is newer)
          this.bulkTemplates = this.bulkTemplates.sort((a, b) => {
            return b.code - a.code;
          });
        };

       if(this.singleBulkSort.code == "3"){
          // Oldest First (assuming lower id is older)
            this.bulkTemplates = this.bulkTemplates.sort((a, b) => {
                return a.code - b.code;
            });
        };

      
      if(this.singleBulkSort.code == "1"){
        //A-Z
        this.bulkTemplates = this.bulkTemplates.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
      }
      if(this.singleBulkSort.code == "2"){
        // Z-A
          this.bulkTemplates = this.bulkTemplates.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
            return 0;
          });

        };
        
      
    },  
    setType(days,i) {
      if(i==0){
        this.type = "LastOutboundDays"+days;
      }else{
        this.type = "LastInboundDays"+days;
      }
    },
    formatStats() {
      let currentSelected = parseInt(this.dateFormat.code);
      console.log("Current Selected:" + currentSelected);
      if(currentSelected == 76){
        // Yesterday
      this.fromDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
      this.toDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
      }else if (currentSelected == 77) {
        // This Week
        this.fromDate = moment().startOf('week').format('MM/DD/YYYY');
        this.toDate = moment().format('MM/DD/YYYY');
      } else if(currentSelected == 78){
        // Last Week
          this.fromDate = moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY');
          this.toDate = moment().subtract(1, 'weeks').endOf('week').format('MM/DD/YYYY');

      } else if (currentSelected == 88) {
        // This Month
        this.fromDate = moment().startOf('month').format('MM/DD/YYYY');
        this.toDate = moment().format('MM/DD/YYYY');
      } else if (currentSelected == 99) {
        // Last Month
        this.fromDate = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
        this.toDate = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
      } else if (currentSelected == 100) {
        // YTD
        this.fromDate = moment().startOf('year').format('MM/DD/YYYY');
        this.toDate = moment().format('MM/DD/YYYY');
      } else if (currentSelected == 101) {
        // All (Example: Setting fromDate to January 1, 2000)
        this.fromDate = moment("2000-01-01").format('MM/DD/YYYY');
        this.toDate = moment().format('MM/DD/YYYY');
      } else {

        this.toDate = moment().add(0, 'days').format('MM/DD/YYYY');

        this.fromDate = moment().subtract(currentSelected - 1, 'days').format('MM/DD/YYYY')

      }
      this.getStats();
    },
    getLottie(path) {
      return require("../assets/json/" + path);
    },
    getImgUrl(path) {
      return require("../assets/images/" + path);
    },
    addfavorites(id) {
      this.$store.dispatch("email/addfavorites", id);
    },
    addChat: function () {
      if (this.text !== "") {
        let currentTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
        // Assuming this.chat is an array of chat entries
        let chatData = this.chat.filter(entry => entry.inbound === "0");
        console.log("Chat data: " + chatData);
        console.log(chatData);
        chatData = chatData[0];

        //add to current chat
        let newEntry = {
          avatar_id: chatData.avatar_id,
          campaign_id: chatData.campaign_id,
          condition_id: "0",
          cost: "0",
          cost_ai: "0",
          from_no: chatData.from_no,
          hook_id: "0",
          id: "",
          inbound: "0",
          lead_id: chatData.lead_id,
          sent_cost: "", // Fill in the value
          sent_score: "", // Fill in the value
          status: "", // Fill in the value
          telynx_id: "", // Fill in the value
          text: this.text,
          time: currentTime, // Fill in the value
          to_no: chatData.to_no,
          updated: "", // Fill in the value
          user_id: chatData.userId, // Fill in the value
          wait: "", // Fill in the value
        };
        let text = this.text;
        this.text = "";

        // Append the new entry to this.chat
        this.chat.push(newEntry);

        this.$toasted.show(" Message Scheduled Successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 2000,
        });

        //lets do the actual request
        // Define the data as an object
        const requestData = {
          avatarID: this.$route.params.id,
          campaignID: this.$route.params.campId,
          text: text,
          from: chatData[0].from_no,
          to: chatData[0].to_no,
          lead_id: chatData[0].lead_id
        };


        // Use the `params` option to send data in the query parameters
        axios.get("https://123avatars.com/backend/scheduleMessage.php", {
          params: requestData
        }).then(response => {
          console.log(response);
        });
      }
    },
    fetchContacts() {

      this.contactsLoading = true;
      // this.contacts=[];
      console.log("Fetching contacts");
      let filter = 0;
      if (this.type == "New Today") {
        filter = 1;
      }
      if (this.type == "New Yesterday") {
        filter = 2;
      }
      if (this.type == "Inbox") {
        filter = 3;
      }
      if (this.type == "Outbox") {
        filter = 4;
      }
      if (this.type == "Non Responsive") {
        filter = 5;
      }
      if (this.type == "Opted Out") {
        filter = 6;
      }
      if (this.type == "Unread") {
        filter = 7;
      }
      if (this.type.startsWith("Sentiment")) {
          // Extract the sentiment number from the type
          const sentimentNumber = parseInt(this.type.replace("Sentiment", ""), 10);

          if (!isNaN(sentimentNumber) && sentimentNumber >= 1 && sentimentNumber <= 10) {
              // Calculate the corresponding filter value
              filter = sentimentNumber + 7;

              // Now, you can use the 'filter' value as needed in your code
              console.log(`Sentiment: ${sentimentNumber}, Filter: ${filter}`);
          } else {
              console.log("Invalid sentiment value.");
          }
      }
      if (this.type.startsWith("Inbound")) {
          // Extract the sentiment number from the type
          const sentimentNumber = parseInt(this.type.replace("Inbound", ""), 10);

          if (!isNaN(sentimentNumber) && sentimentNumber >= 1 && sentimentNumber <= 10) {
              // Calculate the corresponding filter value
              filter = sentimentNumber + 17;

              // Now, you can use the 'filter' value as needed in your code
              console.log(`Inbound: ${sentimentNumber}, Filter: ${filter}`);
          } else {
              console.log("Invalid sentiment value.");
          }
      }
      if (this.type.startsWith("LastInboundDays")) {
          // Extract the sentiment number from the type
          const sentimentNumber = parseInt(this.type.replace("LastInboundDays", ""), 10);

          if (!isNaN(sentimentNumber) && sentimentNumber >= 1 && sentimentNumber <= 10) {
              // Calculate the corresponding filter value
              filter = sentimentNumber + 27;

              // Now, you can use the 'filter' value as needed in your code
              console.log(`Inbound: ${sentimentNumber}, Filter: ${filter}`);
          } else {
              console.log("Invalid sentiment value.");
          }
      }
      if (this.type.startsWith("LastOutboundDays")) {
          // Extract the sentiment number from the type
          const sentimentNumber = parseInt(this.type.replace("LastOutboundDays", ""), 10);

          if (!isNaN(sentimentNumber) && sentimentNumber >= 1 && sentimentNumber <= 10) {
              // Calculate the corresponding filter value
              filter = sentimentNumber + 40;

              // Now, you can use the 'filter' value as needed in your code
              console.log(`Inbound: ${sentimentNumber}, Filter: ${filter}`);
          } else {
              console.log("Invalid sentiment value.");
          }
      }
      if (this.type == "Clicks") {
        filter = 101;
      }
      
      if(this.sequenceTypeMap.has(this.type)) {
        filter = 53 + parseInt(this.sequenceTypeMap.get(this.type)) + 1;
      }

      if(this.type == 'Calls') {
        filter = 64;
      }

      if(this.type == 'Calls ( > 2 mins )') {
        filter = 65;
      }

      let campaignId = 0;
      if (this.campaign.id) {
        campaignId = this.campaign.id;
      }
      if (this.$route.params.campId) {
        campaignId = this.$route.params.campId;
      }

      if (this.type == "Search") {
        return false;
      } else {
        // Default Folders System
        this.contacts = [];
        axios
          .get(
            "https://123avatars.com/backend/folder_leads.php?avatarID=" +
            this.$route.params.id +
            "&campaignID=" +
            campaignId +
            "&folder=" + 
            filter +
            "&u=" + this.userId
          )
          .then((response) => {
            console.log("contacts: ", response);
            this.contacts = [];
            this.contacts = response.data;
            this.contactsLoading = false;
            // localStorage.setItem("contacts", JSON.stringify(this.contacts));
          });

      }
    },
    getChats(contact) {
      this.chatLoading = true;
      this.chat = [];
      this.extractedUrl= null;
      //Avatar id  = this.$route.params.id
      //lead_id = this.currentChat.contact.id
      console.log("Getting Chat");
      //set contact as current selected
      this.currentContact = contact;
      console.log("Current Contact->");
      console.log(this.currentContact);
      console.log(this.contacts);
       // If in the unread folder and you wish to remove all items where opened = 1
      if(this.type =="Unread"){
        this.contacts = this.contacts.filter((item) => item.opened !== 1);
        this.foldersCount.unread = parseInt(this.foldersCount.unread) - 1;
      }
      //mark as opened
      let selected = this.contacts.find((item) => item.id === contact.id);
      selected.opened = 1;
     
      

      this.chat = [];
      axios
        .get(
          "https://123avatars.com/v2/lead-conversation?avatar_id=" +
          this.$route.params.id +
          "&lead_id=" +
          contact.id +
          "&user_id=" +
          this.userId
        )
        .then((response) => {
          console.log(response);
          let sortChats = response.data.sort((chatOne, chatTwo) => {
            console.log("chatOne: ", chatOne.time);
            console.log("chatTwo: ", chatTwo.time);
            return chatOne.time >= chatTwo.time;
          });
          this.chat = sortChats;
          this.extractUrl();
          this.chatLoading = false;

          //update sentiment score for the lead
          let avgSentiment = 0;
          let inbound = 0;
          let inbounds = 0;
          this.chat.forEach(chat=>{
            
            if(chat.inbound == 1){
              avgSentiment = avgSentiment + parseInt(chat.sent_score);
              inbound = 1;
              inbounds = inbounds + 1;
            }
          });
          avgSentiment = avgSentiment / inbounds;
          this.currentContact.avgSentiment = avgSentiment;
          this.currentContact.inbound = inbound;

        });
    },
    getStats(auto) {
       if (this.isGettingStats) {
       return;
     }
     this.isGettingStats = true;
    
       this.loading = true;
       console.log("from: " + this.fromDate);
       console.log("to: " + this.toDate);

       // Parse the input date strings
       const fromDate = new Date(this.fromDate);
       const toDate = new Date(this.toDate);

       // Format the dates as MM/DD/YYYY
       const formattedFromDate = `${(fromDate.getMonth() + 1)
         .toString()
         .padStart(2, "0")}/${fromDate
           .getDate()
           .toString()
           .padStart(2, "0")}/${fromDate.getFullYear()}`;
       const formattedToDate = `${(toDate.getMonth() + 1)
         .toString()
         .padStart(2, "0")}/${toDate
           .getDate()
           .toString()
           .padStart(2, "0")}/${toDate.getFullYear()}`;

    //   // Create the date range string
      let dateRange = `${formattedFromDate} - ${formattedToDate}`;
      if (this.fromDate.length < 1 || this.toDate.length < 1) {
        dateRange = 0;
      }
      console.log(dateRange);
      try{
        axios
          .get(
            "https://123avatars.com/backend/backoffice.php?avatar_id=" +
            this.$route.params.id +
            "&time=" +
            encodeURIComponent(dateRange)
          )
          .then((response) => {
            console.log('backOffice.vue backOffice response: ', response);
           // this.statsData = response.data;
            // this.campaignName = response.data.campaign.name;
            // this.campaignStatus = response.data.campaign.status;
            this.campaign = response.data.campaign;
            this.newCampaign = this.campaign;
            // this.conversationsPerDay = this.campaign.conv_per_day;
            this.newAvatar = this.avatar;
            this.avatar = response.data.avatar;
            if(this.hookText?.length < 1){
              this.hookText = response.data.hook?.prompt;
            }
            this.staticHook = response.data.hook?.static;
            localStorage.setItem("avatar", JSON.stringify(this.avatar));
            localStorage.setItem("campaign", JSON.stringify(this.campaign));
            if (response.data.avatar.gender == "female") {
              this.avatarImage = "f" + response.data.avatar.image + ".jpg";
            } else {
              this.avatarImage = "m" + response.data.avatar.image + ".jpg";
            }

            this.campaignNewConv = response.data.campaign.conv_per_day;

    //         //LiveStats with notifications
            let newSuccess = parseInt(response.data.total_outbound_success) - this.totalOutboundSuccess;
            let newFailed = parseInt(response.data.total_outbound_failed) - this.totalOutboundFailed;
            if(auto == 1){
            if (newSuccess > 0 && this.totalOutboundSuccess > 0) {
              this.$toasted.show(newSuccess + " Messages Sent Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              });

            }
            if (newFailed > 0 && this.totalOutboundFailed > 0) {
              this.$toasted.show(newFailed + " Messages failed to Deliver", {
                theme: "outline",
                position: "top-right",
                type: "danger",
                icon: "info",
                duration: 10000,
              });
            }
            }


            // this.totalOutboundSuccess = parseInt(response.data.total_outbound_success);
            // this.totalOutboundFailed = parseInt(response.data.total_outbound_failed);
            // this.totalOutboundPending = parseInt(response.data.total_inqueue);

            // this.totalColdMessages = response.data.total_outbound;
            // this.totalSMSCost = parseFloat(
            //   response.data.total.total_cost
            // ).toFixed(2);
            // this.totalAICost = parseFloat(
            //   (parseInt(response.data.total.total_cost_ai) / 1000) * 0.002
            // ).toFixed(2);
            // this.totalCost = parseFloat(
            //   this.totalSMSCost + this.totalAICost
            // ).toFixed(2);
            // this.totalSpam = response.data.total_outbound_failed;
            // this.totalDelivered = response.data.total_outbound_success;
            // this.totalPending = parseInt(this.totalColdMessages) - ( parseInt(this.totalSpam) + parseInt(this.totalDelivered) )
            // this.totalPositive = response.data.total_positive;
            // this.totalNegative = response.data.total_negative;
            // this.totalResponses = response.data.total_replies;
            // this.totalNonResponsive = this.totalDelivered - this.totalResponses;
            // this.totalClicks = response.data.clicks;
            // if (response.data.goals) {
            //   this.totalGoals = response.data.goals.length;
            // } else {
            //   this.totalGoals = 0;
            // }
            // if (this.totalGoals > 0) {

            //   this.totalCPA = (this.totalCost / this.totalGoals).toFixed(2);
            // }else{
            //   this.totalCPA = 0;
            // }
            this.loading = false;
          });
      } catch (error) {
      console.error("Error getting stats:", error);
    } finally {
      this.isGettingStats = false;
    }

      // Recursive
      // setTimeout(() => {
      //   this.getStats();
      //   this.getFoldersCount();
      // }, 10000);
    },
    getDomains() {
      axios.get("https://123avatars.com/backend/getDomains.php?noStat=1&u=" + this.userId).then((response) => {
        let domains = response.data.domains;
        console.log(response.data);
        domains.forEach(domain => {
          this.domains.push({
            code: domain.id,
            name: domain.domain,
          })
        })
        let domainIds = this.newCampaign.domains;
        try{
          domainIds = this.newCampaign.domains.split(',');
          console.log(domainIds);
        domainIds.forEach(domainId => {
          let domain = this.domains.find((item) => item.code === domainId);
          this.domainsValue.push(domain);
        });
        }catch(e){
          let domain = this.domains.find((item) => item.code === domainIds);
          this.domainsValue.push(domain);
        };
        
      });
    },
    fetchLeadIndusties() {
      axios.get("https://123avatars.com/backend/getLeadIndusties.php").then((response) => {

        let industries = response.data;
        this.industries = [];
          let id = 0;
        industries.forEach(industry => {
          id= id+1;
          this.industries.push({
            code: id,
            name: industry.Industry + " (" + industry.Count + ")"
          })
        });

        let industriesSelected = this.newCampaign.industries.split(',');
        industriesSelected.forEach(industry => {
          let industryS = this.industries.find((item) => item.name.includes(industry));
          this.industriesValue.push(industryS);
        });

      });
    },
     getConnectedData(s){
      
      axios.get("https://123avatars.com/backend/getData.php?u="+this.userId).then((response) => {
        console.log(response.data)
        this.connectedData = [];
        response.data.forEach(d =>{
          if(d.status == 1){
              this.connectedData.push({
                code:d.id,
                name:d.name,
              })
              if(d.id == s){
                this.selectedData.push({
                  code:d.id,
                  name:d.name,
                });
              }
          }
        });
      });
    },
    getGoals() {
      axios
        .get("https://123avatars.com/backend/integrations.php?u=" + this.userId)
        .then((response) => {
          let integrations = response.data.integrations;
          console.log(integrations);
          integrations.forEach(integration => {
            if (integration.type == 3 && integration.status == 1) {
              //Chargebee is connected
              this.goals.push({
                code: integration.id,
                name: '[ ChargeBee ] ' + integration.name,
              })
            }
          })
        });
      axios.get("https://123avatars.com/backend/getAllGoals.php?u=" + this.userId).then((response) => {
        let goals = response.data.collection;
        console.log(goals);
        goals.forEach(goal => {
          let id = goal.uri.split("/");
          id = id.pop();
          this.goals.push({
            code: id,
            name: '[ ' + goal.profile.name + ' ] ' + goal.name,
          })
        })
        this.getAvatarGoals();
      });
    },
    fetchAvatar() {
      axios.get("https://123avatars.com/backend/getAvatar.php?id=" + this.$route.params.id).then((response) => {
        console.log(response.data.data);
        let data = response.data.data;

        data.forEach(d => {
          let sd = this.connectedData.find((item) => item.code === d.id);
          this.selectedData.push(sd);
        });

      })
    },
    getAvatarGoals() {
      axios.get("https://123avatars.com/backend/getGoals.php?id=" + this.$route.params.id).then((response) => {
        console.log("MAtched goals");
        console.log(response.data);
        let goals = response.data;
        goals.forEach((goal) => {
          let sd = this.goals.find((item) => item.code === goal.event_id);
          // this.goalsValue.push({
          //   code: goal.id,
          //   name: goal.name,
          // });
          // console.log(this.goals);
          // console.log(sd);
          this.goalsValue.push(sd);

        });
      });
    },
    createCampaign() {
      this.editCampaignModal = false;
      this.$toasted.show(" Campaign Edited Successfully!", {
        theme: "outline",
        position: "top-right",
        type: "success",
        icon: "check",
        duration: 10000,
      });

    },
    sendBulk() {
      this.bulkModal = false;
  

       // Default Folders System
        axios
          .get(
            "https://123avatars.com/backend/sendBulk.php?avatarID=" +
            this.$route.params.id +
            "&campaignID=" +
            this.$route.params.campId +
            "&folder=" + this.bulkList.code + "&text=" + this.bulkTemplate.text + "&bulkId="+this.bulkTemplate.code
          )
          .then((response) => {
          //   console.log(response);
            this.contacts = [];
            this.contacts = response.data;
            this.contactsLoading = false;
            // localStorage.setItem("contacts", JSON.stringify(this.contacts));
             this.$toasted.show(" Bulk Messages Scheduled Successfully", {
        theme: "outline",
        position: "top-right",
        type: "success",
        icon: "check",
        duration: 10000,
      });
          });

     
    },
    sendSingleBulk(id) {
      this.bulkModal3 = false;
      let template = this.bulkTemplates.find((item) => item.code === id);
      this.text = template.text;
      this.addChat();

    },
    addReminder() {
      this.reminderModal = false;
      this.$toasted.show(" Reminder Added!", {
        theme: "outline",
        position: "top-right",
        type: "success",
        icon: "check",
        duration: 10000,
      });
    },
    getFoldersCount() {
      if (this.isGettingFoldersCount) {
      return;
    }

    this.isGettingFoldersCount = true;
    try{
      axios.get("https://123avatars.com/backend/foldersCount.php?campaignID=" + this.$route.params.campId + "&avatarID=" + this.$route.params.id).then(response => {
        console.log("FoldersCount-->");
        this.foldersCount = response.data;
        console.log(response);
        // Update bulk list folders count
        // 1 => "new_today",
        //   2 => "new_yesterday",
        //   3 => "inbox",
        //   4 => "outbox",
        //   5 => "non_responsive",
        //   6 => "stop",
       this.bulkTemplatesList = [
    { code: 1, name: `New Today (${this.foldersCount.new_today})` },
    { code: 2, name: `New Yesterday (${this.foldersCount.new_yesterday})` },
    { code: 3, name: `Inbox (${this.foldersCount.inbox})` },
    { code: 4, name: `Outbox (${this.foldersCount.outbox})` },
    { code: 5, name: `Non Responsive (${this.foldersCount.non_responsive})` },
    { code: 6, name: `Opted Out (${this.foldersCount.stop})` },
    { code: 7, name: `Unread (${this.foldersCount.unread})` },
    // Add more folders here using the same pattern
    { code: 8, name: `Sentiment 1 (${this.foldersCount.sentiment_1})` },
    { code: 9, name: `Sentiment 2 (${this.foldersCount.sentiment_2})` },
    { code: 10, name: `Sentiment 3 (${this.foldersCount.sentiment_3})` },
    { code: 11, name: `Sentiment 4 (${this.foldersCount.sentiment_4})` },
    { code: 12, name: `Sentiment 5 (${this.foldersCount.sentiment_5})` },
    { code: 13, name: `Sentiment 6 (${this.foldersCount.sentiment_6})` },
    { code: 14, name: `Sentiment 7 (${this.foldersCount.sentiment_7})` },
    { code: 15, name: `Sentiment 8 (${this.foldersCount.sentiment_8})` },
    { code: 16, name: `Sentiment 9 (${this.foldersCount.sentiment_9})` },
    { code: 17, name: `Sentiment 10 (${this.foldersCount.sentiment_10})` },
    { code: 18, name: `1 Inbound (${this.foldersCount.inbound_1})` },
    { code: 19, name: `2 Inbounds (${this.foldersCount.inbound_2})` },
    { code: 20, name: `3 Inbounds (${this.foldersCount.inbound_3})` },
    { code: 21, name: `4 Inbounds (${this.foldersCount.inbound_4})` },
    { code: 22, name: `5 Inbounds (${this.foldersCount.inbound_5})` },
    { code: 23, name: `6 Inbounds (${this.foldersCount.inbound_6})` },
    { code: 24, name: `7 Inbounds (${this.foldersCount.inbound_7})` },
    { code: 25, name: `8 Inbounds (${this.foldersCount.inbound_8})` },
    { code: 26, name: `9 Inbounds (${this.foldersCount.inbound_9})` },
    { code: 27, name: `10+ Inbounds (${this.foldersCount.inbound_10_plus})` },
    { code: 28, name: `1 Day Since Last Inbound (${this.foldersCount.day_since_last_inbound_1})` },
    { code: 29, name: `2 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_2})` },
    { code: 30, name: `3 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_3})` },
    { code: 31, name: `4 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_4})` },
    { code: 32, name: `5 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_5})` },
    { code: 33, name: `6 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_6})` },
    { code: 34, name: `7 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_7})` },
    { code: 35, name: `8 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_8})` },
    { code: 36, name: `9 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_9})` },
    { code: 37, name: `10 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_10})` },
    { code: 38, name: `11-20 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_11_20})` },
    { code: 39, name: `21-30 Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_21_30})` },
    { code: 40, name: `31+ Days Since Last Inbound (${this.foldersCount.day_since_last_inbound_31_plus})` },
    { code: 41, name: `1 Day Since Last Outbound (${this.foldersCount.day_since_last_outbound_1})` },
    { code: 42, name: `2 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_2})` },
    { code: 43, name: `3 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_3})` },
    { code: 44, name: `4 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_4})` },
    { code: 45, name: `5 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_5})` },
    { code: 46, name: `6 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_6})` },
    { code: 47, name: `7 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_7})` },
    { code: 48, name: `8 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_8})` },
    { code: 49, name: `9 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_9})` },
    { code: 50, name: `10 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_10})` },
    { code: 51, name: `11-20 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_11_20})` },
    { code: 52, name: `21-30 Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_21_30})` },
    { code: 53, name: `31+ Days Since Last Outbound (${this.foldersCount.day_since_last_outbound_31_plus})` },
];

      })
      } catch (error) {
      console.error("Error getting folder count:", error);
    } finally {
      this.isGettingFoldersCount = false;
    }
    },
    saveBulk() {
      let id = this.bulkSelectedDataSet.id;
      if (!id) {
        if (!this.bulkSelectedDataSet.name || !this.bulkSelectedDataSet.text) {
          this.$toasted.show("Please fill in the details", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        axios
          .get("https://123avatars.com/backend/newBulk.php", {
            params: {
              u: this.userId,
              dataText: this.bulkSelectedDataSet.text,
              dataName: this.bulkSelectedDataSet.name,
            },
          })
          .then((response) => {
            //new Data Created
            console.log(response);
            this.getBulk();
            this.bulkModal2 = false;
            this.$toasted.show(
              this.bulkSelectedDataSet.name + " Bulk Template Created Successfully",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
            this.bulkSelectedDataSet = { name: "", text: "" };
          });
        return false;
      }

    },
    getBulk() {
      axios
        .get("https://123avatars.com/backend/getBulk.php?u=" + this.userId)
        .then((response) => {
          console.log(response.data);
          this.bulkData = response.data;
          this.bulkTemplates = [];
          this.bulkData.forEach(bulk => {
            this.bulkTemplates.push({ code: bulk.id, name: bulk.name, text: bulk.text })
          })
        });
    },
    searchLeads() {
      //Prevent default if Search
      this.type = 'Search';
      console.log("Searching...");
      this.contacts = [];
      axios.get("https://123avatars.com/backend/searchLead.php?campaignID=" + this.$route.params.campId + "&avatarID=" + this.$route.params.id + "&search=" + this.search).then(response => {
        this.contacts = [];
        this.contacts = response.data;
        this.contactsLoading = false;
        console.log("SEARCHED LEAD-->");
        console.log(this.contacts);
      })

    },
     saveDataSet() {
      let id = this.SelectedDataSet.id;
      if (!id) {
        if (!this.SelectedDataSet.name || !this.SelectedDataSet.text) {
          this.$toasted.show("Please fill in the details", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        axios
          .get("https://123avatars.com/backend/newData.php", {
            params: {
              u: this.userId,
              dataText: this.SelectedDataSet.text,
              dataName: this.SelectedDataSet.name,
            },
          })
          .then((response) => {
            //new Data Created
            console.log(response.data.data_id);
            this.getConnectedData(response.data.data_id);
            this.dataModal = false;
            this.$toasted.show(
              this.SelectedDataSet.name + " Dataset Created Successfully",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
            this.SelectedDataSet = [];
          });
        return false;
      }
      axios
        .get("https://123avatars.com/backend/editData.php", {
          params: {
            id: this.SelectedDataSet.id,
            dataText: this.SelectedDataSet.text,
            dataName: this.SelectedDataSet.name,
          },
        })
        .then((response) => {
          //new Data Created
          console.log(response);
          this.getConnectedData();
          this.dataModal = false;
          this.$toasted.show(this.SelectedDataSet.name + " Dataset Updated", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 10000,
          });
          this.SelectedDataSet = [];
        });
    },
    saveWebsite() {
      if (this.newWebsite.length < 4) {
        this.$toasted.show("Please input a valid website sitemap", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      } else {
        axios
          .get(
            "https://123avatars.com/backend/addWebsite.php?name=" +
              this.newWebsite +
              "&u=" +
              this.userId
          )
          .then((response) => {
            console.log(response.data.data_id);
            this.webModal = false;
            this.newWebsite = "";
            this.getConnectedData(response.data.data_id);
            this.$toasted.show(
              "Website added Successfully please allow a few minutes for Scraping process.",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
          });
      }
    },
    uploadSuccess(f, s) {
       let obj = JSON.parse(s);
      console.log(obj.file_id);
      this.getConnectedData(obj.file_id);
      this.fileModal = false;
    },
   async extractUrl(click) {
      const urlRegex = /https?:\/\/[^\s]+/g;

      for (let i = 0; i < this.chat.length; i++) {
        const text = this.chat[i].text;
        const urls = text.match(urlRegex);
        if (urls) {
          if(click==1){
            this.text = this.text + urls[0];
          }
          this.extractedUrl= urls[0];
          return urls[0]; // Return the first URL found in the chat
        }
      }

      if(click==1){
        try {
      const response = await axios.get("https://123avatars.com/backend/getCampaignDomains.php?id=" + this.$route.params.campId);
      console.log(response.data);
      let domain = response.data[0];
      let url = this.createShortLink(domain, this.campaign, this.currentContact);
      console.log(url);
     
            this.text = this.text + url;
          
          this.extractedUrl= url;
      return url;
    } catch (error) {
      console.error('Error fetching campaign domains:', error);
      return null; // Return null in case of an error
    }
      }
      
      return null; // Return null if no URL is found in any of the chat messages
    },
  async createShortLink(domain, sms, lead) {
      const postData = {
        domain: domain.domain, // Assuming randomDomain is a variable you have already set
        target: sms.target, // Replace with the appropriate SMS object property
        campaign_id: sms.campaign_id, // Replace with the appropriate SMS object property
        lead: {
          id: lead.id,
          firstName: lead.Owners_Name,
          lastName: "", // If you have a last name in your SMS object, replace the empty string
          email: lead.Company_Email,
          phone: lead.Company_Phone,
          business: lead.Company_Name,
          website: lead.Website,
        },
      };

      try {
        const response = await axios({
          method: "post",
          url: "https://12-3.fun/create",
          data: postData,
          headers: { "Content-Type": "application/json" },
        });

        // console.log(response.data); // The response from the server
        return "https://" + domain.domain + "/" + response.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
  filters: {
    formatPhoneNumber(value) {
      if (!value) return "";

      value = value.replace(/\D/g, "");

      if (value.length === 10) {
        return `(${value.substring(0, 3)}) ${value.substring(
          3,
          6
        )}-${value.substring(6)}`;
      }

      return value;
    },
  },
  computed: {
    formattedPhoneNumber() {
      return this.originalNumber ? this.originalNumber | formatPhoneNumber : "";
    },
  },
};
</script>
