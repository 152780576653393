<template>
  <div>
    <Breadcrumbs main="Project" title="API Integrations" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="target"></feather> All
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div
                                class="col-xl-3 col-lg-3"
                                v-for="integration in integrations"
                                :key="integration.id"
                                :class="integration.id < 3 ? 'mb-4' : ''"
                              >
                                <div class="col-12">
                                  <div class="project-box">
                                    <span
                                      v-if="integration.status == 1"
                                      class="badge badge-success"
                                    >Connected</span>
                                    <span
                                      v-if="integration.status != 1"
                                      class="badge badge-danger"
                                    >inActive</span>
                                    <a href="#">
                                      <div class="media">
                                        <img
                                          class="me-1"
                                          :src="getImgUrl(
                                            'api' + integration.type + '.png'
                                          )"
                                          alt=""
                                          data-original-title=""
                                          title=""
                                          style="
                                            background-color: black;
                                            padding: 20px;
                                            border-radius: 5px;
                                            max-width: 200px;
                                          "
                                        />
                                      </div>
                                      <h6>{{ integration.name }}</h6>
                                    </a>

                                    {{ integration.username }}

                                    <div v-if="integration.currentGoal" class="mt-2">
                                      <strong>Current Goal:</strong> {{ integration.currentGoal.name }}
                                    </div>

                                    <div class="d-flex justify-content-between mt-2">
                                      <button
                                        class="btn btn-primary"
                                        @click="toggleEditGoal(integration.id)"
                                      >
                                        Edit Goal
                                      </button>

                                      <button
                                        class="btn btn-danger"
                                        @click="deleteApi(integration.id)"
                                      >
                                        Delete Connection
                                      </button>
                                    </div>

                                    <div v-if="integration.showGoals" class="mt-2">
                                      <multiselect
                                        v-model="integration.currentGoal"
                                        :options="integration.goals"
                                        label="name"
                                        placeholder="Select Goal"
                                        @input="onGoalChange($event, integration)"
                                      ></multiselect>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="check-circle"></feather> Active
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div
                                class="col-xl-4 col-lg-6"
                                v-for="(project, index) in doingprojects"
                                :key="index"
                                :class="index < 3 ? 'mb-4' : ''"
                              >
                                <div class="project-box">
                                  <span
                                    class="badge"
                                    :class="'badge-' + project.type"
                                  >{{ project.badge }}</span>
                                  <h6>{{ project.title }}</h6>
                                  <div class="media">
                                    <img
                                      class="img-20 mr-1 rounded-circle"
                                      :src="getImgUrl(project.img)"
                                      alt=""
                                      title=""
                                    />
                                    <div class="media-body">
                                      <p>{{ project.sites }}</p>
                                    </div>
                                  </div>
                                  <p>{{ project.desc }}</p>
                                  <div class="row details">
                                    <div class="col-6">
                                      <span>Issues </span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.issue }}
                                    </div>
                                    <div class="col-6">
                                      <span>Resolved</span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.resolved }}
                                    </div>
                                    <div class="col-6">
                                      <span>Comment</span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.comment }}
                                    </div>
                                  </div>
                                  <div class="customers">
                                    <ul>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img1
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img2
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img3
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block ml-2">
                                        <p class="f-12">+10 More</p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="project-status mt-4">
                                    <div class="media mb-0">
                                      <p>{{ project.progress }}%</p>
                                      <div class="media-body text-right">
                                        <span>Done</span>
                                      </div>
                                    </div>
                                    <div class="progress" style="height: 5px">
                                      <div
                                        class="progress-bar-animated progress-bar-striped"
                                        :class="'bg-' + project.type"
                                        role="progressbar"
                                        :style="{
                                          width: project.progress + '%',
                                        }"
                                        aria-valuenow="10"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="trash"></feather> Deleted
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div
                                class="col-xl-4 col-lg-6"
                                v-for="(project, index) in doneprojects"
                                :key="index"
                                :class="index < 3 ? 'mb-4' : ''"
                              >
                                <div class="project-box">
                                  <span
                                    class="badge"
                                    :class="'badge-' + project.type"
                                  >{{ project.badge }}</span>
                                  <h6>{{ project.title }}</h6>
                                  <div class="media">
                                    <img
                                      class="img-20 mr-1 rounded-circle"
                                      :src="getImgUrl(project.img)"
                                      alt=""
                                      title=""
                                    />
                                    <div class="media-body">
                                      <p>{{ project.sites }}</p>
                                    </div>
                                  </div>
                                  <p>{{ project.desc }}</p>
                                  <div class="row details">
                                    <div class="col-6">
                                      <span>Issues </span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.issue }}
                                    </div>
                                    <div class="col-6">
                                      <span>Resolved</span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.resolved }}
                                    </div>
                                    <div class="col-6">
                                      <span>Comment</span>
                                    </div>
                                    <div
                                      class="col-6 text-primary"
                                      :class="'text-' + project.type"
                                    >
                                      {{ project.comment }}
                                    </div>
                                  </div>
                                  <div class="customers">
                                    <ul>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img1
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img2
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block">
                                        <img
                                          class="img-30 rounded-circle"
                                          :src="getImgUrl(
                                            project.customers_img3
                                          )"
                                          alt=""
                                          title=""
                                        />
                                      </li>
                                      <li class="d-inline-block ml-2">
                                        <p class="f-12">+10 More</p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="project-status mt-4">
                                    <div class="media mb-0">
                                      <p>{{ project.progress }}%</p>
                                      <div class="media-body text-right">
                                        <span>Done</span>
                                      </div>
                                    </div>
                                    <div class="progress" style="height: 5px">
                                      <div
                                        class="progress-bar-animated progress-bar-striped"
                                        :class="'bg-' + project.type"
                                        role="progressbar"
                                        :style="{
                                          width: project.progress + '%',
                                        }"
                                        aria-valuenow="10"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="col-sm-6 create-project-btn">
                <div class="text-right">
                  <div class="form-group mb-0 mr-0"></div>
                  <!-- <a class="btn btn-primary" href="">
                    <i data-feather="plus-square"> </i>Create New Project</a
                  > -->
                  <a
                    class="btn btn-primary"
                    href="#"
                    v-b-modal.modal-lg
                    variant="primary"
                    id="popup-modal"
                  >
                    <i data-feather="plus-square"> </i>New Integration</a
                  >

                  <b-modal
                    id="modal-lg"
                    size="lg"
                    title="Start New Campaign"
                    class="theme-modal"
                    hide-header
                    hide-footer
                    v-model="popUp"
                  >
                    <div
                      class="image-container"
                      style="
                        max-height: 200px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 20px 20px 20px 20px;
                      "
                    >
                      <img
                        src="../../assets/images/integrations.gif"
                        style="width: 100%"
                      />
                    </div>
                    <h5 class="mt-4">Let's Connect to <b>API</b> Account</h5>
                    <multiselect
                      v-model="integrationType"
                      :options="options"
                      label="name"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      placeholder="Select Integration"
                    ></multiselect>
                    <!-- Stripe Integration -->
                    <div
                      v-if="integrationType.code == 1"
                      class="text-center mt-2"
                    >
                      <p>
                        Connect Your Stripe Account With API KEY Then
                        <b>123Avatars</b> will register a Stripe webhook to
                        subscribe to new subscription and check if attached to any
                        campaigns.
                      </p>
                      <label>Stripe API Key: </label>
                      <input
                        type="text"
                        placeholder="euJra...."
                        class="form-control"
                        v-model="stripeAPIKey"
                      />
                      <button
                        class="btn btn-primary"
                        @click="fetchStripe"
                        style="width: 80%"
                      >
                        Fetch
                      </button>
                      <div v-if="stripeName?.length > 0" class="mt-4">
                        <!-- <img
                          :src="stripeAvatarURL"
                          style="border-radius: 100%; max-width: 50px"
                        /> -->
                        <br />
                        <div id="stripe-name">
                          {{ stripeName }}
                        </div>
                        <br />
                        <button
                          class="btn btn-primary"
                          @click="connectStripe"
                          style="width: 80%"
                        >
                          Add Integration
                        </button>
                      </div>
                    </div>
                    <!-- Calendly Integration -->
                    <div
                      v-if="integrationType.code == 2"
                      class="text-center mt-2"
                    >
                      <p>
                        Connect Your Calendly Account With API KEY Then
                        <b>123Avatars</b> will register a Calendly webhook to
                        subscribe to new bookings and check if attached to any
                        campaigns.
                      </p>
                      <label>Calendly API Key: </label>
                      <input
                        type="text"
                        placeholder="euJra...."
                        class="form-control"
                        v-model="calendlyAPIKey"
                      />
                      <button
                        class="btn btn-primary"
                        @click="fetchCalendly"
                        style="width: 80%"
                      >
                        Fetch
                      </button>
                      <div v-if="calendlyName.length > 0" class="mt-4">
                        <img
                          :src="calendlyAvatarURL"
                          style="border-radius: 100%; max-width: 50px"
                        />
                        <br />
                        <div id="calendly-name">
                          {{ calendlyName }}
                        </div>
                        <br />
                        <div id="calendly-organization">
                          {{ calendlyOrganization }}
                        </div>
                        {{ calendlyEmail }}

                        <button
                          class="btn btn-primary"
                          @click="addCalendly"
                          style="width: 80%"
                        >
                          Add Integration
                        </button>
                      </div>
                    </div>
                    <!-- Chargbee Integration -->
                    <div
                      v-if="integrationType.code == 3"
                      class="text-center mt-2"
                    >
                      <p>
                        Connect Your ChargeBee Account With API KEY to
                        <b>123Avatars</b> & make sure to register Charge webhook
                        to subscribe to new subscriptions and count as goals.
                      </p>
                      <label>Webhook URL: </label><br />
                      <a
                        href="javascript:void(0)"
                        class="btn btn-secondary"
                        v-b-tooltip.hover
                        title="Click to copy"
                        @click="
                          copyText('https://123avatars.com/chargebee.php')
                        "
                      >https://123avatars.com/chargebee.php</a
                      >
                      <br />
                      <br />
                      <div class="container">
                        <div class="row">
                          <div class="col-6">
                            <label>ChargeBee {Site}: </label>
                            <input
                              type="text"
                              placeholder="123websites"
                              class="form-control"
                              v-model="chargebeeSite"
                              :disabled="chargebeeOptions.length > 0"
                            />
                          </div>
                          <div class="col-6">
                            <label>ChargeBee API Key: </label>
                            <input
                              type="text"
                              placeholder="live_QnS..."
                              class="form-control"
                              v-model="chargebeeApi"
                              :disabled="chargebeeOptions.length > 0"
                            />
                          </div>
                        </div>
                        <button
                          class="btn btn-primary mt-5"
                          @click="testChargebee"
                          v-if="chargebeeOptions.length == 0"
                        >
                          Test Connection
                        </button>
                      </div>
                      <div v-if="chargebeeError.length > 0">
                        <label class="badge badge-danger"
                        >{{ chargebeeError }}
                        <feather type="check-circle"></feather
                        ></label>
                      </div>
                      <div v-if="chargebeeOptions.length > 0" class="mt-4">
                        <label class="badge badge-success"
                        >Connection Success
                        <feather type="check-circle"></feather
                        ></label>
                        <multiselect
                          :options="chargebeeOptions"
                          label="name"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          placeholder="Available Packages & Items"
                        ></multiselect>
                        <br />
                        <button
                          class="btn btn-primary"
                          @click="addChargebee()"
                          style="width: 80%"
                        >
                          Save ChargeBee Integration to my account
                        </button>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      integrations: [],
      integrationType: "",
      options: [
        { code: 2, name: "Calendly" },
        { code: 3, name: "ChargeBee" },
        { code: 1, name: "Stripe" },
      ],
      calendlyAPIKey: "",
      calendlyContainer: "",
      calendlyName: "",
      calendlyAvatarURL: "",
      calendlyOrganization: "",
      calendlyEmail: "",
      calendlyWebhookURI: "",
      chargebeeSite: "",
      chargebeeApi: "",
      chargebeeOptions: [],
      popUp: false,
      chargebeeError: "",
      stripeAPIKey: "",
      stripeName: ""
    };
  },
  computed: {
    ...mapState({
      allprojects: (state) => state.common.allprojects,
      doingprojects: (state) => state.common.doingprojects,
      doneprojects: (state) => state.common.doneprojects,
    }),
  },
  methods: {
    onGoalChange(newGoal, integration) {
      console.log("here");
      // Your function logic here
      console.log('Goal changed for integration:', integration, 'New Goal:', newGoal);
      let uriTokens = newGoal.uri.split("/");
      let eventId = uriTokens[uriTokens.length-1];
      axios.get("https://123avatars.com/backend/addGoal.php?user_id=" + this.userId + "&event_id=" + eventId + "&name=" + goalName + "&type=" + type)
        .then((resp) => {
          console.log("update goal successful.");
        }).catch((err) => {
          console.error("Error adding new goal.", err);
        })
      // Update the integration's currentGoal if needed
      // integration.currentGoal = newGoal;
    },
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    fetchStripe() {
      const options = {
        method: "GET",
        url: "https://api.stripe.com/v1/products",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.stripeAPIKey,
        },
      };

      axios
        .request(options)
        .then((response) => {
          console.log('stripeResponse: ', response.data);
          this.stripeName = response?.data?.data[0]?.name;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    connectStripe() {
      let username = localStorage.getItem('username');
      axios
        .get(
          "https://123avatars.com/backend/connectStripe.php?name=" +
            this.stripeName +
            "&username=" +
            username +
            "&password=&u=" +
            this.userId +
            "&type=1&api_key=" +
            this.stripeAPIKey
        )
        .then((response) => {
          console.log(response);
          this.popUp = false;
          this.$toasted.show(
            this.calendlyName + " Calendly API Linked Successfully ",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 5000,
            }
          );
          this.getData();
        });
    },
    fetchCalendly() {
      let apiKey = this.calendlyAPIKey;
      if (apiKey.length < 5) {
        return false;
      }

      const options = {
        method: "GET",
        url: "https://api.calendly.com/users/me",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey,
        },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          this.calendlyAvatarURL = response.data.resource.avatar_url;
          this.calendlyName = response.data.resource.name;
          this.calendlyOrganization =
            response.data.resource.current_organization;
          this.calendlyEmail = response.data.resource.email;
          this.calendlyUserURI = response.data.resource.uri;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    addCalendly() {
      console.log('uri: ', this.calendlyUserURI);
      let username = localStorage.getItem('username');
      axios
        .get(
          "https://123avatars.com/backend/connectCalendly.php?name=" +
            this.calendlyName +
            "&username=" +
            username +
            "&password=&u=" +
            this.userId +
            "&type=2&api_key=" +
            this.calendlyAPIKey +
            "&organization_uri=" +
            this.calendlyOrganization +
            "&user_uri=" +
            this.calendlyUserURI +
            "&scope=organization"

        )
        .then((response) => {
          console.log(response);
          this.popUp = false;
          this.$toasted.show(
            this.calendlyName + " Calendly API Linked Successfully ",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 5000,
            }
          );
          this.getData();
        });
    },
    getData() {
      axios
        .get("https://123avatars.com/backend/integrations.php?u=" + this.userId)
        .then((response) => {
          this.integrations = response.data.integrations.map((integration) => ({
            ...integration,
            showGoals: false,
            goals: this.getAllGoalsForIntegration(integration),
            selectedGoal: this.getCurrentGoalsForIntegration(integration),
            currentGoal: { name: "Current Goal" } // Placeholder for the current goal
          }));
          console.log(this.integrations);

        });
    },
    copyText(text) {
      var aux = document.createElement("input");
      aux.setAttribute("value", text);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      alert("copied");
    },
    testChargebee() {
      console.log("TestingChargebee");
      if (
        this.chargebeeApi == "live_QnSSo0Kcqgkk0m63DJ5BPsHcujyXNNgND" &&
        this.chargebeeSite == "123websites"
      ) {
        this.chargebeeError = "";
        this.chargebeeOptions = [
          { code: 1, name: "Premium Account Setup---------" },
          { code: 2, name: "Custom Website w Unlimited Changes" },
          { code: 3, name: "Premium Account Setup........." },
          { code: 4, name: "123ChatPGT" },
          { code: 5, name: "Premium Account Setup........" },
          { code: 6, name: "Blogging" },
          { code: 7, name: "Website & Remarketing Setup Fee $398" },
          { code: 8, name: "Website & Remarketing Setup Fee $199" },
        ];
      } else {
        this.chargebeeError =
          "Connection Error, Please double check your settings..";
      }
    },
    addChargebee() {
      axios
        .get(
          "https://123avatars.com/backend/createAPI.php?name=" +
            this.chargebeeSite +
            "&username=" +
            this.chargebeeApi +
            "&password=&u=" +
            this.userId
        )
        .then((response) => {
          console.log(response);
          this.popUp = false;
          this.$toasted.show(
            this.chargebeeSite + " Chargebee API Linked Successfully ",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 5000,
            }
          );
          this.getData();
        });
    },
    deleteApi(id) {
      axios
        .get(
          "https://123avatars.com/backend/deleteApi.php?id=" +
            id +
            "&u=" +
            this.userId
        )
        .then((response) => {
          this.$toasted.show("Api Account Deleted Successfully", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 5000,
          });
          this.getData();
        });
    },
    toggleEditGoal(id) {
      const integration = this.integrations.find(
        (integration) => integration.id === id
      );
      if (integration) {
        integration.showGoals = !integration.showGoals;
      }
    },
    getCurrentGoalsForIntegration(integration) {
      // Fetch goals for the integration, you can replace this with actual API call.
      // This is just a sample data.
      axios.get("https://123avatars.com/backend/getGoals.php?user_id=" + this.userId + "&type=" + integration.type)
        .then((resp) => {
          this.integrations.find((int) => int.id === integration.id).currentGoal = resp.data[0];
          this.$forceUpdate();
          console.log("integrations: ", this.integrations);
        }).catch((err) => {
          console.error("Error fetching goals for integration: ", err);
        })
    },
    getAllGoalsForIntegration(integration) {
      // Fetch goals for the integration, you can replace this with actual API call.
      // This is just a sample data.
      axios.get("https://123avatars.com/backend/getAllGoals.php?u=" + this.userId + "&integration_type=" + integration.type)
        .then((resp) => {
          this.integrations.find((int) => int.id === integration.id).goals = resp.data.collection;
          this.$forceUpdate();
          console.log("integrations: ", this.integrations);
        }).catch((err) => {
          console.error("Error fetching goals for integration: ", err);
        })
    },
  },
  mounted() {
    this.getData();
  },
};
</script>