<template>
  <svg :width="size" :fill="fill" viewBox="0 0 28 28" :height="size" class="quotaSVG">
    <path d="m12,15l-11,0c-0.55,0 -1,0.45 -1,1c0,6.62 5.38,12 12,12c0.55,0 1,-0.45 1,-1l0,-11c0,-0.55 -0.45,-1 -1,-1z" />
    <path d="m27,15l-11,0c-0.55,0 -1,0.45 -1,1l0,11c0,0.55 0.45,1 1,1c6.62,0 12,-5.38 12,-12c0,-0.55 -0.45,-1 -1,-1z" />
    <path d="m12,0c-6.62,0 -12,5.38 -12,12c0,0.55 0.45,1 1,1l11,0c0.55,0 1,-0.45 1,-1l0,-11c0,-0.55 -0.45,-1 -1,-1z" />
    <path d="m16,13l11,0c0.55,0 1,-0.45 1,-1c0,-6.62 -5.38,-12 -12,-12c-0.55,0 -1,0.45 -1,1l0,11c0,0.55 0.45,1 1,1z" />
  </svg>
</template>

<script>
export default {
   name: 'quotaSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>