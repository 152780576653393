<template>
  <div id="callOperatorLeaderboard">
    <UIBox
      classbox="hello"
      v-for="(timePeriod, title) in leaderboardData"
      :key="title"
    >
      <template v-slot:title>
        <span>Top 3 Reps for <b class="bold">{{ title }}</b></span>
      </template>
      <template>
        <div class="table-responsive" id="operatorLeaderboardContainer">
          <div class="date-group">
            <div v-if="loading" class="w-full h-[250px] flex items-center justify-center font-bold text-xl text-center">
              Fetching data...
            </div>
            <div v-else-if="timePeriod.length === 0" class="w-full h-[250px] flex items-center justify-center font-bold text-xl text-center">
              No data available.
            </div>
            <div v-else class="table-fullwidth">
              <table class="table call-backs-table" aria-controls="callbackstable">
                <thead>
                  <tr>
                    <th style="width: 4%;">Rank</th>
                    <th style="width: 15%;">Operator Name</th>
                    <th style="width: 8%;">To's Completed</th>
                    <th style="width: 8%;">Calls Per TO</th>
                    <th style="width: 8%;">Calls Made</th>
                    <th style="width: 8%;">Email Sent</th>
                    <th style="width: 8%;">Text Sent</th>
                    <th style="width: 10%;">Talk Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, dataIndex) in timePeriod" :key="dataIndex">
                    <td>{{ data.rank }}</td>
                    <td>{{ data.fullname }}</td>
                    <td>---</td>
                    <td>---</td>
                    <td>{{ data.salesRepCount }}</td>
                    <td>---</td>
                    <td>---</td>
                    <td>{{ data.totalTalkTime }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </UIBox>
  </div>
</template>

<script>
import UIBox from './uibox.vue';
import axios from 'axios';

export default {
  components: {
    UIBox,
  },
  data() {
   return {
    loading: false,
    leaderboardData: [
      {
        "All-Time": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime":"" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "Today": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime":"" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "Yesterday": [
          {
             "rank": 1,
              "fullname": "",
              "totalTalkTime":"" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "This Week": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime": "" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "Last Week": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime":"" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "This Month": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime": "" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
        "Last Month": [
          {
              "rank": 1,
              "fullname": "",
              "totalTalkTime":"" ,
              "startTime": "",
              "salesRepCount": ""
          },
        ],
      }
      ],
        
    };
  },
  mounted() {
    console.log('Mounted'); // Add this line
    this.fetchLeaderboardData();
  },
  methods: {
    fetchLeaderboardData() {
      console.log('Fetching data'); // Add this line
      this.loading = true
      axios.get('https://123avatars.com/v2/top-operators/')
        .then(response => {
          console.log('successful data'); // Add this line
          this.leaderboardData = response.data;
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
#operatorLeaderboardContainer .table {
  width: 100%;
  table-layout: fixed;
}

#operatorLeaderboardContainer th,
#operatorLeaderboardContainer td {
  text-align: left;
}

#operatorLeaderboardContainer h3 {
  background-color: #B6E7C3;
  padding: 8px;
  margin-top: 16px;
}
</style>
