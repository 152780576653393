<template>
  <div class="card-body default-according style-1">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="div" class="bg-secondary">
        <h5 class="mb-0">
          <b-button v-b-toggle.primary_icon_open_close_1>
            <feather type="bar-chart-2"></feather> Campaign Stats
          </b-button>
        </h5>
      </b-card-header>
      <b-collapse
        id="primary_icon_open_close_1"
        accordion="my-accordion-icon-open-close"
        role="tabpanel"
      >
        <b-card-body>
          <b-card-text>
            <div class="row">
              <div class="col-6">
                <multiselect
                  v-model="dateFormat"
                  placeholder="Select TimeRange"
                  label="name"
                  track-by="code"
                  :options="dateOptions"
                  :multiple="false"
                ></multiselect>
                <br />
                <div class="mb-4" style="display: flex">
                  <div class="mx-2">
                    <label for="from">From:</label>
                    <datepicker
                      input-class="datepicker-here form-control digits"
                      :format="format"
                      v-model="fromDate"
                    ></datepicker>
                  </div>
                  <div class="mx-2">
                    <label for="from">To:</label>
                    <datepicker
                      input-class="datepicker-here form-control digits"
                      :format="format"
                      v-model="toDate"
                    ></datepicker>
                  </div>
                  <div>
                    <div
                      class="mx-2 loader-box"
                      style="
                        height: auto;
                        justify-content: start;
                        margin-top: 25px;
                      "
                      v-show="loading"
                    >
                      <div
                        class="loader-8 mr-2"
                        style="width: 30px; height: 30px"
                      ></div>
                      Updating..
                    </div>
                    <div class="media">
                      <label class="col-form-label m-r-10"
                        >All Stats</label
                      >
                      <div class="media-body text-right icon-state">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked=""
                            v-model="allStats"
                            @click="toggleStats('ALL')"
                          /><span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
                    <div class="media">
                      <label class="col-form-label m-r-10"
                        >Warm Stats</label
                      >
                      <div class="media-body text-right icon-state">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked=""
                            v-model="warmStats"
                            @click="toggleStats('WARM')"
                          /><span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
                    <div class="media">
                      <label class="col-form-label m-r-10"
                        >Cold Stats</label
                      >
                      <div class="media-body text-right icon-state">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked=""
                            v-model="coldStats"
                            @click="toggleStats('COLD')"
                          /><span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <p>{{ lastSync }}</p> -->
                </div>
                <div class="table-responsive mb-0">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="2"
                    class="table b-table"
                    id="__BVID__297"
                  >
                    <tbody role="rowgroup">
                      <!---->
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          New Conversations
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Processed')"
                        >
                          {{ statsData?.conversation_counts?.count_new_conversations || 0 | comma }}
                        </td>
                      </tr>
                      <!-- <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Cancelled
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Outbound Cancelled')"
                        >
                          {{ statsData?.total_outbound_cancelled }}
                        </td>
                      </tr>
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Valid
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Valid')"
                        >
                          {{ parseInt(statsData?.total.total_rows) - parseInt(statsData?.total_outbound_cancelled) }}
                        </td>
                      </tr> -->
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Outbound Sent ( cold, responses, wakeups )
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Outbound')"
                        >
                          {{ statsData?.total_outbound_success || 0 | comma }}
                        </td>
                      </tr>
                      <br>
                      <!-- <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Cold Outbound Sent
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Cold Messages Sent')"
                        >
                          {{ statsData?.total_cold_outbound }}
                        </td>
                      </tr>
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Inbound Replies
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Replies')"
                        >
                          {{
                            statsData?.total_replies
                          }}
                        </td>
                      </tr> -->
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">SMS</td>
                        <td aria-colindex="2" role="cell" class="">
                          ${{ totalSMSCost || 0 | comma }}
                        </td>
                      </tr>
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">Phone</td>
                        <td aria-colindex="2" role="cell" class="">
                          ${{ totalCallCost || 0 | comma }}
                        </td>
                      </tr>
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">Tokens</td>
                        <td aria-colindex="2" role="cell" class="">
                          ${{ totalAICost || 0 | comma }}
                        </td>
                      </tr>
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">
                          Total Cost
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          ${{ totalCost || 0 | comma }}
                        </td>
                      </tr>
                      <br>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all clicks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Landing Page Conversions (Sales)
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          {{
                            (totalClicks != 0) ? 
                            (parseFloat(
                              (totalSales / parseFloat(totalClicks)) * 100
                            ).toFixed(2)) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all clicks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Landing Page Conversions (Bookings)
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          {{
                            (totalClicks != 0) ? 
                            (parseFloat(
                              (totalBookings / parseFloat(totalClicks)) * 100
                            ).toFixed(2)) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-warning">
                        <td aria-colindex="1" role="cell" class="">Messages Per Goal</td>
                        <td aria-colindex="2" role="cell" class="">
                          {{ (totalGoals != 0) ? parseFloat(parseFloat(statsData?.total_outbound_success) / totalGoals).toFixed(2) : 0 | comma}}
                        </td>
                      </tr>
                      <tr role="row" class="table-warning">
                        <td aria-colindex="1" role="cell" class="">CPA</td>
                        <td aria-colindex="2" role="cell" class="">
                          ${{ totalCPA }}
                        </td>
                      </tr>
                      <!----><!---->
                    </tbody>
                    <!---->
                  </table>
                </div>
              </div>
              <div class="col-6">
                <div class="table-responsive mb-0">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="2"
                    class="table b-table"
                    id="__BVID__297"
                  >
                    <tbody role="rowgroup">
                      <!---->
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Sent So Far Today
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Sent So Far Today')"
                          v-b-tooltip.hover
                          :title="
                            'Pending : ' +
                            statsData?.total_inqueue +
                            ' - Sent : ' +
                            statsData?.total_inqueue_sent
                          "
                        >
                          {{ statsData?.total_outbound_sent || 0 | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.conversation_counts?.count_new_conversations) ?
                            parseFloat(
                              ((parseFloat(statsData?.total_outbound_sent)) /
                                parseFloat(statsData?.conversation_counts?.count_new_conversations)) *
                                100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <!-- <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Pending / Sent Pending Confirmation
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Sent Pending Confirmation')"
                          v-b-tooltip.hover
                          :title="
                            'Pending : ' +
                            statsData?.total_inqueue +
                            ' - Sent : ' +
                            statsData?.total_inqueue_sent
                          "
                        >
                          {{
                            parseFloat(statsData?.total_inqueue) +
                            parseFloat(statsData?.total_inqueue_sent)
                          }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            parseFloat(
                              ((parseFloat(statsData?.total_inqueue) +
                                parseFloat(statsData?.total_inqueue_sent)) /
                                totalValid) *
                                100
                            ).toFixed(2)
                          }}%
                        </td>
                      </tr> -->
                      <!-- <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">
                          Cancelled
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          {{ statsData?.total_outbound_cancelled }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            parseFloat(
                              (statsData?.total_outbound_cancelled /
                                totalColdMessages) *
                                100
                            ).toFixed(2)
                          }}%
                        </td>
                      </tr> -->
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">
                          Failed / Spammed
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Failed/Spammed')"
                          v-b-tooltip.hover
                          :title="
                            'Failed : ' +
                            statsData?.total_outbound_failed +
                            ' - Spammed : ' +
                            statsData?.total_outbound_spam
                          "
                        >
                          {{ totalSpam | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            parseFloat(
                              (totalSpam / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2)
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-success">
                        <td aria-colindex="1" role="cell" class="">
                          Delivered
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Delivered')"
                        >
                          {{ totalDelivered | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (totalDelivered / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-success">
                        <td aria-colindex="1" role="cell" class="">
                          Not Sent Yet
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                        >
                          {{
                            (statsType == 'WARM') ? 0 : (statsData?.total_outbound_sent) ?
                            parseFloat(statsData?.conversation_counts?.count_new_conversations) -
                            parseFloat(statsData?.total_outbound_sent) : 0 | comma
                          }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.conversation_counts?.count_new_conversations) ?
                            parseFloat(
                              ((parseFloat(statsData?.conversation_counts?.count_new_conversations) -
                            parseFloat(statsData?.total_outbound_sent)) /
                                parseFloat(statsData?.conversation_counts?.count_new_conversations)) *
                                100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <br>
                      <tr role="row" class="table-success">
                        <td aria-colindex="1" role="cell" class="">
                          Positive Responses
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Positive Responses')"
                        >
                          {{ totalPositive | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (totalPositive / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">
                          Negative Responses
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Negative Responses')"
                        >
                          {{ totalNegative | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (totalNegative / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-danger">
                        <td aria-colindex="1" role="cell" class="">
                          Opt-Outs (Stops)
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Opt-Outs (Stops)')"
                        >
                          {{ statsData?.total_stops || 0 | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (statsData?.total_stops / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Responses
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Responses')"
                        >
                          {{ totalResponses | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (totalResponses / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr role="row" class="table-primary">
                        <td aria-colindex="1" role="cell" class="">
                          Total Non Response
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Total Non Response')"
                        >
                          {{ totalNonResponsive || 0 | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_sent) ?
                            parseFloat(
                              (totalNonResponsive / parseFloat(statsData?.total_outbound_sent)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <br>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all clicks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Clicks Acheived
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Clicks Acheived')"
                        >
                          {{ totalClicks | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_outbound_success) ?
                            parseFloat(
                              (totalClicks / parseFloat(statsData?.total_outbound_success)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all goals"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Sales Acheived
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Sales Acheived')"
                        >
                          {{ totalSales | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (totalSales) ?
                            parseFloat(
                              (totalSales / parseFloat(statsData?.total_outbound_success)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all goals"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Bookings Achieved
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Goals Acheived')"
                        >
                          {{ totalBookings | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (totalBookings) ?
                            parseFloat(
                              (totalBookings / parseFloat(statsData?.total_outbound_success)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <br>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all calls"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Calls
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Calls')"
                        >
                          {{ statsData?.total_calls | comma }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            100
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all voicemails"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Voicemails
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Voicemails')"
                        >
                          {{ statsData?.total_voicemail | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_calls) ?
                            parseFloat(
                              (statsData?.total_voicemail / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all inbounds"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Inbounds
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Inbounds')"
                        >
                          {{ statsData?.total_inbound | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_inbound) ?
                            parseFloat(
                              (statsData?.total_inbound / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all voicemails"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Caller ID Callbacks
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Caller ID Callbacks')"
                        >
                          {{ statsData?.caller_id_inbounds | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.caller_id_inbounds) ?
                            parseFloat(
                              (statsData?.caller_id_inbounds / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all voicemail callbacks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Voicemail Callbacks
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Voicemail Callbacks')"
                        >
                          {{ statsData?.voicemail_inbounds | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.voicemail_inbounds) ?
                            parseFloat(
                              (statsData?.voicemail_inbounds / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all scheduled callbacks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Scheduled Callbacks
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Scheduled Callbacks')"
                        >
                          {{ statsData?.scheduled_callbacks | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.scheduled_callbacks) ?
                            parseFloat(
                              (statsData?.scheduled_callbacks / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all completed callbacks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Completed Callbacks
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Completed Callbacks')"
                        >
                          {{ statsData?.completed_callbacks | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.completed_callbacks) ?
                            parseFloat(
                              (statsData?.completed_callbacks / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all completed callbacks"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Transfer to Human
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Transfer to Human')"
                        >
                          {{ statsData?.transfer_to_human | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.transfer_to_human) ?
                            parseFloat(
                              (statsData?.transfer_to_human / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all phone call links."
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Phone Call Links
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Phone Call Links')"
                        >
                          {{ statsData?.phone_call_links | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.phone_call_links) ?
                            parseFloat(
                              (statsData?.phone_call_links / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all 2 min calls"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Calls over 2 mins
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Calls over 2 mins')"
                        >
                          {{ statsData?.over_two | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_calls) ?
                            parseFloat(
                              (statsData?.over_two / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                      <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all 5 min calls"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Calls over 5 mins
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Calls over 5 mins')"
                        >
                          {{ statsData?.over_five | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_calls) ?
                            parseFloat(
                              (statsData?.over_five / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>
                                            <tr
                        role="row"
                        class="table-warning"
                        v-b-tooltip.hover
                        title="Click for all 10 min calls"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          Calls over 10 mins
                        </td>
                        <td
                          aria-colindex="2"
                          role="cell"
                          class=""
                          @click="showTable('Calls over 10 mins')"
                        >
                          {{ statsData?.over_ten | comma || 0 }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            (statsData?.total_calls) ?
                            parseFloat(
                              (statsData?.over_ten / parseFloat(statsData?.total_calls)) * 100
                            ).toFixed(2) : 0
                          }}%
                        </td>
                      </tr>

                      <!----><!---->
                    </tbody>
                    <!---->
                  </table>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>

      <b-modal
        id="modal-lg22"
        size="lg"
        title="Start New Campaign"
        class="theme-modal"
        hide-footer
        hide-header
        v-model="tableModal"
      >
        <h1>{{ tableTitle }}</h1>
        <b-table
          striped
          hover
          responsive
          table-class="w-auto"
          :items="table"
        ></b-table>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import moment from "moment"; // Import Moment.js at the beginning of your component file

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Vue from "vue";
import VueCommaFilter from "vue-comma-filter";

Vue.use(VueCommaFilter);

export default {
  components: { ckeditor: CKEditor.component, Multiselect, Datepicker },

  data() {
    return {
      userId: localStorage.getItem("userId"),
      statsData: [],
      format: "MM/dd/yyyy",
      fromDate: moment().subtract(0, "days").format("MM/DD/YYYY"),
      toDate: moment().add(0, "days").format("MM/DD/YYYY"),
      dateFormat: [{ code: 1, name: "Today" }],
      dateOptions: [
        { code: 1, name: "Today" },
        { code: 76, name: "Yesterday" },
        { code: 7, name: "Last 7 Days" },
        { code: 30, name: "Last 30 Days" },
        { code: 77, name: "This Week" },
        { code: 78, name: "Last Week" },
        { code: 88, name: "This Month" },
        { code: 99, name: "Last Month" },
        { code: 100, name: "Year To Date" },
        { code: 101, name: "All" },
      ],
      totalColdMessages: 0,
      totalSMSCost: 0,
      totalCallCost: 0,
      totalAICost: 0,
      totalCost: 0,
      totalSpam: 0,
      totalDelivered: 0,
      totalPending: 0,
      totalPositive: 0,
      totalNegative: 0,
      totalResponses: 0,
      totalNonResponsive: 0,
      totalClicks: 0,
      totalGoals: 0,
      totalSales: 0,
      totalBookings: 0,
      totalCPA: 0,
      tableModal: false,
      tableTitle: "",
      table: [],
      lastSync: null,
      isFetching: false,
      loading: false,
      statsType: 'ALL',
      allStats: true,
      warmStats: false,
      coldStats: false
    };
  },
  watch: {
    dateFormat: {
      handler: "formatStats", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    fromDate: {
      handler: "getStats", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    toDate: {
      handler: "getStats", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
  },
  mounted() {
    this.getStats();
    console.log('route', this.$route);
    setInterval(() => {
      if ((this.$route.params.campId && this.$route.params.id) || this.$route.path === '/dashboard') {
        this.getStats(1);
      }
    }, 30000);
  },
  methods: {
    // Date Selection
    toggleStats(statsType) {
      this.statsType = statsType;
      if(statsType == "ALL") {
        setTimeout(() => {
          this.allStats = true;
          this.warmStats = false;
          this.coldStats = false;
        },1);
      }
      if(statsType == "WARM") {
        setTimeout(() => {
          this.allStats = false;
          this.warmStats = true;
         this.coldStats = false;
        },1);
      }
      if(statsType == "COLD") {
        setTimeout(() => {
          this.allStats = false;
          this.warmStats = false;
         this.coldStats = true;
        },1);
      }
      this.getStats();
    },
    formatStats() {
      let currentSelected = parseInt(this.dateFormat.code);
      console.log("Current Selected:" + currentSelected);
      if (currentSelected == 76) {
        // Yesterday
        this.fromDate = moment().subtract(1, "days").format("MM/DD/YYYY");
        this.toDate = moment().subtract(1, "days").format("MM/DD/YYYY");
      } else if (currentSelected == 77) {
        // This Week
        this.fromDate = moment().startOf("week").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 78) {
        // Last Week
        this.fromDate = moment()
          .subtract(1, "weeks")
          .startOf("week")
          .format("MM/DD/YYYY");
        this.toDate = moment()
          .subtract(1, "weeks")
          .endOf("week")
          .format("MM/DD/YYYY");
      } else if (currentSelected == 88) {
        // This Month
        this.fromDate = moment().startOf("month").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 99) {
        // Last Month
        this.fromDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        this.toDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("MM/DD/YYYY");
      } else if (currentSelected == 100) {
        // YTD
        this.fromDate = moment().startOf("year").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 101) {
        // All (Example: Setting fromDate to January 1, 2000)
        this.fromDate = moment("2000-01-01").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else {
        this.toDate = moment().add(0, "days").format("MM/DD/YYYY");

        this.fromDate = moment()
          .subtract(currentSelected - 1, "days")
          .format("MM/DD/YYYY");
      }

      this.getStats();
    },
    getStats(auto) {
      if (this.isFetching) {
        return;
      }

      this.isFetching = true;

      this.loading = true;
      console.log("from: " + this.fromDate);
      console.log("to: " + this.toDate);

      // Parse the input date strings
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      // Format the dates as MM/DD/YYYY
      const formattedFromDate = `${(fromDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${fromDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${fromDate.getFullYear()}`;
      const formattedToDate = `${(toDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${toDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${toDate.getFullYear()}`;

      // Create the date range string
      let dateRange = `${formattedFromDate} - ${formattedToDate}`;
      console.log('dateRange', dateRange);
      if (this.fromDate.length < 1 || this.toDate.length < 1) {
        dateRange = 0;
      }
      console.log(dateRange);

      try {
        let link =
          "https://123avatars.com/backend/newStats.php?u=" +
          this.userId +
          "&time=" +
          encodeURIComponent(dateRange) +
          "&type=" +
          this.statsType;
        if (this.$route.params.id) {
          link =
            "https://123avatars.com/backend/newStats.php?campaign_id=" +
            this.$route.params.campId +
            "&time=" +
            encodeURIComponent(dateRange) +
            "&type=" +
            this.statsType;
        }
        axios.get(link).then((response) => {
          
          
          this.statsData = response.data;

          if(this.statsData.conversation_counts == null) {
            console.log("CONVERSATION COUNTS NULL", this.statsData);
          }
          // this.campaignName = response.data.campaign.name;
          // this.campaignStatus = response.data.campaign.status;

          //LiveStats with notifications
          let newSuccess =
            parseInt(response.data.total_outbound_success) -
            this.totalOutboundSuccess;
          let newFailed =
            parseInt(response.data.total_outbound_failed) -
            this.totalOutboundFailed;
          if (auto == 1) {
            if (newSuccess > 0 && this.totalOutboundSuccess > 0) {
              this.$toasted.show(newSuccess + " Messages Sent Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              });
            }
            if (newFailed > 0 && this.totalOutboundFailed > 0) {
              this.$toasted.show(newFailed + " Messages failed to Deliver", {
                theme: "outline",
                position: "top-right",
                type: "danger",
                icon: "info",
                duration: 10000,
              });
            }
          }

          this.totalOutboundSuccess = parseInt(
            response.data.total_outbound_success
          );
          this.totalOutboundFailed = parseInt(
            response.data.total_outbound_failed
          );
          this.totalOutboundPending =
            parseInt(response.data.total_inqueue) +
            parseInt(response.data.total_inqueue_sent);

          this.totalColdMessages = response.data.total_cold_outbound == 0 ? this.totalOutboundPending : response.data.total_cold_outbound;
          this.totalValid = parseInt(response?.data?.total?.total_rows) - parseInt(response?.data?.total_outbound_cancelled);
          this.totalSMSCost = parseFloat(
            response?.data?.total?.total_cost
          ).toFixed(2);
          this.totalAICost = parseFloat(
            (parseInt(response?.data?.total?.total_cost_ai) / 1000) * 0.002
          ).toFixed(2);
          this.totalCallCost = parseFloat(response?.data?.total?.total_cost_calls);
          this.totalCost = parseFloat(
            parseFloat(this.totalSMSCost) + parseFloat(this.totalAICost) + parseFloat(this.totalCallCost)
          ).toFixed(2);
          this.totalSpam =
            parseInt(response.data.total_outbound_failed) +
            parseInt(response.data.total_outbound_spam);
          this.totalDelivered = response.data.total_outbound_success;
          this.totalPending =
            parseInt(this.totalColdMessages) -
            (parseInt(this.totalSpam) + parseInt(this.totalDelivered));
          this.totalPositive = response.data.total_positive;
          this.totalNegative = response.data.total_negative;
          this.totalResponses = response.data.total_replies;
          this.totalNonResponsive = this.totalDelivered - this.totalResponses;
          this.totalClicks = response.data.clicks;
          if (response.data.goals) {
            this.totalGoals = response.data.goals;
          } else {
            this.totalGoals = 0;
          }
          this.totalSales = response.data.sales;
          this.totalBookings = response.data.bookings;
          if (this.totalSales > 0) {
            this.totalCPA = (this.totalCost / this.totalSales).toFixed(2);
          } else {
            this.totalCPA = 0;
          }
          var currentdate = new Date(); 
          this.lastSync = "Last Sync: " + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
          this.loading = false;
        });
      } catch (error) {
        console.error("Error getting stats:", error);
      } finally {
        this.isFetching = false;
      }

      // Recursive
      // setTimeout(() => {
      //   this.getStats();
      //   this.getFoldersCount();
      // }, 10000);
    },
    showTable(table) {
      this.tableModal = true;
      this.tableTitle = table;
      // Parse the input date strings
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      // Format the dates as MM/DD/YYYY
      const formattedFromDate = `${(fromDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${fromDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${fromDate.getFullYear()}`;
      const formattedToDate = `${(toDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${toDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${toDate.getFullYear()}`;

      // Create the date range string
      let dateRange = `${formattedFromDate} - ${formattedToDate}`;
      if (this.fromDate.length < 1 || this.toDate.length < 1) {
        dateRange = 0;
      }
      console.log(dateRange);

      if (table.length > 0) {
        let link =
          "https://123avatars.com/backend/advancedStats.php?table=" +
          table +
          "&u=" +
          this.userId +
          "&time=" +
          encodeURIComponent(dateRange);
        if (this.$route.params.campId) {
          link =
            "https://123avatars.com/backend/advancedStats.php?table=" +
            table +
            "&campaign=" +
            this.$route.params.campId +
            "&avatar=" +
            this.$route.params.id +
            "&time=" +
            encodeURIComponent(dateRange);
        }
        axios.get(link).then((response) => {
          this.table = response.data;
        });
      }
    },
  },
};
</script>
