<template>
	<svg viewBox="0 0 17 25">
  		<path d="M15.2823 9c.2134 0 .3879.08.5237.22.1357.14.2133.32.2133.54V12c0 2.06-.6594 3.86-1.9782 5.42s-2.9673 2.46-4.92606 2.74v1.6h2.54066c.2133 0 .3878.08.5236.22.1357.14.2133.32.2133.54v.76c0 .22-.0776.4-.2133.54-.1358.14-.3103.22-.5236.22H4.38292c-.21333 0-.38788-.08-.52363-.22-.13576-.14-.21334-.32-.21334-.54v-.76c0-.22.07758-.4.21334-.54.13575-.14.3103-.22.52363-.22h2.54061v-1.6c-1.97818-.28-3.60727-1.24-4.92606-2.88C.67868 15.64.01929 13.76.01929 11.64V9.76c0-.22.07757-.4.21333-.54S.54292 9 .75626 9h.73697c.21333 0 .38788.08.52363.22.13576.14.21334.32.21334.54v1.96c0 1.6.50424 3 1.49333 4.22.98909 1.22 2.2497 1.9 3.72364 2.02 1.72606.18 3.21943-.32 4.49943-1.52 1.28-1.2 1.9006-2.68 1.9006-4.42V9.78c0-.22.0775-.4.2133-.54.1358-.14.3103-.22.5236-.22h.737L15.2823 9Zm-4.1891 6.18c-.8533.88-1.88119 1.32-3.08361 1.32-1.20242 0-2.2497-.44-3.08364-1.32-.83394-.88-1.28-1.94-1.28-3.18V4.5c0-1.24.42667-2.32 1.28-3.18C5.77929.46 6.80717 0 8.00959 0s2.24971.44 3.08361 1.32c.834.88 1.28 1.94 1.28 3.18V12c0 1.24-.4266 2.32-1.28 3.18Z"/>
	</svg>
</template>

<script>
export default {
   name: 'MuteASVG',
}
</script>