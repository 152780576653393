<template>
	<svg viewBox="0 0 11 18" class="paginrightSVG">
	  <g clip-path="url(#clip0_211_522)">
		<path d="M10.6818 9.7045L2.63517 17.7006C2.44063 17.8943 2.21073 17.9824 1.92777 17.9824C1.64481 17.9824 1.41491 17.8943 1.22038 17.7006L0.300763 16.7847C0.106229 16.591 0.000120109 16.362 -0.0175648 16.0802C-0.0352497 15.7984 0.0531748 15.5695 0.247709 15.3757L6.66732 8.98239L0.265393 2.60665C0.0708597 2.41292 0.000120109 2.16634 0.000120109 1.90215C0.000120109 1.63796 0.123914 1.39139 0.318448 1.19765L1.22038 0.2818C1.41491 0.0880626 1.64481 0 1.92777 0C2.21073 0 2.44063 0.0880626 2.63517 0.2818L10.6818 8.2955C10.894 8.48924 11.0178 8.7182 11.0178 9C11.0178 9.2818 10.9117 9.51076 10.6818 9.7045Z" />
	  </g>
	</svg>
</template>

<script>
export default {
   name: 'paginrightSVG',
}
</script>