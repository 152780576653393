<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 100 100"
    :width="size"
    :height="size"
  >
    <!-- Rounded rectangle background -->
    <rect 
      x="10" 
      y="10" 
      width="80" 
      height="80" 
      rx="20" 
      ry="20" 
      :fill="fill" 
    />

    <!-- White checkmark -->
    <path 
      d="M25 50 L45 70 L75 30" 
      stroke="white" 
      stroke-width="12" 
      stroke-linecap="round" 
      stroke-linejoin="round"
      fill="none" 
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckedSVG',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
    stroke: {
      type: String,
      default: "#0041C4"
    },
    fill: {
      type: String,
      default: "#FFF"
    }
  }
}
</script>
