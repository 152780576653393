<template>
    <div class="container mt-5">
      <div class="alert alert-info text-center" role="alert">
        <h4 class="alert-heading">Important Notice</h4>
        <p>Please check your email for new login credentials to access your dashboard.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "EmailNotificationBanner",
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>