<template>
	<svg viewBox="0 0 19 18">
  	<path d="M3.18036 2.63339C4.97629.88296 7.15365 0 9.71245 0c2.55885 0 4.73615.88296 6.53215 2.63339C18.0405 4.38382 18.9464 6.50602 18.9464 9c0 2.494-.9059 4.6162-2.7018 6.3666C14.4486 17.117 12.2713 18 9.71245 18c-2.5588 0-4.73616-.883-6.53209-2.6334C1.38443 13.6162.47852 11.494.47852 9c0-2.49398.90591-4.61618 2.70184-6.36661ZM15.0843 10.0224V7.99312c0-.29432-.143-.43374-.445-.43374h-3.4329V4.21343c0-.29432-.143-.43374-.445-.43374h-2.082c-.30197 0-.44501.13942-.44501.43374v3.34595H4.80146c-.30197 0-.44501.13942-.44501.43374v2.02928c0 .2943.14304.4337.44501.4337h3.43293v3.346c0 .2943.14304.4337.44501.4337h2.082c.302 0 .445-.1394.445-.4337v-3.346h3.4329c.302 0 .445-.1394.445-.4337Z"/>
	</svg>
</template>

<script>
export default {
   name: 'addSVG',
}
</script>