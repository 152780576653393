<template>
  <div class="modal show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Voicemail Guide</h5>
          <button type="button" class="close" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea v-model="localPrompt" class="form-control" placeholder="Enter your prompt" rows="5"></textarea>
          <textarea v-model="localVoicemailGuide" class="form-control mt-2" placeholder="Voicemail Guide" rows="5"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveData">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    voicemailGuide: {
      type: String,
      required: true
    },
    prompt: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localVoicemailGuide: this.voicemailGuide,
      localPrompt: this.prompt
    };
  },
  methods: {
    saveData() {
      let data = {
        promptType: "Script-Voicemail",
        script: this.localVoicemailGuide,
        prompt: this.localPrompt
      };
      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Origin': 'http://localhost:8080',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 5000,
        });
        setTimeout(() => {
          this.$emit('close-modal');
        }, 3000);
      }).catch(error => {
        this.$toasted.show("Failed to save data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "error",
          duration: 5000,
        });
      });
    },
    closeModal() {
      this.$emit('close-modal');
    }
  },
  watch: {
    voicemailGuide(newVal) {
      this.localVoicemailGuide = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  }
};
</script>