<template>
    <svg :width="size" :height="size" viewBox="0 0 18 15" :fill="fill">
        <path d="M0.615238 2.75843C0.437143 2.75843 0.291429 2.69314 0.178095 2.57889C0.0647619 2.46464 0 2.31774 0 2.13819V0.620239C0 0.440696 0.0647619 0.293798 0.178095 0.179543C0.291429 0.0652884 0.437143 0 0.615238 0H16.401C16.579 0 16.7248 0.0652884 16.8381 0.179543C16.9514 0.293798 17.0162 0.440696 17.0162 0.620239V2.15452C17.0162 2.33406 16.9514 2.48096 16.8381 2.59521C16.7248 2.70947 16.579 2.77476 16.401 2.77476H0.615238V2.75843ZM0.615238 8.87922C0.437143 8.87922 0.291429 8.81393 0.178095 8.69967C0.0647619 8.58542 0 8.43852 0 8.25898V6.7247C0 6.54516 0.0647619 6.39826 0.178095 6.284C0.291429 6.16975 0.437143 6.10446 0.615238 6.10446H16.401C16.579 6.10446 16.7248 6.16975 16.8381 6.284C16.9514 6.39826 17.0162 6.54516 17.0162 6.7247V8.25898C17.0162 8.43852 16.9514 8.58542 16.8381 8.69967C16.7248 8.81393 16.579 8.87922 16.401 8.87922H0.615238ZM0.615238 15C0.437143 15 0.291429 14.9347 0.178095 14.8205C0.0647619 14.7062 0 14.5593 0 14.3798V12.8455C0 12.6659 0.0647619 12.519 0.178095 12.4048C0.291429 12.2905 0.437143 12.2252 0.615238 12.2252H16.401C16.579 12.2252 16.7248 12.2905 16.8381 12.4048C16.9514 12.519 17.0162 12.6659 17.0162 12.8455V14.3798C17.0162 14.5593 16.9514 14.7062 16.8381 14.8205C16.7248 14.9347 16.579 15 16.401 15H0.615238Z" />
    </svg>
</template>

<script>
export default {
   name: 'threeLinesSVG',
   props: {
    size: {
      type: [Number, String],
      default: 22
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>