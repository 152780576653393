<template>
  <div>
    <Breadcrumbs main="" title="Knowledge Center" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-3 pr-sm-0 box-col-12" v-show="false">
          <div class="file-sidebar">
            <div class="card">
              <div class="card-body">
                <ul>
                  <li>
                    <div class="btn btn-primary">
                      <feather type="folder"></feather>
                      All
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="file-text"></feather>
                      DataSets
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="globe"></feather>
                      Websites
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="file"></feather>
                      Documents
                    </div>
                  </li>
                  <li>
                    <div class="btn btn-light">
                      <feather type="trash-2"></feather>
                      Deleted
                    </div>
                  </li>
                </ul>
                <hr />
                <ul>
                  <li>
                    <div class="m-t-15">
                      <div class="progress sm-progress-bar mb-1">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 30%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p>3 GB of 10 GB used</p>
                    </div>
                  </li>
                </ul>
                <hr />
                <ul>
                  <!-- <li>
                    <div class="btn btn-outline-primary">
                      <feather type="grid"></feather> Pricing plan
                    </div>
                  </li> -->
                  <li>
                    <div class="pricing-plan">
                      <h6>Regular User</h6>
                      <h5>FREE</h5>
                      <p>10 GB Space</p>
                      <div class="btn btn-outline-primary btn-xs">
                        Current Plan
                      </div>
                      <img
                        class="bg-img"
                        src="../assets/images/dashboard/folder.png"
                        alt=""
                      />
                    </div>
                  </li>
                  <li>
                    <div class="pricing-plan">
                      <h6>Premium User</h6>
                      <h5>Invites Only</h5>
                      <p>100 GB Space</p>
                      <div class="btn btn-outline-primary btn-xs">
                        Contact Us
                      </div>
                      <img
                        class="bg-img"
                        src="../assets/images/dashboard/folder1.png"
                        alt=""
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-md-12 box-col-12">
          <div class="file-content">
            <div class="card">
              <div class="card-header">
                <div class="media">
                  <!-- <form class="form-inline" action="#" method="get">
                    <div class="form-group mb-0">
                      <i class="fa fa-search"></i>
                      <input
                        class="form-control-plaintext"
                        type="text"
                        placeholder="Search..."
                      />
                    </div>
                  </form> -->
                  <div class="media-body text-right">

                    <!-- <div
                      class="btn btn-primary"
                      @click="
                        knowledgeModal = true;
                        SelectedDataSet = [];
                      "
                    >
                      <feather type="plus-square" class="text-top"></feather>
                       Avatar Personality
                    </div>

                    <div
                      class="btn btn-primary"
                      @click="
                        knowledgeModal = true;
                        SelectedDataSet = [];
                      "
                    >
                      <feather type="plus-square" class="text-top"></feather>
                       Inbound Script
                    </div> -->

                    <div
                      class="btn btn-primary"
                      @click="
                        dataModal = true;
                        SelectedDataSet = [];
                      "
                    >
                      <feather type="plus-square" class="text-top"></feather>
                      Add New Dataset
                    </div>

                    <div
                      class="btn btn-secondary ml-2"
                      @click="webModal = true"
                    >
                      <feather type="plus-square" class="text-top"></feather>
                      Scrape Website
                    </div>

                    <div
                      class="btn btn-outline-primary ml-2"
                      @click="fileModal = true"
                    >
                      <feather type="upload" class="text-top"></feather> Upload
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body file-manager">
                <!-- <h4 class="mb-3">All Files</h4> -->
                <h6>Prompts</h6>
                <ul class="folder">
                  <li
                    class="folder-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="prompt in prompts"
                    :key="prompt.id"
                    @click="editPrompt(prompt.id)"
                  >
                    <div class="media">
                      <i class="fa fa-file f-36 txt-primary"></i>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">{{ prompt.name }}</h6>
                        <p>{{ prompt.prompt | truncate(64) }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <h6>Datasets</h6>
                <ul class="folder">
                  <li
                    class="folder-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="dataset in data"
                    :key="dataset.id"
                    v-if="
                      (dataset.type == 1 && dataset.status == 1) ||
                      (dataset.type == 0 && dataset.status == 1)
                    "
                    @click="editDataset(dataset.id)"
                  >
                    <div class="media">
                      <i class="fa fa-file f-36 txt-primary"></i>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">{{ dataset.name }}</h6>
                        <p>{{ dataset.text | truncate(64) }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <h6 class="mt-4">Websites</h6>
                <ul class="folder">
                  <li
                    class="folder-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="dataset in data"
                    :key="dataset.id"
                    v-if="dataset.type == 4 && dataset.status == 1"
                    @click="editDataset(dataset.id)"
                  >
                    <div class="media">
                      <i class="fa fa-globe f-36 txt-secondary"></i>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">{{ dataset.name }}</h6>
                        <p>
                          {{ dataset.activeLinks }}/<small>{{
                            dataset.totalLinks
                          }}</small>
                          Web Pages
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <h6 class="mt-4">Documents</h6>
                <ul class="files">
                  <li
                    class="file-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="dataset in data"
                    :key="dataset.id"
                    v-if="dataset.type == 2 && dataset.status == 1"
                    @click="editDataset(dataset.id)"
                  >
                    <div class="file-top">
                      <i class="fa fa-file-text-o txt-info"></i
                      ><i class="fa fa-ellipsis-v f-14 ellips"></i>
                    </div>
                    <div class="file-bottom">
                      <h6>{{ dataset.name }}</h6>
                      <p class="mb-1">0.90 KB</p>
                    </div>
                  </li>
                </ul>
                <h6 class="mt-4">MMS Images</h6>
                <ul class="files">
                  <li
                    class="file-box"
                    v-b-tooltip.hover
                    title="Click to edit"
                    v-for="dataset in data"
                    :key="dataset.id"
                    v-if="dataset.type == 3"
                    @click="editDataset(dataset.id)"
                  >
                    <div class="file-top">
                      <i class="fa fa-file-text-o txt-info"></i
                      ><i class="fa fa-ellipsis-v f-14 ellips"></i>
                    </div>
                    <div class="file-bottom">
                      <h6>{{ dataset.name }}</h6>
                      <p class="mb-1">0.90 KB</p>
                    </div>
                  </li>
                </ul>
              </div>


              <b-modal
                id="modal-lg"
                size="lg"
                title=""
                class="theme-modal"
                hide-footer
                hide-header
                v-model="knowledgeModal"
              >
              <PromptComponent v-if="promptType === 'System-Prompt'" :prompt="promptModal.prompt" @close-modal="closeModal"></PromptComponent>
              <ScriptComponent v-if="promptType === 'Script-Main'" :prompt="promptModal.prompt" :fullScript="promptModal.script" @close-modal="closeModal"></ScriptComponent>
              <PromblemSolutionComponent v-if="promptType === 'Script-Problem_Solution'" :prompt="promptModal.prompt" :problem="promptModal.problem" :solution="promptModal.solution" :questions="promptModal.questions" @close-modal="closeModal"></PromblemSolutionComponent>
              <ObjectionRebuttalComponent v-if="promptType === 'Script-Objection_Rebuttal'" :objectionsRebuttals="promptModal.script" :prompt="promptModal.prompt" @close-modal="closeModal"></ObjectionRebuttalComponent>
              <TransitionsComponent v-if="promptType === 'Script-Transition'" :transitionQuestions="promptModal.script" :prompt="promptModal.prompt" @close-modal="closeModal"></TransitionsComponent>
              <CloseComponent v-if="promptType === 'Script-Close'" :initialPrompt="promptModal.prompt" :initialClosingScript="promptModal.script" @close-modal="closeModal"></CloseComponent>
              <ROICalculatorComponent v-if="promptType === 'Script-ROI_Calculator'" :initialPrompt="promptModal.prompt" @close-modal="closeModal"></ROICalculatorComponent>
              <VoicemailComponent v-if="promptType === 'Script-Voicemail'" :prompt="promptModal.prompt" :voicemailGuide="promptModal.script" @close-modal="closeModal"></VoicemailComponent>
              <InboundComponent v-if="promptType === 'Script-Inbound'" :initialInboundScript="promptModal.script" :initialUserPrompt="promptModal.prompt" @close-modal="closeModal"></InboundComponent>
              <SaleRetailComponent v-if="promptType === 'Script-Sale_Retail'" :initialPrompt="promptModal.prompt" :initialSalesPrice="promptModal.salePrice" :initialRetailPrice="promptModal.retailPrice" @close-modal="closeModal"></SaleRetailComponent>
            </b-modal>

              <b-modal
                id="modal-lg"
                size="lg"
                title="Dataset"
                class="theme-modal"
                hide-footer
                hide-header
                v-model="dataModal"
              >
                <div class="row">
                  <div class="col-12">
                    <label>DataSet Name</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Rules and data for XYZ"
                      v-model="SelectedDataSet.name"
                    />
                  </div>
                  <div class="col-12 mt-4">
                    <label>{{
                      SelectedDataSet.type != 4 ? "Information" : "Webpages"
                    }}</label>
                    <b-dropdown
                      text="Add Variables"
                      variant="primary"
                      style="float: right"
                    >
                      <b-dropdown-header id="dropdown-header-label">
                        LeadVariables
                      </b-dropdown-header>
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Owners_Name')"
                        >lead-Owners_Name</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Name')"
                        >lead-Company_Name</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Street_Address')"
                        >lead-Company_Street_Address</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_City')"
                        >lead-Company_City</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Zipcode')"
                        >lead-Company_Zipcode</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_State')"
                        >lead-Company_State</b-dropdown-item
                      >
                      <b-dropdown-item @click="addHookVariable('@lead-Website')"
                        >lead-Website</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Industry')"
                        >lead-Industry</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Year_Established')"
                        >lead-Year_Established</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Years_in_Business')"
                        >lead-Years_in_Business</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Facebook_Page')"
                        >lead-Facebook_Page</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Linkedin_Profile')"
                        >lead-Linkedin_Profile</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Twitter_Profile')"
                        >lead-Twitter_Profile</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Instagram_Page')"
                        >lead-Instagram_Page</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-YouTube_Channel')"
                        >lead-YouTube_Channel</b-dropdown-item
                      >
                      <b-dropdown-header id="dropdown-header-label1">
                        Connected Data
                      </b-dropdown-header>
                      <b-dropdown-item></b-dropdown-item>
                    </b-dropdown>
                    <textarea
                      rows="25"
                      placeholder="Information Goes Here"
                      style="
                        background-color: black;
                        color: white;
                        border-radius: 20px;
                        padding: 10px;
                        width: 100%;
                      "
                      spellcheck="false"
                      v-model="SelectedDataSet.text"
                      v-if="SelectedDataSet.type != 4"
                    ></textarea>
                    <!-- Webpages View -->
                    <div
                      v-for="(linkItem, index) in parsedLinks"
                      :key="index"
                      style="
                        display: flex;
                        justify-content: start;
                        align-items: center;
                      "
                      v-if="SelectedDataSet.type == 4"
                    >
                      <label class="mr-2">{{ "/" + linkItem.name }}</label>
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="linkItem.checked"
                          @change="updateLinkState(linkItem)"
                        /><span class="switch-state"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <button class="btn btn-primary" @click="saveDataSet">
                      Save
                    </button>
                    <button
                      class="btn btn-danger ml-4"
                      @click="deleteDataSet"
                      v-if="SelectedDataSet.id > 0"
                    >
                      <feather type="trash-2" size="14"></feather> Delete
                    </button>
                  </div>
                </div>
              </b-modal>
              <b-modal
                id="modal-lg"
                size="lg"
                title="Dataset"
                class="theme-modal"
                hide-footer
                hide-header
                v-model="webModal"
              >
                <div class="row">
                  <div class="col-12">
                    <label>Please Input your Website URL / Sitemap</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://www.google.com/sitemap.xml"
                      v-model="newWebsite"
                    />
                    <div style="float: right">
                      <b>Scrape One Page Only</b><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked=""
                          v-model="one_page"
                        /><span class="switch-state"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-12 mt-4">
                    <button class="btn btn-primary" @click="saveWebsite">
                      Scrape Now
                    </button>
                    <button
                      class="btn btn-danger ml-4"
                      @click="deleteDataSet"
                      v-if="SelectedDataSet.id > 0"
                    >
                      <feather type="trash-2" size="14"></feather> Delete
                    </button>
                  </div>
                </div>
              </b-modal>
              <b-modal
                id="modal-lg"
                size="lg"
                title="Dataset"
                class="theme-modal"
                hide-footer
                hide-header
                v-model="fileModal"
              >
                <div class="row">
                  <div class="col-12">
                    <label>Upload your files below</label>
                    <vue-dropzone
                      id="singledropzone"
                      :options="singledropzoneOptions"
                      class="dropzone digits"
                      @vdropzone-success="uploadSuccess"
                    >
                    </vue-dropzone>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import PromblemSolutionComponent from "../components/script/ProblemSolution.vue";
import PromptComponent from "../components/script/Prompt.vue";
import ScriptComponent from "../components/script/Script.vue";
import ObjectionRebuttalComponent from "../components/script/ObjectionRebuttal.vue";
import TransitionsComponent from "../components/script/Transitions.vue";
import CloseComponent from "../components/script/Close.vue";
import ROICalculatorComponent from "../components/script/ROICalculator.vue";
import VoicemailComponent from "../components/script/Voicemail.vue";
import InboundComponent from "../components/script/Inbound.vue";
import SaleRetailComponent from "../components/script/SaleRetail.vue";

import AvatarPersonaComponent from "../components/personality/Personality.vue";

export default {
  components: {
    vueDropzone: vue2Dropzone, PromblemSolutionComponent, AvatarPersonaComponent, ScriptComponent, ObjectionRebuttalComponent, TransitionsComponent, 
      CloseComponent, ROICalculatorComponent, VoicemailComponent, InboundComponent, SaleRetailComponent, PromptComponent
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      data: [],
      dataModal: false,
      webModal: false,
      fileModal: false,
      SelectedDataSet: [{ name: "", text: "" }],
      newWebsite: "",
      singledropzoneOptions: {
        url:
          "https://123avatars.com/backend/upload.php?u=" +
          localStorage.getItem("userId"),
        thumbnailWidth: 50,
        maxFiles: 4,
        maxFilesize: 5,
        addRemoveLinks: false,
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6><span>(Allowed Formats <strong>PDF, CSV, TXT</strong>, you can upload multiple files at once.)</span>",
      },
      one_page: false,
      knowledgeModal: false,
      promptModal: {
        prompt: "",
        script: "",
        problem: "",
        solution: "",
        questions: "",
        salePrice: "",
        retailPrice: ""
      },
      prompts: [],
      promptType: ''

    };
  },
  computed: {
    parsedLinks() {
      if (this.SelectedDataSet.text) {
        return this.SelectedDataSet.text.split(",").map((item) => {
          const parts = item.split("-");
          return {
            checked: parts[0] == "1",
            name: parts[1],
            originalValue: item,
          };
        });
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getPrompts();
    this.getConnectedData();
  },
  methods: {
    async closeModal() {
      console.log("updateModal");
      this.knowledgeModal = false;
      this.getPrompts();
    },
    async getPrompts() {
      let response = await axios("https://123avatars.com/backend/getPrompts.php?id=" + this.userId );
      if(response.status == 200) {
        this.prompts = response.data;
      } else {
        console.error("Error fetching prompts.");
      }
    },
    async editPrompt(id) {
      console.log("id: ", id);
      console.log("editPrompt");
      this.knowledgeModal = true;
      let prompt = this.prompts.find((prompt) => prompt.id === id);
      this.promptType = `${prompt.section}-${prompt.type}`;
      console.log("promptType: ", this.promptType);
      this.promptModal = {
        script: prompt.script,
        prompt: prompt.prompt,
        problem: prompt.problem,
        solution: prompt.solution,
        questions: prompt.questions,
        salePrice: prompt.sale_price,
        retailPrice: prompt.retail_price
      };
      // find data
      // open component
      // save data
    },
    async addPrompt() {
      let response = await axios(); // save prompt.php

    },
    updateSelectedDataSetText() {
      this.SelectedDataSet.text = this.parsedLinks
        .map((link) => {
          return `${link.checked ? "1" : "0"}-${link.name}`;
        })
        .join(",");
    },

    updateLinkState(linkItem) {
      // Toggle the checked state
      linkItem.checked = !linkItem.checked;

      // Update the original value if needed
      linkItem.originalValue = `${linkItem.checked ? "1" : "0"}-${
        linkItem.name
      }`;

      // Reflect this change in the SelectedDataSet.text
      this.updateSelectedDataSetText();

      // Here you would typically send the updated data back to the server or emit an event
      // this.$emit('update-data', this.SelectedDataSet.text);
      // Or, for example, send a POST request to your backend to update the data in the database
    },
    getConnectedData() {
      axios
        .get("https://123avatars.com/backend/getData.php?u=" + this.userId)
        .then((response) => {
          console.log(response.data);
          this.data = response.data;
          // Loop through each data item and calculate active and total links if type is 4
          this.data.forEach((item) => {
            if (item.type == 4 && item.text) {
              const links = item.text.split(",");
              const linkStatusCounts = links.reduce(
                (acc, link) => {
                  const [status] = link.split("-");
                  acc.total++;
                  if (status === "1") {
                    acc.active++;
                  }
                  return acc;
                },
                { active: 0, total: 0 }
              );

              // Assign the counts to the item
              item.activeLinks = linkStatusCounts.active;
              item.totalLinks = linkStatusCounts.total;
            }
          });
        });
    },
    editDataset(id) {
      this.dataModal = true;
      let dataSet = this.data.find((item) => item.id === id);
      this.SelectedDataSet = dataSet;
    },
    saveDataSet() {
      let id = this.SelectedDataSet.id;
      if (!id) {
        if (!this.SelectedDataSet.name || !this.SelectedDataSet.text) {
          this.$toasted.show("Please fill in the details", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        axios
          .get("https://123avatars.com/backend/newData.php", {
            params: {
              u: this.userId,
              dataText: this.SelectedDataSet.text,
              dataName: this.SelectedDataSet.name,
            },
          })
          .then((response) => {
            //new Data Created
            console.log(response.data.data_id);
            this.getConnectedData();
            this.dataModal = false;
            this.$toasted.show(
              this.SelectedDataSet.name + " Dataset Created Successfully",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
            this.SelectedDataSet = [];
          });
        return false;
      }
      axios
        .get("https://123avatars.com/backend/editData.php", {
          params: {
            id: this.SelectedDataSet.id,
            dataText: this.SelectedDataSet.text,
            dataName: this.SelectedDataSet.name,
          },
        })
        .then((response) => {
          //new Data Created
          console.log(response);
          this.getConnectedData();
          this.dataModal = false;
          this.$toasted.show(this.SelectedDataSet.name + " Dataset Updated", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 10000,
          });
          this.SelectedDataSet = [];
        });
    },
    saveWebsite() {
      if (this.newWebsite.length < 4) {
        this.$toasted.show("Please input a valid website sitemap", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      } else {
        axios
          .get(
            "https://123avatars.com/backend/addWebsite.php?name=" +
              this.newWebsite +
              "&u=" +
              this.userId +
              "&onePage=" +
              this.one_page
          )
          .then((response) => {
            console.log(response);
            this.webModal = false;
            this.newWebsite = "";
            this.getConnectedData();
            this.$toasted.show(
              "Website added Successfully please allow a few minutes for Scraping process.",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
          });
      }
    },
    deleteDataSet() {
      let id = this.SelectedDataSet.id;
      if (!id) {
        return false;
      }
      axios
        .get("https://123avatars.com/backend/deleteData.php?id=" + id)
        .then((response) => {
          this.getConnectedData();
          this.dataModal = false;
          this.$toasted.show(
            this.SelectedDataSet.name + " Dataset Deleted Successfully",
            {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 10000,
            }
          );
          this.SelectedDataSet = [];
        });
    },
    addHookVariable(variable) {
      this.SelectedDataSet.text = this.SelectedDataSet.text + " " + variable;
    },
    uploadSuccess(f, s) {
      let obj = JSON.parse(s);
      console.log(obj.file_id);
      this.getConnectedData();
      this.fileModal = false;
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length <= limit) {
        return value;
      } else {
        return value.substring(0, limit) + "...";
      }
    },
  },
};
</script>
