<template>
	<svg viewBox="0 0 12 12" class="playSVG">
		<path d="M12 7.16444V4.83556C12 4.49778 11.84 4.33778 11.5022 4.33778H7.66222V0.497777C7.66222 0.16 7.50222 0 7.16444 0H4.83556C4.49778 0 4.33778 0.16 4.33778 0.497777V4.33778H0.497777C0.16 4.33778 0 4.49778 0 4.83556V7.16444C0 7.50222 0.16 7.66222 0.497777 7.66222H4.33778V11.5022C4.33778 11.84 4.49778 12 4.83556 12H7.16444C7.50222 12 7.66222 11.84 7.66222 11.5022V7.66222H11.5022C11.84 7.66222 12 7.50222 12 7.16444Z" />
	  </svg>
</template>

<script>
export default {
   name: 'plusSVG',
}
</script>