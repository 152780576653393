<template>
  <div class="stepper-container">
    <div class="step-content">
      <div class="content-container">
        <div style="padding: 5px;">
          <span class="font-bold text-1xl">Negative Keyword Filter</span>
        </div>
        <div class="keywords-controls">
          <div class="search-container">
            <input type="text" v-model="searchTerm" placeholder="Search or add keyword..." class="search-input"  @input="filterKeywords" @keyup.enter="addKeyword" />
            <button @click="addKeyword" class="btn solid" :disabled="!searchTerm.trim()">
              Add
            </button>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th style="width: 99%;">Keywords</th>
                <th style="width: 1%;"></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="(row, index) in filteredKeywords" :key="row.id" :data-keyword-id="row.id">
                <td>{{ row.keyword || '-' }}</td>
                <td @click="deleteKeyword(row.keyword)">
                  <closeSVG size="18" fill="red" style="cursor: pointer" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import eraseSVG from '../SVG-icons/eraseSVG.vue';
import closeSVG from '../SVG-icons/closeSVG.vue';

export default {
  components: {
    eraseSVG,
    closeSVG,
  },
  data() {
    return {
      filteredKeywords: [],
      keywords: [],
      rowHeight: 41, // Height of each row in pixels (including padding and borders)
      searchTerm: '',
    }
  },
  computed: {
    visibleKeywords() {
      return this.keywords;
    }
  },
  async mounted() {
    await this.getKeywords();
  },
  methods: {
    async getKeywords() {
      let target = `https://123avatars.com/v2/negative-keywords`;
      const response = await axios.get(target);
      this.keywords = response.data;
      this.filteredKeywords = response.data;
    },
    async deleteKeyword(keyword) {
      let target = `https://123avatars.coms/v2/negative-keywords`;
      let response = await axios.delete(target, { data: { keyword }});
      if (response.status === 200) {
        this.keywords = this.keywords.filter(k => k.keyword !== keyword);
        this.filteredKeywords = this.filteredKeywords.filter(k => k.keyword !== keyword);
      }
    },
    filterKeywords() {
      if (!this.searchTerm.trim()) {
        this.filteredKeywords = [...this.keywords];
        return;
      }
      const term = this.searchTerm.toLowerCase();
      this.filteredKeywords = this.keywords.filter(item => 
        item.keyword.toLowerCase().includes(term)
      );
    },
    async addKeyword() {
      const newKeyword = this.searchTerm.trim();
      if (!newKeyword) return;
      // Check if keyword already exists
      const exists = this.keywords.some(k => 
        k.keyword.toLowerCase() === newKeyword.toLowerCase()
      );
      
      if (!exists) {
        let target = `https://123avatars.com/v2/negative-keywords`;
        await axios.post(target, { keyword: newKeyword });
        this.searchTerm = '';
        await this.getKeywords();
      }
    }
  }
}
</script>

<style scoped>
.keywords-controls {
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.search-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #2563eb;
}
.stepper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}

.content-container {
  min-height: 200px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table-wrapper {
  overflow-y: auto;
  height: 400px;
  /* 41px per row * 20 rows = 820px */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #e5e7eb;
  text-align: left;
  height: 25px;
  /* Fixed height for each cell */
}

.table thead th {
  background-color: #f9fafb;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Custom scrollbar styling */
.table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>