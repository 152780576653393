<template>
    <svg :width="size" :height="size" viewBox="0 0 15 16" :fill="fill">
        <path d="M14.2946 0C14.607 0 14.8238 0.146655 14.9386 0.439965C15.0534 0.733275 15.0087 0.988327 14.792 1.19874L9.37209 6.6186V14.2957C9.37209 14.589 9.24457 14.7994 8.98952 14.9269C8.73446 15.0545 8.49216 15.0417 8.25624 14.8823L5.90976 13.2436C5.7121 13.0842 5.61645 12.8929 5.61645 12.657V6.62498L0.209346 1.19874C-0.00107224 0.988327 -0.058459 0.733275 0.0626908 0.439965C0.183841 0.146655 0.394259 0 0.706697 0H14.301H14.2946Z" />
    </svg>
</template>

<script>
export default {
   name: 'funnelsSVG',
   props: {
    size: {
      type: [Number, String],
      default: 22
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>