<template>
  <div id="operator-quota-policy" class="mt-3">
    <UIBox>
      <template v-slot:title>
        <span class="bold">
          <span class="font-bold"> Probation </span>
        </span>
      </template>
      <b-table striped hover :items="probationPeriod" :fields="fields" class="mb-2">
        <template #cell(period)="period">
          <div class="bold">Day {{ period.value }}</div>
        </template>
        <template #cell(minimum_calls)="row">
          <span
            class="editable"
            contenteditable="true"
            @input="
              $event.target.textContent = $event.target.textContent.replace(/[^0-9]/g, '')
            "
            @blur="
              updateProbation(row.item.period, 'minimum_calls', $event.target.textContent)
            "
          >
            {{ row.item.minimum_calls }}
          </span>
        </template>
        <template #cell(probation_type)="{ value, item }">
          <div class="form-group select w-80">
            <select
              v-model="value"
              @change="updateProbation(item.period, 'probation_type', value)"
              class="form-control"
            >
              <option
                v-for="probationType in probationTypes"
                :value="probationType"
                :key="probationType"
                :selected="value === probationType"
              >
                {{ probationType }}
              </option>
            </select>
          </div>
        </template>
        <template #cell(minimum)="{ value, item }">
          <div class="form-group select w-16">
            <select
              v-model="value"
              @change="updateProbation(item.period, 'minimum', value)"
              class="form-control"
            >
              <option v-for="n in 50" :key="n" :value="n" :selected="value === n">
                {{ n }}
              </option>
            </select>
          </div>
        </template>
      </b-table>
    </UIBox>
    <UIBox>
      <template v-slot:title>
        <span class="bold">
          <span class="font-bold"
            >Weekly After Probation |
            <span class="font-normal">Weeks are from Monday - Sunday</span>
          </span>
        </span>
      </template>
      <b-table striped hover :items="afterProbation" :fields="fields" class="mb-2">
        <template #cell(period)="period">
          <div class="bold">
            {{ period.value }}
          </div>
        </template>
        <template #cell(minimum_calls)="row">
          <span
            class="editable"
            contenteditable="true"
            @input="
              $event.target.textContent = $event.target.textContent.replace(/[^0-9]/g, '')
            "
            @blur="
              updateAfterProbation(
                row.item.period,
                'minimum_calls',
                $event.target.textContent
              )
            "
          >
            {{ row.item.minimum_calls }}
          </span>
        </template>
        <template #cell(probation_type)="{ value, item }">
          <div class="form-group select w-80">
            <select
              v-model="value"
              @change="updateAfterProbation(item.period, 'probation_type', value)"
              class="form-control"
            >
              <option
                v-for="probationType in probationTypes"
                :value="probationType"
                :key="probationType"
                :selected="value === probationType"
              >
                {{ probationType }}
              </option>
            </select>
          </div>
        </template>
        <template #cell(minimum)="{ value, item }">
          <div class="form-group select w-16">
            <select
              v-model="value"
              @change="updateAfterProbation(item.period, 'minimum', value)"
              class="form-control"
            >
              <option v-for="n in 50" :key="n" :value="n" :selected="value === n">
                {{ n }}
              </option>
            </select>
          </div>
        </template>
      </b-table>
    </UIBox>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AuthService from "../../_services/AuthService.js";
import UIBox from "@/components/uibox.vue";
import PaginleftdoubleSVG from "../../components/SVG-icons/paginleftdoubleSVG.vue";
import PaginleftSVG from "../../components/SVG-icons/paginleftSVG.vue";
import PaginrightSVG from "../../components/SVG-icons/paginrightSVG.vue";
import PaginrightdoubleSVG from "../../components/SVG-icons/paginrightdoubleSVG.vue";
import CloseSVG from "../../components/SVG-icons/closeSVG.vue";

export default {
  components: {
    DatePicker,
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    CloseSVG,
  },
  props: {
    salesRepId: String,
    toCallsFilter: String,
  },
  data() {
    return {
      fields: [
        { key: "period", label: "" },
        { key: "minimum_calls", label: "Minimum Calls" },
        { key: "probation_type", label: "Type" },
        { key: "minimum", label: "Minimum" },
      ],
      probationTypeSelection: null,
      probationPeriod: [],
      afterProbation: [],
      loading: false,
      role: "",
      userId: "",
      probationTypes: ["Completed Script Asked all Questions", "Paid TO"],
    };
  },
  methods: {
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
    async fetchProbationPeriod() {
      this.loading = true;
      try {
        const response = await axios.get("https://123avatars.com/v2/probation-period", {});
        this.probationPeriod = response.data.results;
      } catch (error) {
        console.error("Error fetching probation period:", error);
      } finally {
        this.loading = false;
      }
    },
    async updateProbation(itemId, field, value) {
      axios
        .put(`https://123avatars.com/v2/probation-period/${itemId}`, {
          field,
          value,
        })
        .then(() => {
          console.log(`Tags for item ${itemId} updated successfully.`);
        })
        .catch((error) => {
          console.error(`Error updating tags for item ${itemId}:`, error);
        });
    },
    async fetchAfterProbation() {
      this.loading = true;
      try {
        const response = await axios.get("https://123avatars.com/v2/after-probation", {});
        this.afterProbation = response.data.results;
      } catch (error) {
        console.error("Error fetching after probation:", error);
      } finally {
        this.loading = false;
      }
    },
    async updateAfterProbation(itemId, field, value) {
      axios
        .put(`https://123avatars.com/v2/after-probation/${itemId}`, {
          field,
          value,
        })
        .then(() => {
          console.log(
            `${field} for after probation item ${itemId} updated successfully.`
          );
        })
        .catch((error) => {
          console.error(
            `Error updating ${field} for after probation item ${itemId}:`,
            error
          );
        });
    },
  },
  mounted() {
    this.initUser();
    this.fetchProbationPeriod();
    this.fetchAfterProbation();
  },
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);
$color_white: #ffffff;
$black: #000000;

.editable {
  padding: 8px;

  &:focus {
    border: 3px solid $interactive;
    background-color: white;
    border-radius: 10px;
  }
}
</style>
