<template>
  <div class="fixed inset-0 bg-light overflow-y-auto h-full w-full d-flex align-items-center justify-content-center">
    <div class="relative p-5 border w-100 md:w-75 lg:w-50 xl:w-66 shadow-lg rounded bg-light dark:bg-dark text-dark dark:text-light">
      <div class="mt-3 text-center">
        <h3 class="text-lg font-medium">
          The Script
        </h3>
        <div class="mt-2 px-3 py-3">
          <textarea
            placeholder="Full Script"
            class="form-control mb-2"
            v-model="fullScript"
          ></textarea>
          <textarea
            placeholder="Enter your prompt"
            class="form-control mb-4"
            v-model="prompt"
          ></textarea>
        </div>
        <div class="items-center px-4 py-3">
          <button
            id="ok-btn"
            class="btn btn-primary w-100"
            @click="saveData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      fullScript: '',
      prompt: '',
    };
  },
  methods: {
    saveData() {
      const data = {
        fullScript: this.fullScript,
        prompt: this.prompt,
      };
      axios.post('your-backend-url', data)
        .then(response => {
          console.log('Data saved:', response.data);
          this.animateButton();
        })
        .catch(error => {
          console.error('Error saving data:', error);
        });
    },
    animateButton() {
      const saveButton = document.getElementById('ok-btn');
      saveButton.classList.add('animate-pulse');
      setTimeout(() => {
        saveButton.classList.remove('animate-pulse');
      }, 1000);
    }
  },
  mounted() {
    axios.get('your-api-url')
      .then(response => {
        this.fullScript = response.data.fullScript;
        this.prompt = response.data.prompt;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
};
</script>

<style>
body {
  background-color: var(--bs-light);
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--bs-dark);
  }
}

.animate-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>