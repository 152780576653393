// src/services/socketService.js

import io from "socket.io-client";

class SocketService {
  constructor() {
    if (SocketService.instance) {
      return SocketService.instance;
    }

    this.socket = null;
    this.retryCount = 0;
    this.maxRetries = 5; // Maximum number of retry attempts
    this.retryDelay = 2000; // Delay between retries in milliseconds
    SocketService.instance = this;

    return this;
  }

  connect() {
    if (!this.socket) {
      this.socket = io("https://123avatars.com", {
        reconnection: true, // Enables reconnection
        reconnectionAttempts: this.maxRetries, // Limits the reconnection attempts
        reconnectionDelay: this.retryDelay, // Delay between retries
        timeout: 10000, // Time to wait before considering the connection failed
      });

      this.socket.on("connect", () => {
        console.log("Socket connected");
        this.retryCount = 0; // Reset retry count on successful connection
      });

      // Listen for disconnect events
      this.socket.on("disconnect", () => {
        console.log("Socket disconnected. Attempting to reconnect...");
        this.retryConnection();
      });
    }
  }

  retryConnection() {
    if (this.retryCount < this.maxRetries) {
      setTimeout(() => {
        this.retryCount++;
        console.log(`Retry attempt ${this.retryCount}`);
        this.connect();
      }, this.retryDelay);
    } else {
      console.log("Max retry attempts reached. Could not reconnect.");
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.retryCount = 0; // Reset retry count on manual disconnect
    }
  }

  on(event, handler) {
    if (this.socket) {
      this.socket.on(event, handler);
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  register(userId) {
    if (this.socket) {
      this.socket.emit("register", userId);
    }
  }
}

const instance = new SocketService();
// Object.freeze(instance);

export default instance;
