<template>
  <div class="fixed inset-0 bg-light overflow-y-auto h-full w-full d-flex align-items-center justify-content-center">
    <div class="relative p-5 border w-100 md:w-75 lg:w-50 xl:w-66 shadow-lg rounded bg-light dark:bg-dark text-dark dark:text-light">
      <button class="close" @click="closeModal">&times;</button>
      <div class="mt-3 text-center">
        <h3 class="text-lg font-medium">
          System Prompt
        </h3>
        <div class="mt-2 px-3 py-3">
          <textarea
            placeholder="Enter your prompt"
            class="form-control mb-2 growable"
            v-model="localPrompt"
            rows="6"
          ></textarea>
        </div>
        <div class="items-center px-4 py-3">
          <button
            id="ok-btn"
            class="btn btn-primary w-100"
            @click="saveData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    prompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localFullScript: this.fullScript,
      localPrompt: this.prompt
    };
  },
  methods: {
    saveData() {
      const data = {
        promptType: 'System-Prompt',
        script: this.localFullScript,
        prompt: this.localPrompt,
      };
      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Origin': 'http://localhost:8080',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('Data saved:', response.data);
        this.animateButton();
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check_circle",
          duration: 5000,
        });
        setTimeout(() => {
          this.$emit('close-modal');
        }, 3000);
      })
      .catch(error => {
        console.error('Error saving data:', error);
        this.$toasted.show("Error saving data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "error",
          duration: 5000,
        });
      });
    },
    animateButton() {
      const saveButton = document.getElementById('ok-btn');
      saveButton.classList.add('animate-pulse');
      setTimeout(() => {
        saveButton.classList.remove('animate-pulse');
      }, 1000);
    },
    closeModal() {
      this.$emit('close-modal');
    }
  },
  mounted() {
    // Uncomment the following lines if you need to fetch initial data
    // axios.get('your-api-url')
    //   .then(response => {
    //     this.localFullScript = response.data.fullScript;
    //     this.localPrompt = response.data.prompt;
    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error);
    //   });
  },
  watch: {
    fullScript(newVal) {
      this.localFullScript = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  }
};
</script>

<style>
body {
  background-color: var(--bs-light);
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--bs-dark);
  }
}

.animate-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
}

.growable {
  max-height: 60vh;
  overflow-y: auto;
  resize: vertical;
}
</style>