<template>
  <div class="container">
    <div class="header bg-primary text-white text-center p-4 mb-4 rounded">
      <h1>Add Avatar to Your Website</h1>
    </div>

    <div class="section bg-white p-4 mb-4 rounded shadow">
      <h2>HTML Code</h2>
      <div class="html-code p-3 rounded" contenteditable="true" ref="htmlCode">
        &lt;div&gt;<br>
        &nbsp;&nbsp;&lt;button class="btn btn-primary embedded-avatar-start-button"&gt;Talk to the AI Voice Avatar&lt;/button&gt;<br>
        &nbsp;&nbsp;&lt;div id="demo-avatar" class="mt-4"&gt;<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;img src="{{selectedDesign?.imgSrc}}" alt="Selected Design" style="width: 100px; height: 100px;"&gt;<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;script src="https://123avatars.com/uploads/twilio.min.js"&gt;&lt;/script&gt;<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;script src="https://123avatars.com/uploads/embedded-avatar.js"&gt;&lt;/script&gt;<br>
        &nbsp;&nbsp;&lt;/div&gt;<br>
        &lt;/div&gt;
      </div>
    </div>

    <div class="section bg-white p-4 mb-4 rounded shadow">
      <h2>Select a Design</h2>
      <div class="upload-section text-center mb-4">
        <h2>Upload Design</h2>
        <label class="file-upload btn btn-primary mx-2">
          Choose SVG File
          <input type="file" @change="uploadSVG" accept=".svg" hidden>
        </label>
        <label class="file-upload btn btn-primary mx-2">
          Choose GIF File
          <input type="file" @change="uploadGIF" accept=".gif" hidden>
        </label>
      </div>
      <div class="row">
        <div
          v-for="design in designs"
          :key="design.id"
          class="col-md-4 col-lg-3 mb-4"
        >
          <div
            class="design p-3 bg-white rounded shadow text-center position-relative"
            @click="selectDesign(design)"
            :class="{ selected: design.id === selectedDesign }"
          >
            <img :src="design.imgSrc" :alt="design.altText" class="img-fluid mb-3">
            <button class="btn btn-primary mb-2" @click.stop="selectDesign(design)">
              Choose Design
            </button>
            <button class="remove-button btn btn-danger position-absolute top-0 end-0 mt-1 me-1" @click.stop="removeDesign(design.id)">
              X
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="section avatar-demo text-center bg-white p-4 rounded shadow">
      <h2>Active Demo</h2>
      <button id="startConvoButton" class="btn btn-primary" @click="startConversation">Talk to the AI Voice Avatar</button>
      <div id="demo-avatar" class="mt-4"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userId: localStorage.getItem('userId') || 123011,
      selectedDesign: null,
      designs: []
    };
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://123avatars.com/uploads/twilio.min.js')
      document.head.appendChild(recaptchaScript)
  },
  created() {
    this.fetchDesigns();
  },
  methods: {
    initializeDevice() {
    //logDiv.classList.remove("hide");
    console.log("Initializing device");
    this.device = new Twilio.Device(this.token, {
      logLevel:1,
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      codecPreferences: ["opus", "pcmu"],
    });
    console.log("device: ", this.device); 

    this.addDeviceListeners(this.device);

    // Device must be registered in order to receive incoming calls
    this.device.register();

    this.makeOutgoingCall();
  },
    addDeviceListeners(device) {
    this.device.on("registered", function () {
      console.log("Twilio.Device Ready to make and receive calls!");
      //callControlsDiv.classList.remove("hide");
    });

    this.device.on("error", function (error) {
      console.log("Twilio.Device Error: " + error.message);
    });

    // this.device.on("incoming", handleIncomingCall);

    // this.device.audio.on("deviceChange", updateAllAudioDevices.bind(this.device));

    // Show audio selection UI if it is supported by the browser.
    if (this.device.audio.isOutputSelectionSupported) {
      //audioSelectionDiv.classList.remove("hide");
    }
  },
    async makeOutgoingCall() {
    var params = {
      // get the phone number to call from the DOM
      "From": "+19498764971",
      To: "+19498321096",
    };

    if (this.device) {
      console.log(`Attempting to call ${params.To} ...`);

      // Twilio.Device.connect() returns a Call object
      const call = await this.device.connect({ params });

      setTimeout(() => {
        let button = document.getElementById('startConvoButton');
        button.onclick = () => {
          console.log("Hanging up ...");
          call.disconnect();
        };
      }, 1000);

    } else {
      console.log("Unable to make call.");
    }
    
  },
    async fetchDesigns() {
      try {
        const response = await axios.get('https://123avatars.com/v2/designs', {
          params: { userId: this.userId }
        });
        this.designs = JSON.parse(response.data[0].designs);
        this.selectedDesign = this.designs.find((design) => design.id == response.data[0].selected_design_id);
        setTimeout(() => {
          const demoAvatar = document.getElementById('demo-avatar');
          demoAvatar.innerHTML = `<img src="${this.selectedDesign?.imgSrc}" alt="Selected Design" style="width: 100px; height: 100px;">`;
        }, 1000);
      } catch (error) {
        console.error('Error fetching designs:', error);
      }
    },
    async selectDesign(design) {
      this.selectedDesign = this.designs.find((des) => design.id == des.id);
      const htmlCodeBox = this.$refs.htmlCode;
      const baseHtml = `&lt;div id="avatar-widget" class="design-${design.id}"&gt;&lt;/div&gt;`;
      const scriptTag = '&lt;script src="your-script.js"&gt;&lt;/script&gt;';
      //htmlCodeBox.innerHTML = `${baseHtml}\n${scriptTag}`;

      const demoAvatar = document.getElementById('demo-avatar');
      demoAvatar.innerHTML = `<img src="${this.selectedDesign?.imgSrc}" alt="Selected Design" style="width: 100px; height: 100px;">`;

      try {
        await axios.post('https://123avatars.com/v2/designs', {
          userId: this.userId,
          selected_design_id: this.selectedDesign.id,
          designs: JSON.stringify(this.designs)
        });
      } catch (error) {
        console.error('Error updating selected design:', error);
      }
    },
    async startConversation() {
      let response = await axios.post('https://123avatars.com/v2/embedded-token', {
        userId: localStorage.getItem('userId')
      });
      if(response.status == 200) {
        this.token = response.data.token;
        this.initializeDevice();
        
      } else {
        console.error("error:", response.data);
      }
      const demoAvatar = document.getElementById('demo-avatar');
      //demoAvatar.innerHTML = '<iframe src="your-voice-avatar-url" width="300" height="400"></iframe>';
    },
    uploadSVG(event) {
      const file = event.target.files[0];
      if (file && file.type === 'image/svg+xml') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const svgContent = e.target.result;
          const newDesign = {
            id: this.designs.length + 1,
            imgSrc: svgContent,
            altText: 'Custom SVG Design',
          };
          // this.designs.push(newDesign);

          try {
            // Create a new FormData object
            const formData = new FormData();

            // Append the userId and file to the form data
            formData.append('userId', this.userId);
            formData.append('file', gifContent);
            formData.append('type', 'gif');

            try {
              // Send the form data using axios
              let response = await axios.post('https://123avatars.com/v2/upload-design', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              let url = response.data.url;
              newDesign.imgSrc = url;
              this.designs.push(newDesign);
              await axios.post('https://123avatars.com/v2/designs', {
              userId: this.userId,
              selected_design_id: this.selectedDesign.id,
              designs: JSON.stringify(this.designs)
            });
            } catch (error) {
              console.error('Error uploading file:', error);
            }
          } catch (error) {
            console.error('Error uploading GIF design:', error);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid SVG file.');
      }
    },
    uploadGIF(event) {
      const file = event.target.files[0];
      if (file && file.type === 'image/gif') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const gifContent = e.target.result;
          const newDesign = {
            id: this.designs.length + 1,
            imgSrc: gifContent,
            altText: 'Custom GIF Design',
          };
          // this.designs.push(newDesign);

          try {
            // Create a new FormData object
            const formData = new FormData();

            // Append the userId and file to the form data
            formData.append('userId', this.userId);
            formData.append('file', gifContent);
            formData.append('type', 'gif');

            try {
              // Send the form data using axios
              let response = await axios.post('https://123avatars.com/v2/upload-design', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              let url = response.data.url;
              newDesign.imgSrc = url;
              this.designs.push(newDesign);
              await axios.post('https://123avatars.com/v2/designs', {
              userId: this.userId,
              selected_design_id: this.selectedDesign.id,
              designs: JSON.stringify(this.designs)
            });
            } catch (error) {
              console.error('Error uploading file:', error);
            }
          } catch (error) {
            console.error('Error uploading GIF design:', error);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid GIF file.');
      }
    },
    async removeDesign(id) {
      this.designs = this.designs.filter((design) => design.id !== id);
      if (this.selectedDesign?.id === id) {
        this.selectedDesign = null;
        document.getElementById('demo-avatar').innerHTML = '';
        this.$refs.htmlCode.innerHTML = '&lt;div&gt;<br>&nbsp;&nbsp;&lt;button class="btn btn-primary embedded-avatar-start-button"&gt;Talk to the AI Voice Avatar&lt;/button&gt;<br>&nbsp;&nbsp;&lt;div id="demo-avatar" class="mt-4"&gt;<br>&nbsp;&nbsp;&nbsp;&nbsp;&lt;img src="{{selectedDesign?.imgSrc}}" alt="Selected Design" style="width: 100px; height: 100px;"&gt;<br>&nbsp;&nbsp;&nbsp;&nbsp;&lt;script src="https://123avatars.com/uploads/twilio.min.js"&gt;&lt;/script&gt;<br>&nbsp;&nbsp;&nbsp;&nbsp;&lt;script src="https://123avatars.com/uploads/embedded-avatar.js"&gt;&lt;/script&gt;<br>&nbsp;&nbsp;&lt;/div&gt;<br>&lt;/div&gt;';
      }

      try {
        await axios.post('https://123avatars.com/v2/designs', {
          userId: this.userId,
          selected_design_id: this.selectedDesign.id,
          designs: JSON.stringify(this.designs)
        });
      } catch (error) {
        console.error('Error removing design:', error);
      }
    },
  },
};
</script>

<style scoped>
.header h1 {
  margin: 0;
}

.section h2 {
  margin-top: 0;
}

.html-code {
  font-family: 'Courier New', Courier, monospace;
  height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.design.selected {
  border: 2px solid #4a90e2;
}

.remove-button {
  padding: 5px 10px;
  border-radius: 50%;
}

.avatar-demo button {
  margin-top: 10px;
}
</style>