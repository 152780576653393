<template>
    <div class="container onboarding-container">
      <h2 class="text-primary">New Hire Approval</h2>
      <p>Welcome to the team! To complete your onboarding process, please follow the steps below. This will only take about 2-3 minutes.</p>
      <ul>
        <li>e-Sign the Independent Contractor Agreement</li>
        <li>e-Sign your W9 form</li>
        <li>Upload two forms of ID</li>
      </ul>
      <button class="btn btn-primary btn-block" @click="startOnboarding">Start Onboarding</button>
      <p class="text-center text-info">It's easier to do this from your cell phone!</p>
      <p>Or, you can send the link to your cell phone to complete it there:</p>
      <input type="text" id="phoneNumber" v-model="phoneNumber" placeholder="Enter your cell phone number" class="form-control mb-2">
      <button class="btn btn-primary btn-block" @click="sendLink">Send Link to Phone</button>
      <p>Once you have completed your onboarding process, the support team will review and approve your paperwork within 24 hours.</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        phoneNumber: ''
      };
    },
    methods: {
      startOnboarding() {
        window.open('https://123websites.typeform.com/to/PkgOQWnC', '_blank');
      },
      async sendLink() {
        if (this.phoneNumber) {
          try {
            const response = await axios.post('https://123avatars.com/v2/send-link', {
              phoneNumber: this.phoneNumber
            });
            alert(`Link sent to ${this.phoneNumber}`);
            console.log('Response:', response.data);
          } catch (error) {
            console.error('Error sending link:', error);
            alert('Failed to send link. Please try again later.');
          }
        } else {
          alert('Please enter a valid phone number.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  body {
      font-family: 'Arial', sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f4f4f4;
      color: #333;
  }

  .container {
      max-width: 600px;
      margin: 50px auto;
      background: #ffffff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-top: 10px solid #6A1B9A;
      text-align: center;
  }

  h2 {
      font-size: 24px;
      color: #6A1B9A;
      margin-bottom: 20px;
  }

  p {
      font-size: 16px;
      color: #666666;
      line-height: 1.6;
      margin-bottom: 20px;
  }

  ul {
      list-style: none;
      padding: 0;
      margin-bottom: 20px;
  }

  ul li {
      font-size: 16px;
      color: #666666;
      margin-bottom: 10px;
  }

  button {
      background-color: #6A1B9A;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 25px;
      cursor: pointer;
      margin-right: 10px;
      transition: background-color 0.3s;
  }

  button:hover {
      background-color: #5A157D;
  }

  input[type="text"] {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 25px;
      width: calc(100% - 22px);
      margin-bottom: 20px;
  }

  .note {
      margin-top: 10px;
      font-size: 14px;
      color: #6A1B9A;
  }
</style>