<template>
  <div>
    <div class="flex items-center gap-2" style="display: flex !important; align-items: center; gap: 0.5rem; padding: 10px;">
      <span class="font-bold text-1xl">Website:</span>
      <div @click="toggleFilterWebsite()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth === true ? true : websiteYes" :size="24" class="checkbox-checked"
          :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">Yes</label>
      <div @click="toggleFilterWebsite()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth === true ? true : !websiteYes" :size="24" class="checkbox-checked"
          :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">No</label>
      <div @click="toggleFilterBoth()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">Both</label>
    </div>
    <div class="table-wrapper">
      <div v-if="fetchingRecords" style="font-size: 1rem; padding: 20px; font-weight: bold;">
        <CountdownTimer></CountdownTimer>
      </div>
      <table class="table call-backs-table" aria-controls="tagstable" v-if="!fetchingRecords">
        <thead>
          <tr>
            <td></td>
            <th style="width: 40%;">Industry</th>
            <th style="width: 20;">Leads</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="(row, index) in industriesData" :key="row.key" :data-keyword-id="row.key">
            <td>
              <div @click="toggleSelectIndustry(index)">
                <checkSVG v-if="row.selected" :size="24" class="checkbox-checked" :fill="roleColor()"
                  :stroke="roleColor()" />
                <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
              </div>
            </td>
            <td>{{ row.industry || '-' }}</td>
            <td>{{ formatNumberString(row.leads) || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottom-input">

      <div class="per-page">
        <div class="select">
          Per Page
          <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchLeadsIndustries">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>

      <template>
        <div class="pagin">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage" aria-controls="tagstable">
            <template #first-text>
              <PaginleftdoubleSVG />
            </template>
            <template #prev-text>
              <PaginleftSVG />
            </template>
            <template #next-text>
              <PaginrightSVG />
            </template>
            <template #last-text>
              <PaginrightdoubleSVG />
            </template>
          </b-pagination>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import checkSVG from '../SVG-icons/checkedSVG.vue';
import unCheckedSVG from '../SVG-icons/unCheckedSVG.vue';
import AuthService from '../../_services/AuthService';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import CountdownTimer from './CountdownTimer.vue';

export default {
  components: {
    checkSVG,
    unCheckedSVG,
    PaginleftSVG,
    PaginrightSVG,
    PaginleftdoubleSVG,
    PaginrightdoubleSVG,
    CountdownTimer
  },
  data() {
    return {
      websiteYes: true,
      websiteBoth: false,
      role: '',
      userId: '',
      industriesData: [], // Renamed to avoid confusion
      isInitialized: false,
      rowsPerPage: 50,
      currentPage: 1,
      totalRows: 0,
      fetchingRecords: false
    }
  },
  watch: {
    selectedLeadsSum(newSum) {
      this.$emit('selection-sum-changed', {
        totalLeads: newSum,
        selectedIndustries: this.selectedIndustries
      });
    },
    currentPage() {
      this.fetchLeadsIndustries();
    },
  },
  computed: {
    selectedLeadsSum() {
      return this.industriesData
        .filter(row => row.selected)
        .reduce((sum, row) => sum + row.leads, 0);
    },
    selectedIndustries() {
      return this.industriesData.filter(row => row.selected);
    }
  },
  methods: {
    formatNumberString(number) {
      if (number === null || number === undefined || number === '') return '0';

      try {
        const num = typeof number === 'string' ? Number(number.replace(/,/g, '')) : Number(number);
        if (isNaN(num)) return '0';

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch (error) {
        console.error('Error formatting number:', error);
        return '0';
      }
    },
    toggleSelectIndustry(index) {
      // Use Vue.set to ensure reactivity when modifying nested objects
      this.industriesData.forEach((industry, i) => {
        this.$set(industry, 'selected', i === index && !industry.selected);
      });
    },
    async fetchLeadsIndustries() {     
      try {
        this.fetchingRecords = true;
        let withWebsiteInt = 2;
        if (this.websiteYes === true) {
          withWebsiteInt = 1;
        } else if (this.websiteYes === false) {
          withWebsiteInt = 0;
        }

        if (this.websiteBoth) {
          withWebsiteInt = 2;
        }

        const target = `https://123avatars.com/v2/sms-broadcast/industries?withWebsite=${withWebsiteInt}&sub=${this.userId}&page=${this.currentPage}&pageSize=${this.rowsPerPage}`;
        const response = await axios.get(target);

        // Preserve existing selections when updating data
        const newData = response.data.data.map(item => {
          const existingItem = this.industriesData.find(existing => existing.key === item.key);
          return {
            ...item,
            selected: existingItem ? existingItem.selected : false
          };
        });

        this.industriesData = newData;
        this.totalRows = response.data.pagination.totalItems;
        this.fetchingRecords = false;
      } catch (error) {
        console.error('Error fetching industries:', error);
      }
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    toggleFilterWebsite() {
      this.websiteYes = !this.websiteYes;
      this.websiteBoth = false;
      this.industriesData = [];
      this.fetchLeadsIndustries();
    },
    toggleFilterBoth() {
      this.websiteBoth = !this.websiteBoth;
      this.websiteYes = true;
      this.industriesData = [];
      this.fetchLeadsIndustries();
    },
    async initUsers() {
      if (!this.isInitialized) {
        this.role = await AuthService.getUserRole();
        if (this.role === null) {
          this.role = 'Operators';
        }
        this.userId = await AuthService.getCognitoSub();
        this.isInitialized = true;
        await this.fetchLeadsIndustries();
      }
    }
  },
  mounted() {
    this.initUsers();
  }
}
</script>

<style scoped>
.checkbox-checked,
.checkbox-unchecked {
  cursor: pointer;
}
</style>