<template>
  <div class="fixed inset-0 bg-light overflow-y-auto h-full w-full d-flex align-items-center justify-content-center">
    <div class="relative p-5 border w-100 md:w-75 lg:w-50 xl:w-66 shadow-lg rounded bg-light">
      <button class="close-button" @click="$emit('close-modal')">&times;</button>
      <div class="mt-3 text-center">
        <h3 class="text-lg font-medium text-dark">
          Transition Questions to the Closing Script
        </h3>
        <div class="mt-2 px-7 py-3 d-flex flex-column">
          <textarea
            placeholder="Enter your prompt"
            class="mt-4 mb-2 form-control large-textarea"
            v-model="localPrompt"
          ></textarea>
          <textarea
            placeholder="Transition Questions to the Closing Script"
            class="mt-2 mb-4 form-control large-textarea"
            v-model="localTransitionQuestions"
          ></textarea>
        </div>
        <div class="items-center px-4 py-3">
          <button
            id="ok-btn"
            class="btn btn-primary w-100"
            @click="saveData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    transitionQuestions: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localTransitionQuestions: this.transitionQuestions,
      localPrompt: this.prompt
    };
  },
  methods: {
    saveData() {
      let data = {
        promptType: "Script-Transition",
        script: this.localTransitionQuestions,
        prompt: this.localPrompt
      };
      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Origin': 'http://localhost:8080',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 5000
        });
        setTimeout(() => {
          this.$emit('close-modal');
        }, 3000);
      }).catch(error => {
        this.$toasted.show("Error saving data", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "error",
          duration: 10000
        });
      });
    },
    fetchData() {
      this.$http.get('/api/data')
        .then(response => {
          this.localTransitionQuestions = response.data.transitionQuestions;
          this.localPrompt = response.data.prompt;
        })
        .catch(error => {
          this.$toasted.show("Error fetching data", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "error",
            duration: 10000
          });
        });
    }
  },
  watch: {
    transitionQuestions(newVal) {
      this.localTransitionQuestions = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  },
  mounted() {
    // this.fetchData();
  }
};
</script>

<style>
.bg-light {
  background-color: #f8f9fa !important;
}
.text-dark {
  color: #343a40 !important;
}
.form-control {
  resize: vertical;
}
.large-textarea {
  height: 200px !important;
  overflow-y: auto;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>