<template>
  <div id="schedules">
    <div class="menu-navigation">
      <span 
        :key="0"
        @click="selectSchedule(null)"
        :class="{ 'active': selectedScheduleId === null }"
        class="menu-item"
      >All
      </span>
      <span 
        v-for="schedule in schedules" 
        :key="schedule.id"
        @click="selectSchedule(schedule.id)"
        :class="{ 'active': selectedScheduleId === schedule.id }"
        class="menu-item"
      >
        {{ schedule.name }}
        <span v-if="!isLastSchedule(schedule)" class="separator"></span>
      </span>
    </div>
    <UIBox classbox="hello" v-for="schedule in schedules" :key="schedule.id" v-show="selectedScheduleId === null || selectedScheduleId === schedule.id">
      <template v-slot:title>
        <div class="header-container">
          <div class="title-section">
            <span class="bold"><b>Schedule</b> | {{ schedule.name }}</span>
          </div>
        </div>
      </template>
      <div class="button-wrapper">
        <button class="btn solid save-button" @click="updateSchedule()">
          Save Changes
        </button>
      </div>
      <template>
        <div class="container-body" id="reportContainer">
          <div class="container-body" id="reportContainer">
            <div class="table-fullwidth">
              <table class="table schedules-table" aria-controls="tagstable">
                <thead>
                  <tr>
                    <th style="width: 16%;">Active</th>
                    <th style="width: 16%;">Day</th>
                    <th style="width: 16%;">Open</th>
                    <th style="width: 16%;">Closing</th>
                    <th style="width: 16%;">Total Time Open</th>
                    <th style="width: 16%;">24 Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="day in schedule.days" :key="day.name">
                    <td @click="toggleActive(schedule.id, day)">
                      <CheckedSVG v-if="day.isActive" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                      <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                    </td>
                    <td>
                      {{ day.name }}
                    </td>
                    <td class="time-input-container">
                      <input 
                        :value="formatTimeWithPeriod(day.openTime)"
                        type="text"
                        placeholder="Open Time"
                        class="custom-input"
                        @click="day.isActive && toggleTimeDropdown(schedule.id, day, 'open')"
                        :class="{'inactive': !day.isActive}"
                        readonly 
                        />
                      <div v-if="activeDropdown.scheduleId === schedule.id &&
                        activeDropdown.day === day.name &&
                        activeDropdown.type === 'open'" class="time-dropdown">
                        <div class="time-selectors">
                          <select v-model="selectedTime.hours" @change="updateTime(schedule.id, day, 'open')">
                            <option v-for="hour in hours" :key="hour" :value="hour">
                              {{ hour.toString().padStart(2, '0') }}
                            </option>
                          </select>
                          <span>:</span>
                          <select v-model="selectedTime.minutes" @change="updateTime(schedule.id, day, 'open')">
                            <option v-for="minute in minutes" :key="minute" :value="minute">
                              {{ minute.toString().padStart(2, '0') }}
                            </option>
                          </select>
                          <select v-model="selectedTime.period" @change="updateTime(schedule.id, day, 'open')">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td class="time-input-container">
                      <input 
                        :value="formatTimeWithPeriod(day.closeTime)"
                        type="text"
                        placeholder="Close Time"
                        class="custom-input"
                        @click="day.isActive && toggleTimeDropdown(schedule.id, day, 'close')"
                        :class="{'inactive': !day.isActive}"
                        readonly 
                        />
                      <div v-if="activeDropdown.scheduleId === schedule.id &&
                        activeDropdown.day === day.name &&
                        activeDropdown.type === 'close'" class="time-dropdown">
                        <div class="time-selectors">
                          <select v-model="selectedTime.hours" @change="updateTime(schedule.id, day, 'close')">
                            <option v-for="hour in hours" :key="hour" :value="hour">
                              {{ hour.toString().padStart(2, '0') }}
                            </option>
                          </select>
                          <span>:</span>
                          <select v-model="selectedTime.minutes" @change="updateTime(schedule.id, day, 'close')">
                            <option v-for="minute in minutes" :key="minute" :value="minute">
                              {{ minute.toString().padStart(2, '0') }}
                            </option>
                          </select>
                          <select v-model="selectedTime.period" @change="updateTime(schedule.id, day, 'close')">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input type="text" :value="day.totalTime" />
                    </td>
                    <td @click="toggle24Hours(schedule.id, day)">
                      <CheckedSVG v-if="day.is24Hours" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                      <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                    </td>
                  </tr>
                  <tr>
                    <td class="no-bottom-border">
                      <input
                        v-if="schedule.name === 'Inbound Calls'"
                        @blur="formatPhoneNumber(schedule)"
                        v-model="schedule.callForwardingOutsideSchedule" type="text" placeholder="Call Forwarding Outside Of Schedule" class="custom-input-light" />
                    </td>
                    <td class="no-bottom-border"></td>
                    <td class="no-bottom-border"></td>
                    <td class="no-bottom-border"></td>
                    <td class="no-bottom-border">Set to 24/7</td>
                    <td class="no-bottom-border" @click="toggleAll24Hours(schedule)">
                      <CheckedSVG :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" v-if="isAll24Hours(schedule)" />
                      <UncheckedSVG :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" v-else />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </UIBox>
    <b-modal v-model="notificationModal" title="Schedules Saved" hide-footer>
      <div class="form-container">
        <div class="row notification-text">
          Schedules Saved.
        </div>
        <div class="button-group">
          <button class="btn solid btn-save" type="button" @click="notificationModal = false">OK</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import UIBox from '../components/uibox.vue';
import AuthService from '../_services/AuthService.js';
import CheckedSVG from '../components/SVG-icons/checkedSVG.vue';
import UncheckedSVG from '../components/SVG-icons/unCheckedSVG.vue';

export default {
  components: {
    UIBox,
    UncheckedSVG,
    CheckedSVG,
  },
  data() {
    return {
      role: '',
      userId: '',
      schedules: [],
      activeDropdown: {
        scheduleId: null,
        day: null,
        type: null // 'open' or 'close'
      },
      // selectedTime: {
      //   hours: 0,
      //   minutes: 0
      // },
      // hours: Array.from({ length: 24 }, (_, i) => i),
      // minutes: Array.from({ length: 60 }, (_, i) => i),
      hours: Array.from({ length: 12 }, (_, i) => i + 1), // 1-12 instead of 0-23
      minutes: Array.from({ length: 60 }, (_, i) => i),
      selectedTime: {
        hours: 1,
        minutes: 0,
        period: 'AM'
      },
      notificationModal: false,
      selectedScheduleId: null,
    }
  },
  methods: {
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C'
      };
      return colors[this.role.toLowerCase()] || '#46A57C';
    },
    formatTimeWithPeriod(time) {
      if (!time) return '';
      const [hoursStr, minutes] = time.split(':');
      let hours = parseInt(hoursStr);
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      if (hours === 0) hours = '00';
      return `${hours}:${minutes} ${period}`;
    },
    selectSchedule(scheduleId) {
      this.selectedScheduleId = scheduleId;
    },

    isLastSchedule(schedule) {
      return this.schedules.indexOf(schedule) === this.schedules.length - 1;
    },
    formatPhoneNumber(schedule) {
      let phoneNumber = schedule.callForwardingOutsideSchedule;
      if (phoneNumber && phoneNumber.trim().length > 0) {
        phoneNumber = phoneNumber.toString();
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
        }
        const areaCode = phoneNumber.slice(0, 3);
        const centralOfficeCode = phoneNumber.slice(3, 6);
        const stationCode = phoneNumber.slice(6, 10);
        const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        schedule.callForwardingOutsideSchedule = formattedPhoneNumber;
      }
      return '';
    },
    isAll24Hours(schedule) {
      return schedule.days.every(day => day.is24Hours);
    },

    toggleAll24Hours(schedule) {
      const shouldBeChecked = !this.isAll24Hours(schedule);
      schedule.days.forEach(day => {
        day.is24Hours = shouldBeChecked;
        if (shouldBeChecked) {
          day.openTime = "00:00";
          day.closeTime = "23:59"; 
          day.totalTime = "24h 0m";
          day.isActive = true;
        }
      });
    },
    async fetchSchedules() {
      const userId = localStorage.getItem('userId');
      let target = `https://123avatars.com/v2/schedules?userId=${userId}`;
      const response = await axios.get(target);
      const { schedules } = response.data;
      this.schedules = JSON.parse(schedules);
    },
    toggleActive(scheduleId, day) {
      const schedule = this.schedules.find(s => s.id === scheduleId);
      if (schedule) {
        const dayToUpdate = schedule.days.find(d => d.name === day.name);
        if (dayToUpdate) {
          // dayToUpdate.isActive = !dayToUpdate.isActive;
          // if (!dayToUpdate.isActive) {
          //   dayToUpdate.openTime = "00:00";
          //   dayToUpdate.closeTime = "00:00";
          //   dayToUpdate.totalTime = "0h 0m";
          // }
          dayToUpdate.isActive = !dayToUpdate.isActive;
          dayToUpdate.openTime = "00:00";
          dayToUpdate.closeTime = "00:00";
          dayToUpdate.totalTime = "0h 0m";
        }
      }
    },
    toggle24Hours(scheduleId, day) {
      const schedule = this.schedules.find(s => s.id === scheduleId);
      if (schedule) {
        const dayToUpdate = schedule.days.find(d => d.name === day.name);
        if (dayToUpdate) {
          dayToUpdate.is24Hours = !dayToUpdate.is24Hours;
          if (dayToUpdate.is24Hours) {
            dayToUpdate.openTime = "00:00";
            dayToUpdate.closeTime = "23:59";
            dayToUpdate.totalTime = "24h 0m";
            dayToUpdate.isActive = true;
          }
        }
      }
    },
    async updateSchedule() {
      try {
        const userId = localStorage.getItem('userId');
        const target = `https://123avatars.com/v2/schedules`;
        await axios.post(target, {
          userId,
          schedules: this.schedules
        });
        this.notificationModal = true;
      } catch (error) {
        console.error('Error updating schedule:', error);
        // You might want to add error handling here
      }
    },
    toggleTimeDropdown(scheduleId, day, type) {
      // Close dropdown if clicking the same input
      if (this.activeDropdown.scheduleId === scheduleId &&
        this.activeDropdown.day === day.name &&
        this.activeDropdown.type === type) {
        this.activeDropdown = { scheduleId: null, day: null, type: null };
        return;
      }

      // Set initial selected time based on current value
      const timeStr = type === 'open' ? day.openTime : day.closeTime;
      const [hours, minutes] = timeStr.split(':').map(Number);
      this.selectedTime = {
        hours: hours % 12 || 12,
        minutes: minutes || 0,
        period: hours >= 12 ? 'PM' : 'AM'
      };

      // Open new dropdown
      this.activeDropdown = {
        scheduleId,
        day: day.name,
        type
      };
    },

    updateTime(scheduleId, day, type) {
      let hours = this.selectedTime.hours;
      if (this.selectedTime.period === 'PM' && hours !== 12) {
        hours += 12;
      } else if (this.selectedTime.period === 'AM' && hours === 12) {
        hours = 0;
      }
      
      const timeString = `${hours.toString().padStart(2, '0')}:${this.selectedTime.minutes.toString().padStart(2, '0')}`;
      
      const schedule = this.schedules.find(s => s.id === scheduleId);
      if (schedule) {
        const dayToUpdate = schedule.days.find(d => d.name === day.name);
        if (dayToUpdate) {
          if (type === 'open') {
            dayToUpdate.openTime = timeString;
          } else {
            dayToUpdate.closeTime = timeString;
          }
          dayToUpdate.is24Hours = false;
          dayToUpdate.totalTime = this.calculateTotalTime(dayToUpdate.openTime, dayToUpdate.closeTime);
        }
      }
    },

    // Add click outside handler to close dropdown
    handleClickOutside(event) {
      const dropdowns = document.querySelectorAll('.time-dropdown');
      const inputs = document.querySelectorAll('.time-input-container');

      let clickedInside = false;
      [...dropdowns, ...inputs].forEach(el => {
        if (el.contains(event.target)) {
          clickedInside = true;
        }
      });

      if (!clickedInside && this.activeDropdown.scheduleId) {
        // Get the current schedule and day before clearing the dropdown
        const schedule = this.schedules.find(s => s.id === this.activeDropdown.scheduleId);
        if (schedule) {
          const day = schedule.days.find(d => d.name === this.activeDropdown.day);
          if (day) {
            // Calculate and update total time
            day.totalTime = this.calculateTotalTime(day.openTime, day.closeTime);
            //this.updateSchedule(schedule.id, schedule);
          }
        }

        this.activeDropdown = { scheduleId: null, day: null, type: null };
      }
    },
    calculateTotalTime(openTime, closeTime) {
      if (!openTime || !closeTime) return '';

      // Parse the times
      const [openHours, openMinutes] = openTime.split(':').map(Number);
      const [closeHours, closeMinutes] = closeTime.split(':').map(Number);

      // Convert to minutes since midnight
      let openInMinutes = (openHours * 60) + openMinutes;
      let closeInMinutes = (closeHours * 60) + closeMinutes;

      // Handle cases where closing time is on the next day (e.g., 01:00)
      if (closeInMinutes < openInMinutes) {
        closeInMinutes += 24 * 60; // Add 24 hours worth of minutes
      }

      // Calculate difference in minutes
      const diffMinutes = closeInMinutes - openInMinutes;

      // Convert back to hours and minutes
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;

      return `${hours}h ${minutes}m`;
    },
  },
  async mounted() {
    await this.initUser();
    this.fetchSchedules();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
:slotted([slot="title"]) {
  width: 100% !important;
  flex: 1 1 auto !important;
}

#reportContainer .table {
  width: 100%;
  table-layout: fixed;
}

#reportContainer th,
#reportContainer td {
  text-align: left;
}

#reportContainer h3 {
  background-color: #B6E7C3;
}

.checkbox-checked,
.checkbox-unchecked {
  cursor: pointer;
}

.custom-input, .custom-input-light {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.custom-input-light {
  font-weight: normal;
  width: 300px;
}

.table-fullwidth {
  overflow-x: hidden;
}

.time-input-container {
  position: relative;
}

.time-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.time-selectors {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.time-selectors select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

input[readonly] {
  background-color: white;
  cursor: pointer;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100;
  position: relative;
  box-sizing: border-box;
}

.title-section {
  flex: 1;
}

.button-wrapper {
  margin-left: auto;
  margin-top: -88px;
}

.save-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
}

:deep(.title-slot) {
  width: 100%;
  flex: 1 1 auto;
}

.no-bottom-border {
  border-bottom-style: none;
  text-align: right !important;
  font-weight: bold;
}

.form-container {
  width: 100%;
}

.notification-text {
  text-align: center;
  font-size: 1.5em;
  padding: 10px;
}

.btn-save {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 6px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.menu-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.menu-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.menu-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  position: relative;
  color: #333;
  font-weight: 500;
}

.menu-item.active {
  font-weight: bold;
}

.separator {
  margin: 0 0.5rem;
  color: #333;
}

/* Active state */
tr td .custom-input {
  border-color: #ddd;
}

tr:has(.checkbox-checked) td .custom-input {
  border-color: black;
}
</style>