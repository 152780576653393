<template>
  <div>
    <div>
      <span class="font-bold text-1xl">Summary:</span>
      <div class="summary-content">
        <div>Selected Industry: {{ selectedIndustries[0].industry }}</div>
        <div>Total Leads to Send: {{ totalLeads }}</div>
        <div>Selected Message: {{ selectedPremadeMessage?.message || 'None' }}</div>
      </div>
    </div>
    <div>
      <button class="btn solid" @click="sendBroadcast">Send Broadcast</button>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import AuthService from '../../_services/AuthService.js';

export default {
  data() {
    return {
      userId: '',
      isBroadcasting: false,
    }
  },
  methods: {
    async sendBroadcast() {
      if (this.isBroadcasting) return;

      this.isBroadcasting = true;

      const selectedIndustriesToSave = this.selectedIndustries
        .filter(item => item.selected)
        .map(item => item.industry)
        .join(', ');

      console.log('selectedIndustries', JSON.stringify(this.selectedIndustries));
      console.log('totalLeads', JSON.stringify(this.totalLeads));
      console.log('selectedPremadeMessage', JSON.stringify(this.selectedPremadeMessage));

      try {
        const result = await axios.post('https://123avatars.com/v2/sms-broadcast', {
          sub: this.userId,
          industries: selectedIndustriesToSave,
          industryId: this.selectedIndustries[0].key,
          leadsToSend: this.totalLeads,
          message: this.selectedPremadeMessage.message
        });

        // this.$router.push('/broadcast-sent');

        if (result.data.status) {
          this.$emit('broadcast-sent');
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        } else {
          this.$toasted.show(result.data.message, {
            theme: "outline",
            position: "top-right",
            type: "error",
            icon: "close",
            duration: 2000,
          });
        }
      } catch (error) {
        console.error('Error sending broadcast:', error);
      } finally {
        this.isBroadcasting = false;
      }
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    }
  },
  props: {
    selectedIndustries: {
      type: Array,
      required: false,
      default: () => ([])
    },
    totalLeads: {
      type: Number,
      required: true,
      default: 0
    },
    selectedPremadeMessage: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  async mounted() {
    await this.initUser();
  }
}
</script>