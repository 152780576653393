<!-- <template style="width:100%">
  <div>
    <Breadcrumbs main="Project" title="Reports" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Daily Tracking Report
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card" style="padding-left:1%;">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-1">
                                  <label for="from">From:</label>
                                  <datepicker
                                    input-class="datepicker-here form-control digits"
                                    :format="format"
                                    v-model="fromDate"
                                  ></datepicker>
                              </div>
                              <div class="col-1">
                                  <label for="from">To:</label>
                                  <datepicker
                                    input-class="datepicker-here form-control digits"
                                    :format="format"
                                    v-model="toDate"
                                  ></datepicker>
                              </div>
                              <div class="col-1">
                                <label class="col-form-label m-r-10"
                                      >CSP Filter</label
                                    >
                                <multiselect
                                  v-model="selectedCSP"
                                  placeholder="Select CSPs"
                                  label="name"
                                  track-by="id"
                                  :options="CSPs"
                                  :multiple="false"
                                ></multiselect>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >All Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="allStats"
                                      @click="toggleStats('ALL')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >Warm Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="warmStats"
                                      @click="toggleStats('WARM')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >Cold Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="coldStats"
                                      @click="toggleStats('COLD')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br>
                            <div style="overflow-x: scroll; padding-left:2%;">
                              <div class="row">
                              <table class="table" id="basic-1" role="grid" aria-describedby="basic-1_info">
                                    <thead>
                                      <tr style="font-size:12px;" role="row">
                                        <th class="sorting_desc " tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 120px;" aria-sort="ascending">Date</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;" aria-sort="ascending">CSP</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;" aria-sort="ascending">Campaign</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Day</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Sent to CSP</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Spam</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Spam %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Messages Delivered</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Delivery %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Link Click %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Clicks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Landing Page %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Sales</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Bookings</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Messages Per Sale</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Spend</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Cost Per Delivered</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Cost Per Click</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="Goals Acheived: activate to sort column ascending">Cost Per Sale</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Calls</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Voicemail</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Inbound</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Caller ID Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Voicemail Inbound</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Scheduled Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Completed Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Transfer to Human</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Phone Call Links</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Two</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Five</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Ten</th>
                                      </tr>
                                    </thead>
                                    <tbody id="daily-tracking-table" style="font-size: 16px;">
                                      <tr role="row" class="card-body" v-for="(report,index) in reports" :key="report.id" :class="{ 'odd-row-bg': index % 2 !== 0 }" style="padding:10px;">
                                        <td style="font-size:14px;">
                                          {{ report.date | date || '' }} 
                                        </td>
                                        <td>
                                          {{ report.csp || ''}}
                                        </td>
                                        <td>
                                          {{ report.csp_name || ''}}
                                        </td>
                                        <td>
                                          {{ report.day || '' }}
                                        </td>
                                        <td>
                                          {{ report.sent_to_csp || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ report.spam || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.spam_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ report.messages_delivered | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.delivery_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td id="total-leads-17">
                                          {{ parseFloat(report.link_click_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ report.clicks | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.landing_page_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ report.sales | comma }}
                                        </td>
                                        <td>
                                          {{ report.bookings | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.messages_per_sale).toFixed(1) | comma }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(report.total_spend).toFixed(2) }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(report.cost_per_delivered_message).toFixed(3) }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(report.cost_per_click).toFixed(2) }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(report.cost_per_sale).toFixed(2) }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_calls) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_voicemail) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_inbound) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.caller_id_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.voicemail_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.scheduled_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.completed_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.transfer_to_human) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.phone_call_links) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_over_two) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_over_five) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(report.total_over_ten) | comma }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                            </div>
                            <br>
                            <div class="row">
                              <table class="table" id="basic-1" role="grid" aria-describedby="basic-1_info">
                                    <thead>
                                      <tr style="font-size:12px;" role="row">
                                        <th class="sorting_desc " tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 120px; visibility: hidden;" aria-sort="ascending">Date</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px; visibility: hidden;" aria-sort="ascending">CSP</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px; visibility: hidden;" aria-sort="ascending">Campaign</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;visibility: hidden;">Day</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Sent to CSP</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Spam</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Spam %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Messages Delivered</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Delivery %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Link Click %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Clicks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Landing Page %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Sales</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Bookings</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Messages Per Sale</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Spend</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Cost Per Delivered</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Average Cost Per Click</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="Goals Acheived: activate to sort column ascending">Average Cost Per Sale</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Calls</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Voicemail</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Inbound</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Caller ID Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Voicemail Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Scheduled Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Completed Callbacks</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Transfer to Human</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Phone Call Links</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Two</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Five</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Over Ten</th>
                                      </tr>
                                    </thead>
                                    <tbody id="daily-tracking-table" style="font-size: 16px;">
                                      <tr role="row" class="card-body" :class="{ 'odd-row-bg': index % 2 !== 0 }" style="padding:10px;">
                                        <td style="font-size:14px; visibility: hidden;">
                                          {{ reports[0]?.date | date || '' }} 
                                        </td>
                                        <td style="visibility: hidden;">
                                          {{ reports[0]?.csp || ''}}
                                        </td>
                                        <td style="visibility: hidden;">
                                          {{ reports[0]?.csp_name || ''}}
                                        </td>
                                        <td style="visibility: hidden;">
                                          {{ reports[0]?.day || '' }}
                                        </td>
                                        <td>
                                          {{ averages.sent_to_csp || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ averages.spam || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.spam_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ averages.messages_delivered | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.delivery_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td id="total-leads-17">
                                          {{ parseFloat(averages.link_click_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ averages.clicks | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.landing_page_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ averages.sales | comma }}
                                        </td>
                                        <td>
                                          {{ averages.bookings | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.messages_per_sale).toFixed(1) | comma }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(averages.total_spend).toFixed(2) | comma }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(averages.cost_per_delivered_message).toFixed(3) | comma }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(averages.cost_per_click).toFixed(2) | comma }}
                                        </td>
                                        <td>
                                          ${{ parseFloat(averages.cost_per_sale).toFixed(2) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_calls) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_voicemail) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_inbound) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.caller_id_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.voicemail_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.scheduled_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.completed_callbacks) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.transfer_to_human) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.phone_call_links) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_over_two) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_over_five) | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(averages.total_over_ten) | comma }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Call Reports
                    </template>
                      <div class="col-sm-12 p-0">
                            <div class="row w-100 m-0">
                              <callReports style="max-width:100%"></callReports>
                            </div>
                      </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Deliverability Report
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">

                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueSingleSelect from "vue-single-select";
import callReports from "../components/reports/callReports.vue";

export default {
  components: { Multiselect, Datepicker, VueSingleSelect, callReports },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      CSPs: [{
        id: 0,
        name: "All"
      },
      {
        id: 1,
        name: "Total"
      },
      {
        id: 2,
        name: "Roor"
      },
      {
        id: 3,
        name: "Telnyx"
      }],
      selectedCSP: {
        id: 0,
        name: "All"
      },
      reports: [],
      format: "MM/dd/yyyy",
      fromDate: moment().subtract(1, "days").format("MM/DD/YYYY"),
      toDate: moment().add(0, "days").format("MM/DD/YYYY"),
      dateFormat: [{ code: 1, name: "Today" }],
      statsType: 'ALL',
      allStats: true,
      warmStats: false,
      coldStats: false,
      averages: {}
    };
  },
  watch: {
    fromDate: {
      handler: "getReports", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    toDate: {
      handler: "getReports", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    selectedCSP: {
      handler: "getReports", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
  },
  mounted() {
    this.getReports();
    
  },
  methods: {
    async calcAverages() {
      
      let date = '';
      let CSP = '';
      let day = '';
      let totalSentToCSP = 0;
      let totalSpam = 0;
      let sumSpamPercent = 0;
      let totalMessagesDelivered = 0;
      let sumDeliveryPercent = 0;
      let sumClickPercent = 0;
      let totalClicks = 0;
      let sumLandingPagePercent = 0;
      let totalSales = 0;
      let totalBookings = 0;
      let sumMessagesPerSale = 0;
      let sumTotalSpend = 0;
      let sumCostPerDelivered = 0;
      let sumCostPerClick = 0;
      let sumCostPerSale = 0;
      let sumTotalCalls = 0;
      let sumTotalVoicemail = 0;
      let sumTotalInbound = 0;
      let sumTotalCallerIdCallback = 0;
      let sumTotalVoicemailCallback = 0;
      let sumTotalScheduledCallbacks = 0;
      let sumTotalCompletedCallbacks = 0;
      let sumTotalTransferToHuman = 0;
      let sumTotalPhoneCallLinks = 0;
      let sumTotalOverTwo = 0;
      let sumTotalOverFive = 0;
      let sumTotalOverTen = 0;
      for(const report of this.reports) {
        if(date == '') {
          date = report.date;
          CSP = report.csp;
          day = report.day;
        }
        totalSentToCSP = totalSentToCSP + parseFloat(report.sent_to_csp);
        totalSpam = totalSpam + parseFloat(report.spam);
        sumSpamPercent = sumSpamPercent + parseFloat(report.spam_percent);
        totalMessagesDelivered = totalMessagesDelivered + parseFloat(report.messages_delivered);
        sumDeliveryPercent = sumDeliveryPercent + parseFloat(report.delivery_percent);
        sumClickPercent = sumClickPercent + parseFloat(report.link_click_percent);
        totalClicks = totalClicks + parseFloat(report.clicks);
        sumLandingPagePercent = sumLandingPagePercent + parseFloat(report.landing_page_percent);
        totalSales = totalSales + parseFloat(report.sales);
        totalBookings = totalBookings + parseFloat(report.bookings);
        sumMessagesPerSale = sumMessagesPerSale + parseFloat(report.messages_per_sale);
        sumTotalSpend = sumTotalSpend + parseFloat(report.total_spend);
        sumCostPerDelivered = sumCostPerDelivered + parseFloat(report.cost_per_delivered_message);
        sumCostPerClick = sumCostPerClick + parseFloat(report.cost_per_click);
        sumCostPerSale = sumCostPerSale + parseFloat(report.cost_per_sale);
        sumTotalCalls = sumTotalCalls + parseFloat(report.total_calls);
        sumTotalVoicemail = sumTotalVoicemail + parseFloat(report.total_voicemail);
        sumTotalInbound = sumTotalInbound + parseFloat(report.total_inbound);
        sumTotalCallerIdCallback = sumTotalCallerIdCallback + parseFloat(report.caller_id_callbacks);
        sumTotalVoicemailCallback = sumTotalVoicemailCallback + parseFloat(report.voicemail_callbacks)
        sumTotalScheduledCallbacks = sumTotalScheduledCallbacks + parseFloat(report.scheduled_callbacks);
        sumTotalCompletedCallbacks = sumTotalCompletedCallbacks + parseFloat(report.completed_callbacks);
        sumTotalTransferToHuman = sumTotalTransferToHuman + parseFloat(report.transfer_to_human);
        sumTotalPhoneCallLinks = sumTotalPhoneCallLinks + parseFloat(report.phone_call_links);
        sumTotalOverTwo = sumTotalOverTwo + parseFloat(report.total_over_two);
        sumTotalOverFive = sumTotalOverFive + parseFloat(report.total_over_five);
        sumTotalOverTen = sumTotalOverTen + parseFloat(report.total_over_ten);
      }

      let averageSpamPercent = sumSpamPercent / this.reports.length;
      let averageDeliveryPercent = sumDeliveryPercent / this.reports.length;
      let averageClickPercent = sumClickPercent / this.reports.length;
      let averageLandingPagePercent = sumLandingPagePercent / this.reports.length;
      let averageMessagesPerSale = sumMessagesPerSale / this.reports.length;
      let averageTotalSpend = sumTotalSpend;
      let averageCostPerDelivered = sumCostPerDelivered / this.reports.length;
      let averageCostPerClick = sumCostPerClick / this.reports.length;
      let averageCostPerSale = sumTotalSpend / totalSales;

      this.averages = {
        date: date,
        CSP: CSP,
        day: day,
        sent_to_csp: totalSentToCSP,
        spam: totalSpam,
        spam_percent: averageSpamPercent,
        messages_delivered: totalMessagesDelivered,
        delivery_percent: averageDeliveryPercent,
        link_click_percent: averageClickPercent,
        clicks: totalClicks,
        landing_page_percent: averageLandingPagePercent,
        sales: totalSales,
        bookings: totalBookings,
        messages_per_sale: averageMessagesPerSale,
        total_spend: averageTotalSpend,
        cost_per_delivered_message: averageCostPerDelivered,
        cost_per_click: averageCostPerClick,
        cost_per_sale: averageCostPerSale,
        total_calls: sumTotalCalls,
        total_voicemail: sumTotalVoicemail,
        total_inbound: sumTotalInbound,
        caller_id_callbacks: sumTotalCallerIdCallback,
        voicemail_callbacks: sumTotalVoicemailCallback,
        scheduled_callbacks: sumTotalScheduledCallbacks,
        completed_callbacks: sumTotalCompletedCallbacks,
        transfer_to_human: sumTotalTransferToHuman,
        phone_call_links: sumTotalPhoneCallLinks,
        total_over_two: sumTotalOverTwo,
        total_over_five: sumTotalOverFive,
        total_over_ten: sumTotalOverTen

      };
    },
    async toggleStats(statsType) {
      this.statsType = statsType;
      if(statsType == "ALL") {
        setTimeout(() => {
          this.allStats = true;
          this.warmStats = false;
          this.coldStats = false;
        },1);
      }
      if(statsType == "WARM") {
        setTimeout(() => {
          this.allStats = false;
          this.warmStats = true;
         this.coldStats = false;
        },1);
      }
      if(statsType == "COLD") {
        setTimeout(() => {
          this.allStats = false;
          this.warmStats = false;
         this.coldStats = true;
        },1);
      }
      this.getReports();
    },
    async getReports() {
      if(!this.selectedCSP || this.selectedCSP.length == 0) {
        return;
      }
      this.reports = [];
      let url = "https://123avatars.com/backend/getDailyReports.php?id=" + this.userId + "&time=" +
          encodeURIComponent(await this.getDateRange()) + "&cspFilter=" + await this.getCSPFilter() + "&statsFilter=" + this.statsType;
      let response = await axios.get(url);
      this.reports = response.data;
      await this.calcAverages();
    
    },
    async getCSPFilter() {
      console.log("selectedCSP: ", this.selectedCSP);
     // let cspFilter = this.selectedCSP?.map((csp) => { return csp.id; }).join(",");
        let cspFilter = this.selectedCSP.id;
       return cspFilter;
    },
    async getDateRange() {
      // Parse the input date strings
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      // Format the dates as MM/DD/YYYY
      const formattedFromDate = `${(fromDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${fromDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${fromDate.getFullYear()}`;
      const formattedToDate = `${(toDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${toDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${toDate.getFullYear()}`;

      // Create the date range string
      let dateRange = `${formattedFromDate} - ${formattedToDate}`;
      console.log('dateRange', dateRange);
      if (this.fromDate.length < 1 || this.toDate.length < 1) {
        dateRange = 0;
      }
      return dateRange;
    }
  },
};
</script> -->
