<template>
  <div class="d-flex justify-content-center align-items-center min-vh-100 bg-gray-100 dark:bg-gray-900">
    <div class="position-relative p-5 border border-gray-300 dark:border-gray-700 w-100 max-w-xl shadow-lg rounded bg-white dark:bg-gray-800">
      <div class="d-flex justify-content-end">
        <button
          @click="closeModal"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div class="text-center mt-3">
        <h3 class="h4 font-medium text-gray-900 dark:text-gray-100">
          Inbound Calls Script
        </h3>
        <div class="mt-4">
          <textarea
            placeholder="Enter your prompt"
            v-model="userPrompt"
            class="form-control mt-4 mb-2"
            rows="8"
          ></textarea>
          <textarea
            placeholder="Inbound Script"
            v-model="inboundScript"
            class="form-control mt-2 mb-4"
            rows="8"
          ></textarea>
        </div>
        <div>
          <button
            @click="saveData"
            :class="['btn btn-primary w-100', { 'animate-pulse': isSaving }]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initialInboundScript: {
      type: String,
      required: true,
    },
    initialUserPrompt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inboundScript: this.initialInboundScript,
      userPrompt: this.initialUserPrompt,
      isSaving: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    fetchData() {
      axios.get('/api/data')
        .then(response => {
          this.inboundScript = response.data.inboundScript;
          this.userPrompt = response.data.userPrompt;
        })
        .catch(error => {
          this.$toasted.show(`Error fetching data: ${error.message}`, {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
        });
    },
    saveData() {
      this.isSaving = true;
      const data = {
        promptType: "Script-Inbound",
        script: this.inboundScript,
        prompt: this.userPrompt,
      };
      axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          this.isSaving = false;
          this.$toasted.show('Data saved successfully', {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 5000,
          });
          setTimeout(() => {
            this.closeModal();
          }, 3000);
        })
        .catch(error => {
          this.isSaving = false;
          this.$toasted.show(`Error saving data: ${error.message}`, {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
        });
    }
  },
  mounted() {
    // this.fetchData();
  }
};
</script>

<style scoped>
.animate-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>