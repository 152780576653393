<template>
  <div class="container-custom mt-3">
    <div class="title text-center mb-4">
        Sales Team
      </div>
      <div class="card-body default-according style-1">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="div" class="bg-secondary">
            <h5 class="mb-0">
              <b-button v-b-toggle.primary_icon_open_close_1>
                <feather type="bar-chart-2"></feather>Team Scoreboard
              </b-button>
            </h5>
          </b-card-header>
          <b-collapse
            id="primary_icon_open_close_1"
            accordion="my-accordion-icon-open-close"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <scoreboard ></scoreboard>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="card-body default-according style-1">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="div" class="bg-secondary">
            <h5 class="mb-0">
              <b-button v-b-toggle.primary_icon_open_close_2>
                <feather type="bar-chart-2"></feather>Team Management
              </b-button>
            </h5>
          </b-card-header>
          <b-collapse
            id="primary_icon_open_close_2"
            accordion="my-accordion-icon-open-close"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <table class="table table-bordered report-table">
                    <thead class="thead-light">
                    <tr>
                        <th>Report Name</th>
                        <th>Example Link</th>
                        <th>Assign Sales Rep</th>
                        <th>Cell</th>
                        <th>Email</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(report, index) in reports" :key="index">
                        <td>{{ report.name }}</td>
                        <td><a target="_blank" :href="report.link">{{ report.link }}</a></td>
                        <td v-if="true" class="sales-rep">
                          <input type="text" class="form-control" placeholder="Assign Sales Rep" v-model="report.sales_rep">
                        </td>
                        <td v-else>
                          {{ report.sales_rep }}
                        </td>
                        <!-- <td v-if="report.assignable" class="new-leads">
                          <input type="text" class="form-control" placeholder="Sales Rep's Extension" v-model="report.sales_rep_extension">
                        </td>
                        <td v-else>
                          {{ report.sales_rep_extension }}
                        </td>
                        <td v-if="true" class="new-leads">
                          <input type="text" class="form-control" placeholder="Sales Rep's 3CX Password" v-model="report.cx_password">
                        </td>
                        <td v-else>
                          {{ report.cx_password }}
                        </td> -->
                        <td v-if="true" class="new-leads">
                          <input type="phone" class="form-control" placeholder="Sales Rep's Cell" v-model="report.cell">
                        </td>
                        <td v-else>
                          {{ report.cell  }}
                        </td>
                        <td v-if="true" class="new-leads">
                          <input type="email" class="form-control" placeholder="Sales Rep's Email" v-model="report.email">
                        </td>
                        <td v-else>
                          {{ report.email }}
                        </td>
                        <td>
                        <button class="btn btn-success refresh-button" @click="refreshLink(report, index)">Refresh Link</button>
                        <span class="feedback">Action in progress...</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <button class="btn btn-primary mt-3" @click="addSalesRep">+ Sales Rep</button>
                <!-- <div class="links-container mt-4">
                    <div class="form-group">
                    <label for="script-link">Script Link:</label>
                    <input type="text" class="form-control" id="script-link" placeholder="Enter Script Link" v-model="scriptLink">
                    </div>
                    <div class="form-group">
                    <label for="signup-link">Signup Link:</label>
                    <input type="text" class="form-control" id="signup-link" placeholder="Enter Signup Link" v-model="signupLink">
                    </div>
                </div> -->
                <div class="save-button text-right mt-4">
                    <button class="btn btn-success" @click="saveData">Save</button>
                    <span class="feedback">Action in progress...</span>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import scoreboard from "../../components/sales/scoreboard.vue";
  
  export default {
    components: {  'scoreboard': scoreboard },
    data() {
      return {
        reports: [],
        scriptLink: '',
        signupLink: ''
      };
    },
    mounted() {
      this.loadData();
    },
    methods: {
      loadData() {
        let id = this.$route.query.id;
        axios.get('https://123avatars.com/v2/sales_management?userId=' + localStorage.getItem('userId') + '&id=' + id)
          .then(response => {
            this.reports = response.data.reports;
            this.scriptLink = response.data.scriptLink;
            this.signupLink = response.data.signupLink;
          })
          .catch(error => {
            console.error("There was an error loading the data!", error);
          });
      },
      saveData() {
        const data = {
          userId: localStorage.getItem('userId'),
          manager_uuid: this.$route.query.id,
          reports: this.reports,
          scriptLink: this.scriptLink,
          signupLink: this.signupLink
        };
  
        axios.post('https://123avatars.com/v2/sales_management/save', data)
          .then(response => {
            this.showFeedback({ target: document.querySelector('.save-button button') });
            this.loadData();
          })
          .catch(error => {
            console.error("There was an error saving the data!", error);
          });
      },
      addSalesRep() {
        this.reports.push({
          name: 'New Report',
          link: 'New Link',
          sales_rep: '',
          sales_rep_extension: '',
          cx_password: '',
          cell: '',
          email: '',
          assignable: true
        });
      },
      refreshLink(report, index) {
        const userId = localStorage.getItem('userId');
        axios.post('https://123avatars.com/v2/sales_management/refresh_link', { userId, reportId: report.id })
          .then(response => {
            this.$set(this.reports, index, { ...report, link: response.data.newLink });
            if(response.data.updated) {
              this.loadData();
            }
            this.showFeedback({ target: event.target });
            // setTimeout(() => {
            //   this.loadData();
            // },100);
          })
          .catch(error => {
            console.error("There was an error refreshing the link!", error);
          });
      },
      showFeedback(event) {
        const button = event.target;
        const feedback = button.nextElementSibling;
        feedback.style.display = 'inline';
        feedback.innerHTML = 'Action in progress...';
  
        setTimeout(() => {
          feedback.innerHTML = 'Action completed!';
          setTimeout(() => {
            feedback.style.display = 'none';
          }, 2000);
        }, 2000);
      }
    }
  };
</script>

<style scoped>
.custom-table {
  border-color: #000; /* Darker border color */
}

.custom-table th,
.custom-table td {
  border-color: #000; /* Darker border color */
}

.current-call {
  background-color: lightgreen
}
.container-custom {
  width: 90%;
  margin: auto;
}
.stats-section {
  margin-bottom: 10px;
  padding: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.stats-section-wrapper {
  width: 60%; /* Adjust this value to make the width smaller or larger */
  margin: auto;
}
.stats-section {
  margin-bottom: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
</style>