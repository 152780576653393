<template>
	<div id="notifications">
		<div v-for="notif in notifications" :key="notif.id" class="notification">
			<div class="header" :class="{ alert: notif.alert }">
				<div class="text">{{ notif.title }}</div>
				<!-- <button v-if="showCloseButton" class="btn">
					<CloseSVG />
				</button> -->
			</div>
			<div class="body">
				<div v-if="notif.number" class="big-num">{{ notif.number }}</div>
				<div class="text">{{ notif.text }}</div>
				
				<!-- Slot for additional content, including optional call buttons -->
				<div v-if="showCallButtons" class="call-buttons">
					<slot name="call-buttons">
						<button class="answer-btn" @click="answerCall">Answer Call</button>
						<button class="hangup-btn" @click="hangupCall">Deny Call</button>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CloseSVG from './SVG-icons/closeSVG.vue';

export default {
	components: { CloseSVG },
	props: {
		notifications: {
			type: Array,
			required: true,
			default: () => []
		},
		showCallButtons: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		answerCall() {
			// Handle answer call action
			this.$emit('answer-call');
		},
		hangupCall() {
			// Handle hangup call action
			this.$emit('deny-call');
		}
	}
};
</script>

<style scoped>
/* Add your notification styling here */
.call-buttons {
	margin-top: 8px;
}
.answer-btn, .hangup-btn {
	margin-right: 5px;
}
</style>