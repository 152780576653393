<template style="width:100%">
  <div>
    <Breadcrumbs main="Project" title="Schedules" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="container">
        <h1 class="text-center my-4">Schedules</h1>
        <div v-for="schedule in schedules" :key="schedule.id" class="card mb-4">
          <div class="card-body">
            <h2 class="card-title">{{ schedule.name }}</h2>
            <div class="row mb-2">
              <div class="col fw-bold">Day</div>
              <div class="col fw-bold">Open Time</div>
              <div class="col fw-bold">Closing Time</div>
              <div class="col fw-bold">Total Open Time</div>
              <div class="col fw-bold">24 Hours</div>
            </div>
            <div v-for="(day, index) in schedule.days" :key="index" class="row mb-2 align-items-center border-bottom pb-2">
              <div class="col">{{ day.name }}</div>
              <div class="col">
                <input type="time" v-model="day.openTime" @change="updateTotalTime(schedule, day)" class="form-control">
              </div>
              <div class="col">
                <input type="time" v-model="day.closeTime" @change="updateTotalTime(schedule, day)" class="form-control">
              </div>
              <div class="col">{{ day.totalTime }}</div>
              <div class="col">
                <input type="checkbox" v-model="day.is24Hours" @change="toggle24Hours(day)" class="form-check-input">
              </div>
            </div>
            <div class="text-end mt-3">
              <button @click="setSchedule24Hours(schedule)" class="btn btn-link">Set Schedule 24/7</button>
            </div>
            <button @click="saveSchedule()" class="btn btn-primary w-100 mt-3">SAVE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      schedules: []
    };
  },
  created() {
    this.getSchedules();
  },
  methods: {
    async getSchedules() {
      try {
        const response = await axios.get('https://123avatars.com/v2/schedules?userId=' + localStorage.getItem('userId'));
        this.schedules = JSON.parse(response.data.schedules);
        this.schedules.forEach(schedule => {
          schedule.days.forEach(day => {
            day.openTime = this.convertUTCToLocal(day.openTime);
            day.closeTime = this.convertUTCToLocal(day.closeTime);
            this.updateTotalTime(schedule, day);
          });
        });
        console.log("schedulesAfterConvertToLocal: ", this.schedules);
      } catch (error) {
        console.error('Error fetching schedules:', error);
      }
    },
    convertUTCToLocal(utcTimeString) {
        console.log("utc: ", utcTimeString);
        
        // Assuming the input is in HH:MM format, we need to add a date part to create a valid date object
        const [utcHours, utcMinutes] = utcTimeString.split(':');
        
        // Create a new Date object for the current date in UTC
        const now = new Date();
        now.setUTCHours(utcHours, utcMinutes, 0, 0);

        // Get the local hours and minutes
        const localHours = String(now.getHours()).padStart(2, '0');
        const localMinutes = String(now.getMinutes()).padStart(2, '0');

        // Format the local time as HH:MM
        const localTimeString = `${localHours}:${localMinutes}`;
        
        console.log("local: ", localTimeString);

        return localTimeString;
    },
    convertLocalToUTC(localTimeString) {
        // Get the current date
        const today = new Date();
        
        // Split local time string into hours and minutes
        const [hours, minutes] = localTimeString.split(':').map(Number);
        
        // Set the hours and minutes to today's date
        today.setHours(hours);
        today.setMinutes(minutes);
        today.setSeconds(0);
        today.setMilliseconds(0);

        // Get the UTC time as a string in HH:MM format
        const utcHours = String(today.getUTCHours()).padStart(2, '0');
        const utcMinutes = String(today.getUTCMinutes()).padStart(2, '0');
        const utcTimeString = `${utcHours}:${utcMinutes}`;

        return utcTimeString;
    },
    updateTotalTime(schedule, day) {
      const openTime = day.openTime;
      const closeTime = day.closeTime;

      if (openTime && closeTime) {
        const open = new Date(`1970-01-01T${openTime}:00`);
        const close = new Date(`1970-01-01T${closeTime}:00`);

        if (open >= close) {
          alert('Closing time must be later than opening time.');
          day.totalTime = '';
        } else {
          const totalMinutes = (close - open) / (1000 * 60);
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          day.totalTime = `${hours}h ${minutes}m`;
        }
      }
    },
    toggle24Hours(day) {
      if (day.is24Hours) {
        day.openTime = "00:00";
        day.closeTime = "23:59";
        day.totalTime = '24h 0m';
      } else {
        day.openTime = "08:00";
        day.closeTime = "18:00";
        this.updateTotalTime(null, day);
      }
    },
    setSchedule24Hours(schedule) {
      schedule.days.forEach(day => {
        day.is24Hours = true;
        this.toggle24Hours(day);
      });
    },
    async saveSchedule() {
      try {
        this.schedules.forEach(schedule => {
          schedule.days.forEach(day => {
            day.openTime = this.convertLocalToUTC(day.openTime);
            day.closeTime = this.convertLocalToUTC(day.closeTime);
          });
        });

        let data = {
          userId: localStorage.getItem('userId'),
          schedules: this.schedules
        };
        await axios.post('https://123avatars.com/v2/schedules?userId=' + localStorage.getItem('userId'), data);
        this.$toasted.show("Schedule saved successfully.", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "info",
            duration: 10000,
          });
      } catch (error) {
        console.error('Error saving schedule:', error);
        this.$toasted.show("Failed to save schedule.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
      } finally {
        this.getSchedules();
      }
    }
  }
};
</script>

<style>
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.card-title {
  color: #007bff;
}
</style>