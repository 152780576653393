<template>
  <div class="modal fade show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Retail Price vs Sale Price</h5>
          <button type="button" class="close" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea
            placeholder="Enter your prompt"
            class="form-control mb-3 large-textarea"
            v-model="prompt"
          ></textarea>
          <textarea
            placeholder="Retail Price"
            class="form-control mb-3 small-textarea"
            v-model="retailPrice"
          ></textarea>
          <textarea
            placeholder="Sales Price"
            class="form-control mb-3 small-textarea"
            v-model="salesPrice"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initialRetailPrice: {
      type: String,
      default: ''
    },
    initialSalesPrice: {
      type: String,
      default: ''
    },
    initialPrompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      retailPrice: this.initialRetailPrice,
      salesPrice: this.initialSalesPrice,
      prompt: this.initialPrompt
    };
  },
  methods: {
    async saveData() {
      try {
        let data = {
          promptType: "Script-Sale_Retail",
          retailPrice: this.retailPrice,
          salesPrice: this.salesPrice,
          prompt: this.prompt
        };
        await axios.post('https://123avatars.com/backend/updatePrompt.php?id=' + localStorage.getItem("userId"), data, {
          headers: {
            'Origin': 'http://localhost:8080',
            'Content-Type': 'application/json'
          }
        });
        this.$toasted.show("Data saved successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 5000
        });
        setTimeout(() => {
          this.$emit('close-modal');
        }, 1000);
      } catch (error) {
        console.error('Error saving data:', error);
        this.$toasted.show("Failed to save data", {
          theme: "outline",
          position: "top-right",
          type: "error",
          icon: "error",
          duration: 5000
        });
      }
    },
    closeModal() {
      this.$emit('close-modal');
    }
  }
};
</script>

<style scoped>
.large-textarea {
  height: 150px;
  overflow-y: auto;
}
.small-textarea {
  height: 50px;
  overflow-y: auto;
}
</style>