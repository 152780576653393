<template>
  <div>
    <Breadcrumbs main="Account" title="Phone Numbers" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12 morning-sec box-col-12 col-12">
          <px-card class="profile-greeting" bg="primary-gradient">
            <div slot="with-padding">
              <div class="media">
                <div class="media-body">
                  <div class="greeting-user">
                    <h4 class="f-w-600 font-white" id="greeting">
                      Configure Phone Numbers
                    </h4>
                    <p>
                      Phone Numbers are only editable through <b>Admins</b> for
                      now.
                    </p>
                  </div>
                </div>
                <div class="cartoon">
                  <img
                    class="img-fluid"
                    src="../assets/images/domains.png"
                    alt=""
                    style="max-width: 200px"
                  />
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Number Pooling
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-3">
                                <div class="row">
                                  <span>
                                    <label >Messages to Review: </label>
                                    <input  type="number" v-model="numberPoolingData.number_recent_to_review"></input>
                                  </span>
                                </div>
                                <div class="row">
                                  <span class="float-right">
                                    
                                    <label for="from">Maximum Spam %: </label>
                                    <input type="number" max="100" v-model="numberPoolingData.max_spam_percent"></input>
                                  </span>
                                </div>
                              </div>
                              <div class="col-1">
                                <div class="row">

                                </div>
                                <div class="row">
                                  <button class="btn btn-primary float-right" @click="saveSettings()">
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="row">
                                  <span>
                                    <label for="from">Active: </label>
                                    {{  numberPoolingData.num_active }}
                                  </span>
                                </div>
                                <div class="row">
                                  <span>
                                    <label for="from">Only Responding: </label>
                                    {{  numberPoolingData.num_responding }}
                                  </span>
                                </div>
                              </div>
                              <!-- <div class="col-1">
                                  <label for="from">To:</label>
                                  <datepicker
                                    input-class="datepicker-here form-control digits"
                                    :format="format"
                                    v-model="toDate"
                                  ></datepicker>
                              </div>
                              <div class="col-1">
                                <label class="col-form-label m-r-10"
                                      >CSP Filter</label
                                    >
                                <multiselect
                                  v-model="selectedCSP"
                                  tag-placeholder="Select CSPs to filter by."
                                  placeholder="Select CSPs"
                                  label="name"
                                  track-by="code"
                                  :options="CSPs"
                                  :multiple="true"
                                ></multiselect>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >All Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="allStats"
                                      @click="toggleStats('ALL')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >Warm Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="warmStats"
                                      @click="toggleStats('WARM')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-1" >
                                <label class="col-form-label m-r-10"
                                      >Cold Stats</label
                                    >
                                <div class="media-body icon-state">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked=""
                                      v-model="coldStats"
                                      @click="toggleStats('COLD')"
                                    /><span class="switch-state"></span>
                                  </label>
                                </div>
                              </div> -->
                            </div>
                            <br>
                            <div class="row">
                              <table class="table" id="basic-1" role="grid" aria-describedby="basic-1_info">
                                    <thead>
                                      <tr style="font-size:12px;" role="row">
                                        <th class="sorting_desc " tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 120px;" aria-sort="ascending">Number</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;" aria-sort="ascending">Campaign Name</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;" aria-sort="ascending">First Send Date</th>
                                        <!-- <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;" aria-sort="ascending">CSP Name</th> -->
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Active Days</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Sent</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Delivered</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Spam</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Total Spam %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Spam % / Last 2000</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Active / Only Responding</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">First Only Responding Date</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Positive Responses</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Positive %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Negative Responses</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Negative %</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Stops</th>
                                        <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1" aria-label="" style="width: 95.2031px;">Stops %</th>
                                      </tr>
                                    </thead>
                                    <tbody id="daily-tracking-table" style="font-size: 16px;">
                                      <tr role="row" class="card-body" v-for="(number,index) in numberPoolingData.numbers" :key="number.id" :class="{ 'odd-row-bg': index % 2 !== 0 }" style="padding:10px;">
                                        <td style="font-size:14px;">
                                          {{ number.number || 0 }} 
                                        </td>
                                        <td>
                                          {{ number.campaign_name || ''}}
                                        </td>
                                        <td>
                                          {{ number.first_send_date || ''}}
                                        </td>
                                        <td>
                                          {{ number.active_days || 0 | comma}}
                                        </td>
                                        <td>
                                          {{ number.total_sent || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ number.total_delivered || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ number.total_spam || 0  | comma}}
                                        </td>
                                        <td>
                                          {{ parseFloat(number.total_spam_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ parseFloat(number.spam_percent_last_2000*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ number.active_responding || '' }}
                                        </td>
                                        <td id="total-leads-17">
                                          {{ number.first_responding_date || 'N/A' }}
                                        </td>
                                        <td>
                                          {{ number.positive_responses || 0 | comma}}
                                        </td>
                                        <td>
                                          {{ parseFloat(number.positive_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ number.negative_responses || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(number.negative_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                        <td>
                                          {{ number.stops || 0 | comma }}
                                        </td>
                                        <td>
                                          {{ parseFloat(number.stops_percent*100).toFixed(2) || 0 }}%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <!-- <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Deliverability Report
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">

                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab> -->
                </b-tabs>         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Vue from "vue";
import VueCommaFilter from "vue-comma-filter";

Vue.use(VueCommaFilter);

export default {
  components: { Multiselect, Datepicker },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      numberPoolingSettings: {
        numToReview: 2000,
        maxPercentSpam: 25
      },
      numberPoolingData: {}
    };
  },
  watch: {

  },
  mounted() {
    this.getNumberPoolingData();
    
  },
  methods: {
    async saveSettings() {
      let response = await axios.get("https://123avatars.com/backend/saveNumberPoolSettings.php?id=" + this.userId + 
       "&number_recent_to_review=" + this.numberPoolingData.number_recent_to_review + "&max_spam_percent=" + this.numberPoolingData.max_spam_percent);
      console.log("phonesUpdateResponse: ", response);
      if(response.status == 200) {
        this.$toasted.show("Successfully saved settings.", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "info",
            duration: 10000,
          });
      } else {
        this.$toasted.show("Failed to update settings.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
      }
    },
    async getNumberPoolingData() {
      this.numberPoolingData = {};
      let url = "https://123avatars.com/backend/getNumberPoolingData.php?id=" + this.userId;
      let response = await axios.get(url);
      if(response.status == 200) {
        this.numberPoolingData = response.data;
      }
    }
  },
};
</script>