<template>
    <div class="d-flex flex-row" style="margin-top:5%">
      <div class="sidebar-placeholder"></div>
      <div class="container">
        <div class="box mb-4">
          <h2 class="text-center bg-info text-dark p-2 rounded">Add Objection & Rebuttal</h2>
          <form @submit.prevent="addObjection">
            <div class="form-group">
              <label for="objection">Objection</label>
              <input type="text" id="objection" v-model="newObjection.objection" class="form-control" required>
            </div>
            <div class="form-group">
              <label for="rebuttal">Rebuttal</label>
              <textarea id="rebuttal" v-model="newObjection.rebuttal" rows="4" class="form-control" required></textarea>
            </div>
            <button type="submit" class="btn btn-dark mt-3">Add</button>
          </form>
        </div>
        <div class="box">
          <h2 class="text-center bg-info text-dark p-2 rounded">Current Objections & Rebuttals</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Objection</th>
                <th>Rebuttal</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in objections" :key="index">
                <td>{{ item.objection }}</td>
                <td>{{ item.rebuttal }}</td>
                <td><button class="btn btn-danger" @click="deleteObjection(index)">Delete</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        newObjection: {
          objection: '',
          rebuttal: ''
        },
        objections: []
      };
    },
    created() {
      this.fetchObjections();
    },
    methods: {
      async fetchObjections() {
        try {
          const response = await axios.get('https://123avatars.com/v2/objections');
          this.objections = response.data;
        } catch (error) {
          console.error('Error fetching objections:', error);
        }
      },
      async addObjection() {
        try {
          const response = await axios.post('https://123avatars.com/v2/objections', this.newObjection);
          this.objections.push(response.data);
          this.newObjection.objection = '';
          this.newObjection.rebuttal = '';
        } catch (error) {
          console.error('Error adding objection:', error);
        }
      },
      async deleteObjection(index) {
        try {
          const objection = this.objections[index];
          await axios.delete(`https://123avatars.com/v2/objections/${objection.id}`);
          this.objections.splice(index, 1);
        } catch (error) {
          console.error('Error deleting objection:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    flex-direction: row;
  }
  
  .sidebar-placeholder {
    width: 200px; /* Space reserved for future left menu */
  }
  
  .container {
    background-color: #2d3748;
    border-radius: 10px;
    padding: 20px;
    color: #ffffff;
    width: calc(100% - 240px); /* Adjusted width to leave space for the sidebar and padding */
    margin-left: 20px; /* Space between the sidebar and the container */
  }
  
  .box {
    background-color: #e2e8f0;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .box h2 {
    background-color: #bee3f8;
    padding: 10px;
    border-radius: 10px;
    color: #2d3748;
    font-size: 1.5em;
    margin: 0 0 20px 0;
  }
  
  .box .form-group {
    margin-bottom: 20px;
  }
  
  .box input,
  .box textarea {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .box button {
    padding: 10px;
    font-size: 1.2em;
    color: white;
    background-color: #2d3748;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    align-self: center;
  }
  
  .box button:hover {
    background-color: #4a5568;
  }
  
  .box table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .box table th,
  .box table td {
    padding: 10px;
    text-align: left;
    color: #2d3748;
    font-size: 1em;
  }
  
  .box table th {
    background-color: #edf2f7;
    color: #2d3748;
  }
  
  .box table td {
    background-color: #ffffff;
  }
  
  .btn-danger {
    background-color: #e53e3e;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-danger:hover {
    background-color: #c53030;
  }
  </style>