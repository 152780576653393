<template>
  <div id="prompt-editor">
    <UIBox classbox="hello">
      <template v-slot:title>
        <div class="header-container">
          <div class="title-section">
            <span class="bold"><b>AI Prompt</b></span>
          </div>
        </div>
      </template>
      <div class="container-body" id="promptContainer">
        <textarea
          v-model="prompt"
          class="prompt-input"
          placeholder="Edit your AI prompt here..."
        ></textarea>
      </div>
      <div class="button-wrapper">
        <button class="btn solid save-button" @click="savePrompt">Save Changes</button>
      </div>
    </UIBox>

    <b-modal v-model="notificationModal" title="Prompt Saved" hide-footer>
      <div class="form-container">
        <div class="row notification-text">Prompt Saved Successfully.</div>
        <div class="button-group">
          <button class="btn-save" type="button" @click="notificationModal = false">OK</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import UIBox from '../components/uibox.vue';

export default {
  components: {
    UIBox,
  },
  data() {
    return {
      prompt: '', // Holds the AI prompt text
      notificationModal: false, // Controls the success notification modal
    };
  },
  methods: {
    async fetchPrompt() {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.get(`https://123avatars.com/v2/prompt?userId=${userId}`);
        this.prompt = response.data.prompt || '';
      } catch (error) {
        console.error('Error fetching the prompt:', error);
      }
    },
    async savePrompt() {
      try {
        const userId = localStorage.getItem('userId');
        await axios.post('https://123avatars.com/v2/prompt', {
          userId,
          prompt: this.prompt,
        });
        this.notificationModal = true;
      } catch (error) {
        console.error('Error saving the prompt:', error);
      }
    },
  },
  async mounted() {
    await this.fetchPrompt();
  },
};
</script>

<style scoped>
#prompt-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.prompt-input {
  width: 100%;
  height: 300px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  box-sizing: border-box;
}

.container-body {
  width: 100%;
}

.save-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}


.notification-text {
  text-align: center;
  font-size: 1.5em;
  padding: 10px;
}

.btn-save {
  padding: 0.5rem 1.5rem;
  background: #46A57C;
  color: white;
  border: none;
  border-radius: 6px;
}
</style>
