<template>
  <div class="header-wrapper row m-0">
    <SearchBar />
    <Logo />
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
        <!-- <BonusUI /> -->
        <!-- <LevelMenu /> -->
        <!-- <li class="mega-menu outside">
          <a
            class="nav-link active"
            href="javascript:void(0);"
            @click="switchView()"
          >
            <feather type="layers"></feather
            ><span style="display: inline-block !important">Swtich view</span>
          </a>
        </li> -->
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <!-- <li class="language-nav">
          <Language />
        </li> -->
        <li>
          <span class="header-search"
            ><feather type="search" @click="search_open()"></feather
          ></span>
        </li>
        <!-- <Notifications /> -->
        <!-- <li class="onhover-dropdown">
          <div class="notification-box">
            <feather type="star" @click="bookmark_open()"></feather>
          </div>
          <Bookmark v-bind:activeclass="bookmark" />
        </li> -->
        <li>
          <Mode />
        </li>
        <!-- <Cart /> -->
        <!-- <Message /> -->
        <li class="maximize">
          <Maximize />
        </li>
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Bookmark from "../bookmark";
import BonusUI from "./bonusUI";
import LevelMenu from "./levelMenu";
import Language from "./language";
import Notifications from "./notifications";
import Mode from "./mode";
import Cart from "./cart";
import Message from "./message";
import Maximize from "./maximize";
import Profile from "./profile";
import Logo from "./logo";
import SearchBar from "./search";
import { layoutClasses } from "../../constants/layout";

export default {
  name: "Search",
  components: {
    Bookmark,
    BonusUI,
    LevelMenu,
    Language,
    Notifications,
    Mode,
    Cart,
    Message,
    Maximize,
    Profile,
    Logo,
    SearchBar,
  },
  data() {
    return {
      bookmark: false,
      view: 1,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
    }),
  },
  mounted() {
    this.switchView();
  },
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
    switchView() {
      console.log(this.view);
      if (this.view == 1) {
        // New View
        this.layout.settings.layout = "Rome";
        this.view = 2;
        this.handlePageLayputs(
          "compact-sidebar compact-small material-icon",
          "Rome"
        );
      } else if (this.view == 2) {
        // SideBar View
        this.layout.settings.layout = "3";
        this.view = 3;
        this.handlePageLayputs("compact-wrapper modern-type", "Seoul");
      } else if (this.view == 3) {
        // Old View
        this.layout.settings.layout = "2";
        this.view = 1;
        this.handlePageLayputs(
          "horizontal-wrapper material-type",
          "LosAngeles"
        );
      }
    },
    handlePageLayputs(val, layout) {
      const layoutobj = layoutClasses.find(
        (item) => Object.keys(item).pop() === layout
      );
      this.$store.dispatch("layout/setCustomizeSidebarType", layoutobj[layout]);
      this.layout.settings.layout = layout;
      this.$router.push({ query: { layout: layout } }).catch((err) => err);
      if (layoutobj[layout].split(" ").includes("compact-sidebar")) {
        // this.$store.state.menu.activeoverlay = true;
        document.getElementById("sidebar-menu").classList.remove("d-flex");
      } else {
        this.$store.state.menu.activeoverlay = false;
        document.getElementById("sidebar-menu").classList.add("d-flex");
      }

      if (layout === "Dubai") {
        this.$store.state.layout.boxlayout = true;
      } else {
        this.$store.state.layout.boxlayout = false;
      }

      // if (layout === "Tokyo" || layout === "Moscow" || layout === "Rome") {
      //   this.$store.state.menu.togglesidebar = true;
      // }
    },
  },
};
</script>
