<template>
    <div class="container" style="width:100%; margin-top:5%;">
      <callOperatorLeaderboard></callOperatorLeaderboard>
    </div>
  </template>
  
  <script>
  // import callOperatorLeaderboard from "../../pages/sales/callOperatorLeaderboard.vue"
  
  export default {
    components: {
      // 'callOperatorLeaderboard': callOperatorLeaderboard
    },
    data() {
      return {
        
      };
    },
    
    methods: {
      
    }
  };
  </script>
  
  <style scoped>
  
  </style>