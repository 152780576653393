<template>
  <div style="width:90%; margin-top:5%; margin-left:5%;">
    <Panel></Panel>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { TelnyxRTC } from '@telnyx/webrtc';
import Panel from "../components/sales/Panel.vue";


export default {
  components: { Multiselect, Datepicker, 'Panel': Panel },
  data() {
    return {
      salesReps: [],
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      audio: null,
      outboundHuman: false,
      callDestination: '',
      includeVideo: false,
      enableDebugging: false,
      authentication: 'credential',
      username: 'cmharrisiii70663',
      password: 'cPeNxcb1',
      callerIdName: '',
      callerIdNumber: '',
      clientState: '',
      telnyxRTC: null,
      call: null,
      dialpad: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['*', '0', '#']
      ],
      managerId: '',
      currentSalesRepId: '',
      currentSalesRepName: '',
      isVisible: false,
      listenNumber: '+19495461850',
      isListen: false,
      repId: ''
    };
  },
  created() {
    //this.fetchSalesReps();
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchSalesReps();
    // }, 4000);
    
  },
  methods: {
    getClass(rep) {
      return {
        'connected': rep.currentCallTime > 0,
        'two-min': rep.currentCallTime > 120,
        'five-min': rep.currentCallTime > 300
      };
    },
    loadId() {
      this.managerId = this.$route.query.id;
    },
    connect() {
      console.log("this.username: ", this.username);
      console.log("username: ", this.sipUsername);
      if(localStorage.getItem('userId') != null && localStorage.getItem('userId') != '') {
        console.log("config: ", {
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
          debug: this.enableDebugging,
          logLeveL: 'debug'
        });
        this.telnyxRTC = new TelnyxRTC({
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
          debug: this.enableDebugging,
          logLeveL: 'debug'
        });

        this.telnyxRTC.on('telnyx.socket.open', () => {
          console.log('Socket opened');
        });

        this.telnyxRTC.on('telnyx.socket.error', (error) => {
          console.error('Socket error', error);
        });

        this.telnyxRTC.on('telnyx.socket.close', () => {
          console.log('Socket closed');
        });

        this.telnyxRTC.on('telnyx.ready', () => {
          console.log('Telnyx ready');
        });

        this.telnyxRTC.on('telnyx.error', (error) => {
          console.error('Telnyx error', error);
        });

        this.telnyxRTC.on('telnyx.call.incoming', (call) => {
          this.call = call;
          console.log('Incoming call', call);
        });

        this.telnyxRTC.on('telnyx.call.answered', (call) => {
          console.log('Call answered', call);
        });

        this.telnyxRTC.on('telnyx.call.hangup', (call) => {
          console.log('Call hung up', call);
        });

        this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
          console.log("call: ", call);
        });

        this.telnyxRTC.on('telnyx.notification', (notification) => {
          console.log("notification: ", notification);
          this.call = notification.call;
          if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
            this.call.answer();
            
            // .then(() => {
            //   // Mute the call after answering
            //   this.call.muteAudio();
            // });
          }
          if (notification.type === 'callUpdate' && this.call.state === 'active') {
            this.attachStreamToAudioElement(this.call.remoteStream);
          }
        });

        // Handle call state changes
        this.telnyxRTC.on('telnyx.callStateChange', (call) => {
          console.log("stateChange: ", call);
          if (call.state === 'active') {
            attachStreamToAudioElement(call.remoteStream);
          }
        });


        this.telnyxRTC.on('telnyx.call.started', () => {
          console.log('Call started');
        });

        this.telnyxRTC.on('telnyx.call.ended', () => {
          console.log('Call ended');
        });

        this.telnyxRTC.on('telnyx.call.error', (error) => {
          console.error('Call error:', error);
        });

        this.telnyxRTC.on('telnyx.ice.candidate', (candidate) => {
          console.log('Call ICE candidate:', candidate);
        });

        this.telnyxRTC.on('telnyx.ice.stateChange', (state) => {
          console.log('Call ICE state change:', state);
        });
s
        this.telnyxRTC.connect();
      }
    },
    attachStreamToAudioElement(stream) {
        const audioElement = document.getElementById('telnyx-audio');
        audioElement.srcObject = stream;
      },
    navigateToLink(id) {
      this.connect();
      axios.get('https://123avatars.com/v2/barge-call?id=' + id)
        .then(response => {
          console.log("succes");
        })
        .catch(error => {
          console.error("Error barging call.", error);
        });
    },
    fetchSalesReps() {
      axios.get('https://123avatars.com/v2/scoreboard')
        .then(response => {
          this.salesReps = response.data;
        })
        .catch(error => {
          console.error('Error fetching sales reps data:', error);
        });
    },
    getDayKey(day) {
      const today = new Date();
      const dayIndex = this.daysOfWeek.indexOf(day);
      const date = new Date(today.setDate(today.getDate() - today.getDay() + dayIndex + 1)); // Adjust dayIndex to start from Monday
      return date.toDateString();
    },
    getStartTime(data, day) {
      const key = this.getDayKey(day);
      const startTime = data[key]?.startTime;
      const timezone = data[key]?.timezone;
      if (!startTime || !timezone || timezone == 'undefined') {
        return '-';
      }

      // Initialize the date in UTC
      const date = new Date(`${startTime}`);
      if (isNaN(date)) {
        return '-';
      }

      const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };

      return new Intl.DateTimeFormat('en-US', options).format(date);
    },
    formatTime(seconds) {
      if (seconds < 0) {
        return 'Invalid input';
      }

      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let formattedTime = '';

      if (hours > 0) {
        formattedTime += `${hours}h `;
      }
      if (minutes > 0 || hours > 0) {
        formattedTime += `${minutes}m `;
      }
      formattedTime += `${secs}sec`;

      return formattedTime.trim();
    },
    getTalkTime(data, day) {
      const key = this.getDayKey(day);
      const talkTime = data[key]?.talkTime;
      return talkTime ? this.formatTime(talkTime) : '-';
    },
    getCalls(data, day) {
      const key = this.getDayKey(day);
      let callsCount = (data[key]?.humanCalls || '-') + '/' + (data[key]?.calls || '-');
      let humanPercent = 0;
      if(data[key]?.humanCalls > 0) {
        humanPercent = parseFloat((data[key]?.humanCalls / data[key]?.calls) * 100).toFixed(1);
        callsCount += '\n' + (humanPercent) + '%';
      }
      return callsCount;
    },
    getTotalTalkTime(data, period) {
      const dates = this.getPeriodDates(period);
      let total = 0;
      dates.forEach(date => {
        if (data[date] && data[date].talkTime) {
          total += parseFloat(data[date].talkTime);
        }
      });
      return total ? this.formatTime(total) : '-';
    },
    getTotalCalls(data, period) {
      const dates = this.getPeriodDates(period);
      let total = 0;
      let humanTotal = 0;
      dates.forEach(date => {
        if (data[date] && data[date].calls) {
          total += data[date].calls;
          humanTotal += data[date].humanCalls;
        }
      });
      
      console.log('humanCalls: ', humanTotal);

      let callsCount = (humanTotal || '-') + '/' + (total || '-');
      if (humanTotal > 0 && total > 0) {
        let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
        callsCount += '\n' + humanPercent + '%';
      }
      
      return callsCount;
    },
    getPeriodDates(period) {
      const today = new Date();
      let dates = [];
      if (period === 'thisWeek') {
        for (let i = 0; i < 7; i++) {
          dates.push(new Date(today.setDate(today.getDate() - today.getDay() + i + 1)).toDateString());
        }
      } else if (period === 'lastWeek') {
        for (let i = -7; i < 0; i++) {
          dates.push(new Date(today.setDate(today.getDate() - today.getDay() + i)).toDateString());
        }
      } else if (period === 'thisMonth') {
        const start = new Date(today.getFullYear(), today.getMonth(), 1);
        const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          dates.push(new Date(d).toDateString());
        }
      } else if (period === 'lastMonth') {
        const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const end = new Date(today.getFullYear(), today.getMonth(), 0);
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          dates.push(new Date(d).toDateString());
        }
      }
      return dates;
    },
    getColumnTotal(day, key) {
      let total = 0;
      let humanTotal = 0;
      this.salesReps.forEach(rep => {
        const dayKey = this.getDayKey(day);
        if (rep.data[dayKey]) {
          if (key === 'calls') {
            total += rep.data[dayKey].calls || 0;
            humanTotal += rep.data[dayKey].humanCalls || 0;
          } else {
            total += rep.data[dayKey][key] || 0;
          }
        }
      });

      if (key === 'calls') {
        let callsCount = (humanTotal || '-') + '/' + (total || '-');
        if (humanTotal > 0 && total > 0) {
          let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
          callsCount += '\n' + humanPercent + '%';
        }
        return callsCount;
      } else if (key === 'talkTime') {
        return this.formatTime(total);
      } else {
        return total;
      }
    },
    getPeriodTotal(period, key) {
      let total = 0;
      let humanTotal = 0;
      this.salesReps.forEach(rep => {
        const dates = this.getPeriodDates(period);
        dates.forEach(date => {
          if (rep.data[date]) {
            if (key === 'calls') {
              total += rep.data[date].calls || 0;
              humanTotal += rep.data[date].humanCalls || 0;
            } else {
              total += rep.data[date][key] || 0;
            }
          }
        });
      });

      if (key === 'calls') {
        let callsCount = (humanTotal || '-') + '/' + (total || '-');
        if (humanTotal > 0 && total > 0) {
          let humanPercent = parseFloat((humanTotal / total) * 100).toFixed(1);
          callsCount += '\n' + humanPercent + '%';
        }
        return callsCount;
      } else if (key === 'talkTime') {
        return this.formatTime(total);
      } else {
        return total;
      }
    },
    listen(repId) {  
      //this.listenNumber = repNumber;
      console.log("listenNumber: ", this.listenNumber);
      this.repId = repId;
      this.isListen = true;
    },
    openMessages(salesRepId, salesRepName) {
      this.currentSalesRepId = salesRepId;
      this.managerId = this.$route.query.id;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.connected {
  background-color: yellow;
}
.two-min {
  background-color: #02ff00;
}
.five-min {
  background-color: orange;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #00C1C1;
  color: white;
}

td:nth-child(1) {
  background-color: #00C1C1;
}

.total-row {
  background-color: #00C1C1;
  font-weight: bold;
}

.active {
  background-color: #d4edda;
}

.inactive {
  background-color: #f8d7da;
}

.shaded {
  background-color: lightgray;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
</style>