<template>
  <div class="stepper-container">
    <div class="step-content">
      <div class="content-container">
        <div style="padding: 5px;">
          <span class="font-bold text-1xl">Trackings</span>
        </div>
        <div style="padding: 5px;">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th style="width: 15%;">Date Created</th>
                <th style="width: 15%;">Who Sent</th>
                <th style="width: 15%;">Date Sent</th>
                <th style="width: 11%;">Leads To Send</th>
                <th style="width: 11%;">Total Sent</th>
                <th style="width: 11%;">Total Inbounds</th>
                <th style="width: 11%;">Negative Inbounds</th>
                <th style="width: 11%;">Positive Inbounds (Landed In Inbox)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in trackings" :key="row.id" :data-tracking-id="row.id">
                <td>{{ formatDate(row.createdDate) || '-' }}</td>
                <td>{{ row.salesRepName || '-' }}</td>
                <td>{{ formatDate(row.completedDate) || '-' }}</td>
                <td style="text-align: right">{{ row.leadsToSend.toLocaleString() || '-' }}</td>
                <td style="text-align: right">{{ row.succesfulSent.toLocaleString() || '-' }}</td>
                <td style="text-align: right">{{ row.totalInbound.toLocaleString() || '-' }}</td>
                <td style="text-align: right">{{ row.negativeInbounds.toLocaleString() || '-' }}</td>
                <td style="text-align: right">{{ row.positiveInbounds.toLocaleString() || '-' }}</td>
              </tr>
              <tr class="font-bold bg-gray-100">
                <td>Total</td>
                <td>-</td>
                <td>-</td>
                <td style="text-align: right">{{ totals.leadsToSend.toLocaleString() }}</td>
                <td style="text-align: right">{{ totals.succesfulSent.toLocaleString() || '-' }}</td>
                <td style="text-align: right">{{ totals.totalInbound.toLocaleString() }}</td>
                <td style="text-align: right">{{ totals.negativeInbounds.toLocaleString() }}</td>
                <td style="text-align: right">{{ totals.positiveInbounds.toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <div class="bottom-input mt-4">
            <div class="per-page">
              <div class="select">
                Per Page
                <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchTrackings">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>

            <template>
              <div class="pagin">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
                  aria-controls="tagstable">
                  <template #first-text>
                    <PaginleftdoubleSVG />
                  </template>
                  <template #prev-text>
                    <PaginleftSVG />
                  </template>
                  <template #next-text>
                    <PaginrightSVG />
                  </template>
                  <template #last-text>
                    <PaginrightdoubleSVG />
                  </template>
                </b-pagination>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';

export default {
  components: {
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
  },
  data() {
    return {
      // trackings: [{
      //   id: 1,
      //   date: 'December 1, 2024',
      //   who: 'John Steward',
      //   sent: 2000,
      //   totalInbounds: 100,
      //   negativeInbounds: 20,
      //   positiveInbounds: 80
      // }, {
      //   id: 2,
      //   date: 'December 1, 2024',
      //   who: 'Russell Johnson',
      //   sent: 2500,
      //   totalInbounds: 120,
      //   negativeInbounds: 41,
      //   positiveInbounds: 79
      // }, {
      //   id: 3,
      //   date: 'December 1, 2024',
      //   who: 'Been Greenfield',
      //   sent: 3507,
      //   totalInbounds: 140,
      //   negativeInbounds: 62,
      //   positiveInbounds: 78
      // }]
      trackings: [],
      totalRows: 0,
      rowsPerPage: 25,
      currentPage: 1,
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    },
    async fetchTrackings() {
      const result = await axios.get(`https://123avatars.com/v2/sms-broadcast/tracking`, {
        params: {
          limit: this.rowsPerPage,
          offset: this.currentPage - 1,
        }
      });

      console.log('result', result.data);
      this.trackings = result.data.data;
      this.totalRows = result.data.totalRows;
    }
  },
  async mounted() {
    await this.fetchTrackings();
  },
  computed: {
    displayRows() {
      // Create array of 10 rows, fill empty spots with empty objects
      const rows = [...this.trackings];
      while (rows.length < 10) {
        rows.push({
          id: `empty-${rows.length}`
        });
      }
      return rows;
    },
    totals() {
      return this.trackings.reduce((acc, row) => {
        return {
          leadsToSend: acc.leadsToSend + row.leadsToSend,
          succesfulSent: acc.succesfulSent + row.succesfulSent,
          totalInbound: acc.totalInbound + row.totalInbound,
          negativeInbounds: acc.negativeInbounds + row.negativeInbounds,
          positiveInbounds: acc.positiveInbounds + row.positiveInbounds
        };
      }, {
        leadsToSend: 0,
        succesfulSent: 0,
        totalInbound: 0,
        negativeInbounds: 0,
        positiveInbounds: 0
      });
    }
  },
  watch: {
    currentPage() {
      this.fetchTrackings();
    },
  }
}
</script>

<style scoped>
.stepper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}
.content-container {
  min-height: 200px;
  flex: 1;
  width: 100%;
  height: 100%;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  padding: 8px;
  border: 1px solid #e5e7eb;
  text-align: left;
}
.table thead th {
  background-color: #f9fafb;
  font-weight: bold;
}
</style>