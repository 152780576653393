<template>
	<svg viewBox="0 0 32 29" class="starSVG" :width="size" :fill="fill" :height="size">
	   <path d="M16 3.23607L18.6412 11.3647L18.8657 12.0557H19.5922H28.1392L21.2246 17.0795L20.6368 17.5066L20.8613 18.1976L23.5024 26.3262L16.5878 21.3024L16 20.8754L15.4122 21.3024L8.49755 26.3262L11.1387 18.1976L11.3632 17.5066L10.7754 17.0795L3.86078 12.0557H12.4078H13.1343L13.3588 11.3647L16 3.23607Z" />
	 </svg>
</template>

<script>
export default {
   name: 'starSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>