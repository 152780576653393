<template>
<svg viewBox="0 0 16 16">
	<path d="M12.9994 12.2674c0 .1656-.0309.383-.0925.6498-.0451.2163-.11.4282-.1937.6334-.1298.3076-.5051.6339-1.1263.9789-.5783.3142-1.1511.4705-1.7173.4705-.16617 0-.32849-.011-.48476-.0325a4.0488 4.0488 0 0 1-.53097-.115 7.52097 7.52097 0 0 1-.43854-.1337c-.09464-.0336-.26632-.0974-.51227-.1893-.24651-.0924-.39672-.148-.4523-.1662-.56454-.197-1.10598-.4545-1.61605-.767-.78793-.4854-1.60174-1.149-2.44195-1.9892-.83966-.8403-1.50325-1.65413-1.98911-2.4421a8.359 8.359 0 0 1-.76703-1.61613 19.36308 19.36308 0 0 0-.16617-.45232c-.06437-.17003-.1271-.34116-.18873-.5123-.04952-.14472-.09409-.29109-.1348-.43856a3.99436 3.99436 0 0 1-.115-.53155C.01044 5.45346 0 5.29168 0 5.1299c0-.56567.15682-1.13904.47045-1.71737.34445-.62125.67129-.99653.97887-1.12639.15407-.06768.36481-.13207.63223-.1937C2.34951 2.03081 2.56686 2 2.73303 2c.08583 0 .15076.00935.19368.02806.1106.03632.27347.27018.48916.70214.06768.11721.16012.28284.27677.49799.1172.2157.2245.41105.32299.58603.09794.17554.19368.34007.28612.49414.01871.02476.07264.10125.16177.23056.08914.12986.15517.23882.19809.32851.04347.08914.06493.17663.06493.26247 0 .12326-.08749.27679-.26302.46168a4.4933 4.4933 0 0 1-.57224.50844 5.4506 5.4506 0 0 0-.5728.48919c-.17553.17223-.26301.3142-.26301.42425a.62667.62667 0 0 0 .04567.208c.03136.08309.05722.14637.07868.18874.02201.04347.06493.11721.12986.22231.06437.104.09959.16233.10619.17553.4677.84301 1.00308 1.56661 1.60614 2.1697.60306.60306 1.32663 1.13906 2.16959 1.60626.01321.0066.07153.0418.17553.1067.10454.0649.17882.1073.22174.1293.04292.0215.1062.0473.18928.0782.06603.0269.13646.0429.20799.0462.1106 0 .25201-.0875.42424-.263a5.45331 5.45331 0 0 0 .48916-.5723 4.4178 4.4178 0 0 1 .50786-.5723c.18433-.1755.3384-.2641.46165-.2641.08639 0 .17445.0214.26305.0649.0897.0429.1986.109.3285.1981.1293.0891.2057.1431.2305.1618.1541.0924.3186.1871.4941.2861.1755.0991.3703.2058.586.323.2152.1172.3813.2091.4985.2768.4309.2157.6647.3791.7021.4892a.51591.51591 0 0 1 .0276.1942v.0006Z"/>
	<path d="m15.04 6.71-.02.75c0 .15-.06.29-.17.4-.11.11-.24.17-.4.17H8.01995c-.15 0-.29-.06-.4-.17-.11-.11-.17-.24-.17-.4V1.03c0-.15.06-.29.17-.4.11-.11.24-.17.4-.17l.75-.02c.15 0 .28.06.39.17.11.11.16.25.16.41l-.08 3.89L13.99.17c.11-.11.24-.17.4-.17.16 0 .29.06.4.17l.53.53c.11.11.17.24.17.4 0 .16-.06.29-.17.4l-4.76 4.76 3.89-.08c.15 0 .29.05.41.16s.17.23.17.39l.01-.02Z"/>
</svg>
</template>

<script>
export default {
   name: 'CallIncomingSVG',
}
</script>