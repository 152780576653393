<!-- errorModal.vue -->
<template>	
	<div>
	
  	<!-- Error modal -->
  	<b-modal v-if="slots.error" id="alert" hide-header-close modal-class="error">
		<template #modal-title>
	  		Error
		</template>
		<div class="d-block text-center">
	  		<slot name="error"></slot>
		</div>
		<template #modal-footer>
			<button class="btn red solid" @click="$bvModal.hide('alert')">Okay</button>
		</template>
	</b-modal>
	
	<!-- Success modal -->
	<b-modal v-if="slots.success" id="alert" hide-header-close class="success">
		<template #modal-title>
			  Success
		</template>
		<div class="d-block text-center">
			  <slot name="success"></slot>
		</div>
		<template #modal-footer>
			<button class="btn solid" @click="$bvModal.hide('alert')">Okay</button>
		</template>
	</b-modal>
	  
	</div>
</template>

<script setup>
	import { useSlots, ref, onMounted } from 'vue'
	const slots = useSlots()	
</script>