<template>
    <svg :width="size" :height="size" viewBox="0 0 26 26" :fill="fill">
        <path d="M24.2431 6.44864C23.068 4.45127 21.4936 2.88762 19.4971 1.73486C17.5007 0.58209 15.3444 0 12.9943 0C9.40061 0 6.33172 1.2669 3.79903 3.8007C1.26634 6.3345 0 9.40474 0 13C0 15.374 0.581834 17.5654 1.75691 19.5514C2.93199 21.5487 4.50636 23.1124 6.50285 24.2651C8.49934 25.4179 10.6556 26 13.0057 26C16.5994 26 19.6683 24.7331 22.201 22.1993C24.7337 19.6655 26 16.5953 26 13C26 10.626 25.4182 8.43459 24.2431 6.44864ZM12.9943 4.20018C14.6942 4.20018 16.2229 4.63389 17.5691 5.46708L5.46468 17.5768C4.63186 16.23 4.19833 14.712 4.19833 13.0342C4.19833 10.5689 5.06538 8.48025 6.78806 6.76822C8.52216 5.05619 10.5871 4.20018 12.9943 4.20018ZM12.9943 21.8112C11.2944 21.8112 9.76569 21.3775 8.41948 20.5443L20.5239 8.43459C21.3567 9.78139 21.7903 11.2994 21.8017 12.9772C21.8017 15.4425 20.9346 17.5312 19.2119 19.2432C17.4778 20.9552 15.4129 21.8112 13.0057 21.8112H12.9943Z" />
    </svg>
</template>

<script>
export default {
   name: 'blacklistSVG',
   props: {
    size: {
      type: [Number, String],
      default: 22
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>