<template>
	<svg viewBox="0 0 11 18" class="paginleftSVG">
	  <path d="M0.334869 8.28599L8.35409 0.282074C8.54797 0.0881466 8.77709 -1.71302e-06 9.05908 -1.73767e-06C9.34108 -1.76232e-06 9.5702 0.0881466 9.76407 0.282074L10.6806 1.19882C10.8744 1.39275 10.9802 1.62194 10.9978 1.90401C11.0154 2.18609 10.9273 2.41528 10.7334 2.60921L4.33567 9.00882L10.7158 15.3908C10.9097 15.5847 10.9802 15.8315 10.9802 16.096C10.9802 16.3604 10.8568 16.6072 10.6629 16.8012L9.76407 17.7179C9.5702 17.9119 9.34108 18 9.05908 18C8.77709 18 8.54797 17.9119 8.3541 17.7179L0.33487 9.69638C0.123374 9.50245 1.14443e-06 9.27326 1.11977e-06 8.99119C1.09511e-06 8.70911 0.105749 8.47992 0.334869 8.28599Z" />
	</svg>
</template>

<script>
export default {
   name: 'paginleftSVG',
}
</script>