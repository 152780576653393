<template>
  <div>
    <Breadcrumbs main="Project" title="Smart Hooks" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="target"></feather> 
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-xl-12 col-lg-12">
                                <div class="project-box">
                                  <div class="row">
                                    <div class="col-2">
                                      <h6>Distribution Method</h6>
                                      <multiselect
                                        v-model="selectedDistributionMethod"
                                        tag-placeholder="Select hook distribution method."
                                        placeholder="Select Distribution Method"
                                        label="name"
                                        track-by="code"
                                        :options="distributionMethods"
                                        :multiple="false"
                                      ></multiselect>
                                    </div>
                                    <div class="col-2">
                                      <div class="row">
                                        <span class="float-right">
                                          <label for="from">Minimum Conversion %: </label>
                                          <input type="number" max="100" v-model="minimumConversion"></input>
                                        </span>
                                      </div>
                                      <div class="row">
                                        <div>
                                          <label>Conversion Type</label>
                                          <multiselect
                                            v-model="selectedConversionType"
                                            tag-placeholder="Select hook conversion tracking type."
                                            placeholder="Select Hook Conversion Type"
                                            label="name"
                                            track-by="code"
                                            :options="conversionTypes"
                                            :multiple="false"
                                          ></multiselect>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <div class="mx-2">
                                        <label for="from">From:</label>
                                        <datepicker
                                          input-class="datepicker-here form-control digits"
                                          :format="format"
                                          v-model="fromDate"
                                        ></datepicker>
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <div class="mx-2">
                                        <label for="from">To:</label>
                                        <datepicker
                                          input-class="datepicker-here form-control digits"
                                          :format="format"
                                          v-model="toDate"
                                        ></datepicker>
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <multiselect
                                        v-model="dateFormat"
                                        placeholder="Select TimeRange"
                                        label="name"
                                        track-by="code"
                                        :options="dateOptions"
                                        :multiple="false"
                                      ></multiselect>
                                    </div>
                                    <div class="col-2">
                                      <div>
                                        <button
                                          class="btn btn-primary mt-4"
                                          @click="openHookModal()"
                                        >
                                          Add Hook
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          class="btn btn-primary mt-4"
                                          @click="saveSettings()"
                                        >
                                          Save Settings
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="showAllHooks"
                                          value="all"
                                          v-model="showArchived"
                                        >
                                        <label class="form-check-label" for="showAllHooks">
                                          Show All Hooks
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="showActiveHooks"
                                          value="active"
                                          v-model="showArchived"
                                        >
                                        <label class="form-check-label" for="showActiveHooks">
                                          Show Only Active Hooks
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-xl-12 col-lg-12"
                                v-for="smartHook in filteredSmartHooks"
                                :key="smartHook.id"
                              >
                                <div class="project-box">
                                  <span
                                    class="badge"
                                    :class="
                                      smartHook?.status_num == 1
                                        ? 'badge-success'
                                        : 'badge-danger'
                                    "
                                  >{{
                                    smartHook?.status_num == 1
                                      ? "Active"
                                      : "Archived"
                                  }}</span>
                                  <div class="row">
                                    <div class="col-2">
                                      <textarea
                                        id=""
                                        rows="10"
                                        v-model="smartHook.hook"
                                        style="
                                          background-color: black;
                                          color: white;
                                          border-radius: 20px 20px 0px 0px;
                                          padding: 20px;
                                          width: 100%;
                                        "
                                        placeholder="Add a description for this trigger."
                                        data-tribute="true"
                                        spellcheck="false"
                                      ></textarea>
                                    </div>
                                    <div class="col-2">
                                      <div class="form-group">
                                        <label class="control-label">Landing Page</label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          placeholder="google.com/"
                                          required="required"
                                          v-model="smartHook.target"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-7">
                                      <div class="row">
                                        <div class="col-2">
                                          <h6>Character Count</h6>
                                          <div class="text-success">
                                            {{
                                              smartHook?.character_count || 0
                                            }}
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>CTR</h6>
                                          <div class="text-primary">
                                            {{
                                              parseFloat(smartHook?.click_conversion * 100).toFixed(2) || 0
                                            }}%
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Positive Response</h6>
                                          <div class="text-primary">
                                            {{
                                              smartHook?.positive_response || 0
                                            }} - {{
                                              parseFloat(smartHook?.positive_percent).toFixed(3) || 0
                                            }}%
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Negative Response</h6>
                                          <div class="text-primary">
                                            {{
                                              smartHook?.negative_response || 0
                                            }}
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Clicked</h6>
                                          <div class="text-primary">
                                            {{
                                              smartHook?.clicks || 0
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                      <br>
                                      <div class="row">
                                        <div class="col-2">
                                          <h6>Delivery %</h6>
                                          <div class="text-primary">
                                            {{
                                              parseFloat(smartHook?.delivery_percent * 100).toFixed(2) || 0
                                            }}%
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Spam %</h6>
                                          <div class="text-primary">
                                            {{
                                              parseFloat(smartHook?.spam_percent * 100).toFixed(2) || 0
                                            }}%
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Goals</h6>
                                          <div class="text-primary">
                                            {{
                                              smartHook?.goals || 0
                                            }}
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Landing Page Conversion</h6>
                                          <div class="text-primary">
                                            {{
                                              parseFloat(smartHook?.landing_page_conversion * 100).toFixed(2) || 0
                                            }}%
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <h6>Delivered</h6>
                                          <div class="text-primary">
                                            {{
                                              smartHook?.delivered || 0 | comma
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-1">
                                      <h6>Status</h6>
                                      <div>
                                        <multiselect
                                          v-model="smartHook.status"
                                          tag-placeholder="Select hook status."
                                          placeholder="Select Hook Status"
                                          label="name"
                                          track-by="name"
                                          :options="hookStatuses"
                                          :multiple="true"
                                        ></multiselect>
                                      </div>
                                      <br>
                                      <br>
                                      <br>
                                      <div>
                                        <button
                                          class="btn btn-primary mt-4"
                                          @click="updateHook(smartHook.id)"
                                        >
                                          Update Hook
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Trigger Modal -->
    <b-modal
      id="add-hook"
      v-model="hookModal"
      size="md"
      title="hookTitle"
      class="theme-modal"
      hide-footer
      hide-header
    >
      <h6>Target</h6>
      <input
        class="form-control"
        type="text"
        placeholder="Target"
        v-model="newHook.target"
      />

      <h1>Hook</h1>
      <textarea
        id="supervisor-role-prompt"
        rows="10"
        v-model="newHook.hook"
        style="
          background-color: black;
          color: white;
          border-radius: 20px 20px 0px 0px;
          padding: 20px;
          width: 100%;
        "
        placeholder="Add a description for this trigger."
        data-tribute="true"
        spellcheck="false"
      ></textarea>
      <button class="btn btn-primary float-right" @click="addHook()">
        Add Hook
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: { Multiselect, Datepicker },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      smartHooks: [],
      distributionMethods: [],
      selectedDistributionMethod: [],
      conversionTypes: [
        {
          code: "LINK_CLICK",
          name: "Link Click"
        },
        {
          code: "POSITIVE_RESPONSE",
        }
      ],
      selectedConversionType: {
        code: "LINK_CLICK",
        name: "Link Click"
      },
      hookStatuses: [
        {
          name: "ACTIVE"
        },
        {
          name: "ARCHIVED"
        }
      ],
      newHook: {
        name: "",
        hook: "",
        target: ""
      },
      hookModal: false,
      minimumConversion: 0.0,
      days: 0,
      format: "MM/dd/yyyy",
      fromDate: moment().subtract(1, "days").format("MM/DD/YYYY"),
      toDate: moment().subtract(1, "days").format("MM/DD/YYYY"),
      dateFormat: [{ code: 76, name: "Yesterday" }],
      dateOptions: [
        { code: 1, name: "Today" },
        { code: 76, name: "Yesterday" },
        { code: 7, name: "Last 7 Days" },
        { code: 30, name: "Last 30 Days" },
        { code: 77, name: "This Week" },
        { code: 78, name: "Last Week" },
        { code: 88, name: "This Month" },
        { code: 99, name: "Last Month" },
        { code: 100, name: "Year To Date" },
        { code: 101, name: "All" }
      ],
      showArchived: 'all' // Add this data property
    };
  },
  computed: {
    filteredSmartHooks() {
      if (this.showArchived === 'all') {
        return this.smartHooks;
      } else {
        return this.smartHooks.filter(hook => hook.status.name === 'ACTIVE');
      }
    }
  },
  watch: {
    dateFormat: {
      handler: "formatStats", // Specify the method to call
      deep: true // Watch for deep changes in the array
    },
    fromDate: {
      handler: "getSmartHooks", // Specify the method to call
      deep: true // Watch for deep changes in the array
    },
    toDate: {
      handler: "getSmartHooks", // Specify the method to call
      deep: true // Watch for deep changes in the array
    }
  },
  mounted() {
    this.getSmartHookSettings();
    this.getSmartHooks();
  },
  methods: {
    formatStats() {
      let currentSelected = parseInt(this.dateFormat.code);
      console.log("Current Selected:" + currentSelected);
      if (currentSelected == 76) {
        // Yesterday
        this.fromDate = moment().subtract(1, "days").format("MM/DD/YYYY");
        this.toDate = moment().subtract(1, "days").format("MM/DD/YYYY");
      } else if (currentSelected == 77) {
        // This Week
        this.fromDate = moment().startOf("week").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 78) {
        // Last Week
        this.fromDate = moment()
          .subtract(1, "weeks")
          .startOf("week")
          .format("MM/DD/YYYY");
        this.toDate = moment()
          .subtract(1, "weeks")
          .endOf("week")
          .format("MM/DD/YYYY");
      } else if (currentSelected == 88) {
        // This Month
        this.fromDate = moment().startOf("month").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 99) {
        // Last Month
        this.fromDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        this.toDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("MM/DD/YYYY");
      } else if (currentSelected == 100) {
        // YTD
        this.fromDate = moment().startOf("year").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else if (currentSelected == 101) {
        // All (Example: Setting fromDate to January 1, 2000)
        this.fromDate = moment("2000-01-01").format("MM/DD/YYYY");
        this.toDate = moment().format("MM/DD/YYYY");
      } else {
        this.toDate = moment().add(0, "days").format("MM/DD/YYYY");

        this.fromDate = moment()
          .subtract(currentSelected - 1, "days")
          .format("MM/DD/YYYY");
      }
      this.getSmartHooks();
    },
    async saveSettings() {
      if (this.selectedDistributionMethod.length != 1) {
        // show toast
        this.$toasted.show("Double check your settings.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000
        });
        return;
      }
      let response = await axios.get(
        "https://123avatars.com/backend/saveSmartHookSettings.php?id=" +
          this.userId +
          "&settingId=" +
          this.selectedDistributionMethod[0].code +
          "&minimum_conversion=" +
          this.minimumConversion +
          "&days=" +
          this.days +
          "&conversion_type=" +
          this.selectedConversionType.code
      );
      if (response.status == 200) {
        this.$toasted.show("Successfully saved settings.", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "info",
          duration: 10000
        });
      } else {
        this.$toasted.show("Failed to update settings.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000
        });
      }
      //this.smartHooks = response.data;
      // show toast on resp.
    },
    async addHook() {
      console.log("newHook", this.newHook);
      let response = await axios.get(
        "https://123avatars.com/backend/addSmartHook.php?id=" +
          this.userId +
          "&hook=" +
          encodeURIComponent(this.newHook.hook) +
          "&status=1" +
          "&target=" +
          this.newHook.target
      );
      if (response.status == 200) {
        let hook = response.data;
        let status = hook.status;
        hook.status = {
          name: status
        };
        this.smartHooks.push(hook);
        this.hookModal = false;
        this.$toasted.show("Successfully added smart hook.", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "info",
          duration: 10000
        });
      } else {
        // show toast
        this.$toasted.show("Failed to add smart hook.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000
        });
      }
    },
    async openHookModal() {
      this.newHook = {
        name: "",
        hook: "",
        target: ""
      };
      this.hookModal = true;
    },
    async updateHook(hookId) {
      console.log("smartHooks: ", this.smartHooks);
      let selectedHookIdx = this.smartHooks.findIndex(
        hook => hook.id == hookId
      );
      console.log("selectedSmartHook: ", this.smartHooks[selectedHookIdx]);
      let status = "";
      if (this.smartHooks[selectedHookIdx].status[0]) {
        if (this.smartHooks[selectedHookIdx].status.length != 1) {
          // show toast
          this.$toasted.show("Double check your settings.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000
          });
          return;
        }
        status = this.smartHooks[selectedHookIdx].status[0].name;
      } else {
        status = this.smartHooks[selectedHookIdx].status.name;
      }
      let response = await axios.get(
        "https://123avatars.com/backend/updateSmartHook.php?id=" +
          this.userId +
          "&hook=" +
          encodeURIComponent(this.smartHooks[selectedHookIdx].hook) +
          "&status=" +
          status +
          "&hook_id=" +
          hookId +
          "&target=" +
          this.smartHooks[selectedHookIdx].target
      );
      if (response.status == 200) {
        let smartHook = response.data;
        let status = smartHook.status;
        let hookStatus = this.hookStatuses.find(
          hook => hook.name == status
        );
        smartHook.status = hookStatus;
        this.smartHooks[selectedHookIdx] = smartHook;
        this.$forceUpdate();
        this.$toasted.show("Successfully updated smart hook.", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "info",
          duration: 10000
        });
      } else {
        // show toast
        this.$toasted.show("Failed to update campaign.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000
        });
      }
    },
    async getSmartHooks() {
      let fromDate = new Date(this.fromDate);
      let toDate = new Date(this.toDate);
      // Format the dates as MM/DD/YYYY
      const formattedFromDate = `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${fromDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      const formattedToDate = `${toDate.getFullYear()}-${(toDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${toDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      // Create the date range string
      let dateRange = `${formattedFromDate} - ${formattedToDate}`;
      console.log("dateRange: ", dateRange);
      this.smartHooks = [];
      let response = await axios.get(
        "https://123avatars.com/backend/getSmartHooks.php?id=" +
          this.userId +
          "&fromDate=" +
          formattedFromDate +
          "&toDate=" +
          formattedToDate
      );
      let hooks = response.data;
      hooks?.forEach(async hook => {
        let status = hook.status;
        hook.status = {
          name: status
        };
        this.smartHooks.push(hook);
      });
    },
    async getSmartHookSettings() {
      let response = await axios.get(
        "https://123avatars.com/backend/getSmartHookSettings.php?id=" +
          this.userId
      );
      this.minimumConversion = response.data.minimumConversion;
      console.log(
        "conversionType: ",
        this.conversionTypes.find(
          type => type.code == response.data.conversionType
        )
      );
      this.selectedConversionType = this.conversionTypes.find(
        type => type.code == response.data.conversionType
      );
      let settings = response.data.smart_hook_settings;
      settings?.forEach(async setting => {
        let newSetting = {
          code: setting.id,
          name: setting.name,
          mainHook: setting.main_hook,
          test: setting.test_hook
        };
        this.distributionMethods.push(newSetting);
        if (setting.status == 1) {
          this.selectedDistributionMethod.push({
            code: setting.id,
            name: setting.name,
            mainHook: setting.main_hook,
            test: setting.test_hook
          });
        }
      });
    }
  }
};
</script>