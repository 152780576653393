<template>
	<svg viewBox="0 0 30 25">
  		<path d="M29.6808 21.4285c.3789.3189.4188.6777.1396 1.0764l-.9375 1.1761c-.3192.3787-.6583.4186-1.0373.1395L.31905 2.57133c-.37898-.31893-.41888-.67774-.13962-1.07641l.9375-1.17608c.31915-.37874.65824-.4186 1.01728-.13953l8.37769 6.43853V4.52482c0-1.23588.4388-2.31229 1.3165-3.16943C12.706.49824 13.7632.03977 14.9999.03977c.8178 0 1.5559.19934 2.234.598.6782.39868 1.2367.95682 1.6357 1.63456.3989.67774.5984 1.43522.5984 2.23256V11.98c0 .4385-.0798.897-.2394 1.3953l1.2168.9369c.3391-.7575.5186-1.5349.5186-2.3322V9.74741c0-.21927.0798-.39867.2194-.5382.1396-.13954.3192-.21927.5386-.21927h.758c.2194 0 .3989.07973.5385.21927.1397.13953.2194.31893.2194.5382V11.98c0 1.3156-.3191 2.5515-.9375 3.7475l7.3804 5.701Zm-10.9309.299c.2194 0 .3989.0797.5386.2193.1396.1395.2194.3189.2194.5382v.7574c0 .2193-.0798.3987-.2194.5382-.1397.1396-.3192.2193-.5386.2193h-7.48c-.2195 0-.399-.0797-.5386-.2193-.1396-.1395-.2194-.3189-.2194-.5382v-.7574c0-.2193.0798-.3987.2194-.5382.1396-.1396.3191-.2193.5386-.2193h2.613v-1.5947c-2.0346-.2791-3.7101-1.2359-5.0665-2.8704-1.35639-1.6346-2.03458-3.5083-2.03458-5.6213v-.3189l2.43351 1.9136c.17952.8173.51862 1.5548.99737 2.2325.4787.6778 1.097 1.2559 1.8151 1.7143.7181.4585 1.516.7176 2.3538.7774.2194.0399.5385.0399.9773 0l2.3338 1.814c-.5585.1794-1.097.3189-1.5957.3787v1.5947h2.613l.0399-.0199Z"/>
	</svg>
</template>

<script>
export default {
   name: 'MuteBSVG',
}
</script>