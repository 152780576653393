<template>
  <div>
    <Breadcrumbs main="Project" title="Wakeup Settings" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Triggers -->
        <div class="col-md-6 project-list">
          <div class="card bg-white text-black p-4" style="color: black">
            <h4>Triggers</h4>
            <p>
              These are the available triggers to initiate a given wakeup funnel.
            </p>
            <!-- trigger Sample -->
            <div class="btn btn-secondary text-white mt-2" v-for="trigger of triggers" @click="openTriggersModal(trigger)">
              <i class="fa fa-clock-o"></i>
              {{ trigger.name }} ({{ trigger.count | comma }})
            </div>
          </div>
        </div>
        <!-- Wakeup Funnels -->
        <div class="col-md-6 project-list">
          <div class="card bg-white text-black p-4" style="color: black">
            <h4>Wakeup Funnels</h4>
            <p>
              This is Wakeup Funnels <b>Starts</b> as soon as
              <b>Prospect triggers</b> attached event.
            </p>
            <!-- trigger Sample -->
            <div
              class="btn btn-info text-white mt-2"
              v-for="funnel in funnels"
              :key="funnel.id"
              @click="getSteps(funnel)"
            >
              {{ funnel.name }}
            </div>

            <!-- Add Trigger -->
            <div
              class="btn btn-primary text-white mt-3"
              v-b-modal.add-funnel
              @click="
                steps = [];
                newFunnel = '';
                selectedFunnel = [];
                triggersValue = [];
              "
            >
              <i class="fa fa-plus"></i> Add Funnel
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Add Trigger Modal -->
    <b-modal
      id="add-trigger"
      v-model="triggerModal"
      size="md"
      title="Btitle"
      class="theme-modal"
      hide-footer
      hide-header
    >
      <h6>Modify the Trigger for your Event</h6>
      <input
        class="form-control"
        type="text"
        placeholder="Trigger Name"
        v-model="trigger.name"
      />

      <p class="mt-2">
        Event will be saved to your <i>123Avatars Account</i> with a
        <b>unique-identifier</b> for tracking from 3rd-party websites and can be
        used all across your campaigns.
      </p>
      <h1>{{ "Trigger Description" }}</h1>
      <textarea
        id="supervisor-role-prompt"
        rows="10"
        v-model="trigger.trigger_description"
        class="textarea-custom"
        placeholder="Add a description for this trigger."
        data-tribute="true"
        spellcheck="false"
      ></textarea>
      <h6>Modify goal URL for this trigger.</h6>
      <input
        class="form-control"
        type="text"
        placeholder="Trigger Goal URL"
        v-model="trigger.goal_url"
      />
      <button class="btn btn-primary float-right" @click="updateTrigger()">
        Update Trigger
      </button>
    </b-modal>

    <!-- Wakeup Funnel Modal -->
    <b-modal
      id="add-funnel"
      v-model="funnelModal"
      size="lg"
      title="Btitle"
      class="theme-modal"
      hide-footer
      hide-header
    >
      <h6>Add new Wakeup Funnel</h6>
      <p>This can be attached to one or multiple <i>Trigger Events</i>.</p>
      <p class="mt-2">
        Funnel will start immediately after <i>Trigger Event</i> is triggered.
      </p>
      <input
        class="form-control"
        type="text"
        placeholder="Funnel Name"
        v-model="newFunnel"
      />
      <label class="control-label">Select Triggers to associate with wakeup funnel.</label>
      <div class="form-group"
      style="
      max-height: 5%;
      max-width: 40%;
      overflow: auto;
      display: flex;
      justify-content: left;
      align-items: center;
      border-radius: 20px 20px 20px 20px;
    ">
        
        <multiselect
          v-model="triggersValue"
          tag-placeholder="Add a trigger for the funnel"
          placeholder="Select Triggers"
          label="name"
          track-by="id"
          :options="triggers"
          :multiple="false"
          :max-height=600

        ></multiselect>
      </div>
      <!-- Current steps -->
      <div class="container mt-2" v-for="(_step,index) in steps" :key="_step.id">
        <div class="row">
          <div class="col-md-2">
            <div class="btn btn-primary text-white mt-2 w-100"
            :style="_step.type.code == 2 ? 'bg-danger' : ''">
              {{ _step.type.name || "sms" }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="btn btn-info text-white mt-2 w-100">
              {{ _step.days != null ? _step.days + " Days" : "" }}
              {{ _step.hours != null ? _step.hours + " Hours" : "" }}
              {{ _step.mins != null ? _step.mins + " Mins" : "" }}
            </div>
          </div>
          <div class="col-md-4">
            
            <div v-if="_step.type.code != 2" class="btn btn-primary text-white mt-2 w-100" @click="showTable(index, 'Description', true)">
              Description
            </div>
            <div v-if="_step.type.code == 2" class="btn btn-primary text-white mt-2 w-100" @click="showTable(index, 'Description', false)">
              Audio Transcripts
            </div>
            <b-modal
              id="modal-lg22" 
              size="lg"
              title="Step Description"
              hide-footer
              hide-header
              v-model="tableModal"
            >
              <h1>Step Description</h1>
              <textarea
                id=""
                rows="10"
                v-model="steps[stepDescriptionId].sequence_step_description"
                class="textarea-custom"
                placeholder="Add a prompt for this _step of the sequence."
                data-tribute="true"
                spellcheck="false"
              ></textarea>
            </b-modal>
            <b-modal
              id="modal-lg22" 
              size="lg"
              title="Voicemail Modal"
              hide-footer
              hide-header
              v-model="voicemailModal"
            >
            <h2>{{ steps[stepDescriptionId].days != null ? steps[stepDescriptionId].days + " Days" : "", steps[stepDescriptionId].hours != null ? steps[stepDescriptionId].hours + " Hours" : "", steps[stepDescriptionId].mins != null ? steps[stepDescriptionId].mins + " Mins" : "" }}</h2>
            <br>
            <div class="justify-content-center">
              <h1>Voicemail Message</h1>
              <div class="row">
                <div class="col">
                  <vue-audio :file="steps[stepDescriptionId].audio_url"></vue-audio>
                </div>
                <div class="col">
                  <button @click="generateVoicemail()">REGENERATE</button>
                </div>
              </div>
              <br>
              <br>
              <div class="row">
                <textarea
                  id=""
                  rows="10"
                  v-model="steps[stepDescriptionId].sequence_step_description"
                  class="textarea-custom"
                  placeholder="Add a description for this voicemail message."
                  data-tribute="true"
                  spellcheck="false"
                ></textarea>
              </div>
            </div>
            <div class="justify-content-center">
              <h1>Human Answer Message</h1>
              <div class="row">
                <div class="col">
                  <vue-audio :file="steps[stepDescriptionId].transfer_audio_url"></vue-audio>
                </div>
                <div class="col">
                  <button @click="generateTransferMessage()">REGENERATE</button>
                </div>
              </div>
              <br>
              <br>
              <div class="row">
                <textarea
                  id=""
                  rows="10"
                  v-model="steps[stepDescriptionId].transfer_message_transcript"
                  class="textarea-custom"
                  placeholder="Add a description for this transfer message."
                  data-tribute="true"
                  spellcheck="false"
                ></textarea>
              </div>
            </div>
            </b-modal>
          </div>
          <div class="col-md-2 w-100">
            <div
              class="btn btn-danger text-white mt-2"
              @click="removeStep(_step.id)"
            >
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- New Step -->
      <div class="container mb-4 mt-2">
        <div class="row">
          <div class="col-md-4">
            <multiselect
              v-model="step.type"
              placeholder="Method"
              label="name"
              track-by="code"
              :options="typeOptions"
              :multiple="false"
            ></multiselect>
          </div>
          <div
            class="col-md-6 d-flex"
            style="justify-content: center; align-items: center"
          >
            <small class="ml-2">Days: </small>
            <input
              class="form-control"
              placeholder="Days"
              v-model="step.days"
            />
            <small class="ml-2">Hours: </small>
            <input
              class="form-control"
              placeholder="Hours"
              v-model="step.hours"
            />
            <small class="ml-2">Mins: </small>
            <input
              class="form-control"
              placeholder="Mins"
              v-model="step.mins"
            />
          </div>
          <div class="col-md-2">
            <div class="btn btn-primary text-white" @click="addStep()">
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
      </div>

      <button
        v-if="selectedFunnel.id > 0"
        class="btn btn-danger float-left"
        @click="deleteFunnel()"
      >
        Delete Funnel
      </button>
      <button class="btn btn-primary float-right" @click="saveFunnel()">
        Save Funnel
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import VueAudio from "vue-audio";

export default {
  components: { Multiselect, 'vue-audio': VueAudio },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      triggers: [],
      triggersValue: [],
      newTrigger: "",
      triggerModal: false,
      voicemailModal: false,
      typeOptions: [
        { code: 1, name: "Text" },
        { code: 2, name: "Call" }
      ],

      step: {
        type: { code: 1, name: "sms" },
        days: 0,
        hours: 0,
        mins: 0,
        sequence_step_description: ''
      },
      steps: [],
      newFunnel: "",
      funnelModal: false,
      selectedFunnel: "",
      funnels: [],
      stepText: {},
      tableModal: false,
      key: 0,
      stepDescriptions: [],
      stepDescriptionId: 0,
      trigger: {
        name: '',
        trigger_description: '',
        goal_url: ''
      }
    };
  },
  mounted() {
    this.getTriggers();
    this.getFunnels();
  },
  methods: {
    async generateVoicemail() {
      let data = {
        userId: localStorage.getItem('userId'),
        stepId: this.steps[this.stepDescriptionId].id,
        modelId: "tts-1-hd",
        voiceId: "nova",
        text: this.steps[this.stepDescriptionId].sequence_step_description
      };
      let response = await axios.post('https://123avatars.com/v2/generate-voicemail', data);
      if(response.status == 200) {
        this.steps[this.stepDescriptionId].audio_url = response.data;
        this.$forceUpdate();
        this.$toasted.show("Successfully generated new voicemail.", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "info",
          duration: 10000,
        });
      } else {
        this.$toasted.show("Failed to generate voicemail.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      }
    },
    async generateTransferMessage() {
      let data = {
        userId: localStorage.getItem('userId'),
        stepId: this.steps[this.stepDescriptionId].id,
        modelId: "tts-1-hd",
        voiceId: "nova",
        text: this.steps[this.stepDescriptionId].transfer_message_transcript
      };
      let response = await axios.post('https://123avatars.com/v2/generate-transfer-message', data);
      if(response.status == 200) {
        this.steps[this.stepDescriptionId].transfer_audio_url = response.data;
        this.$forceUpdate();
        this.$toasted.show("Successfully generated new transfer message.", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "info",
          duration: 10000,
        });
      } else {
        this.$toasted.show("Failed to generate transfer message.", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      }
    },
    openTriggersModal(trigger) {
      this.triggerModal = true;
      this.trigger = trigger;
    },
    showTable(stepDescriptionId, table, isTable) {
      this.stepDescriptionId = stepDescriptionId;
      if(isTable === true) {
        this.tableModal = true;
      } else {
        this.voicemailModal = true;
      }
    },
    deleteFunnel() {
      if (this.selectedFunnel.id) {
        axios.get(
          "https://123avatars.com/backend/deleteFunnel.php?id=" +
            this.selectedFunnel.id
        ).then((res) => {
          this.funnelModal = false;
          this.getFunnels();
        });
      }
    },
    getTriggers() {
      axios
        .get(
          "https://123avatars.com/backend/getWakeupTriggers.php?u=" +
            this.userId
        )
        .then((res) => {
          this.triggers = [];
          let triggers = res.data;
          console.log('triggersResp: ', triggers);
          triggers.forEach((trigger) => {
            console.log("trigger: ", trigger);
            if(trigger.trigger_sequence_status == 1) {
              this.triggersValue.push({
                id: trigger.trigger_id,
                name: trigger.name,
                code: trigger.identifier,
                trigger_description: trigger.trigger_description,
                goal_url: trigger.goal_url,
                count: trigger.count
              });
            }
            this.triggers.push({
              id: trigger.trigger_id,
              name: trigger.name,
              code: trigger.identifier,
              trigger_description: trigger.trigger_description,
              goal_url: trigger.goal_url,
              count: trigger.count
            });
          });
          console.log("this.triggers: ", this.triggers);
        });
    },
    updateTrigger() {
      //check if valid trigger name
      console.log("trigger: ", this.trigger);
      if (this.trigger.name.length < 4) {
        //Through an error
        this.$toasted.show("Trigger name must be greater than 3 characters", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
        return false;
      }
      //Add trigger to database

      axios
        .get(
          "https://123avatars.com/backend/updateTrigger.php?u=" +
            this.userId +
            "&id=" +
            this.trigger.id +
            "&name=" +
            this.trigger.name
            + "&trigger_description=" +
            this.trigger.trigger_description
            + "&goal_url=" +
            this.trigger.goal_url
        )
        .then((res) => {
          console.log(res.data);
          this.getTriggers();

          //empty newTrigger
          this.newTrigger = "";
          this.triggerModal = false;
        });
    },
    addTrigger() {
      //check if valid trigger name
      if (this.newTrigger.length < 4) {
        //Through an error
        this.$toasted.show("Trigger name must be greater than 3 characters", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
        return false;
      }
      //Add trigger to database

      axios
        .get(
          "https://123avatars.com/backend/addWakeupTrigger.php?u=" +
            this.userId +
            "&name=" +
            this.newTrigger
        )
        .then((res) => {
          console.log(res.data);
          this.triggers.push({
            id: res.data.id,
            name: this.newTrigger,
            code: res.data.identifier,
          });

          //empty newTrigger
          this.newTrigger = "";
          this.triggerModal = false;
        });
    },
    addStep() {
      let time =
        parseInt(this.step.days) * 1440 +
        parseInt(this.step.hours) * 60 +
        parseInt(this.step.mins);
        this.step.time = time;

      console.log("this.step: ", this.step);
      if (this.selectedFunnel.id) {
        let stepType = this.step.type.code ? this.step.type.code : this.step.type;
        axios
          .get(
            "https://123avatars.com/backend/addStep.php?u=" +
              this.userId + 
              "&sequence_id=" +
              this.selectedFunnel.id +
              "&sequence_step_id=" +
              this.steps.length +
              "&sequence_step_description=" +
              this.step.sequence_step_description +
              "&type=" +
              stepType +
              "&status=" +
              "1" +
              "&time=" +
              time
          )
          .then((response) => {
            this.step.id = response.data.id;
                this.steps.push(this.step);
                this.step = {
                  type: { code: 1, name: "sms" },
                  days: 0,
                  hours: 0,
                  mins: 0,
                  sequence_step_description: ''
                };
          });
      } else {
        axios
          .get(
            "https://123avatars.com/backend/addFunnel.php?u=" +
            this.userId +
            "&name=" +
            this.newFunnel +
            "&triggers=" + 
            this.triggersValue[0].id
          )
          .then((response) => {
            this.funnelId = response.data.id;
            this.selectedFunnel = response.data;
            let stepType = this.step.type.code ? this.step.type.code : this.step.type;
            axios
              .get(
                "https://123avatars.com/backend/addStep.php?u=" +
                  this.userId + 
                  "&sequence_id=" +
                  this.selectedFunnel.id +
                  "&sequence_step_id=" +
                  this.steps.length +
                  "&sequence_step_description=" +
                  this.step.text +
                  "&type=" +
                  stepType +
                  "&status=" +
                  "1" +
                  "&time=" +
                  time
              )
              .then((response) => {
                this.step.id = response.data.id;
                this.steps.push(this.step);
                this.step = {
                  type: { code: 1, name: "sms" },
                  days: 0,
                  hours: 0,
                  mins: 0,
                  sequence_step_description: ''
                };
              });
          });
      }
      console.log("step: ", this.step);
      console.log("steps: ", this.steps);
    },
    removeStep(id) {
      let index = this.steps.findIndex((step) => step.id == id);
      this.steps.splice(index, 1);

      axios.get("https://123avatars.com/backend/deleteStep.php?id=" + id);
    },
    saveFunnel() {
      console.log("triggersValue: ", this.triggersValue);
      if (this.selectedFunnel?.id) {
        axios({
          method: "post",
          url: "https://123avatars.com/backend/updateFunnel.php?u=" +
              this.userId + 
              "&sequence_id=" +
              this.selectedFunnel.id +
              "&name=" +
              this.newFunnel
              + "&triggers=" +
              this.triggersValue[0].id,
          data: { "sequence_steps": this.steps },
        }).then((response) => {
            this.getFunnels();
            this.$toasted.show("Funnel Updated", {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "info",
              duration: 3000,
            });
          });
        this.funnelModal = false;
        return false;
      } else {
        if (this.newFunnel.length < 3) {
          this.$toasted.show("Trigger name must be greater than 3 characters", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        console.log("triggersValue: ", this.triggersValue);
        axios
          .get(
            "https://123avatars.com/backend/addFunnel.php?u=" +
              this.userId +
              "&name=" +
              this.newFunnel +
              "&triggers=" + 
              this.triggersValue[0].id
          )
          .then((res) => {
            this.funnelId = res.data.id;
            this.funnelModal = false;

            this.getFunnels();

            this.$toasted.show(this.newFunnel + " Funnel Created.", {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 10000,
            });
            this.newFunnel = "";
          }).catch((err) => {
            this.$toasted.show("Error saving funnel. Please check your funnel configurations.", {
              theme: "outline",
              position: "top-right",
              type: "danger",
              icon: "check",
              duration: 10000,
            });
          });
      }
    },
    getFunnels() {
      axios
        .get("https://123avatars.com/backend/getFunnels.php?u=" + this.userId)
        .then((res) => {
          this.funnels = res.data;
        });
    },
    getSteps(funnel) {
      this.steps = [];
      this.selectedFunnel = funnel;
      this.newFunnel = this.selectedFunnel.name;

      this.funnelModal = true;
      axios
        .get(
          "https://123avatars.com/backend/getSteps.php?u=" +
            this.userId +
            "&id=" +
            this.selectedFunnel.id
        )
        .then((res) => {
          this.steps = res.data;
          console.log("steps: ", this.steps);
          let idx = 0;
          this.steps.forEach((step) => {
            step.days = Math.floor(step.time / 1440);
            step.hours = Math.floor((step.time % 1440) / 60);
            step.mins = Math.floor((step.time % 1440) % 60);
            step.type = (step.type == 2) ? { code: 2, name: "Call" } : { code: 0, name: "Text" };
          });
          console.log("steps: ", this.steps);
        });
        axios
        .get(
          "https://123avatars.com/backend/getSequenceTrigger.php?u=" +
            this.userId +
            "&id=" +
            this.selectedFunnel.id
        )
        .then((res) => {
          this.triggersValue = [];
          console.log("triggers : ", res.data);
          res.data.forEach((trigger) => {
            this.triggersValue.push({
                id: trigger.id,
                name: trigger.name,
                code: trigger.identifier,
                trigger_description: trigger.trigger_description,
                goal_url: trigger.goal_url
              });
          });
        });
    },
  },
};
</script>

<style>
.textarea-custom {
  background-color: #2c2c2c; /* Custom background color */
  color: white; /* Custom text color */
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
  width: 100%;
}

.b-modal {
  background: rgba(0, 0, 0, 0.6); /* Dimmed background color */
}

.b-modal .modal-content {
  background-color: white; /* Modal content background color */
}

.b-modal .modal-header,
.b-modal .modal-body,
.b-modal .modal-footer {
  border: none; /* Remove default border */
  padding: 20px; /* Add padding */
}

.b-modal .modal-header {
  border-bottom: none; /* Remove bottom border */
}

.b-modal .modal-footer {
  border-top: none; /* Remove top border */
}
</style>