<template>
  <div class="flex items-center gap-2">
    <checkSVG fill="#5cba47"></checkSVG>
    <span class="font-bold text-2x2" style="font-size: 20px;">Broadcast Sent</span>
  </div>
</template>

<script>
  
  import checkSVG from '../SVG-icons/checkSVG.vue';

  export default {
    components: {
      checkSVG
    },
    data() {
      return {

      }
    }
  }
</script>