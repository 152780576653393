<template>
  <div class="stepper-container">
    <div class="step-content">
      <div class="content-container">
        <div style="padding: 5px;">
          <span class="font-bold text-1xl">Manage DIDs</span>
        </div>
        <div class="keywords-controls">
          <div class="search-container select">
            <select class="form-control" v-model="selectedProfile" @change="handleProfileChange">
              <option value="">Select a Profile</option> <!-- Optional default/placeholder option -->
              <option 
                v-for="profile in messagingProfiles" 
                :key="profile.id"
                :value="profile.id"
              >
                {{ profile.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th style="width: 33%;">ID</th>
                <th style="width: 34%;">DID's From Telnyx Profile</th>
                <th style="width: 33;">Cold Sent Texts</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="(row, index) in dids" :key="row.id" :data-keyword-id="row.id">
                <td>
                  <div @click="toggleSelection(row)">
                    {{ index + 1 }}
                  </div>
                </td>
                <td>{{ formatPhoneNumber(row.phone_number) || '-' }}</td>
                <td>{{ row.health.message_count.toLocaleString() || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrapper" style="padding-top: 20px; padding-bottom: 20px;">
          <div>
            <b>Leads That Have Had 1 Message Sent: {{ summary.actual_sent.toLocaleString() }}</b>
          </div>
          <div>
            <b>Total Cell Phones: {{ (6264111).toLocaleString() }}</b>
        </div>
          <div>
            <b>Leads Still Available To Send To: {{ summary.still_available.toLocaleString() }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import checkedSVG from '../SVG-icons/checkedSVG.vue';
import unCheckedSVG from '../SVG-icons/unCheckedSVG.vue';
import AuthService from '../../_services/AuthService';
import axios from 'axios';

export default {
  components: {
    checkedSVG,
    unCheckedSVG
  },
  methods: {
    async fetchManageDidSummary() {
      const result = await axios.get(`https://123avatars.com/v2/sms-broadcast/did-summary`)
      this.summary = result.data
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
      }
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      const formattedPhoneNumber = `1 (${areaCode}) ${centralOfficeCode}-${stationCode}`;
      return formattedPhoneNumber;
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A'
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    toggleSelection(row) {
      row.selected = !row.selected;
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
    async fetchMessagingProfiles() {
      let target = `https://123avatars.com/v2/dids/messaging-profiles`;
      const response = await axios.get(target);
      this.messagingProfiles = response.data;
    },
    async handleProfileChange() {
      if (!this.selectedProfile) {
        this.dids = [];
        return;
      };
      
      try {
        let target = `https://123avatars.com/v2/dids/messaging-profiles/${this.selectedProfile}/phone_numbers`;
        const response = await axios.get(target);
        this.dids = response.data;
      } catch (error) {
        console.error('Error fetching profile details:', error);
      }
    }
  },
  async mounted() {
    await this.initUser();
    await this.fetchMessagingProfiles();
    await this.fetchManageDidSummary();
  },
  data() {
    return {
      selectedProfile: '',
      messagingProfiles: [],
      dids: [],
      userId: '',
      role: '',
      summary: {}
    }
  }
}
</script>

<style scoped>
.stepper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}

.content-container {
  min-height: 200px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.checkbox-checked,
.checkbox-unchecked {
  cursor: pointer;
}
</style>
