<template>
    <div class="blocked-container">
      <h1>Access Denied</h1>
      <p>You no longer have access to the sales dashboard.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SalesBlocked'
  };
  </script>
  
  <style scoped>
  .blocked-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #ff4c4c;
  }
  
  p {
    font-size: 1.2rem;
    color: #333;
  }
  </style>