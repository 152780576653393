<template>
<svg viewBox="0 0 263.28 236.25">
  <path d="M260.59 83.84 217.9 41.15c-1.87-1.87-4.1-2.69-6.67-2.69s-4.91.94-6.67 2.69l-8.94 8.47c-1.84 1.86-2.65 3.84-2.53 6.5.12 2.69 1.17 4.91 2.93 6.67l13.9 12.03-60.55.1-.12-65.57c0-2.57-.82-4.8-2.69-6.67s-4.1-2.69-6.67-2.69h-12.4c-2.57 0-4.8.82-6.67 2.69s-2.69 4.1-2.69 6.67V96.7c0 2.57.82 4.8 2.69 6.67s4.1 2.69 6.67 2.69h82.31l-13.9 12.03c-1.87 1.87-2.93 4.1-2.93 6.67s.82 4.8 2.57 6.55l9.01 8.54c1.87 1.87 4.1 2.69 6.67 2.69s4.91-.94 6.67-2.69l42.69-42.69c1.87-1.87 2.69-4.1 2.69-6.67 0-2.57-.94-4.91-2.69-6.67Z"/>
  <path d="M236.25 186.59c0 3.01-.56 6.96-1.68 11.81-.82 3.93-2 7.78-3.52 11.51-2.36 5.59-9.18 11.52-20.47 17.79-10.51 5.71-20.92 8.55-31.21 8.55-3.02 0-5.97-.2-8.81-.59-3.26-.48-6.49-1.18-9.65-2.09-2.68-.73-5.34-1.54-7.97-2.43-1.72-.61-4.84-1.77-9.31-3.44-4.48-1.68-7.21-2.69-8.22-3.02-10.26-3.58-20.1-8.26-29.37-13.94-14.32-8.82-29.11-20.88-44.38-36.15-15.26-15.27-27.32-30.06-36.15-44.38a151.905 151.905 0 0 1-13.94-29.37c-.97-2.75-1.98-5.49-3.02-8.22-1.17-3.09-2.31-6.2-3.43-9.31-.9-2.63-1.71-5.29-2.45-7.97a72.59 72.59 0 0 1-2.09-9.66C.19 62.76 0 59.82 0 56.88c0-10.28 2.85-20.7 8.55-31.21C14.81 14.38 20.75 7.56 26.34 5.2c2.8-1.23 6.63-2.4 11.49-3.52C42.7.56 46.65 0 49.67 0c1.56 0 2.74.17 3.52.51 2.01.66 4.97 4.91 8.89 12.76 1.23 2.13 2.91 5.14 5.03 9.05 2.13 3.92 4.08 7.47 5.87 10.65 1.78 3.19 3.52 6.18 5.2 8.98.34.45 1.32 1.84 2.94 4.19 1.62 2.36 2.82 4.34 3.6 5.97.79 1.62 1.18 3.21 1.18 4.77 0 2.24-1.59 5.03-4.78 8.39a81.664 81.664 0 0 1-10.4 9.24 99.073 99.073 0 0 0-10.41 8.89c-3.19 3.13-4.78 5.71-4.78 7.71.06 1.3.34 2.58.83 3.78.57 1.51 1.04 2.66 1.43 3.43.4.79 1.18 2.13 2.36 4.04 1.17 1.89 1.81 2.95 1.93 3.19 8.5 15.32 18.23 28.47 29.19 39.43s24.11 20.7 39.43 29.19c.24.12 1.3.76 3.19 1.94 1.9 1.18 3.25 1.95 4.03 2.35.78.39 1.93.86 3.44 1.42 1.2.49 2.48.78 3.78.84 2.01 0 4.58-1.59 7.71-4.78 3.2-3.26 6.17-6.73 8.89-10.4a80.33 80.33 0 0 1 9.23-10.4c3.35-3.19 6.15-4.8 8.39-4.8 1.57 0 3.17.39 4.78 1.18 1.63.78 3.61 1.98 5.97 3.6 2.35 1.62 3.74 2.6 4.19 2.94 2.8 1.68 5.79 3.4 8.98 5.2 3.19 1.8 6.73 3.74 10.65 5.87 3.91 2.13 6.93 3.8 9.06 5.03 7.83 3.92 12.08 6.89 12.76 8.89.39 1.13.56 2.33.5 3.53Z"/>
</svg>


</template>

<script>
export default {
   name: 'missedcallSVG',
}
</script>