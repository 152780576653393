<template>
    <div class="container" style="margin-top:10%; margin-bottom:5%;">
      <div class="header bg-gradient text-white p-3 rounded mb-4 text-center">
        <h1>Lead Summary</h1>
      </div>
      <table class="table table-bordered table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Lead Name</th>
            <th>Mon</th>
            <th>Tues</th>
            <th>Wed</th>
            <th>Thurs</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
            <th>This Week</th>
            <th>Last Week</th>
            <th>This Month</th>
            <th>Last Month</th>
            <th>Last 90 Days</th>
            <th>Last 6 Months</th>
            <th>YTD</th>
            <th>All</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.name">
            <td>{{ row.name }}</td>
            <td v-for="(value, index) in row.values" :key="index">
              <a href="#">{{ value }}</a>
            </td>
          </tr>
        </tbody>
        <tfoot class="bg-gradient text-white">
          <tr>
            <th>Totals</th>
            <td v-for="total in totals" :key="total">
              <a href="#" class="text-white font-weight-bold">{{ total }}</a>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        rows: [],
        totals: [],
      };
    },
    created() {
      this.fetchLeadsData();
    },
    methods: {
      fetchLeadsData() {
        const userId = localStorage.getItem('userId');
        axios.get(`https://123avatars.com/v2/lead-summary`, {
          params: {
            userId: userId
          }
        })
        .then(response => {
          this.rows = response.data.rows;
          this.totals = response.data.totals;
        })
        .catch(error => {
          console.error("There was an error fetching the leads data:", error);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Arial', sans-serif;
    background: #f4f7fa;
    margin: 0;
    padding: 0;
  }
  
  .bg-gradient {
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  }
  
  .table {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  a {
    color: #4b6cb7;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>