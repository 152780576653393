<template>
  <button class="btn big" :class="buttonStyle" @click="emitClick">
	<slot></slot>
  </button>
</template>

<script>
	
export default {
  name: "ScriptButton",
  props: ['buttonStyle'],
  emits: ['click'],
  methods: {
	  emitClick() {
		this.$emit('click');
	  }
	}
};
</script>