<template>
	<svg viewBox="0 0 17 15" class="editSVG">
	  <path d="M11.338 10.133c0-.1.037-.175.113-.237l1.183-1.176c.1-.113.226-.15.364-.088.139.063.227.163.227.325v4.63c0 .387-.138.725-.415 1a1.362 1.362 0 0 1-1.007.413H1.422c-.39 0-.73-.138-1.007-.413a1.346 1.346 0 0 1-.415-1V3.264c0-.388.138-.725.415-1a1.362 1.362 0 0 1 1.007-.413h8.43c.152 0 .265.075.328.225.063.15.025.262-.088.363L8.909 3.616a.293.293 0 0 1-.24.112H1.9v9.383h9.438v-2.99.012Zm.566-7.706 2.655 2.64c.138.137.138.275 0 .413l-6.455 6.417-2.718.288a.477.477 0 0 1-.34-.062.605.605 0 0 1-.252-.25.544.544 0 0 1-.063-.338l.29-2.703 6.468-6.405c.138-.138.276-.138.415 0Zm4.769-.676a1.203 1.203 0 0 1 0 1.652l-1.057 1.026c-.139.137-.277.137-.415 0l-2.655-2.64c-.139-.138-.139-.275 0-.413L13.602.35c.214-.237.479-.35.793-.35.315 0 .592.113.83.35l1.448 1.401Z"/>
	</svg>
</template>

<script>
export default {
   name: 'editSVG',
}
</script>