<template>
  <div
    :class="Btitle == 'Campaign Settings' ? '' : 'col-sm-6 create-project-btn'"
  >
    <div class="text-right">
      <div class="form-group mb-0 mr-0"></div>
      <a
        :class="
          Btitle == 'Campaign Settings'
            ? 'btn btn-secondary'
            : 'btn btn-primary'
        "
        href="#"
        v-b-modal.modal-campaign
        variant="primary"
        @click="moveToStepOne"
      >
        <feather
          class="mr-1"
          type="settings"
          size="14"
          v-if="Btitle == 'Campaign Settings'"
        ></feather>
        <span>{{ Btitle }}</span>
      </a>

      <!-- Large Modal Component -->
      <b-modal
        id="modal-campaign"
        size="xl"
        :title="Btitle"
        class="theme-modal"
        hide-footer
        hide-header
      >
        <div class="card-body">
          <form-wizard
            color="#4466f2"
            :start-index="campaignStep"
            :title="Btitle"
            :subtitle="
              Btitle == 'Campaign Settings'
                ? 'Edit Any Step and click next to save'
                : 'It takes only a few simple steps to start'
            "
            back-button-text="Back"
            :next-button-text="Btitle == 'Campaign Settings' ? 'Next' : 'Next'"
            :finish-button-text="
              Btitle == 'Campaign Settings'
                ? 'Next'
                : 'Confirm Details & Create Now'
            "
            @on-complete="createCampaign"
            v-show="!createCampaignLoading"
            @on-error="handleErrorMessage"
          >
            <tab-content
              title="Campaign Details"
              icon="fa fa-bolt"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img src="../assets/images/step1.gif" style="width: 100%" />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="assets/images/campaigns.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-1">
                <div class="col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Campaign Name</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Campaign A - Roofers"
                      required="required"
                      v-model="newCampaign.name"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Landing Page</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="google.com/"
                      required="required"
                      v-model="newCampaign.target"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label"
                      >Domains ({{ domainsValue.length }} Selected)</label
                    >
                    <multiselect
                      v-model="domainsValue"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Domains"
                      label="name"
                      track-by="code"
                      :options="domains"
                      :multiple="true"
                    ></multiselect>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Available CSPs</label>
                    <multiselect
                      v-model="selectedBrand"
                      tag-placeholder="Select a CSP for this campaign."
                      placeholder="Select a CSP for this campaign."
                      label="name"
                      track-by="code"
                      :options="availableBrands"
                      :multiple="false"
                    ></multiselect>
                  </div>
                  <!-- <div class="form-group">
                    <label class="control-label">Messaging Channel</label>
                    <multiselect
                      v-model="selectedChannel"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Messaging Channel"
                      label="name"
                      track-by="code"
                      :options="channels"
                      :multiple="false"
                    ></multiselect>
                  </div> -->
                  <!-- <div class="form-group">
                    <label class="control-label"
                      >Messaging Brand ({{ brands.length }} Available)</label
                    >
                    <multiselect
                      v-model="selectedBrand"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Messaging Brand"
                      label="name"
                      track-by="code"
                      :options="brands"
                      :multiple="false"
                    ></multiselect>
                  </div> -->
                  <div class="form-group">
                    <label class="control-label"
                      >Messaging Campaign ({{
                        telynxCampaigns.length
                      }}
                      Available)</label
                    >
                    <multiselect
                      v-model="selectedTelynxCampaign"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Brand Campaign"
                      label="name"
                      track-by="code"
                      :options="telynxCampaigns"
                      :multiple="false"
                    ></multiselect>
                  </div>
                  <div class="form-group">
                    <label class="control-label d-flex items-center">
                      Phone Numbers ({{ phonesValue?.length || 0 }} Selected -
                      {{ campaignPhones?.length || 0 }} Available)

                      <!-- Check if there are available phone numbers -->
                      <div v-if="campaignPhones?.length > 0">
                        <!-- Select All Button -->
                        <span
                          class="btn btn-primary ml-2 p-0 pr-2 pl-2"
                          @click="phonesValue = campaignPhones"
                          >Select All</span
                        >
                      </div>
                      <div v-if="phonesValue?.length > 0">
                        <!-- Select All Button -->
                        <span
                          class="btn btn-danger ml-2 p-0 pr-2 pl-2"
                          @click="phonesValue = []"
                          >Deselect All</span
                        >
                      </div>
                    </label>
                    <multiselect
                      v-model="phonesValue"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Phone Numbers"
                      label="name"
                      track-by="code"
                      :options="campaignPhones"
                      :multiple="true"
                    ></multiselect>
                    <button
                      v-if="Btitle == 'Campaign Settings'"
                      class="btn btn-primary mt-4"
                      @click="updateCampaignDetails()"
                    >
                      Update Campaign
                    </button>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content
              title="Leads"
              icon="fa fa-group"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img
                  src="../assets/images/industries.gif"
                  style="width: 100%"
                />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="../assets/images/avatars.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-2">
                <div class="media">
                  <label class="col-form-label m-r-10"
                    >All Leads</label
                  >
                  <div class="media-body text-right icon-state">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked=""
                        v-model="allLeads"
                        @click="toggleLeads('allLeads')"
                      /><span class="switch-state"></span>
                    </label>
                  </div>
                </div>
                <div class="media">
                  <label class="col-form-label m-r-10"
                    >Upload Your Own Leads</label
                  >
                  <div class="media-body text-right icon-state">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked=""
                        v-model="uploadLeads"
                        @click="toggleLeads('uploadLeads')"
                      /><span class="switch-state"></span>
                    </label>
                  </div>
                </div>
                <div class="container" v-if="uploadLeads === true">
                  <h5>Upload your Leads</h5>
                  <p>
                    Your <b>File Structure</b> should be in the following format
                    <a href="sample.csv" data-bs-original-title="" title=""
                      >Download Sample</a
                    >
                  </p>
                  <vue-dropzone
                    id="singledropzone"
                    :options="singledropzoneOptions"
                    class="dropzone digits"
                  >
                  </vue-dropzone>
                </div>
                <div class="container" v-if="allLeads === true">

                </div>
                <div v-if="allLeads === false">
                  <multiselect
                    v-model="industriesValue"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Select Industries"
                    label="name"
                    track-by="code"
                    :options="industries"
                    :multiple="true"
                  >
                  </multiselect>
                  <h5 style="margin-top: 5px; margin-bottom: -10px">
                    Include:
                  </h5>
                  <small>Fields that leads <b>must have</b></small>
                  <multiselect
                    v-model="industriesIncludeValue"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Must have fields"
                    label="name"
                    track-by="code"
                    :options="industriesIncludes"
                    :multiple="true"
                  >
                  </multiselect>
                  <h5 style="margin-top: 5px; margin-bottom: -10px">
                    Exclude:
                  </h5>
                  <small>Fields that leads must <b>NOT</b> have</small>
                  <multiselect
                    v-model="industriesExcludeValue"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Must be empty fields "
                    label="name"
                    track-by="code"
                    :options="industriesExcludes"
                    :multiple="true"
                  >
                  </multiselect>
                </div>
                <button
                  v-if="Btitle == 'Campaign Settings'"
                  class="btn btn-primary mt-4"
                  @click="updateIndustries()"
                >
                  Update Industries
                </button>
              </div>
            </tab-content>
            <tab-content
              title="Limits"
              icon="fa fa-legal"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img src="../assets/images/leads.gif" style="width: 100%" />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="../assets/images/avatars.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-3">
                <!-- Communication method -->
                <div class="col-lg-12" style="text-align: left">
                  Total Leads Selected:
                  <b id="total-selected-leads">{{
                    totalSelectedLeads | formatNumber
                  }}</b>
                </div>
                <!-- <div class="col-lg-12" style="text-align: left">
                  Prevent Conflict:
                  <input
                    class="form-check-input ml-2"
                    id="conflict-check"
                    type="checkbox"
                    checked=""
                    data-bs-original-title=""
                    title=""
                  /><br />
                  <small
                    >Check this to make sure to skip all leads already or ever
                    has been in a conversation with your other avatars</small
                  >
                </div> -->
                <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >New Conversations to start</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="EX: 100"
                      aria-label="EX: 100"
                      id="conv_per_day"
                      data-bs-original-title=""
                      title=""
                      v-model="conversationsPerDay"
                    /><span class="input-group-text">Per Day</span>
                  </div>
                  <button
                  v-if="Btitle == 'Campaign Settings'"
                  class="btn btn-primary mt-4"
                  @click="updateConversations()"
                >
                  Update Conversations
                </button>
                </div>
              </div>
            </tab-content>
            <tab-content
              title="Outreach Method"
              icon="fa fa-paper-plane"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img src="../assets/images/campaigns.gif" style="width: 100%" />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="../assets/images/avatars.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-4">
                <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Communication Method</label
                  >
                  <select id="hooks-select" class="form-control" optional="">
                    <option>SMS</option>
                  </select>

                  <label class="form-label" for="exampleFormControlTextarea4"
                    >ColdReach Hook</label
                  >
                  <small> Select Hook From Templates:</small><br />
                  <multiselect
                    v-model="selectedHook"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Select Hook Template"
                    label="name"
                    track-by="code"
                    :options="hooks"
                    :multiple="false"
                  ></multiselect>
                  <br />
                  <div class="form-check checkbox checkbox-primary mb-0">
                    <span>
                      <input
                      class="form-check-input"
                      id="static-hook-check"
                      type="checkbox"
                      checked=""
                      data-bs-original-title=""
                      title=""
                      v-model="staticHook"
                      @click="staticHookToggle()"
                    />
                    <label class="form-check-label" for="static-hook-check" style="margin-right: 5%"
                      >Static Hook</label
                    >
                    <input
                      class="form-check-input"
                      id="smart-hook-check"
                      type="checkbox"
                      checked=""
                      data-bs-original-title=""
                      title=""
                      v-model="smartHook"
                      @click="smartHookToggle()"
                    />
                    <label class="form-check-label" for="smart-hook-check"
                      >Smart Hook</label
                    >
                    </span>
                    <b-dropdown
                      v-if="smartHook != true"
                      text="Add Variables"
                      variant="primary"
                      style="float: right"
                    >
                      <b-dropdown-header id="dropdown-header-label">
                        System Variables
                      </b-dropdown-header>
                      <b-dropdown-item @click="addHookVariable('@URL-URL')"
                        >URL</b-dropdown-item
                      >

                      <b-dropdown-header id="dropdown-header-label">
                        Lead Variables
                      </b-dropdown-header>
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Owners_Name')"
                        >lead-Owners_Name</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Name')"
                        >lead-Company_Name</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Street_Address')"
                        >lead-Company_Street_Address</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_City')"
                        >lead-Company_City</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_Zipcode')"
                        >lead-Company_Zipcode</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Company_State')"
                        >lead-Company_State</b-dropdown-item
                      >
                      <b-dropdown-item @click="addHookVariable('@lead-Website')"
                        >lead-Website</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Industry')"
                        >lead-Industry</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Year_Established')"
                        >lead-Year_Established</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Years_in_Business')"
                        >lead-Years_in_Business</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Facebook_Page')"
                        >lead-Facebook_Page</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Linkedin_Profile')"
                        >lead-Linkedin_Profile</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Twitter_Profile')"
                        >lead-Twitter_Profile</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-Instagram_Page')"
                        >lead-Instagram_Page</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="addHookVariable('@lead-YouTube_Channel')"
                        >lead-YouTube_Channel</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                  <div v-if="smartHook != true">
                    <label class="form-check-label" for="static-hook-check"
                        >Hook Name</label
                      >
                    <input class="form-control" placeholder="Hook Name" v-model="hookName"
                      style="margin-bottom: 5px"
                    />
                    <div class="form-check checkbox checkbox-primary mb-0">
                    <input
                      class="form-check-input"
                      id="mms-check"
                      type="checkbox"
                      checked=""
                      data-bs-original-title=""
                      title=""
                      v-model="mms"
                    />
                    <label class="form-check-label" for="mms-check"
                      >MMS</label
                    >
                    <multiselect
                    v-if="mms"
                    v-model="selectedMMSImage"
                    tag-placeholder="Add as MMS Image"
                    placeholder="Select Image File"
                    label="name"
                    track-by="code"
                    :options="mmsImages"
                    :multiple="false"
                  ></multiselect>
                  </div>
                  <textarea
                    id="hook-prompt"
                    v-model="hookText"
                    style="
                      padding: 10px;
                      width: 100%;
                      background-color: black;
                      border-radius: 10px;
                      color: white;
                    "
                    rows="5"
                    placeholder="Write a perfect SMS coldreach message to offer our free service to the owner of the following business details:  @lead-Owner-Name @lead-Business-Name @lead-Business-Address , @lead-Business-City , @lead-Business-State "
                    data-tribute="true"
                    spellcheck="false"
                  >
                  </textarea>
                  <div
                    class="counter-container"
                    style="
                      display: flex;
                      justify-content: end;
                      align-items: center;
                    "
                  >
                    <div
                      class="counter mb-2 badge"
                      style="text-align: right"
                      :class="
                        hookText?.length > 160 ? 'badge-danger' : 'badge-success'
                      "
                    >
                      <small>{{
                        hookText?.includes("@URL-URL")
                          ? hookText?.length + 31
                          : hookText?.length
                      }}</small
                      >/160
                    </div>
                  </div>

                  <!-- Limit -->
                  <label
                    >Characters Limit
                    <small
                      >The limit the system shouldn't exceed for replies</small
                    ></label
                  >
                  <input class="form-control" placeholder="160" v-model="charLimit" />

                  <div id="dynamic-hook" v-if="staticHook == false">
                    <small
                      >Provide some coldreach messages you like for the Hook to
                      Generate similar:</small
                    ><br />

                    <label>Example 1</label>
                    <textarea
                      id="hook-1"
                      v-model="example1"
                      style="
                        width: 100%;
                        background-color: black;
                        border-radius: 10px;
                        color: white;
                      "
                      rows="2"
                      placeholder="Hi There, do you want a free website for your business?"
                      optional=""
                    ></textarea>
                    <label>Example 2</label>
                    <textarea
                      id="hook-2"
                      v-model="example2"
                      style="
                        width: 100%;
                        background-color: black;
                        border-radius: 10px;
                        color: white;
                      "
                      rows="2"
                      placeholder="Hi There, do you want a free website for your business?"
                      optional=""
                    ></textarea>
                    <label>Example 3</label>
                    <textarea
                      id="hook-3"
                      v-model="example3"
                      style="
                        width: 100%;
                        background-color: black;
                        border-radius: 10px;
                        color: white;
                      "
                      rows="2"
                      placeholder="Hi There, do you want a free website for your business?"
                      optional=""
                    ></textarea>
                  </div>
                  </div>
                </div>
                <button
                  v-if="Btitle == 'Campaign Settings'"
                  class="btn btn-primary mt-4"
                  @click="updateHook()"
                >
                  Update Hook
                </button>
              </div>
            </tab-content>
            <tab-content
              title="Avatar Identity"
              icon="fa fa-male"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <!-- <img src="../assets/images/industries.gif" style="width: 100%" /> -->
                <video width="100%" autoplay loop muted>
                  <source src="../assets/images/avatars.mp4" type="video/mp4" />
                </video>
              </div>
              <div class="setup-content" id="step-5">
                <div class="row">
                  <!-- Avatar Name -->
                  <div class="col-lg-12" style="text-align: left">
                    <div class="mb-3">
                      <label class="form-label" for="exampleFormControlInput15"
                        >Avatar Name</label
                      >
                      <input
                        class="form-control input-air-primary"
                        id="avatar-name"
                        type="text"
                        placeholder="Tiffany Smith"
                        v-model="newAvatar.name"
                      />
                    </div>
                  </div>
                  <!-- Choose GPT -->
                  <div class="col-lg-12" style="text-align: left">
                    <div class="mb-3">
                      <label class="form-label" for="exampleFormControlInput15"
                        >GPT Version</label
                      >
                      <multiselect
                        v-model="newAvatar.gpt"
                        tag-placeholder="Add this as a new Industry"
                        placeholder="Select GPT version"
                        class="mt-1"
                        label="name"
                        track-by="code"
                        :options="gpts"
                        :multiple="false"
                      ></multiselect>
                    </div>
                  </div>

                  <!-- Avatar Age -->
                  <div class="col-lg-12" style="text-align: left">
                    <label class="form-label" for="exampleFormControlTextarea4"
                      >Avatar Age</label
                    >
                    <div class="input-group bootstrap-touchspin">
                      <button
                        class="btn btn-primary btn-square bootstrap-touchspin-down"
                        type="button"
                        @click="newAvatar.age = parseInt(newAvatar.age) - 1"
                      >
                        <i class="fa fa-minus"></i>
                      </button>
                      <span
                        class="input-group-text bootstrap-touchspin-prefix"
                        style="display: none"
                      ></span>
                      <input
                        id="avatar-age"
                        class="touchspin form-control"
                        type="text"
                        value="28"
                        data-bts-min="18"
                        data-bts-max="99"
                        style="display: block"
                        v-model="newAvatar.age"
                      />

                      <span
                        class="input-group-text bootstrap-touchspin-postfix"
                        style="display: none"
                      ></span>
                      <button
                        class="btn btn-primary btn-square bootstrap-touchspin-up"
                        type="button"
                        @click="newAvatar.age = parseInt(newAvatar.age) + 1"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <!-- Avatar Gender -->
                  <div class="col-lg-12" style="text-align: left">
                    <label class="form-label" for="exampleFormControlTextarea4"
                      >Avatar Gender</label
                    >
                    <select
                      id="avatar-gender"
                      class="form-control"
                      v-model="newAvatar.gender"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <!-- Avatar State and City -->

                  <div class="col-lg-6" style="text-align: left">
                    <div class="mb-3">
                      <label class="form-label" for="exampleFormControlInput15"
                        >Avatar State</label
                      >
                      <input
                        class="form-control input-air-primary"
                        id="avatar-state"
                        type="text"
                        placeholder="Oh"
                        v-model="newAvatar.state"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6" style="text-align: left">
                    <div class="mb-3">
                      <label class="form-label" for="exampleFormControlInput15"
                        >Avatar City</label
                      >
                      <input
                        class="form-control input-air-primary"
                        id="avatar-city"
                        type="text"
                        placeholder="Cleveland"
                        v-model="newAvatar.city"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content
              title="Avatar Personality"
              icon="fa fa-meh-o"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <!-- <img src="../assets/images/industries.gif" style="width: 100%" /> -->
                <video width="100%" autoplay loop muted>
                  <source src="../assets/images/avatars.mp4" type="video/mp4" />
                </video>
              </div>
              <div class="setup-content" id="step-6">
                <!-- Avatar Interest -->
                <!-- <div class="col-lg-12" style="text-align: left">
                  <div class="mb-3">
                    <label class="form-label" for="exampleFormControlInput15"
                      >Avatar Interest</label
                    >
                    <input
                      class="form-control input-air-primary"
                      id="avatar-interest"
                      type="text"
                      placeholder=""
                      v-model="newAvatar.interest"
                    />
                  </div>
                </div> -->

                <!-- AVATAR TONE -->
                <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Avatar Tone</label
                  >
                  <select
                    id="avatar-tone"
                    class="form-control"
                    v-model="newAvatar.tone"
                  >
                    <option value="Funny">Funny</option>
                    <option value="Casual">Casual</option>
                    <option value="Excited">Excited</option>
                    <option value="Sarcastic">Sarcastic</option>
                    <option value="Feminine">Feminine</option>
                    <option value="Masculine">Masculine</option>
                    <option value="Bold">Bold</option>
                    <option value="Dramatic">Dramatic</option>
                    <option value="Witty">Witty</option>
                    <option value="Polite">Polite</option>
                    <option value="Professional">Professional</option>
                  </select>
                </div>

                <!-- Avatar Target Audience -->
                <div class="col-lg-12" style="text-align: left">
                  <div class="mb-3">
                    <label class="form-label" for="exampleFormControlInput15"
                      >Target Audience</label
                    >
                    <input
                      class="form-control input-air-primary"
                      id="avatar-target-audience"
                      type="text"
                      placeholder="Specific Industry or Small Businesses"
                      v-model="newAvatar.target"
                    />
                  </div>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="exampleFormControlInput15"
                      >Role Name</label
                    >
                    <input
                      class="form-control input-air-primary"
                      id="avatar-role-name"
                      type="text"
                      placeholder="Bookings opener"
                      style="background-color: lightgreen"
                      v-model="newAvatar.role"
                    />
                  </div>

                <!-- Avatar Working Time -->
                <!-- <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Working in the company for</label
                  >
                  <select
                    id="avatar-working-time"
                    class="form-control"
                    v-model="newAvatar.working"
                  >
                    <option value="over 5 years">Over 5 years</option>
                    <option value="over 7 years">Over 7 years</option>
                    <option value="over 10 years">Over 10 years</option>
                    <option value="over 15 years">Over 15 years</option>
                    <option value="over 20 years">Over 20 years</option>
                  </select>
                </div> -->

                <!-- Avatar Bio  -->
                <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Bio</label>
                  <textarea
                    id="avatar-bio"
                    class="form-control"
                    rows="3"
                    v-model="newAvatar.bio"
                    placeholder="Helped thousands of clients to signup for free websites in the past"
                  ></textarea>
                </div>
                                    <button
                      class="btn btn-primary mt-4"
                      @click="generateBio()"
                    >
                      Generate New Bio
                    </button>
              </div>
              <button
                v-if="Btitle == 'Campaign Settings'"
                class="btn btn-primary mt-4 "
                @click="updateAvatarPersonality()"
                >
                  Update Personality
                </button>
            </tab-content>
            <!-- <tab-content
              title="Train Avatar"
              icon="fa fa-puzzle-piece"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
               <img
                  src="../assets/images/integrations.gif"
                  style="width: 100%"
                /> 
                <video width="100%" autoplay loop muted>
                  <source src="../assets/images/avatars.mp4" type="video/mp4" />
                </video>
              </div>
              <div class="setup-content" id="step-6">
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-4"><h3>Connected Data</h3></div>
                    <div
                      class="col-8"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: end;
                      "
                    >
                      <div
                        class="btn btn-primary"
                        @click="
                          dataModal = true;
                          SelectedDataSet = [];
                        "
                      >
                        <feather type="plus-square" class="text-top"></feather
                        ><br />
                        Add New Dataset
                      </div>

                      <div
                        class="btn btn-secondary ml-2"
                        @click="webModal = true"
                      >
                        <feather type="plus-square" class="text-top"></feather
                        ><br />
                        Scrape Website
                      </div>

                      <div
                        class="btn btn-outline-primary ml-2"
                        @click="fileModal = true"
                      >
                        <feather type="upload" class="text-top"></feather><br />
                        Upload
                      </div>
                    </div>
                  </div>

                  Data Modals

                  <b-modal
                    id="modal-lg"
                    size="lg"
                    title="Dataset"
                    class="theme-modal"
                    hide-footer
                    hide-header
                    v-model="dataModal"
                  >
                    <div class="row">
                      <div class="col-12">
                        <label>DataSet Name</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Rules and data for XYZ"
                          v-model="SelectedDataSet.name"
                        />
                      </div>
                      <div class="col-12 mt-4">
                        <label>Information</label>
                        <b-dropdown
                          text="Add Variables"
                          variant="primary"
                          style="float: right"
                        >
                          <b-dropdown-header id="dropdown-header-label">
                            LeadVariables
                          </b-dropdown-header>
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Owners_Name')"
                            >lead-Owners_Name</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Company_Name')"
                            >lead-Company_Name</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="
                              addHookVariable('@lead-Company_Street_Address')
                            "
                            >lead-Company_Street_Address</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Company_City')"
                            >lead-Company_City</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Company_Zipcode')"
                            >lead-Company_Zipcode</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Company_State')"
                            >lead-Company_State</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Website')"
                            >lead-Website</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Industry')"
                            >lead-Industry</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Year_Established')"
                            >lead-Year_Established</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Years_in_Business')"
                            >lead-Years_in_Business</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Facebook_Page')"
                            >lead-Facebook_Page</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Linkedin_Profile')"
                            >lead-Linkedin_Profile</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Twitter_Profile')"
                            >lead-Twitter_Profile</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-Instagram_Page')"
                            >lead-Instagram_Page</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="addHookVariable('@lead-YouTube_Channel')"
                            >lead-YouTube_Channel</b-dropdown-item
                          >
                          <b-dropdown-header id="dropdown-header-label1">
                            Connected Data
                          </b-dropdown-header>
                          <b-dropdown-item></b-dropdown-item>
                        </b-dropdown>
                        <textarea
                          rows="25"
                          placeholder="Information Goes Here"
                          style="
                            background-color: black;
                            color: white;
                            border-radius: 20px;
                            padding: 10px;
                            width: 100%;
                          "
                          spellcheck="false"
                          v-model="SelectedDataSet.text"
                        ></textarea>
                      </div>
                      <div class="col-12 mt-4">
                        <button class="btn btn-primary" @click="saveDataSet">
                          Save
                        </button>
                        <button
                          class="btn btn-danger ml-4"
                          @click="deleteDataSet"
                          v-if="SelectedDataSet.id > 0"
                        >
                          <feather type="trash-2" size="14"></feather> Delete
                        </button>
                      </div>
                    </div>
                  </b-modal>
                  <b-modal
                    id="modal-lg"
                    size="lg"
                    title="Dataset"
                    class="theme-modal"
                    hide-footer
                    hide-header
                    v-model="webModal"
                  >
                    <div class="row">
                      <div class="col-12">
                        <label>Please Input your Website URL / Sitemap</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="https://www.google.com/sitemap.xml"
                          v-model="newWebsite"
                        />
                        <div style="float: right">
                          <b>Scrape One Page Only</b><br />
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                              v-model="one_page"
                            /><span class="switch-state"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-4">
                        <button class="btn btn-primary" @click="saveWebsite">
                          Scrape Now
                        </button>
                        <button
                          class="btn btn-danger ml-4"
                          @click="deleteDataSet"
                          v-if="SelectedDataSet.id > 0"
                        >
                          <feather type="trash-2" size="14"></feather> Delete
                        </button>
                      </div>
                    </div>
                  </b-modal>
                  <b-modal
                    id="modal-lg"
                    size="lg"
                    title="Dataset"
                    class="theme-modal"
                    hide-footer
                    hide-header
                    v-model="fileModal"
                  >
                    <div class="row">
                      <div class="col-12">
                        <label>Upload your files below</label>
                        <vue-dropzone
                          id="singledropzone"
                          :options="singledropzoneOptions"
                          class="dropzone digits"
                          @vdropzone-success="uploadSuccess"
                        >
                        </vue-dropzone>
                      </div>
                    </div>
                  </b-modal>

                  <div class="form-group">
                    <label class="control-label">Select Data to connect</label>
                    <multiselect
                      v-model="selectedData"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Data"
                      label="name"
                      track-by="code"
                      :options="connectedData"
                      :multiple="true"
                    ></multiselect>
                  </div>

                  <h3>Training Prompt</h3>
                  <div class="mb-3">
                    <label class="form-label" for="exampleFormControlInput15"
                      >Role Name</label
                    >
                    <input
                      class="form-control input-air-primary"
                      id="avatar-role-name"
                      type="text"
                      placeholder="Bookings opener"
                      style="background-color: lightgreen"
                      v-model="newAvatar.role"
                    />
                  </div>

                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Clone Previous Prompt</label
                  >
                  <small> Select Prompt From Previous Avatars:</small><br />
                  <multiselect
                    v-model="selectedPrompt"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Select Prompt Template"
                    label="name"
                    track-by="code"
                    :options="prompts"
                    :multiple="false"
                  ></multiselect>
                  <br />

                  <div class="row" id="roles-container">
                    <div class="col-12 mb-2">
                      <b-dropdown
                        text="Add Variables"
                        variant="primary"
                        style="float: right"
                      >
                        <b-dropdown-header id="dropdown-header-label">
                          System Variables
                        </b-dropdown-header>
                        <b-dropdown-item @click="addAvatarVariable('@URL-URL')"
                          >URL</b-dropdown-item
                        >
                        <b-dropdown-header id="dropdown-header-label">
                          Lead Variables
                        </b-dropdown-header>
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Owners_Name')"
                          >lead-Owners_Name</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Company_Name')"
                          >lead-Company_Name</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            addAvatarVariable('@lead-Company_Street_Address')
                          "
                          >lead-Company_Street_Address</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Company_City')"
                          >lead-Company_City</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Company_Zipcode')"
                          >lead-Company_Zipcode</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Company_State')"
                          >lead-Company_State</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Website')"
                          >lead-Website</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Industry')"
                          >lead-Industry</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Year_Established')"
                          >lead-Year_Established</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Years_in_Business')"
                          >lead-Years_in_Business</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Facebook_Page')"
                          >lead-Facebook_Page</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Linkedin_Profile')"
                          >lead-Linkedin_Profile</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Twitter_Profile')"
                          >lead-Twitter_Profile</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-Instagram_Page')"
                          >lead-Instagram_Page</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="addAvatarVariable('@lead-YouTube_Channel')"
                          >lead-YouTube_Channel</b-dropdown-item
                        >
                        <b-dropdown-header id="dropdown-header-label">
                          Connected Data
                        </b-dropdown-header>
                        <div
                          v-for="data in selectedData"
                          :key="'D' + data.code"
                        >
                          <b-dropdown-item
                            @click="
                              addAvatarVariable(
                                '@' + data.name + '-' + data.code
                              )
                            "
                            >{{ data.name }}</b-dropdown-item
                          >
                        </div>
                      </b-dropdown>
                    </div>
                    <textarea
                      id="avatar-role-prompt"
                      rows="10"
                      v-model="newAvatar.prompt"
                      style="
                        background-color: black;
                        color: white;
                        border-radius: 20px 20px 0px 0px;
                        padding: 20px;
                        width: 100%;
                      "
                      placeholder="Act like an expert customer support agent whom is working for @companyDetails"
                      data-tribute="true"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </div>
              </div>
            </tab-content> -->
            <tab-content
              title="Supervisor"
              icon="fa fa-lightbulb-o"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img
                  src="../assets/images/supervisor.gif"
                  style="width: 100%"
                />
                <!-- <video width="100%" autoplay loop muted>
                  <source src="../assets/images/avatars.mp4" type="video/mp4" />
                </video> -->
              </div>
              <div class="setup-content" id="step-6">
                <h4>Control Replies Quality <small>Supervisor Rules</small></h4>
                <textarea
                  id="supervisor-role-prompt"
                  rows="10"
                  v-model="newCampaign.supervisor"
                  style="
                    background-color: black;
                    color: white;
                    border-radius: 20px 20px 0px 0px;
                    padding: 20px;
                    width: 100%;
                  "
                  placeholder="Make sure that replies doesn't contain missleading information or dummy [variables], messages should be ready to be sent to prospects using their variable data."
                  data-tribute="true"
                  spellcheck="false"
                ></textarea>
              </div>
            </tab-content>

            <tab-content
              title="Schedule"
              icon="fa fa-calendar"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img src="../assets/images/reminder.gif" style="width: 100%" />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="../assets/images/avatars.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-6">
                <!-- Communication method -->
                <div class="col-lg-12" style="text-align: left">
                  <label class="form-label" for="exampleFormControlTextarea4"
                    >Active Days</label
                  >
                  <multiselect
                    v-model="selectedDays"
                    tag-placeholder="Add this as a new Industry"
                    placeholder="Select Days"
                    label="name"
                    track-by="code"
                    :options="scheduleDays"
                    :multiple="true"
                  ></multiselect>
                  <p>
                    Timezone is <b>Lead</b> Based, and might differ for each
                    lead indvidually, we suggest contacting during the working
                    days and hours from 8 AM to 8 PM.
                  </p>
                  <div class="mb-3 row g-sm-3">
                    <label class="col-md-3 col-form-label text-end"
                      >Start time ({{timezone}})</label
                    >
                    <b-form-timepicker v-model="startTime">

                    </b-form-timepicker>
                    <!-- <div class="col-xl-5 col-sm-7 col-lg-8">
                      <div
                        class="input-group date"
                        id="dt-time"
                        data-target-input="nearest"
                      >
                        <input
                          class="form-control datetimepicker-input digits"
                          type="text"
                          data-target="#dt-time"
                          id="start_time"
                          data-bs-original-title=""
                          title=""
                          placeholder="8 AM"
                          v-model="newCampaign.startTime"
                        />
                        <div
                          class="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i class="fa fa-clock-o"></i>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="mb-3 row g-sm-3">
                    <label class="col-sm-3 col-form-label text-end"
                      >End Time ({{timezone}})</label
                    >
                    <b-form-timepicker v-model="endTime">

                    </b-form-timepicker>
                    <!-- <div class="col-xl-5 col-sm-7 col-lg-8">
                      <div
                        class="input-group date"
                        id="dt-time2"
                        data-target-input="nearest"
                      >
                        <input
                          class="form-control datetimepicker-input digits"
                          type="text"
                          data-target="#dt-time2"
                          id="end_time"
                          data-bs-original-title=""
                          title=""
                          placeholder="8 PM"
                          v-model="newCampaign.endTime"
                        />
                        <div
                          class="input-group-text"
                          data-target="#dt-time2"
                          data-toggle="datetimepicker"
                        >
                          <i class="fa fa-clock-o"></i>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <button
                  v-if="Btitle == 'Campaign Settings'"
                  class="btn btn-primary mt-4"
                  @click="updateSchedule()"
                >
                  Update Schedule
                </button>
            </tab-content>
            <tab-content
              title="Goals"
              icon="fa fa-crosshairs"
              :before-change="campaignProcess"
            >
              <div
                class="image-container mb-2"
                style="
                  max-height: 200px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                "
              >
                <img src="../assets/images/goals.gif" style="width: 100%" />
                <!-- <video width="100%" autoplay loop muted>
                                          <source
                                            src="../assets/images/avatars.mp4"
                                            type="video/mp4"
                                          />
                                        </video> -->
              </div>
              <div class="setup-content" id="step-6">
                <div class="col-xs-12">
                  <label class="control-label">Select Goals to assign</label>
                  <div class="form-group"
                  style="
                  max-height: 5%;
                  max-width: 40%;
                  overflow: auto;
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  border-radius: 20px 20px 20px 20px;
                ">
                    
                    <multiselect
                      v-model="goalsValue"
                      tag-placeholder="Add this as a new Industry"
                      placeholder="Select Data"
                      label="name"
                      track-by="code"
                      :options="goals"
                      :multiple="true"
                    ></multiselect>
                  </div>
                </div>

                <!-- Wakeups -->

                <div class="container-fluid">
                  <div class="row">
                    <!-- Triggers -->
                    <div class="col-md-6 project-list">
                      <div
                        class="card bg-white text-black p-4"
                        style="color: black"
                      >
                        <h4>Triggers</h4>
                        <p>
                          This is Funnels Triggered by <b>Events</b>, you can
                          create custom Triggers as needed.
                        </p>
                        <!-- trigger Sample -->
                        <div class="btn btn-secondary text-white mt-2">
                          <i class="fa fa-clock-o"></i>
                          Never Responded
                        </div>
                        <div class="btn btn-secondary text-white mt-2">
                          <i class="fa fa-comments-o"></i>
                          Positive Response
                        </div>
                        <div class="btn btn-secondary text-white mt-2">
                          <i class="fa fa-hand-pointer-o"></i>
                          Link Click
                        </div>
                        <!-- List dynamic triggers -->
                        <div
                          v-for="trigger in triggers"
                          :key="trigger.id"
                          class="btn btn-info text-white mt-2"
                          v-b-tooltip.hover
                          :title="'Click to copy (' + trigger.code + ') '"
                          @click="copyText(trigger.code)"
                        >
                          {{ trigger.name }} - ({{ trigger.code }})
                        </div>
                      </div>
                    </div>
                    <!-- Wakeup Funnels -->
                    <div class="col-md-6 project-list">
                      <div
                        class="card bg-white text-black p-4"
                        style="color: black"
                      >
                        <h4>Wakeup Funnels</h4>
                        <p>
                          This is Wakeup Funnels <b>Starts</b> as soon as
                          <b>Prospect triggers</b> attached event.
                        </p>
                        <!-- trigger Sample -->
                        <multiselect
                          v-model="triggersSelected.n"
                          tag-placeholder="Add this as a new Industry"
                          placeholder="Never Responded Funnel Sequence"
                          class="mt-1"
                          label="name"
                          track-by="code"
                          :options="funnels"
                          :multiple="false"
                        ></multiselect>
                        <multiselect
                          v-model="triggersSelected.r"
                          tag-placeholder="Add this as a new Industry"
                          placeholder="Postive Response Funnel Sequence"
                          class="mt-1"
                          label="name"
                          track-by="code"
                          :options="funnels"
                          :multiple="false"
                        ></multiselect>
                        <multiselect
                          v-model="triggersSelected.c"
                          tag-placeholder="Add this as a new Industry"
                          class="mt-1"
                          placeholder="Link Click Funnel Sequence"
                          label="name"
                          track-by="code"
                          :options="funnels"
                          :multiple="false"
                        ></multiselect>

                        <multiselect
                          v-for="trigger in triggers"
                          :key="trigger.id"
                          v-model="triggersSelected[trigger.id]"
                          tag-placeholder="Add this as a new Industry"
                          :placeholder="trigger.name + ' Funnel Sequence'"
                          class="mt-1"
                          label="name"
                          track-by="code"
                          :options="funnels"
                          :multiple="false"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
          <div
            v-show="createCampaignLoading"
            class="text-center"
            style="width: 100%"
          >
            <lottie-vue-player
              :src="`https://lottie.host/7cf3f0e5-8df4-4bad-ac65-1965d7472866/nXXmk2hrMa.json`"
              :autoplay="true"
              :loop="true"
              :player-controls="false"
              style="width: 100%; height: 350px; margin-top: -50px"
              v-show="createCampaignLoadingText != 'Created Successfully'"
            >
            </lottie-vue-player>
            <lottie-vue-player
              :src="`https://lottie.host/8c772637-3f2c-4056-a3f7-cc93b4ae4c0e/Llg9qqwoUC.json`"
              :autoplay="true"
              :loop="false"
              :player-controls="false"
              style="width: 100%; height: 350px; margin-top: -50px"
              v-show="createCampaignLoadingText == 'Created Successfully'"
            >
            </lottie-vue-player>
            <div style="font-size: 24px">{{ createCampaignLoadingText }}</div>
            <button
              class="btn btn-primary mt-4"
              @click="createCampaignLoading = false"
            >
              Back to campaign creation
            </button>
          </div>
        </div>
        <template #modal-footer>
          <button v-b-modal.modal-close_visit class="btn btn-danger btn-sm m-1">
            Close
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import { _ } from "core-js";
export default {
  name: "CampaignModal",
  props: {
    Btitle: {
      type: String,
      required: true,
    },
  },
  components: { Multiselect, vueDropzone: vue2Dropzone },
  data() {
    return {
      startTime: '09:00:00',
      endTime: '15:00:00',
      userId: localStorage.getItem("userId"),
      username: localStorage.getItem("username"),

      campaignStep: 8,
      multiValue: [],
      options: [
        { code: 1, name: "1-23.fun" },
        { code: 2, name: "1-2-3w.com" },
        { code: 3, name: "1-23w.com" },
      ],
      uploadLeads: false,
      scheduleDays: [
        { code: "monday", name: "Monday" },
        { code: "tuesday", name: "Tuesday" },
        { code: "wednesday", name: "Wednesday" },
        { code: "thursday", name: "Thursday" },
        { code: "friday", name: "Friday" },
        { code: "saturday", name: "Saturday" },
        { code: "sunday", name: "Sunday" },
      ],
      selectedDays: [
        { code: "monday", name: "Monday" },
        { code: "tuesday", name: "Tuesday" },
        { code: "wednesday", name: "Wednesday" },
        { code: "thursday", name: "Thursday" },
        { code: "friday", name: "Friday" },
        { code: "saturday", name: "Saturday" },
        { code: "sunday", name: "Sunday" },
      ],
      industriesValue: [],
      industries: [],
      totalSelectedLeads: 0,
      allLeads: true,
      conversationsPerDay: 0,
      staticHook: true,
      smartHook: false,

      connectedData: [],
      selectedData: [],
      goals: [],
      goalsValue: [],
      domains: [],
      domainsValue: [],
      phones: [],
      phonesValue: [],

      createCampaignLoading: false,
      createCampaignLoadingText: "Creating campaign...",
      hookText: "",
      example1: "",
      example2: "",
      example3: "",
      avatarText: "",
      newAvatar: {
        name: "",
        age: 22,
        prompt: "",
        gpt: [{ code: 0, name: "gpt-4" }],
      },
      newCampaign: {
        name: "",
        target: "",
        //startTime: "08:00:00",
        //endTime: "20:00:00",
        supervisor: "",
      },
      dataModal: false,
      webModal: false,
      fileModal: false,
      SelectedDataSet: [{ name: "", text: "" }],
      newWebsite: "",
      singledropzoneOptions: {
        url:
          "https://123avatars.com/backend/upload.php?u=" +
          localStorage.getItem("userId"),
        thumbnailWidth: 50,
        maxFiles: 4,
        maxFilesize: 5,
        addRemoveLinks: false,
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6><span>(Allowed Formats <strong>PDF, CSV, TXT</strong>, you can upload multiple files at once.)</span>",
      },
      industriesIncludeValue: [],
      industriesExcludeValue: [],
      industriesIncludes: [
        { code: 0, name: "Owners_Name" },
        { code: 1, name: "Company_Name" },
        { code: 2, name: "Company_Street_Address" },
        { code: 3, name: "Company_City" },
        { code: 4, name: "Company_Zipcode" },
        { code: 5, name: "Company_State" },
        { code: 6, name: "Website" },
        { code: 7, name: "Industry" },
        { code: 8, name: "Year_Established" },
        { code: 9, name: "Years_in_Business" },
        { code: 10, name: "Facebook_Page" },
      ],
      industriesExcludes: [
        { code: 0, name: "Owners_Name" },
        { code: 1, name: "Company_Name" },
        { code: 2, name: "Company_Street_Address" },
        { code: 3, name: "Company_City" },
        { code: 4, name: "Company_Zipcode" },
        { code: 5, name: "Company_State" },
        { code: 6, name: "Website" },
        { code: 7, name: "Industry" },
        { code: 8, name: "Year_Established" },
        { code: 9, name: "Years_in_Business" },
        { code: 10, name: "Facebook_Page" },
      ],
      neverRepliedWakeups: [],
      positiveWakeups: [],
      wakeupModal: false,
      wakeupDays: 0,
      wakeupHours: 0,
      wakeupMinutes: 0,
      wakeupPositive: 0,
      wakeupStatic: 1,
      wakeupPrompt: "",
      hooks: [],
      selectedHook: [],
      prompts: [],
      selectedPrompt: [],
      brands: [],
      selectedBrand: [],
      availableBrands: [],
      static: null,
      channels: [
        { code: 0, name: "Telnyx SMS" },
        { code: 1, name: "Sinch SMS" },
        { code: 2, name: "RooR SMS" },
      ],
      selectedChannel: [{ code: 0, name: "Telnyx SMS" }],
      telynxCampaigns: [],
      selectedTelynxCampaign: [],
      campaignPhones: [],
      one_page: false,
      triggers: [],
      funnels: [],
      triggersSelected: [],
      gpts: [
        { code: 0, name: "gpt-4" },
        { code: 1, name: "gpt-3.5-turbo" },
        { code: 2, name: "gpt-3.5-turbo-16k" },
        { code: 3, name: "gpt-3.5-turbo-1106" },
        { code: 4, name: "text-davinci-003" },
      ],
      hookName: '',
      mms: false,
      selectedMMSImage: [],
      mmsImages: [],
      charLimit: 160
    };
  },
  mounted() {
    this.getTriggers();
    this.getFunnels();
    var d = new Date();
    var curHr = this.addZero(d.getHours());
    var curMi = this.addZero(d.getMinutes());
    var meridiem = curHr >= 12 ? "PM" : "AM";
    this.time = curHr + ":" + curMi + " " + meridiem;
    if (curHr < 12) {
      this.greeting = "Good morning!";
    } else if (curHr < 18) {
      this.greeting = "Good afternoon!";
    } else {
      this.greeting = "Good evening!";
    }

    this.getConnectedData();
    // this.getGoals();
    // this.campaignProcess();
    this.getHooks();
    this.getAvatars();
    
    this.getTelnyxData();
    if (this.$route.params.id) {
      this.getCampaign();
    }
    // if (!this.$route.params.id) {
    //   this.generateBio();
    // }
    
    setTimeout(() => {
      console.log('here');
      this.getAvailableBrands();
    }, 100);
    setTimeout(() => {
      // console.log("Fetching data after 5 Seconds");
      this.fetchLeadIndusties();
      this.getDomains();
      //this.getTelynxPhoneNumbers();
    }, 100);
    this.getMMSData();
  },
  watch: {
    selectedChannel: {
      handler: "getDids", // Specify the method to call
      deep: false, // Watch for deep changes in the array
    },
    selectedHook: {
      handler: "addHookText", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    selectedPrompt: {
      handler: "addPromptText",
      deep: true,
    },
    industriesValue: {
      handler: "updateTotalSelected", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    hookVariable: {
      handler: "addHookVariable", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    selectedBrand: {
      handler: "getCampaigns", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    },
    selectedTelynxCampaign: {
      handler: "fetchCampaignPhoneNumbers", // Specify the method to call
      deep: true, // Watch for deep changes in the array
    }
    // uploadLeads: {
    //   handler: "toggleLeads",
    //   deep: true
    // },
    // allLeads: {
    //   handler: "toggleLeads",
    //   deep: true
    // }
  },
  computed: {
    ...mapState({
      allprojects: (state) => state.common.allprojects,
      doingprojects: (state) => state.common.doingprojects,
      doneprojects: (state) => state.common.doneprojects,
    }),
    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } 
  },
  methods: {
    async smartHookToggle() {
      setTimeout(() => {
        console.log("smartHook: ", this.smartHook);
        if(this.smartHook == true) {
          this.staticHook = false;
        }
        if(this.smartHook == false) {
          this.staticHook = true;
        }
        console.log("staticHook: ", this.staticHook);
      }, 100);
      // this.$forceUpdate();
    },
    async staticHookToggle() {
      setTimeout(() => {
        console.log("staticHook: ", this.staticHook);
        if(this.staticHook == true) {
          this.smartHook = false;
        }
        // if(this.staticHook == false) {
        //   this.smartHook = true;
        // }
        console.log("staticHook: ", this.staticHook);
      }, 100);
      // this.$forceUpdate();
    },
    async updateCampaignDetails() {
      let domains = "";
      this.domainsValue.forEach((domain) => {
        domains = domains + domain.code + ",";
      });
      domains = domains.slice(0, -1);

      console.log("phonesValue: ", this.phonesValue);

      let resp = await axios.get("https://123avatars.com/backend/updateCampaignDetails.php?u=" +
        this.userId +
        "&campaign_id=" +
        this.newCampaign.campaign_id + 
        "&avatar_id=" +
        this.newAvatar.id +
        "&name=" + 
        this.newCampaign.name + 
        "&target=" + 
        this.newCampaign.target +
        "&domains=" +
        domains + 
        "&brand_id=" +
        this.newCampaign.brand_brand_id +
        "&phones=" +
        this.phonesValue.map((phone) => phone.phone).join(",")

      );
      if(resp?.status == 200) {
        this.$toasted.show("Campaign Updated Successfully ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 10000,
          });
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_avatar', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
          localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
      } else {
        console.log("ERROR UPDATING CAMPAING.");
        this.$toasted.show("Failed to update campaign.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
      }
    },
    async getMMSData() {
      let response = await axios
        .get("https://123avatars.com/backend/getData.php?u=" + this.userId + "&type=3");
      this.mmsImages = response.data;
      console.log("mmsImages: ", this.mmsImages);
    },
    toggleMMS() {

    },
    async generateBio() {
   //   if(!this.newAvatar.bio) {
        let text = "New avatar bio attributes: Name: " + this.newAvatar.name + ", Age: " + this.newAvatar.age + ", Gender: " + this.newAvatar.gender + ", Location: "  + this.newAvatar.city + ", " + this.newAvatar.state
          + ", Tone: " + this.newAvatar.tone + ", Target Audience: " + this.newAvatar.target + ", Role Name: " + this.newAvatar.role + ".";

        let response = await axios.get("https://123avatars.com/backend/generateBio.php?id=&text=" + text);
        this.newAvatar.bio = response.data;
        localStorage.setItem('pending_avatar', "");
        localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
        this.$forceUpdate();
      //}
    },
    updateSchedule() {
      this.newCampaign.days = this.selectedDays;
      this.newCampaign.startTime = this.startTime;
      this.newCampaign.endTime = this.endTime;
      this.newCampaign.timezone = this.timezone;
      axios
        .get("https://123avatars.com/backend/updateSchedule.php", {
          params: {
            u: this.userId,
            days: this.selectedDays.map((day) => `${day.code}`).join(),
            startTime: this.startTime,
            endTime: this.endTime,
            timezone: this.timezone,
          },
        })
        .then((response) => {
          setTimeout(() => {
            this.$toasted.show("Schedule Updated Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }, 500);
          });
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
        }).catch((err) => {
          this.$toasted.show(" Failed to update schedule. Please contact your system administrator. ", {
                theme: "outline",
                position: "center",
                type: "danger",
                icon: "info",
                duration: 10000,
              }), 500;
        });
    },
    updateAvatarPersonality() {
      axios
        .get("https://123avatars.com/backend/updateAvatar.php", {
          params: {
            u: this.userId,
            tone: this.newAvatar.tone,
            targetAudience: this.newAvatar.target,
            bio: this.newAvatar.bio,
            roleName: this.newAvatar.role
          },
        })
        .then((response) => {
          setTimeout(() => {
            this.$toasted.show("Avatar Updated Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }), 500;
          });
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_avatar', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
          localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
        }).catch((err) => {
          this.$toasted.show(" Failed to update avatar. Please contact your system administrator. ", {
                theme: "outline",
                position: "center",
                type: "danger",
                icon: "info",
                duration: 10000,
              }), 500;
        });
    },
    toggleLeads(leadType) {
      setTimeout(() => {
        if('uploadLeads' === leadType) {
          if(this.uploadLeads == true) {
            this.allLeads = false;
          }
        }
        if('allLeads' === leadType) {
          if(this.allLeads == true) {
            this.uploadLeads = false;
          }
        }
      }, 1);
    },
    updateConversations() {
      this.newCampaign.conversationsPerDay = this.conversationsPerDay;
      console.log("newCampaign: ", this.newCampaign);
      axios
        .get("https://123avatars.com/backend/updateConversations.php", {
          params: {
            campaign_id: this.$route.params.campId,
            convPerDay: this.conversationsPerDay
          },
        })
        .then((response) => {
          setTimeout(() => {
            //this.getCampaign();
            this.$toasted.show("Conversations Updated Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }, 500);
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_avatar', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
          localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
          });

        }).catch((err) => {
          this.$toasted.show(" Failed to update conversations. Please contact your system administrator. ", {
                theme: "outline",
                position: "center",
                type: "danger",
                icon: "info",
                duration: 10000,
              }, 500);
        });
    },
    updateHook() {
      console.log("selectedMMSImage: ", this.selectedMMSImage);
      this.newCampaign.hookName = this.hookName;
      this.newCampaign.hookText = this.hookText;
      this.newCampaign.static = this.static;
      this.newCampaign.smart = this.smartHook;
      this.newCampaign.selectedMMSImage = this.selectedMMSImage;
      this.newCampaign.example1 = this.example1;
      this.newCampaign.example2 = this.example2;
      this.newCampaign.example3 = this.example3;

      let campaignId = this.$route.params.campId;
      let queries = this.hookText +
            "&example1=" +
            this.example1 +
            "&example2=" +
            this.example2 +
            "&example3=" +
            this.example3 +
            "&campaign_id=" +
            campaignId +
            "&static=" +
            this.static +
            "&smart=" +
            this.smartHook +
            "&name=" +
            this.hookName +
            "&u=" +
            this.userId;
        if(this.selectedHook?.id) {
          queries += "&id=" +
            this.selectedHook.id;
        }
        if(this.mms == true) {
          queries += "&mmsImage=" +
            this.selectedMMSImage.name;
        }
      
      axios
        .get(
          "https://123avatars.com/backend/campaignHook.php?prompt=" +
              queries
        ).then((response) => {
          console.log("resp: ", response);
          setTimeout(() => {
            this.$toasted.show(" Hook Updated Successfully ", {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }, 500);
          });
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_avatar', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
          localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
        }).catch((err) => {
          this.$toasted.show(" Failed to update hook. Please contact your system administrator. ", {
                theme: "outline",
                position: "center",
                type: "danger",
                icon: "info",
                duration: 10000,
              });
        });
    },
    getAvailableBrands() {
      axios
        .get("https://123avatars.com/backend/getAvailableBrands.php?u=" + this.userId, {
          params: {
            //campaign_id: 60,
            //industries: industries
          },
        })
        .then((response) => {
          console.log('brandsResponse: ', response);
          if(response.data) {
            let brands = response.data;
            console.log('brands', brands.data);
            brands?.data?.forEach((brand) => {
              this.availableBrands.push(brand);
            });
          }
        });
    },
    updateIndustries() {
      let industries = "";
      this.industriesValue.forEach((industry) => {
        let modifiedName = industry.name.replace(/\s*\([^)]*\)/, ""); // This removes the (0239) and the space before it
        industries = industries + modifiedName + ",";
      });
      industries = industries.slice(0, -1);
      axios
        .get("https://123avatars.com/backend/updateCampaign.php", {
          params: {
            campaign_id: 60,
            industries: industries
          },
        })
        .then((response) => {
          console.log(response);
          localStorage.setItem('pending_campaign', "");
          localStorage.setItem('pending_avatar', "");
          localStorage.setItem('pending_campaign', JSON.stringify(this.newCampaign));
          localStorage.setItem('pending_avatar', JSON.stringify(this.newAvatar));
        }).catch((err) => {
          console.error("Error updating industries. ", err);
        });
    },
    getDids() {
      // console.log("Selected channel change->" + this.selectedChannel.code);
      // console.log(this.selectedChannel);
      // this.phones = [];
      // this.telynxCampaigns = [];
      // this.brands = [];
      // this.selectedBrand = [];
      // this.selectedTelynxCampaign = [];
      // this.campaignPhones = [];
      // this.phonesValue = [];

      // if (this.selectedChannel.name == "Telnyx SMS") {
      //   //Telnyx
      //   this.getTelynxPhoneNumbers();
      //   this.getTelnyxData();
      // }
      // if (this.selectedChannel.name == "RooR SMS") {
      //   //Roor
      //   this.getRoorPhoneNumbers();
      //   this.getRoorBrands();
      // }
    },
    getRoorCampaigns(apiKey) {
      this.selectedTelynxCampaign = [];
      const config = {
        headers: {
          Authorization: apiKey,
        },
      };
      //Get Campaigns
      axios
        .get("https://api.signalmash.com/campaigns", config)
        .then((response) => {
          this.telynxCampaigns = [];
          let brands = response.data.data;
          brands.forEach((brand) => {
            let campaigns = response.data.data;
            campaigns.forEach((campaign) => {
              this.telynxCampaigns.push({
                code: campaign.campaign_key,
                tel_campaign_name: campaign.campaign_key,
                name:
                  "[" +
                  campaign.campaign_key +
                  "] " +
                  " ( BrandName: " +
                  campaign.brand_name +
                  ")",
              });
            });
          });
        });
    },
    getRoorPhoneNumbers(campKey, apiKey) {
      const config = {
        headers: {
          Authorization: apiKey,
        },
      };
      //Get Phone Numbers
      let url = "https://api.signalmash.com/get_did?page=1&recordsPerPage=500";
      if (campKey) {
        url =
          "https://api.signalmash.com/get_did?campaignKey=" +
          campKey +
          "&page=1&recordsPerPage=500";
      }
      axios
        .get(url, config)
        .then((response) => {
          this.phones = [];
          let phones = response.data.data;
          phones.forEach((phone) => {
            this.phones.push({
              code: phone.did,
              phone: phone.did,
              name: phone.did,
            });
          });
          this.campaignPhones = this.phones;

          //assign selected
          let industriesSelected = this.newAvatar?.phone_number?.split(",");
          // console.log("phones selected");
          // console.log(this.newAvatar.phone_number);
          industriesSelected?.forEach((industry) => {
            // console.log("Campaign Phones: " + industry);
            let industryS = this.phones.find((item) =>
              item.name.includes(industry)
            );
            this.phonesValue.push(industryS);
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    getRoorBrands() {
      const config = {
        headers: {
          Authorization: "gIcQw66fGaljLAX50WdMd4-wQa3ltR",
        },
      };
      //Get Brands
      axios
        .get("https://api.signalmash.com/getbrands", config)
        .then((response) => {
          this.brands = [];
          let brands = response.data.data;
          brands.forEach((brand) => {
            this.brands.push({
              code: brand.brandId,
              name:
                "[" +
                brand.brand_relation +
                "] " +
                brand.brand_name +
                " (" +
                brand.identityStatus +
                ")",
            });
          });
        });
    },
    getCampaign() {
      axios
        .get(
          "https://123avatars.com/backend/backoffice.php?avatar_id=" +
            this.$route.params.id +
            "&time=11/25/2023 - 12/02/2023"
        )
        .then(async (response) => {


          this.newCampaign = response.data.campaign;
          this.newAvatar = response.data.avatar;
          // this.selectedHook = response.data.hook;
          // this.example1 = response.data.hook?.example1;
          // this.example2 = response.data.hook?.example2;
          // this.example3 = response.data.hook?.example3;
          // this.selectedMMS = response.data.hook?.mms_image;
          // this.hookText = response.data.hook?.prompt;
          // this.hookName = response.data.hook?.name;
          // this.smartHook = (response.data.hook?.smart === 1) ? true : false;
          // this.static = response.data.hook?.static === 1 ? true : false;
          console.log("this.smartHook: ", this.smartHook);
          console.log("this.staticHook: ", this.static);
          
          
          this.newAvatar.gpt = [{ code: 0, name: "gpt-4" }];
          this.conversationsPerDay = this.newCampaign.conv_per_day;
          this.domainsValue = [];
          this.newCampaign?.domains?.split(",")?.forEach((domain) => {
            console.log("domain: ", domain);
            
            let selectedDomain = this.domains?.find((list_domain) => (list_domain?.code == domain));
            this.domainsValue.push(selectedDomain);
            console.log("getCampaigns: ", selectedDomain);
          });

          this.selectedBrand = []; 
          this.selectedBrand.push({
              code: this.newCampaign.brand_brand_id,
              name:
                " [" +
                this.newCampaign.brand_name +
                "]"
            });
          this.selectedTelynxCampaign = [{
                code: this.newCampaign.tel_campaign_name,
                tel_campaign_name: this.newCampaign.tel_campaign_name,
                name:
                  "[" +
                  this.newCampaign.tel_campaign_name +
                  "] " +
                  " ( BrandName: " +
                  this.newCampaign.tel_campaign +
                  ")",
              }];
          this.phonesValue = [];
          this.newAvatar?.phone_number?.split(",").forEach((phone) => {
            this.phonesValue.push({
              code: phone,
              phone: phone,
              name: phone
            });
          });
          this.$forceUpdate();
          
        }).catch((err) => {
          console.error("Error fetching campaign: ", err);
        });
    },
    fetchCampaignPhoneNumbers() {
      console.log('selectedBrand: ', this.selectedBrand);
      console.log('selectedCampaign: ', this.selectedTelynxCampaign);

      if(!this.selectedBrand || this.selectedBrand.length == 0) {
        this.campaignPhones = [];
        this.phonesValue = [];
        return;
      }

      if (this.selectedBrand.csp == "ROOR") {
        this.getRoorPhoneNumbers(this.selectedTelynxCampaign.code, this.selectedBrand.api_key);
        return;
      }
      if(this.selectedBrand.csp == "TELNYX") {
        this.getTelynxPhoneNumbers();
        return;
      }
      // this.phonesValue = [];
      // const config = {
      //   headers: {
      //     Authorization:
      //       "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
      //   },
      // };
      // //Get Campaign Phone Numbers
      // axios
      //   .get(
      //     "https://api.telnyx.com/10dlc/phoneNumberCampaign?recordsPerPage=1000&page=1",
      //     config
      //   )
      //   .then((response) => {
      //     // console.log(response.data);
      //     this.campaignPhones = [];
      //     let phones = response.data.records;
      //     phones.forEach((record) => {
      //       //check if phone number is assigned to selected campaign
      //       if (record.campaignId == this.selectedTelynxCampaign.code) {
      //         //select from this.phones where this.phones.phone == record.phoneNumber
      //         this.phones.forEach((phone) => {
      //           if (phone.phone == record.phoneNumber) {
      //             //push it to allowed phones this.campaignPhones
      //             this.campaignPhones.push(phone);
      //           }
      //         });
      //       }
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("There was an error!", error);
      //   });
    },
    filterCampaignTelynxNumbers() {
      if (this.selectedChannel.name == "RooR SMS") {
        this.getRoorPhoneNumbers(this.selectedTelynxCampaign.code, '');
        return;
      }
      this.phonesValue = [];
      const config = {
        headers: {
          Authorization:
            "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
        },
      };
      //Get Campaign Phone Numbers
      axios
        .get(
          "https://api.telnyx.com/10dlc/phoneNumberCampaign?recordsPerPage=1000&page=1",
          config
        )
        .then((response) => {
          // console.log(response.data);
          this.campaignPhones = [];
          let phones = response.data.records;

          

          phones.forEach((record) => {
            //check if phone number is assigned to selected campaign
            if (record.campaignId == this.selectedTelynxCampaign.code) {
              //select from this.phones where this.phones.phone == record.phoneNumber
              this.phones.forEach((phone) => {
                if (phone.phone == record.phoneNumber) {
                  //push it to allowed phones this.campaignPhones
                  this.campaignPhones.push(phone);
                }
              });
            }
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    getTelynxPhoneNumbers() {
      const config = {
        headers: {
          Authorization:
            "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
        },
      };
      //Get Phone Numbers
      axios
        .get(
          "https://api.telnyx.com/10dlc/phone_number_campaigns?recordsPerPage=2000&page=1",
          config
        )
        .then((response) => {
          console.log(response.data);
          let phones = response?.data?.records;
          phones?.forEach((phone) => {
            console.log("phone: ", phone);
            if(phone.campaignId == this.selectedTelynxCampaign.code) {
              this.phones.push({
                code: phone.id,
                phone: phone.phoneNumber,
                name: phone.phoneNumber
              });
            }

            
            this.campaignPhones = this.phones;
          });

          //assign selected
          let industriesSelected = this.newAvatar?.phone_number?.split(",");
          // console.log("phones selected");
          // console.log(this.newAvatar.phone_number);
          industriesSelected?.forEach((industry) => {
            // console.log("Campaign Phones: " + industry);
            let industryS = this.phones.find((item) =>
              item.name.includes(industry)
            );
            this.phonesValue.push(industryS);
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    getCampaigns() {
      if (!this.selectedBrand || this.selectedBrand.length == 0) {
        this.selectedTelynxCampaign = [];
        this.telynxCampaigns = [];
        return;
      }

      this.telynxCampaigns = [];
      if (this.selectedBrand.csp == "ROOR") {
        this.getRoorCampaigns(this.selectedBrand.api_key);
        return;
      }
      if(this.selectedBrand.csp == 'TELNYX') {
        this.getTelynxCampaigns();
        return;
      }
      // this.selectedTelynxCampaign = [];
      // this.telynxCampaigns = [];
      // const config = {
      //   headers: {
      //     Authorization:
      //       "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
      //   },
      // };
      // //Get Brands
      // axios
      //   .get(
      //     "https://api.telnyx.com/10dlc/campaign?page=1&recordsPerPage=10&brandId=" +
      //       this.selectedBrand.code,
      //     config
      //   )
      //   .then((response) => {
      //     let campaigns = response.data.records;
      //     campaigns.forEach((campaign) => {
      //       this.telynxCampaigns.push({
      //         code: campaign.campaignId,
      //         name:
      //           "[" +
      //           campaign.campaignId +
      //           "] " +
      //           " ( Tmobile Registered:" +
      //           campaign.isTMobileRegistered +
      //           ")",
      //       });
      //     });
      //     // this.brands = response.data.records;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },    
    getTelynxCampaigns() {
      if (this.selectedBrand.length == 0) {
        return;
      }
      this.selectedTelynxCampaign = [];
      this.telynxCampaigns = [];
      const config = {
        headers: {
          Authorization:
            "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
        },
      };
      console.log("brandId: ", this.selectedBrand.brand_id);
      //Get Brands
      axios
        .get(
          "https://api.telnyx.com/10dlc/campaign?page=1&recordsPerPage=10&brandId=" +
            this.selectedBrand.brand_id,
          config
        )
        .then((response) => {
          let campaigns = response.data.records;
          console.log("getTelynxCampaigns - campaigns: ", campaigns);
          campaigns.forEach((campaign) => {
            this.telynxCampaigns.push({
              code: campaign.campaignId,
              tel_campaign_name: campaign.cspId,
              name:
                "[" +
                campaign.cspId + 
                " - " + 
                campaign.campaignId +
                "] " +
                " ( Tmobile Registered:" +
                campaign.isTMobileRegistered +
                ")",
            });
          });
          // this.brands = response.data.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTelnyxData() {
      const config = {
        headers: {
          Authorization:
            "Bearer KEY018AB943E3B78EFC5FE3754A4DE8BDE5_k0RXu5AoQZ1RcAugkKh0UM",
        },
      };
      //Get Brands
      axios
        .get(
          "https://api.telnyx.com/10dlc/brand?page=1&recordsPerPage=10",
          config
        )
        .then((response) => {
          // console.log(response.data);
          let brands = response.data.records;
          brands.forEach((brand) => {
            this.brands.push({
              code: brand.brandId,
              name:
                "[" +
                brand.displayName +
                "] " +
                brand.companyName +
                " (" +
                brand.identityStatus +
                ")",
            });
          });
          // this.brands = response.data.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addHookText() {
      this.hookText = this.selectedHook?.prompt;
    },
    addPromptText() {
      this.newAvatar.prompt = this.selectedPrompt.prompt;
    },
    moveToStepOne() {
      let cached = localStorage.getItem("pending_campaign");
      if (cached) {
        this.newCampaign = JSON.parse(cached);

        setTimeout(() => {
          console.log("newCampaign: ", this.newCampaign);
          this.selectedTelynxCampaign = [];
          this.selectedTelynxCampaign.push(this.newCampaign.selectedTelynxCampaign);
          this.selectedBrand = this.newCampaign.selectedBrand;      
          this.phonesValue = this.newCampaign.phonesValue;
          this.conversationsPerDay = this.newCampaign.conversationsPerDay;
          // this.hookName = this.newCampaign.hookName;
          // this.mms = this.newCampaign.mms;
          // this.selectedMMSImage = this.newCampaign.selectedMMSImage;
          // this.hookText = this.newCampaign.hookText;
          // this.charLimit = this.newCampaign.charLimit;
          this.startTime = this.newCampaign.startTime;
          this.endTime = this.newCampaign.endTime;
          this.selectedDays = this.newCampaign.selectedDays;
          this.selectedBrand = []; 
          this.selectedBrand.push({
              code: this.newCampaign.brand_brand_id,
              name:
                " [" +
                this.newCampaign.brand_name +
                "]"
            });
          this.selectedTelynxCampaign = [{
                code: this.newCampaign.tel_campaign_name,
                tel_campaign_name: this.newCampaign.tel_campaign_name,
                name:
                  "[" +
                  this.newCampaign.tel_campaign_name +
                  "] " +
                  " ( BrandName: " +
                  this.newCampaign.tel_campaign +
                  ")",
              }];
          this.phonesValue = [];
          this.newAvatar?.phone_number?.split(",").forEach((phone) => {
            this.phonesValue.push({
              code: phone,
              phone: phone,
              name: phone
            });
          });
          this.$forceUpdate();
        },10);

        console.log("selectedTelynxCampaign: ", this.selectedTelynxCampaign);
        
      }
      let cachedAvatar = localStorage.getItem("pending_avatar");

      if(cachedAvatar) {
        this.newAvatar = JSON.parse(localStorage.getItem("pending_avatar"));
      }
      // console.log("Move to step one");
      // Click on first step for creating campaigns after 3 seconds
      setTimeout(() => {
        let element = document.getElementById("step-CampaignDetails0");
        // console.log(element);
        if (element) {
          element.click();
          // console.log("Element Clicked");
        }
      }, 1000);
    },
    handleErrorMessage() {
      return true;
    },
    campaignProcess(id) {
      this.newCampaign.selectedBrand = this.selectedBrand;
      this.newCampaign.selectedTelynxCampaign = this.selectedTelynxCampaign;
      this.newCampaign.phonesValue = this.phonesValue;
      this.newCampaign.conversationsPerDay = this.conversationsPerDay;
      this.newCampaign.hookName = this.hookName;
      this.newCampaign.mms = this.mms;
      this.newCampaign.selectedMMSImage = this.selectedMMSImage;
      this.newCampaign.hookText = this.hookText;
      this.newCampaign.charLimit = this.charLimit;
      this.newCampaign.selectedDays = this.selectedDays;
      this.newCampaign.startTime = this.startTime;
      this.newCampaign.endTime = this.endTime;
      localStorage.setItem(
        "pending_campaign",
        JSON.stringify(this.newCampaign)
      );
      localStorage.setItem(
        "pending_avatar",
        JSON.stringify(this.newAvatar)
      );


      console.log("brand: ", this.selectedselectedTelynxCampaign);
      // console.log("Saved to cache");
      //Save Data to cache
      // localStorage.setItem("pc_name", JSON.stringify(campaigns));
      // console.log("processing");
      console.log("asdf",  (this.newCampaign.name?.length > 0 &&
        this.newCampaign.target?.length > 0 &&

        this.selectedTelynxCampaign != null &&
        this.phonesValue?.length > 0));
      if (
        this.newCampaign.name?.length > 0 &&
        this.newCampaign.target?.length > 0 &&
        this.selectedBrand &&
        this.selectedTelynxCampaign &&
        this.phonesValue?.length > 0
      ) {
        this.changeStepColors("CampaignDetails0");
      }
      // Leads1
      if (
        (this.allLeads == true) ||
        (this.uploadLeads == true) || 
        (this.industriesValue.length > 0)) {
          this.changeStepColors("Leads1");
      }
      //Limits2
      if (this.conversationsPerDay?.length > 0) {
        this.changeStepColors("Limits2");
      }
      //OutreachMethod3
      if (

        this.hookText?.length > 10) {
        this.changeStepColors("OutreachMethod3");
      }
      //AvatarIdentity4
      console.log("newavatar:", this.newAvatar);
      if (
        this.newAvatar.name?.length > 2 &&
        this.newAvatar.gpt?.length > 0 &&
        this.newAvatar.age > 0 &&
        this.newAvatar.gender &&
        this.newAvatar.state &&
        this.newAvatar.city
      ) {
        this.changeStepColors("AvatarIdentity4");
      }
      //AvatarPersonality5
      if (
        this.newAvatar.tone &&
        this.newAvatar.target &&
        this.newAvatar.bio &&
        this.newAvatar.role
      ) {
        this.changeStepColors("AvatarPersonality5");
      }
      //TrainAvatar6
      if (
        this.selectedData?.length > 0 &&
        this.newAvatar.role &&
        this.newAvatar.prompt.length > 10
      ) {
        this.changeStepColors("TrainAvatar6");
      }
      //AvatarRole7
      if (
        this.newCampaign.supervisor &&
        this.newCampaign.supervisor?.length > 10
      ) {
        this.changeStepColors("Supervisor6");
      }
      //Schedule8
      if (
        this.selectedDays?.length > 0 &&
        this.newCampaign.startTime &&
        this.newCampaign.endTime
      ) {
        this.changeStepColors("Schedule7");
      }
      //Goals9
      if (this.goalsValue?.length > 0) {
        this.changeStepColors("Goals9");
      }

      return true;
    },
    changeStepColors(id) {
      const element = document.getElementById("step-" + id);
      //  console.log(element);
      if (element) {
        element.setAttribute("style", "border-color: #50BB25 !important;");
        // Color Icon
        const iElement = element.querySelector("i");
        // console.log(iElement);
        if (iElement) {
          iElement.setAttribute("style", "color: #50BB25 !important;");
        }
      }
    },
    saveWakeup() {
      if (this.wakeupPositive == 1) {
        let wakeup = {
          id: this.positiveWakeups.length + 1,
          mins:
            parseInt(this.wakeupDays) * 1440 +
            parseInt(this.wakeupHours) * 60 +
            parseInt(this.wakeupMinutes),
          static: this.wakeupStatic,
          text: this.wakeupPrompt,
        };
        this.positiveWakeups.push(wakeup);
      } else {
        let wakeup = {
          id: this.neverRepliedWakeups.length + 1,
          mins:
            parseInt(this.wakeupDays) * 1440 +
            parseInt(this.wakeupHours) * 60 +
            parseInt(this.wakeupMinutes),
          static: this.wakeupStatic,
          text: this.wakeupPrompt,
        };
        this.neverRepliedWakeups.push(wakeup);
      }
      this.wakeupModal = false;
    },
    addHookVariable(variable) {
      this.hookText = this.hookText + " " + variable;
    },
    addAvatarVariable(variable) {
      this.newAvatar.prompt = this.newAvatar.prompt + " " + variable;
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },

    fetchLeadIndusties() {
      // console.log("Fetching Industries");
      axios
        .get("https://123avatars.com/backend/getLeadIndusties.php")
        .then((response) => {
          let industries = response.data;
          this.industries = [];
          let id = 0;
          // console.log("Got industries");
          // console.log(industries);
          industries.forEach((industry) => {
            // console.log(industry);
            id = id + 1;
            //check if used
            let used = 0;
            // this.campaigns.forEach((campaign) => {
            //   // console.log(campaign.industries);
            //   // console.log("Checking if campaign contains industry->"+industry.Industry)
            //   if (campaign.industries.includes(industry.Industry)) {
            //     used = 1;
            //     // console.log("industry used->"+industry.Industry);
            //   }
            // });
            if (used == 0) {
              this.industries.push({
                code: id,
                name: industry.Industry + " (" + industry.Count + ")",
                used: used,
              });
            }
          });

          //assign selected
          let industriesSelected = this.newCampaign?.industries?.split(",");
          industriesSelected?.forEach((industry) => {
            let industryS = this.industries.find((item) =>
              item.name.includes(industry)
            );
            this.industriesValue.push(industryS);
          });
          // console.log("industries");
          // console.log(this.industries);
        });
    },
    updateTotalSelected() {
      let totalCount = 0;
      this.industriesValue?.forEach((industry) => {
        const match = industry.name.match(/\((\d+)\)/); // Use regular expression to extract the number
        if (match) {
          const count = parseInt(match[1]); // Parse the extracted number as an integer
          totalCount += count; // Add the count to the total count
        }
      });
      this.totalSelectedLeads = totalCount;
    },
    getConnectedData() {
      axios
        .get("https://123avatars.com/backend/getData.php?u=" + this.userId)
        .then((response) => {
          console.log(response.data);
          this.connectedData = [];
          response.data.forEach((d) => {
            if (d.status == 1) {
              this.connectedData.push({
                code: d.id,
                name: d.name,
              });
              if (d.avatar_id == this.$route.params.id) {
                this.selectedData.push({
                  code: d.id,
                  name: d.name,
                });
              }
            }
          });
        });
    },
    getGoals() {
      axios
        .get("https://123avatars.com/backend/integrations.php?u=" + this.userId)
        .then((response) => {
          let integrations = response.data.integrations;
          integrations.forEach((integration) => {
            if (integration.type == 3 && integration.status == 1) {
              //Chargebee is connected
              this.goals.push({
                code: integration.id,
                name: "[ ChargeBee ] " + integration.name,
              });
            }
            if (integration.type == 2 && integration.status == 1) {
              //Calendly is connected
              this.goals.push({
                code: integration.id,
                name: "[ Calendly ] " + integration.name,
              });
            }
          });
        });
      axios
        .get("https://123avatars.com/backend/getAllGoals.php?u=" + this.userId)
        .then((response) => {
          let goals = response.data.collection;
           console.log('goals: ', goals);
          if(goals && goals.length > 0) {
              goals.forEach((goal) => {
                if(goal.type === 2) {
                  let id = goal.uri.split("/");
                  id = id.pop();
                  this.goals.push({
                    code: id,
                    name: "[ " + goal.profile.name + " ] " + goal.name,
                  });
                }
                if(goal.type == 1) {
                  this.goals.push({
                    code: goal.product_id,
                    type: goal.type,
                    name: "[ " + goal.name + " ]"
                  }); 
                }
            });
          }
          //get curent Goals
          axios
            .get(
              "https://123avatars.com/backend/getGoals.php?id=" +
                this.$route.params.id
            )
            .then((response) => {
              let goals = response.data;
              goals.forEach((goal) => {
                let sd = this.goals.find((item) => { 
                  console.log("item: ", item);
                  console.log("goal: ", goal);
                  return item.code === goal.event_id;
                });
                this.goalsValue.push(sd);
              });
            });
        });
    },
    addDomain() {
      let newDomain = this.newDomain;
      if (newDomain.length > 3) {
        axios
          .post("https://12-3.fun/addDomain", { domain: newDomain })
          .then((response) => {
            console.log(response.data);
            if (response.data == true || response.data == "Already Exists") {
              this.newDomain = "";
              //let's add the domain and refresh
              axios
                .get(
                  "https://123avatars.com/backend/addDomain.php?domain=" +
                    newDomain +
                    "&u=" +
                    this.userId
                )
                .then((response) => {
                  this.getDomains();
                  console.log(response);
                  if (response.data == false) {
                    alert("Domain already exists in your account.");
                  } else {
                    alert("Domain addedd succesfully");
                  }
                });
            } else {
              alert(response.data);
            }
          });
      } else {
        alert("Invalaid domain name: " + newDomain);
      }
    },
    getDomains() {
      axios
        .get("https://123avatars.com/backend/getDomains.php?u=" + this.userId)
        .then((response) => {
          let domains = response.data.domains;
          // console.log(response.data);
          domains.forEach((domain) => {
            this.domains.push({
              code: domain.id,
              name: `${domain.domain} (Delivered:${domain.status_2_count} - Failed:${domain.status_4_count} - Spam:${domain.status_3_count})`,
            });
            // this.domainsValue.push({
            //   code: domain.id,
            //   name: `${domain.domain}`,
            // });
          });
          //assign selected
          console.log('newCampaign: ', this.newCampaign);
          this.domainsValue = [];
          let industriesSelected = this.newCampaign?.domains?.split(",");
          console.log("getDomains: ", industriesSelected);
          industriesSelected?.forEach((industry) => {
            let industryS = this.domains.find((item) => {
              console.log("item: ", item);
              console.log("industry: ", industry);
              return item.code == industry;
            });
            console.log("getDomains: ", industryS);
            this.domainsValue.push(industryS);
          });
        });
    },
    createCampaign() {
      // console.log("createCampaign");
      this.createCampaignLoading = true;
      this.createCampaignLoadingText = `Creating ${this.newAvatar.name} Avatar... `;
      //phones
      let phones = "";
      // console.log(this.phonesValue);
      this.phonesValue.forEach((phone) => {
        let modifiedName = phone.phone.replace(/\s*\([^)]*\)/, ""); // This removes the (0239) and the space before it
        phones = phones + modifiedName + ",";
      });
      phones = phones.slice(0, -1);
      //Create Avatar
      axios
        .get("https://123avatars.com/backend/createAvatar.php", {
          params: {
            u: this.userId,
            name: this.newAvatar.name,
            age: this.newAvatar.age,
            gender: this.newAvatar.gender,
            state: this.newAvatar.state,
            city: this.newAvatar.city,
            interest: this.newAvatar.interest,
            tone: this.newAvatar.tone,
            targetAudience: this.newAvatar.target,
            workingTime: this.newAvatar.working,
            bio: this.newAvatar.bio,
            roleName: this.newAvatar.role,
            prompt: this.newAvatar.prompt,
            phones: phones
          },
        })
        .then((response) => {
          this.createCampaignLoadingText = `${this.newAvatar.name} Avatar Created Successfully `;
          let avatarId = response.data.avatar_id;
          if (!avatarId) {
            this.createCampaignLoadingText =
              "Something went wrong while creating the avatar " +
              this.newAvatar.name;
            return false;
          }
          let encodedPrompt = encodeURIComponent(this.newAvatar.prompt);
          this.createCampaignLoadingText = "Updating Avatar Prompt";

         // `https://123avatars.com/backend/saveRole.php?id=${avatarId}&prompt=${encodedPrompt}`

          axios
            .get(
              `https://123avatars.com/backend/saveRole.php?id=${avatarId}&prompt=`
            )
            .then((response) => {
              this.createCampaignLoadingText =
                "Avatar Prompt Updated successfully..";
              // console.log(response);

              //Connect Data & Goals
              this.selectedData.forEach((data) => {
                this.createCampaignLoadingText =
                  "connecting dataset - " + data.name;
                axios
                  .get(
                    "https://123avatars.com/backend/connectData.php?dataId=" +
                      data.code +
                      "&avatarId=" +
                      avatarId
                  )
                  .then((response) => {
                    //Close and show success then refresh data
                    this.createCampaignLoadingText =
                      "Dataset - " + data.name + " Connected successfully";
                  });
              });
              this.goalsValue.forEach((goal) => {
                this.createCampaignLoadingText =
                  "connecting Goal - " + goal.name;
                axios
                  .get(
                    "https://123avatars.com/backend/connectGoal.php?avatar_id=" +
                      avatarId +
                      "&name=" +
                      goal.name +
                      "&id=" +
                      goal.code +
                      "&type=" +
                      goal.type +
                      "&u=" +
                      this.userId
                  )
                  .then((response) => {
                    // console.log(response);
                    this.createCampaignLoadingText =
                      goal.name + " Goal Connected! ";
                  });
              });
              this.createCampaignLoadingText = "Creating Campaign structure...";
              //Create Campaign and connect the avatar
              let industries = "";
              this.industriesValue.forEach((industry) => {
                let modifiedName = industry.name.replace(/\s*\([^)]*\)/, ""); // This removes the (0239) and the space before it
                industries = industries + modifiedName + ",";
              });
              industries = industries.slice(0, -1);
              let domains = "";
              this.domainsValue.forEach((domain) => {
                domains = domains + domain.code + ",";
              });
              domains = domains.slice(0, -1);
              let activeDays = this.selectedDays.map((day) => `${day.code}`);
              console.log("selectedTelnyxCampaign: ", this.selectedTelynxCampaign)
              axios
                .get("https://123avatars.com/backend/createCampaign.php", {
                  params: {
                    name: this.newCampaign.name,
                    avatarId: avatarId,
                    type: 1,
                    industries: industries,
                    convPerDay: this.conversationsPerDay,
                    days: activeDays.join(),
                    startTime: this.startTime,
                    endTime: this.endTime,
                    timezone: this.timezone,
                    u: this.userId,
                    target: this.newCampaign.target,
                    domains: domains,
                    brand: this.selectedBrand.code,
                    campaign: this.selectedTelynxCampaign.code,
                    tel_campaign_name: this.selectedTelynxCampaign.tel_campaign_name,
                    supervisor: this.newCampaign.supervisor,
                    brand_id: this.selectedBrand.id,
                    all_leads: this.allLeads
                  },
                })
                .then((response) => {
                  // console.log(response);
                  this.createCampaignLoadingText =
                    "Campaign Created Successfully";

                  let campaignId = response.data.campaign_id;
                  let staticH = 0;
                  if (this.staticHook) {
                    staticH = 1;
                  }

                  this.createCampaignLoadingText =
                    "Finalizing Hook and coldreach strategy..";
                  //create and attach hook
                  axios
                    .get(
                      "https://123avatars.com/backend/campaignHook.php?prompt=" +
                        this.hookText +
                        "&example1=" +
                        this.example1 +
                        "&example2=" +
                        this.example2 +
                        "&example3=" +
                        this.example3 +
                        "&name=" +
                        this.hookName +
                        "&campaign_id=" +
                        campaignId +
                        "&static=" +
                        staticH +
                        "&smart=" +
                        this.smartHook +
                        "&hook=" +
                        this.hookText +
                        "&u=" +
                        this.userId  +
                        "&mmsImage=" +
                        this.selectedMMSImage
                    )
                    .then((response) => {
                      // console.log(response);
                      //force assign leads
                      axios
                        .get(
                          "https://123avatars.com/backend/cron/assign_leads.php"
                        )
                        .then((respsonse) => {
                          setTimeout(() => {
                          this.getCampaigns();
                        }, 100);
                        });
                      setTimeout(() => {
                        
                        this.createCampaignLoadingText = "Created Successfully";
                        this.newCampaign = {
                          name: "",
                          target: "",
                          //startTime: "8 AM",
                          //  endTime: "8 PM",
                          supervisor: "",
                        };
                        this.newCampaign.selectedBrand = this.selectedBrand;
                        this.newCampaign.selectedTelynxCampaign = this.selectedTelynxCampaign;
                        this.newCampaign.phonesValue = this.phonesValue;
                        this.newCampaign.conversationsPerDay = this.conversationsPerDay;
                        this.newCampaign.hookName = this.hookName;
                        this.newCampaign.mms = this.mms;
                        this.newCampaign.selectedMMSImage = this.selectedMMSImage;
                        this.newCampaign.hookText = this.hookText;
                        this.newCampaign.charLimit = this.charLimit;
                        this.newCampaign.startTime = this.startTime;
                        this.newCampaign.endTime = this.endTime;
                        this.newCampaign.selectedDays = this.selectedDays;
                        localStorage.setItem(
                          "pending_Campaign",
                          JSON.stringify(this.newCampaign)
                        );
                        localStorage.setItem(
                          "pending_avatar",
                          JSON.stringify(this.newAvatar)
                        );
                        this.$toasted.show(" Campaign Created Successfully ", {
                          theme: "outline",
                          position: "top-right",
                          type: "success",
                          icon: "check",
                          duration: 10000,
                        });
                      }, 5000);
                    });
                })
                .catch((e) => {
                  console.error(e);
                  this.createCampaignLoadingText =
                    "ERROR: Something went wrong While Creating the campaign, please double check your campaign data.";
                });
            })
            .catch((e) => {
              console.error(e);
              this.createCampaignLoadingText =
                "ERROR: Something went wrong While saving Avatar prompt, Please double check your data.";
            });
        });
    },
    saveDataSet() {
      let id = this.SelectedDataSet.id;
      if (!id) {
        if (!this.SelectedDataSet.name || !this.SelectedDataSet.text) {
          this.$toasted.show("Please fill in the details", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
          return false;
        }

        axios
          .get("https://123avatars.com/backend/newData.php", {
            params: {
              u: this.userId,
              dataText: this.SelectedDataSet.text,
              dataName: this.SelectedDataSet.name,
            },
          })
          .then((response) => {
            //new Data Created
            // console.log(response.data.data_id);
            this.getConnectedData(response.data.data_id);
            this.dataModal = false;
            this.$toasted.show(
              this.SelectedDataSet.name + " Dataset Created Successfully",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
            this.SelectedDataSet = [];
          });
        return false;
      }
      axios
        .get("https://123avatars.com/backend/editData.php", {
          params: {
            id: this.SelectedDataSet.id,
            dataText: this.SelectedDataSet.text,
            dataName: this.SelectedDataSet.name,
          },
        })
        .then((response) => {
          //new Data Created
          // console.log(response);
          this.getConnectedData();
          this.dataModal = false;
          this.$toasted.show(this.SelectedDataSet.name + " Dataset Updated", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 10000,
          });
          this.SelectedDataSet = [];
        });
    },
    saveWebsite() {
      if (this.newWebsite.length < 4) {
        this.$toasted.show("Please input a valid website sitemap", {
          theme: "outline",
          position: "top-right",
          type: "danger",
          icon: "info",
          duration: 10000,
        });
      } else {
        axios
          .get(
            "https://123avatars.com/backend/addWebsite.php?name=" +
              this.newWebsite +
              "&u=" +
              this.userId
          )
          .then((response) => {
            // console.log(response.data.data_id);
            this.webModal = false;
            this.newWebsite = "";
            this.getConnectedData(response.data.data_id);
            this.$toasted.show(
              "Website added Successfully please allow a few minutes for Scraping process.",
              {
                theme: "outline",
                position: "top-right",
                type: "success",
                icon: "check",
                duration: 10000,
              }
            );
          });
      }
    },
    uploadSuccess(f, s) {
      let obj = JSON.parse(s);
      // console.log(obj.file_id);
      this.getConnectedData(obj.file_id);
      this.fileModal = false;
    },
    getHooks() {
      axios
        .get("https://123avatars.com/backend/getHooks.php?u=" + this.userId)
        .then(async (response) => {
          let hooks = response.data;
          if(hooks) {
            hooks.forEach((hook) => {
              console.log("hook: ", hook);
            this.hooks.push({
              code: hook.id,
              name: hook.name,
              static: hook.static,
              smartHook: hook.smart,
              prompt: hook.prompt,
            });
            if (hook.campaign_id == this.$route.params.campId) {
              console.log("selected hook: ", hook);
              this.selectedHook = hook;
              this.static = (hook.static === "1") ? true : false;
              this.smartHook = (hook.smart === "1") ? true : false; 
              this.mms = (hook.mms === "1") ? true : false;
              // this.staticHook = true;
              this.hookText = hook.prompt;
              this.charLimit = hook.char_limit;
              this.$forceUpdate();
            }
          });
          console.log("hookText: ", this.hookText);
          this.$forceUpdate();
          }
        });
    },
    getAvatars() {
      axios
        .get("https://123avatars.com/backend/getAvatars.php?u=" + this.userId)
        .then((response) => {
          let avatars = response.data.avatars;
          console.log(avatars);
          avatars.forEach((avatar) => {
            this.prompts.push({
              code: avatar.id,
              name: avatar.name,
              prompt: avatar.prompt,
            });
          });
        });
    },
    getTriggers() {
      axios
        .get(
          "https://123avatars.com/backend/getWakeupTriggers.php?u=" +
            this.userId
        )
        .then((res) => {
          let triggers = res.data;
          if(triggers && triggers.length > 0) {
            triggers.forEach((trigger) => {
            this.triggers.push({
              id: trigger.id,
              name: trigger.name,
              code: trigger.identifier,
            });
          });
          }
        });
    },
    getFunnels() {
      axios
        .get("https://123avatars.com/backend/getFunnels.php?u=" + this.userId)
        .then((res) => {
          this.funnels = res.data;
        });
    },
  },
  filters: {
    toFixed(value, decimals) {
      // Check if the value is a number
      if (typeof value === "number") {
        return value.toFixed(decimals);
      }
      return value;
    },
  },
};
</script>
