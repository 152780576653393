<template>
  <div id="funnels-container">
    <!-- Header Section -->
    <div class="header">
      <button class="btn solid icon" @click="toggleAddEditor(true)"><PlusSVG />Add New Funnel</button>
      <div class="filter-section">
        <div class="form-group select">
          <select v-model="sortBy" class="form-control">
            <option value="name">Sort By Name</option>
            <option value="date">Sort By Date</option>
          </select>
        </div>
        <vue-typeahead-bootstrap
          type="text"
          class="search"
          inputClass="search-icon"
          placeholder="Search Funnels"
          data-ignore="true"
          v-model="searchQuery"
        />
      </div>
    </div>

    <!-- Add Funnel Editor -->
    <UIBox v-if="addEditorVisible" classbox="hello" class="mt-4 mb-0">
      <template v-slot:title>
        <span class="bold">
          <input
            type="text"
            class="form-control"
            v-model="funnelData.name"
            placeholder="Funnel Name"
          />
        </span>
      </template>
      <template>
        <div class="followups">
          <div
            v-for="(followup, index) in funnelData.followUps"
            :key="followup.id"
            class="followup-editor"
          >
            <div class="followup-title">
              <h4>Followup {{ index + 1 }}</h4>
              <button v-b-modal="'followupPrompt-' + index"><ThreeGearsSVG :fill="roleColor()" /></button>
            </div>
            <div class="followup-content">
              <div class="toggle-container">
                <emailSVG :size="32" :fill="roleColor()" />
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="followup.channel"
                    true-value="SMS"
                    false-value="Email"
                  />
                  <span class="slider"></span>
                </label>
                <chatSVG :size="32" :fill="roleColor()" />
              </div>
              <div class="custom-radio-group" :class="{ 'one-choice': index > 0 }">
                <label v-if="index == 0" class="custom-radio">
                  <input
                    type="radio"
                    v-model="followup.type"
                    value="immediately"
                  />
                  <span class="radio-label">Immediately</span>
                </label>
                <label class="custom-radio">
                  <input
                    type="radio"
                    v-model="followup.type"
                    value="delay"
                  />
                  <div class="radio-label" >{{ index === 0 ? 'Delay' : 'Delay Since Last Followup' }}</div>
                </label>
                <div class="delay-inputs">
                  <input
                    type="number"
                    v-model.number="followup.delay.days"
                    placeholder="D"
                    class="form-control"
                    min="0"
                    :disabled="followup.type !== 'delay'"
                  />
                  <input
                    type="number"
                    v-model.number="followup.delay.hours"
                    placeholder="H"
                    class="form-control"
                    min="0"
                    max="23"
                    :disabled="followup.type !== 'delay'"
                  />
                  <input
                    type="number"
                    v-model.number="followup.delay.minutes"
                    placeholder="M"
                    class="form-control"
                    min="0"
                    max="59"
                    :disabled="followup.type !== 'delay'"
                  />
                </div>
                <div class="delay-inputs">
                  <label><b>Send at</b> (PST)</label>
                </div>
                <div class="delay-inputs">
                  <input
                    type="number"
                    v-model.number="followup.delay.time.hours"
                    placeholder="H"
                    class="form-control sm-input"
                    min="1"
                    max="12"
                    :disabled="followup.type !== 'delay'"
                  />
                  <span>:</span>
                  <input
                    type="number"
                    v-model.number="followup.delay.time.minutes"
                    placeholder="M"
                    class="form-control sm-input"
                    min="0"
                    max="59"
                    :disabled="followup.type !== 'delay'"
                  />
                  <div class="form-group select" :class="{ 'disable': followup.type !== 'delay' }">
                    <select
                      v-model="followup.delay.time.period"
                      class="form-control"
                      :class="{ 'disabled': followup.type !== 'delay' }"
                      :disabled="followup.type !== 'delay'"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="prompt-container">
                <b-modal :id="'followupPrompt-' + index" :title="'Followup '+(index + 1)+' Prompt'" size="md">
                  <template #modal-header="{ close }" class="header">
                    <div style="color: white;">Enter Prompt for <span class="bold">Followup {{ index + 1 }}</span></div>
                    <button class="btn" @click="close()">
                      <closeSVG fill="#fff" />
                    </button>
                  </template>
                  <div class="form-group">
                      <textarea
                      v-model="followup.prompt"
                      rows="5"
                      class="form-control"
                      :placeholder="'Enter your prompt...'"
                    ></textarea>
                  </div>
                  <template #modal-footer="{ close }">
                    <div class="buttons">
                      <b-button class="btn solid icon" @click="close()">Close</b-button>
                    </div>
                  </template>
                </b-modal>
              </div>
              <button v-if="index > 0" class="btn solid mt-3 w-100 text-center justify-content-center" @click="removeFollowup(index, funnelData.followUps)">
                Remove Followup
              </button>
            </div>
          </div>
          <button class="add-followup-btn" @click="addFollowup(funnelData.followUps)">
            Add Follow Up <i><PlusSVG /></i>
          </button>
        </div>
        <div class="actions">
          <div class="checkbox-container" @click="funnelData.removeOnResponse = !funnelData.removeOnResponse">
            <label class="font-bold" style="float: right; position: relative; margin: 0; margin-right: 12.5px;">Remove prospect from funnel if they respond</label>
            <td style="float: right; position: relative; margin-right: 5px;">
              <CheckedSVG v-if="funnelData.removeOnResponse" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
              <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
            </td>
          </div>
          <button class="btn outline" @click="toggleAddEditor(false)">Cancel</button>
          <button class="btn solid px-3" :disabled="!isFormValid(funnelData)" @click="saveFunnel">Save</button>
        </div>
      </template>
    </UIBox>

    <!-- Funnels Display Section -->
    <div class="funnels-list mt-4">
      <div
        v-for="(funnel, index) in filteredFunnels"
        :key="funnel.id"
        class="funnel-card"
      >
        <UIBox v-if="editingFunnelId === funnel.id" classbox="hello" class="m-0">
          <template v-slot:title>
            <span class="bold">
              <input
                type="text"
                class="form-control"
                v-model="editingFunnel.name"
                placeholder="Funnel Name"
              />
            </span>
          </template>
          <template>
            <div class="followups">
              <div
                v-for="(followup, index) in editingFunnel.followUps"
                :key="followup.id"
                class="followup-editor"
              >
                <div class="followup-title">
                  <h4>Followup {{ index + 1 }}</h4>
                  <button v-b-modal="'editFollowupPrompt-' + index"><ThreeGearsSVG :fill="roleColor()" /></button>
                </div>
                <div class="followup-content">
                  <div class="toggle-container">
                    <emailSVG :size="32" :fill="roleColor()" />
                    <label class="switch">
                      <input
                        type="checkbox"
                        v-model="followup.channel"
                        true-value="SMS"
                        false-value="Email"
                      />
                      <span class="slider"></span>
                    </label>
                    <chatSVG :size="32" :fill="roleColor()" />
                  </div>
                  <div class="custom-radio-group" :class="{ 'one-choice': index > 0 }">
                    <label v-if="index == 0" class="custom-radio">
                      <input
                        type="radio"
                        v-model="followup.type"
                        value="immediately"
                      />
                      <span class="radio-label">Immediately</span>
                    </label>
                    <label class="custom-radio">
                      <input
                        type="radio"
                        v-model="followup.type"
                        value="delay"
                      />
                      <div class="radio-label" >{{ index === 0 ? 'Delay' : 'Delay Since Last Followup' }}</div>
                    </label>
                    <div class="delay-inputs">
                      <input
                        type="number"
                        v-model.number="followup.delay.days"
                        placeholder="D"
                        class="form-control"
                        min="0"
                        :disabled="followup.type !== 'delay'"
                      />
                      <input
                        type="number"
                        v-model.number="followup.delay.hours"
                        placeholder="H"
                        class="form-control"
                        min="0"
                        max="23"
                        :disabled="followup.type !== 'delay'"
                      />
                      <input
                        type="number"
                        v-model.number="followup.delay.minutes"
                        placeholder="M"
                        class="form-control"
                        min="0"
                        max="59"
                        :disabled="followup.type !== 'delay'"
                      />
                    </div>
                    <div class="delay-inputs">
                      <label><b>Send at</b> (PST)</label>
                    </div>
                    <div class="delay-inputs">
                      <input
                        type="number"
                        v-model.number="followup.delay.time.hours"
                        placeholder="H"
                        class="form-control sm-input"
                        min="1"
                        max="12"
                        :disabled="followup.type !== 'delay'"
                      />
                      <span>:</span>
                      <input
                        type="number"
                        v-model.number="followup.delay.time.minutes"
                        placeholder="M"
                        class="form-control sm-input"
                        min="0"
                        max="59"
                        :disabled="followup.type !== 'delay'"
                      />
                      <div class="form-group select" :class="{ 'disable': followup.type !== 'delay' }">
                        <select
                          v-model="followup.delay.time.period"
                          class="form-control"
                          :class="{ 'disabled': followup.type !== 'delay' }"
                          :disabled="followup.type !== 'delay'"
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="prompt-container">
                    <b-modal :id="'editFollowupPrompt-' + index" :title="'Followup '+(index + 1)+' Prompt'" size="md">
                      <template #modal-header="{ close }" class="header">
                        <div style="color: white;">Enter Prompt for <span class="bold">Followup {{ index + 1 }}</span></div>
                        <button class="btn" @click="close()">
                          <closeSVG fill="#fff" />
                        </button>
                      </template>
                      <div class="form-group">
                          <textarea
                          v-model="followup.prompt"
                          rows="5"
                          class="form-control"
                          :placeholder="'Enter your prompt...'"
                        ></textarea>
                      </div>
                      <template #modal-footer="{ close }">
                        <div class="buttons">
                          <b-button class="btn solid icon" @click="close()">Close</b-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                  <button v-if="index > 0" class="btn solid mt-3 w-100 text-center justify-content-center" @click="removeFollowup(index, funnelData.followUps)">
                    Remove Followup
                  </button>
                </div>
              </div>
              <button class="add-followup-btn" @click="addFollowup(editingFunnel.followUps)">
                Add Follow Up <i><PlusSVG /></i>
              </button>
            </div>
            <div class="actions">
              <div class="checkbox-container" @click="editingFunnel.removeOnResponse = !editingFunnel.removeOnResponse">
                <label class="font-bold" style="float: right; position: relative; margin: 0; margin-right: 12.5px;">Remove prospect from funnel if they respond</label>
                <td style="float: right; position: relative; margin-right: 5px;">
                  <CheckedSVG v-if="editingFunnel.removeOnResponse" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                  <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                </td>
              </div>
              <button class="btn outline" @click="cancelEdit">Cancel</button>
              <button class="btn solid px-3" :disabled="!isFormValid(editingFunnel)" @click="saveEditingFunnel">Save</button>
            </div>
          </template>
        </UIBox>

        <!-- View Mode -->
        <UIBox v-else classbox="hello" class="m-0">
          <template v-slot:title>
            <span class="bold">{{ funnel.name }} &mdash; Prospects: <a v-b-modal="'prospectsInfo-' + index" style="text-decoration: underline; cursor: pointer;">{{ funnel.prospects }}</a> <sub style="font-size: 16px;">(Followups: {{ funnel.followUps.length }}, SMS Followups: {{ funnel.followUps.filter(f => f.channel === 'SMS').length }}, Email Followups: {{ funnel.followUps.filter(f => f.channel === 'Email').length }})</sub></span>
            <div class="actions-btns">
              <button class="btn solid icon" @click="deleteFunnel(funnel.id)"><deleteSVG /></button>
              <button class="btn solid icon" @click="editFunnel(funnel)"><penSVG /></button>
              <b-modal :id="'prospectsInfo-' + index" :title="'Prospects Information'" size="md">
                <template #modal-header="{ close }" class="header">
                  <div style="color: white;">Prospects Information for <span class="bold">{{ funnel.name }}</span></div>
                  <button class="btn" @click="close()">
                    <closeSVG fill="#fff" />
                  </button>
                </template>
                <template #modal-footer="{ close }">
                  <div class="buttons">
                    <b-button class="btn solid icon" @click="close()">Close</b-button>
                  </div>
                </template>
              </b-modal>
            </div>
          </template>
          <template>
            <div class="followups">
              <div
                v-for="(followup, index) in funnel.followUps"
                :key="followup.id"
                class="followup-card"
              >
                <h5>Followup {{ index + 1 }}</h5>

                <p v-if="followup.type === 'immediately'"><b>Immediately</b></p>
                <div>
                  <p v-if="followup.type === 'delay'">
                    <b>Delay:</b> {{ followup.delay.days || 0 }}d {{ followup.delay.hours || 0 }}h
                    {{ followup.delay.minutes || 0 }}m
                  </p>
                  <p v-if="followup.type === 'delay'">
                    <b>Send at:</b> {{ formatTime(followup.delay.time) }}
                  </p>
                </div>

                <div class="followup-icon-container">
                  <emailSVG v-if="followup.channel === 'Email'" :size="32" fill="#ffffff" />
                  <chatSVG v-if="followup.channel === 'SMS'" :size="32" fill="#ffffff" />
                  <ThreeGearsSVG v-if="followup.prompt" fill="#ffffff" class="sm-svg" />
                </div>
              </div>
            </div>
          </template>
        </UIBox>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import UIBox from "@/components/uibox.vue";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import ThreeGearsSVG from '../../components/SVG-icons/threeGearsSVG.vue';
import PlusSVG from '../../components/SVG-icons/plusSVG.vue';
import CheckedSVG from '../../components/SVG-icons/checkedSVG.vue';
import UncheckedSVG from '../../components/SVG-icons/unCheckedSVG.vue';
import emailSVG from '../../components/SVG-icons/emailSVG.vue';
import chatSVG from '../../components/SVG-icons/chatSVG.vue';
import penSVG from '../../components/SVG-icons/penSVG.vue';
import deleteSVG from '../../components/SVG-icons/deleteSVG.vue';
import ChatSVG from "../../components/SVG-icons/chatSVG.vue";
import closeSVG from "../../components/SVG-icons/closeSVG.vue";

export default {
  components: {
    AuthService, UIBox, VueTypeaheadBootstrap, ThreeGearsSVG, PlusSVG, CheckedSVG, UncheckedSVG, emailSVG, chatSVG, penSVG, deleteSVG, closeSVG
  },
  data() {
    return {
      funnels: [],
      funnelData: {
        id: null,
        name: "",
        removeOnResponse: false,
        prospects: 0,
        followUps: [],
      },
      editingFunnelId: null,
      editingFunnel: null,
      addEditorVisible: false,
      searchQuery: "",
      sortBy: "name",
      role: ""
    };
  },
  computed: {
    filteredFunnels() {
      return this.funnels
        .filter((funnel) =>
          funnel.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          if (this.sortBy === "name") return a.name.localeCompare(b.name);
          if (this.sortBy === "date") return b.id - a.id;
        });
    },
  },
  methods: {
    isFormValid(funnelData) {
      console.log(funnelData);
      if (!funnelData.name.trim()) return false;

      for (const followup of funnelData.followUps) {
        if (
          !followup.channel ||
          !followup.type ||
          (followup.type === 'delay' &&
            (
              ((followup.delay.days === null || followup.delay.days == 0) &&
              (followup.delay.hours === null || followup.delay.hours == 0) &&
              (followup.delay.minutes === null || followup.delay.minutes == 0)) ||
              (followup.delay.time.hours === null || followup.delay.time.hours == 0) ||
              followup.delay.time.minutes === null ||
              !followup.delay.time.period
            )
          )
        ) {
          return false;
        }
      }

      return true;
    },
    toggleAddEditor(visible) {
      if (!visible) this.resetEditor();
      else if(this.addEditorVisible != visible) this.addFollowup(this.funnelData.followUps, 'immediately');

      this.addEditorVisible = visible;
    },
    resetEditor() {
      this.funnelData = {
        id: null,
        name: "",
        removeOnResponse: false,
        prospects: 0,
        followUps: [],
      };
    },
    addFollowup(followUps, type = 'delay') {
      followUps.push({
        id: Date.now(),
        type: type,
        delay: { days: null, hours: null, minutes: null, time: { hours: null, minutes: null, period: "AM" } },
        channel: "Email",
        prompt: "",
        showPrompt: false,
      });
    },
    removeFollowup(index, followUps) {
      followUps.splice(index, 1);
    },
    saveFunnel() {
      if (this.isFormValid(this.funnelData)) {
        this.funnelData.id = Date.now();
        this.funnels.push({ ...this.funnelData });
        this.toggleAddEditor(false);
      }
    },
    editFunnel(funnel) {
      this.editingFunnelId = funnel.id;
      this.editingFunnel = JSON.parse(JSON.stringify(funnel));
    },
    saveEditingFunnel() {
      const index = this.funnels.findIndex((f) => f.id === this.editingFunnel.id);
      this.$set(this.funnels, index, { ...this.editingFunnel });
      this.editingFunnelId = null;
      this.editingFunnel = null;
    },
    cancelEdit() {
      this.editingFunnelId = null;
      this.editingFunnel = null;
    },
    deleteFunnel(id) {
      this.funnels = this.funnels.filter((funnel) => funnel.id !== id);
    },
    formatTime(time) {
      return `${time.hours}:${String(time.minutes).padStart(2, "0")} ${time.period}`;
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    }
  },
  mounted() {
    this.initUsers();
  }
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);

#funnels-container {

  .header {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .filter-section {
      display: flex;
      gap: 15px;

        .form-group {
          margin: 0;
          display: flex;
          align-items: center;

          select {
            width: 175px;
            height: 100%;
          }

          &.select {
            &::after {
              bottom: 15px;
            }
          }
        }

        .search {
          min-width: 300px;
          height: 100%;

          ::v-deep(.input-group) {
            height: 100%;
          }

          ::v-deep(.search-icon) {
            height: 100%;
          }
        }
    }
  }

  .box {
    ::v-deep(.header .title) {
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    ::v-deep(.header .title span) {
      font-size: 24px;
    }
  }

  .funnels-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    .funnel-card {
      width: 100%;

      .funnel-actions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
      }

      .actions-btns {
        display: flex;
        gap: 10px;

        button {
          width: 40px;
          height: 40px;

          svg {
            display: flex;
            flex-shrink: 0;
            height: 18px;
            width: auto;
          }
        }
      }
    }
  }

  .followups {
    width: 100%;
    display: flex;
    gap: 25px;
    overflow: hidden;
    overflow-x: auto;

    .followup-editor {
      border: 2px solid $interactive;
      border-radius: 20px;
      flex-shrink: 0;
      overflow: hidden;

      .followup-title {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #ffffff;
        background-color: $interactive;

        h4 {
          font-size: 24px;
          font-weight: 500;
        }

        button {
          padding: 0 7.5px;
          background-color: #ffffff;
          border: none;
          border-radius: 5px;

          svg {
            display: flex;
            width: 22px;
            height: auto;
          }
        }
      }

      .delay-inputs {
        width: 230px;
        padding-left: 30px;
        display: flex;
        align-items: center;
        gap: 10px;

        input,
        select {
          padding: 17.5px 5px;
          width: 60px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          background-color: transparent;
          border: 2px solid var(--interactive);
          border-radius: 10px;
          transition: border 0.35s;

          &.sm-input {
            width: 55px;
          }

          &:disabled {
            border-color: #ced4da;
          }
        }

        .form-group {
          margin: 0;
          display: flex;
          flex-grow: 1;
          height: unset;
          align-self: stretch;

          select {
            width: 65px;
            padding: 0;
            padding-right: 20px;
            display: flex;
            flex-grow: 1;
            align-self: stretch;
            height: unset;

            &.disabled {
              color: #5a636b;
            }
          }

          &.select {
            &::after {
              bottom: 15px;
            }
          }

          &.disable {
            &::after {
              border-top-color: #ced4da;
            }
          }
        }

        label {
          margin: -5px 0;
          font-size: 14px;
          font-weight: 400;

          b {
            font-weight: 700;
          }
        }

        span {
          margin: 0 -5px;
          font-size: 15px;
          font-weight: 700;
        }
      }

      .followup-content {
        padding: 20px;
      }

      .toggle-container {
        display: flex;
        gap: 7.5px;
        align-items: center;
        justify-content: flex-start;

        .switch {
          position: relative;
          display: inline-block;
          width: 67px;
          height: 40px;
          margin: 0;

          input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider:before {
              transform: translate(27px, -50%);
            }
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $interactive;
            transition: 0.25s;
            border-radius: 24px;

            &:before {
              position: absolute;
              content: '';
              height: 25px;
              width: 25px;
              left: 7.5px;
              top: 50%;
              transform: translateY(-50%);
              background-color: white;
              transition: 0.4s;
              border-radius: 50%;
            }
          }
        }

        .toggle-labels {
          display: flex;
          justify-content: space-between;
          width: 60px;
          margin-top: 5px;
          font-size: 12px;

          span {
            &:first-child {
              text-align: left;
            }
            &:last-child {
              text-align: right;
            }
          }
        }
      }

      .custom-radio-group {
        margin-top: 25px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .custom-radio {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;

          input[type='radio'] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .radio-label::after {
              opacity: 1;
            }
          }

          &:last-of-type {
            margin: 0;
            margin-bottom: -5px;
          }

          .radio-label {
            position: relative;
            padding-left: 30px;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              border: 1px solid $interactive;
              border-radius: 50%;
              transition: border-color 0.3s ease;
            }

            &::after {
              content: '';
              position: absolute;
              left: 5px;
              top: 50%;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              background-color: $interactive;
              border-radius: 50%;
              opacity: 0;
              transition: opacity 0.3s ease;
            }
          }
        }

        &.one-choice {
          .radio-label,
          .delay-inputs {
            padding: 0;
          }

          .delay-inputs {
            width: 200px;
          }

          .custom-radio {
            cursor: default;

            &:last-of-type {
              margin-top: -12.5px;
            }
          }

          .radio-label {
            cursor: default;

            &::before,
            &::after {
              content: unset;
            }
          }
        }
      }

      textarea {
        margin-top: 10px;
        width: 100%;
        resize: none;
      }
    }

    .followup-card {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      background-color: $interactive;
      color: #fff;
      border-radius: 20px;
      height: 150px;

      p,
      h5 {
        margin: 0;
      }

      h5 {
        font-size: 24px;
        font-weight: 600;
      }

      p {
        line-height: 1.25;
        font-size: 13px;

        b {
          font-weight: 600;
        }
      }

      .followup-icon-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .sm-svg {
          height: 20px;
          width: auto;
        }
      }
    }

    .add-followup-btn {
      opacity: 0.85;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      flex-grow: 0;
      color: $interactive;
      border: 2px dashed $interactive;
      font-size: 21px;
      background-color: transparent;
      border-radius: 20px;
      transition: opacity 0.35s, background 0.35s;

      i {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: $interactive;
        border-radius: 50%;

        svg {
          fill: #ffffff;
          width: 50%;
          height: auto;
        }
      }

      &:hover {
        opacity: 0.95;
        background-color: rgba(167, 58, 24, 0.1);
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    gap: 17.5px;
    align-items: center;
    justify-content: flex-end;

    .checkbox-container {
      cursor: pointer;
      display: flex;
      align-items: center;

      td {
        padding: 0;
        display: flex;
      }

      label {
        cursor: pointer;
        font-weight: 400;
      }
    }
  }

  input[type='text'] {
    border: 2px solid var(--interactive);
    border-radius: 10px;
  }
}

textarea.form-control {
  padding: 12.5px;
  font-size: 17px;
  border: 2px solid var(--interactive);
  border-radius: 10px;
}

.modal-body {
  .form-group {
    width: 100%;
  }
}
</style>