<template>
	<svg viewBox="0 0 20 20" class="passwordSVG">
	  <path d="M20 6.867c0 1.9-.667 3.516-2.017 4.866-1.35 1.35-2.966 2.017-4.866 2.017-.45 0-.867-.033-1.284-.117l-.933 1.05a.922.922 0 0 1-.7.317H8.75v1.567a.892.892 0 0 1-.267.666.892.892 0 0 1-.666.267H6.25v1.567a.892.892 0 0 1-.267.666.892.892 0 0 1-.666.267H.933a.892.892 0 0 1-.666-.267.892.892 0 0 1-.267-.666v-3.05c0-.267.083-.484.267-.667L6.6 9.017a6.756 6.756 0 0 1-.35-2.15c0-1.25.3-2.4.917-3.45a6.914 6.914 0 0 1 2.5-2.5C10.717.3 11.867 0 13.117 0c1.9 0 3.516.667 4.866 2.017C19.333 3.367 20 4.983 20 6.883v-.016Zm-6.333-3.2c-.367.366-.55.8-.55 1.333 0 .533.183.967.55 1.333.366.367.8.55 1.333.55.533 0 .967-.183 1.333-.55.367-.366.55-.8.55-1.333 0-.533-.183-.967-.55-1.333-.366-.367-.8-.55-1.333-.55-.533 0-.967.183-1.333.55Z"/>
	</svg>
</template>

<script>
export default {
   name: 'chatSVG',
}
</script>