<template>
	<svg viewBox="0 0 17 19" class="playSVG">
	   <path d="M0.2146 18.9688V0.369644L16.0239 9.66923L0.2146 18.9688Z" />
	 </svg>
</template>

<script>
export default {
   name: 'playSVG',
}
</script>