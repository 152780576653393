<template>
<svg viewBox="0 0 24 15">
  <path d="M23.5582.46902C23.2621.15751 22.903 0 22.4828 0h-9.9622c-.422 0-.781.15576-1.0755.46902-.2962.31327-.4434.69129-.4434 1.13756v4.03395l2.4438 2.58488c.268.28352.4086.64578.4086 1.0483s-.1423.76659-.4103 1.05009L11 12.9086v.4848c0 .4463.1473.8261.4434 1.1376.2945.3115.6536.469 1.0755.469h9.9622c.4219 0 .781-.1558 1.0755-.469.2945-.3133.4434-.6913.4434-1.1376V1.60658c0-.44627-.1473-.82604-.4434-1.13756h.0016Zm-1.5817 4.888h-8.9496V2.54463c0-.26777.1274-.40252.3805-.40252h8.1902c.2531 0 .3789.13475.3789.40252v2.81239Z" />
  <path d="M11.7998 9.46871c.1328-.1274.2002-.28522.2002-.46776 0-.18254-.0674-.34036-.2002-.46775L9.57368 6.39788 6.23153 3.19205c-.0337-.03233-.06938-.06085-.10506-.08367C6.01546 3.03613 5.8886 3 5.74389 3c-.19228 0-.35483.06465-.48765.19205l-.6601.60656c-.13479.12929-.19625.28521-.18633.46775.00991.18444.08127.34036.21607.46775L8.0711 7.90002H.68786c-.19229 0-.35484.06464-.48765.19204C.06541 8.22136 0 8.37728 0 8.55982v.88036c0 .18444.0674.34036.20021.46776.13282.12926.29735.19206.48764.19206h7.38127l-3.44523 3.1659c-.13479.1293-.20615.2852-.21607.4677-.00991.1845.05154.3404.18634.4678l.6601.6066c.13282.1274.29735.192.48765.192.14272 0 .27157-.0361.38258-.1084.03766-.0247.07334-.0513.10506-.0836l3.34214-3.2059 2.22611-2.1353.002.00191Z"/>
</svg>
</template>

<script>
export default {
   name: 'launchindialerSVG',
}
</script>