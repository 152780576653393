<template>
		<svg viewBox="0 0 23 23" class="searchSVG">
		  <path d="m22.674 21.428-1.264 1.265c-.21.211-.46.307-.765.307-.307 0-.556-.096-.767-.307l-4.48-4.485a1.024 1.024 0 0 1-.307-.766v-.729c-1.685 1.323-3.6 1.974-5.745 1.974-2.567 0-4.769-.92-6.607-2.74C.9 14.126 0 11.922 0 9.334c0-2.587.92-4.772 2.739-6.593C4.558.92 6.76 0 9.346 0c2.585 0 4.768.92 6.607 2.74 1.838 1.822 2.738 4.026 2.738 6.613 0 2.147-.651 4.064-1.972 5.75h.727c.307 0 .556.096.766.307l4.482 4.485c.21.21.306.46.306.767 0 .306-.096.556-.306.766h-.02Zm-9.039-7.781c1.188-1.189 1.8-2.645 1.8-4.313 0-1.667-.593-3.105-1.8-4.312C12.43 3.814 10.992 3.22 9.326 3.22c-1.666 0-3.102.594-4.309 1.802-1.206 1.207-1.8 2.645-1.8 4.312 0 1.668.594 3.105 1.8 4.313 1.207 1.207 2.643 1.801 4.31 1.801 1.665 0 3.102-.594 4.308-1.801Z"/>
		</svg>
</template>

<script>
export default {
   name: 'SearchSVG',
}
</script>