<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 100 100"
    :width="size" 
    :height="size"
  >
    <!-- Rounded rectangle outline -->
    <rect 
      x="10" 
      y="10" 
      width="80" 
      height="80" 
      rx="20" 
      ry="20" 
      :fill="fill" 
      :stroke="stroke" 
      stroke-width="8" 
    />
  </svg>
</template>

<script>
export default {
  name: 'UncheckedSVG',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
    stroke: {
      type: String,
      default: "#0041C4"
    },
    fill: {
      type: String,
      default: ""
    }
  }
}
</script>