<template>
	<svg viewBox="0 0 17 14" class="tagsSVG" :width="size" :fill="fill" :height="size">
	  <path d="M13.217 6.185c.25.256.374.559.374.931 0 .373-.125.676-.374.932l-5.414 5.568a1.204 1.204 0 0 1-.906.384c-.362 0-.656-.128-.906-.384l-5.617-5.8A1.275 1.275 0 0 1 0 6.883V1.316C0 .956.125.641.374.384.623.128.917 0 1.28 0h5.414c.35 0 .656.128.906.384l5.629 5.789-.012.012ZM2.073 3.995c.249.256.543.384.906.384.362 0 .657-.128.906-.384s.373-.56.373-.932-.124-.675-.373-.932a1.205 1.205 0 0 0-.906-.384c-.363 0-.657.128-.906.384-.25.257-.374.56-.374.932 0 .373.124.676.374.932Zm14.542 4.042L11.2 13.604a1.204 1.204 0 0 1-.906.384c-.362 0-.657-.128-.906-.384l4.621-4.752c.464-.49.69-1.072.69-1.747 0-.676-.226-1.258-.69-1.747L8.789.012h1.302c.351 0 .657.128.906.384l5.63 5.789c.248.256.373.559.373.931 0 .373-.125.676-.374.932l-.011-.011Z"/>
	</svg>
</template>

<script>
export default {
   name: 'tagsSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>