/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";
import Breadcrumbs from "./components/bread_crumbs";
import { store } from "./store";
import firebase from "firebase/app";
import Vue2Filters from "vue2-filters";
import VueSweetalert2 from "vue-sweetalert2";
import VueFormWizard from "vue-form-wizard";
import VueTour from "vue-tour";
import Notifications from "vue-notification";
import { Vue2Dragula } from "vue2-dragula";
import Toasted from "vue-toasted";
import SmartTable from "vuejs-smart-table";
import * as VueGoogleMaps from "vue2-google-maps";
import { VueMasonryPlugin } from "vue-masonry";
import VueFeather from "vue-feather";
import VueApexCharts from "vue-apexcharts";
import FunctionalCalendar from "vue-functional-calendar";
import vueKanban from "vue-kanban";
import ElementUI from 'element-ui';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports'; // Make sure this points to your Amplify configuration file
import Toast from 'vue-toastification';

Amplify.configure(awsconfig);
import 'element-ui/lib/theme-chalk/index.css';
import VueFormulate from '@braid/vue-formulate';
import 'vue-toastification/dist/index.css';

// Globally register Vue Formulate
Vue.use(VueFormulate);
Vue.use(Toast);


import PxCard from "./components/Pxcard.vue";
Vue.component(PxCard.name, PxCard);

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

// Multi Language Add
import en from "./locales/en.json";
import es from "./locales/es.json";
import { defaultLocale, localeOptions } from "./constants/config";
import VueI18n from "vue-i18n";

Vue.use(ElementUI, { locale: localeOptions[0].id })

// Import Theme scss
import "./assets/scss/app.scss";

// Import custom scss
import "./assets/scss/custom.scss";

// Import tailwind css
import './index.css'

// config for firebase
import config from "./config.json";

// Lottie
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(LottieVuePlayer);

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

Vue.use(VueFilterDateFormat);

// global import
import VueAudioPlayer from '@liripeng/vue-audio-player'

Vue.use(VueAudioPlayer);

//NumberFormat Filter
Vue.filter("formatNumber", function (value) {
  if (typeof value !== "number") {
    return value;
  }

  const numStr = value.toString();
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
import moment from "moment";
// Set the start of the week to Monday
moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});
Vue.filter("minsToTime", function (minutes) {
  const minsInHour = 60;
  const hoursInDay = 24;
  const daysInWeek = 7;

  const weeks = Math.floor(minutes / (minsInHour * hoursInDay * daysInWeek));
  minutes %= minsInHour * hoursInDay * daysInWeek;

  const days = Math.floor(minutes / (minsInHour * hoursInDay));
  minutes %= minsInHour * hoursInDay;

  const hours = Math.floor(minutes / minsInHour);
  minutes %= minsInHour;

  let result = "";
  if (weeks) {
    result += `${weeks} ${weeks > 1 ? "weeks" : "week"} `;
  }
  if (days) {
    result += `${days} ${days > 1 ? "days" : "day"} `;
  }
  if (hours) {
    result += `${hours} ${hours > 1 ? "hours" : "hour"} `;
  }
  if (minutes) {
    result += `${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
  }

  return result.trim();
});

Vue.filter("formatTimeFromNow", function (value) {
  if (!value) return "";

  // Parse the UTC time
  const utcTime = moment.utc(value);

  // Convert to local time
  const localTime = utcTime.local();

  // Return the time from now in local timezone
  return localTime.fromNow(true);
});
Vue.filter(
  "formatToLocalTime",
  function (value, format = "MM/DD/YYYY hh:mm:ss A") {
    if (!value) return "";

    // Parse the timestamp as UTC
    const utcMoment = moment.utc(value);

    // Convert to local timezone and format it
    return utcMoment.local().format(format);
  }
);

Icon.Default.mergeOptions({
  iconRetinaUrl: require("./assets/images/vue2leaflet/marker-icon.png"),
  iconUrl: require("./assets/images/vue2leaflet/marker-icon.png"),
});

Vue.use(VueFeather);

Vue.use(Toasted, {
  iconPack: "fontawesome",
});
Vue.use(Vue2Dragula);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ",
    libraries: "places",
  },
});

Vue.use(Notifications);
Vue.use(Vue2Filters);
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard);
Vue.use(VueTour);
Vue.use(BootstrapVue);
Vue.use(SmartTable);
Vue.use(require("vue-chartist"));
Vue.use(require("vue-moment"));
Vue.component("Breadcrumbs", Breadcrumbs);
Vue.use(VueMasonryPlugin);
Vue.component("apexchart", VueApexCharts);
Vue.use(FunctionalCalendar, {
  dayNames: ["M", "T", "W", "T", "F", "S", "S"],
});
Vue.use(vueKanban);
Vue.use(VueI18n);
Vue.use(require("vue-moment"));

// setup fake backend
import { configureFakeBackend } from "./_helpers";
configureFakeBackend();

const messages = { en: en, es: es };
const locale =
  localStorage.getItem("currentLanguage") &&
  localeOptions.filter((x) => x.id === localStorage.getItem("currentLanguage"))
    .length > 0
    ? localStorage.getItem("currentLanguage")
    : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
});

Vue.config.productionTip = false;
firebase.initializeApp(config.firebase);

export const db = firebase.firestore();



new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
