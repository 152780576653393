<template>
  <div class="table-wrapper" style="margin-bottom: 20px; padding: 10px;">
    <table class="table call-backs-table" aria-controls="tagstable">
      <thead>
        <tr>
          <th style="width: 89%;">Premade Message</th>
          <th style="width: 10%;">Date</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr 
          v-for="(row, index) in messages" 
          :key="row.id" 
          :data-keyword-id="row.id" 
          @click="selectMessage(row)"
          :class="{ 'selected': isSelected(row) }"
          style="cursor: pointer"
        >
          <td>{{ row.message || '-' }}</td>
          <td>{{ row.created_date.toLocaleString() || '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "SelectPremadeMessage",
  props: {
    selectedPremadeMessage: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  data() {
    return {
      messages: []
    }
  },
  methods: {
    isSelected(row) {
      return this.selectedPremadeMessage?.id === row.id;
    },
    selectMessage(row) {
      this.$emit('update:selectedPremadeMessage', row);
      this.$emit('move-next');
    },
    async fetchPremadeMessages() {
      try {
        let target = `https://123avatars.com/v2/premade-messages`;
        const response = await axios.get(target);
        this.messages = response.data;
      } catch (error) {
        console.error('Error fetching premade messages:', error);
      }
    }
  },
  async mounted() {
    await this.fetchPremadeMessages();
  }
}
</script>

<style scoped>
.selected {
  background-color: #f0f7ff;
}

tr:hover {
  background-color: #f5f5f5;
}

.table-wrapper {
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

th {
  background-color: #f8fafc;
  font-weight: 600;
}
</style>