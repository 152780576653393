<template>
	<svg viewBox="0 0 17 17" class="closeSVG" :width="size" :fill="fill" :height="size">
	   <path d="M16.704 12.8698L12.2778 8.5L16.704 4.13018C17.0987 3.73549 17.0987 3.3408 16.704 2.9461L14.0539 0.29602C13.6592 -0.0986733 13.2645 -0.0986733 12.8698 0.29602L8.5 4.72222L4.13018 0.29602C3.73549 -0.0986733 3.3408 -0.0986733 2.9461 0.29602L0.29602 2.9461C-0.0986733 3.3408 -0.0986733 3.73549 0.29602 4.13018L4.72222 8.5L0.29602 12.8698C-0.0986733 13.2645 -0.0986733 13.6592 0.29602 14.0539L2.9461 16.704C3.3408 17.0987 3.73549 17.0987 4.13018 16.704L8.5 12.2778L12.8698 16.704C13.2645 17.0987 13.6592 17.0987 14.0539 16.704L16.704 14.0539C17.0987 13.6592 17.0987 13.2645 16.704 12.8698Z"/>
	   </svg>
</template>

<script>
export default {
   name: 'closeSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>