<template>
  <div class="container-fluid">
    <div>

  </div>
    <div class="row">
      <div class="col-xl-7" style="padding: 0px">
        <!-- <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        /> -->
        <div
          class="video-container"
          style="
            width: 100%;
            height: 100vh;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          "
        >
          <video width="150%" autoplay loop muted>
            <source src="../../assets/images/avatars.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          
          <div>
            <div>
              <a class="logo text-left">
                <img
                  class="img-fluid for-light"
                  src="../../assets/images/logo/logo.png"
                  alt="looginpage"
                  style="max-width: 200px"
                />
                <img
                  class="img-fluid for-dark"
                  src="../../assets/images/logo/logo_dark.png"
                  alt="looginpage"
                  style="max-width: 200px"
                />
              </a>
            </div>
            <div class="login-main login-form-card">
              <form class="theme-form">
                <h4>Sign in to account</h4>
                <p>Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    class="form-control"
                    type="text"
                    required=""
                    placeholder="email@domain.com"
                    v-model="username"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input
                    class="form-control"
                    :type="type"
                    name="login[password]"
                    required=""
                    placeholder="*********"
                    v-model="password"
                  />
                  <div class="show-hide" @click="showPassword">
                    <span class="show"> </span>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1"
                      >Remember password</label
                    >
                  </div>
                  <a
                    href="#"
                    class="btn btn-primary btn-block"
                    @click="login"
                    :disabled="buttonStatus"
                  >
                    {{ buttonText }}
                  </a>
                </div>
                <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light">
                      <i class="fa fa-google txt-linkedin"></i>
                      Google
                    </a>
                    <a class="btn btn-light">
                      <feather type="twitter" class="txt-twitter"></feather
                      >twitter</a
                    >
                    <a class="btn btn-light">
                      <feather type="facebook" class="txt-fb"></feather
                      >facebook</a
                    >
                  </div>
                </div>
                <p class="mt-4 mb-0">
                  Don't have account?<router-link
                    class="ml-2"
                    tag="a"
                    to="/auth/register"
                  >
                    Create Account
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Userauth from "../../auth/js/index";
import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      authState: null,
      type: "password",
      email: "",
      password: "",
      username: "",
      passwordjwt: "",
      submitted: false,
      buttonText: "Sign In",
      buttonStatus: false,
      authConfig: {
          signUpConfig: {
            header: 'My Customized Sign Up',
            hideAllDefaults: true,
            defaultCountryCode: '1',
            signUpFields: [
              {
                label: 'Email',
                key: 'email',
                required: true,
                displayOrder: 1,
                type: 'string',
                signUpWith: true,
              },
              {
                label: 'Password',
                key: 'password',
                required: true,
                displayOrder: 2,
                type: 'password',
              },
              {
                label: 'PhoneNumber',
                key: 'phone_number',
                required: true,
                displayOrder: 3,
                type: 'string',
              },
              {
                label: 'Custom Attribute',
                key: 'custom_attr',
                required: false,
                displayOrder: 4,
                type: 'string',
                custom: true,
              },
            ],
          },
        },
    };
  },
  computed: {
    
    // JWT authentication
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    console.log('AmplifyAuthenticator:', AmplifyAuthenticator);
    console.log('AmplifyAuthenticator.render:', AmplifyAuthenticator.render);
    // reset login status for JWT
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    // show/hide password
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },

    // Auth0 login
    login() {
      this.buttonText = "Signing in...";
      this.buttonStatus = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      axios
        .post("https://123avatars.com/backend/login.php", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          if (response.data.user) {
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("userId", response.data.user.default_company_id);
            localStorage.setItem("username", response.data.user.username);
            localStorage.setItem("userType", response.data.user.type);
            const self = this;
            setTimeout(function () {
              self.$router.push({ path: "/" });
            }, 2000);
          } else {
            this.buttonText = "Sign In";
            this.buttonStatus = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.buttonText = "Sign In";
          this.buttonStatus = false;
        });
    },
  },
};
</script>
