var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"login-card"},[_c('div',[_vm._m(0),_c('div',{staticClass:"login-main login-form-card"},[_c('form',{staticClass:"theme-form"},[_c('h4',[_vm._v("Reset Your Password")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('h6',{staticClass:"mt-4"},[_vm._v("Create Your Password")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v("Already have an password? "),_c('router-link',{staticClass:"ml-2",attrs:{"tag":"a","to":"/auth/login"}},[_vm._v(" Login ")])],1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid for-light",attrs:{"src":require("../../assets/images/logo/login.png"),"alt":"looginpage"}}),_c('img',{staticClass:"img-fluid for-dark",attrs:{"src":require("../../assets/images/logo/logo_dark.png"),"alt":"looginpage"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Enter Your Mobile Number")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('input',{staticClass:"form-control mb-1",attrs:{"type":"text","value":"+ 91"}})]),_c('div',{staticClass:"col-md-9"},[_c('input',{staticClass:"form-control mb-1",attrs:{"type":"tel","value":"000-000-0000"}})]),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-primary btn-block m-t-10",attrs:{"type":"submit"}},[_vm._v("Send")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-4 mb-4"},[_c('span',{staticClass:"reset-password-link"},[_vm._v("If don't receive OTP? "),_c('a',{staticClass:"btn-link text-danger",attrs:{"href":"#"}},[_vm._v("Resend")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label pt-0"},[_vm._v("Enter OTP")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('input',{staticClass:"form-control text-center opt-text",attrs:{"type":"text","value":"00","maxlength":"2"}})]),_c('div',{staticClass:"col"},[_c('input',{staticClass:"form-control text-center opt-text",attrs:{"type":"text","value":"00","maxlength":"2"}})]),_c('div',{staticClass:"col"},[_c('input',{staticClass:"form-control text-center opt-text",attrs:{"type":"text","value":"00","maxlength":"2"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("New Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","name":"login[password]","required":"","placeholder":"*********"}}),_c('div',{staticClass:"show-hide"},[_c('span',{staticClass:"show"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Retype Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","name":"login[password]","required":"","placeholder":"*********"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"checkbox p-0"},[_c('input',{attrs:{"id":"checkbox1","type":"checkbox"}}),_c('label',{staticClass:"text-muted",attrs:{"for":"checkbox1"}},[_vm._v("Remember password")])]),_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("Done ")])])
}]

export { render, staticRenderFns }