<template>
  <div class="container p-4 bg-light shadow-sm rounded">
    <h2 class="h4 font-weight-bold mb-4 text-primary">Daily Q&A Link</h2>
    <form @submit.prevent="submitLink">
      <div class="form-group">
        <label for="qaLink" class="font-weight-medium text-secondary">Enter Q&A Link</label>
        <input
          type="url"
          id="qaLink"
          v-model="qaLink"
          required
          class="form-control"
          placeholder="https://example.com/qa"
        />
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-block"
      >
        Submit
      </button>
    </form>
    <p v-if="message" class="mt-3 text-success">{{ message }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qaLink: '',
      message: ''
    };
  },
  methods: {
    async submitLink() {
      try {
        const response = await fetch('https://123avatars.com/v2/update-qa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ link: this.qaLink }),
        });

        if (response.ok) {
          this.message = 'Link submitted successfully!';
          this.qaLink = ''; // Clear the input field
        } else {
          this.message = 'Failed to submit the link. Please try again.';
        }
      } catch (error) {
        this.message = 'An error occurred. Please try again.';
      }
    },
  },
};
</script>