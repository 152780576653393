<template>
    <div class="container mt-5 p-3 bg-white rounded shadow w-100">
      <div class="title text-center mb-4">
        Sales Team Management
      </div>
      <table class="table table-bordered report-table">
        <thead class="thead-light">
          <tr>
            <th>Team Name</th>
            <th>Example Link</th>
            <th>Assign Sales Manager</th>
            <th>Sales Manager's Extension</th>
            <th>3CX Password</th>
            <th>Cell</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in reports" :key="index">
            <td v-if="report.assignable" class="sales-team-name">
              <input type="text" class="form-control" placeholder="Assign Team Name" v-model="report.name">
            </td>
            <td><a target="_blank" :href="report.link">{{ report.link }}</a></td>
            <td v-if="report.assignable" class="sales-rep">
              <input type="text" class="form-control" placeholder="Assign Sales Rep" v-model="report.sales_manager">
            </td>
            <td v-if="report.assignable" class="new-leads">
              <input type="text" class="form-control" placeholder="Sales Rep's Extension" v-model="report.sales_rep_extension">
            </td>
            <td v-else>
              {{ report.sales_rep_extension }}
            </td>
            <td v-if="report.assignable" class="new-leads">
              <input type="text" class="form-control" placeholder="Sales Rep's 3CX Password" v-model="report.cx_password">
            </td>
            <td v-if="report.assignable" class="new-leads">
              <input type="phone" class="form-control" placeholder="Sales Rep's Cell" v-model="report.cell">
            </td>
            <td v-if="report.assignable" class="new-leads">
              <input type="email" class="form-control" placeholder="Sales Rep's Email" v-model="report.email">
            </td>
            <td>
              <button class="btn btn-success refresh-button" @click="refreshLink(report, index)">Refresh Link</button>
              <span class="feedback">Action in progress...</span>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary mt-3" @click="addSalesRep">+ Sales Manager</button>
      <div class="links-container mt-4">
        <div class="form-group">
          <label for="script-link">Script Link:</label>
          <input type="text" class="form-control" id="script-link" placeholder="Enter Script Link" v-model="scriptLink">
        </div>
        <div class="form-group">
          <label for="signup-link">Signup Link:</label>
          <input type="text" class="form-control" id="signup-link" placeholder="Enter Signup Link" v-model="signupLink">
        </div>
        <div class="form-group">
          <label for="signup-link">Q & A Link:</label>
          <input type="text" class="form-control" id="signup-link" placeholder="Enter Signup Link" v-model="qaLink">
        </div>
        <div class="form-group">
          <label for="signup-link">Training Locker Link:</label>
          <input type="text" class="form-control" id="signup-link" placeholder="Enter Signup Link" v-model="trainingLockerLink">
        </div>
      </div>
      <div class="save-button text-right mt-4">
        <button class="btn btn-success" @click="saveData">Save</button>
        <span class="feedback">Action in progress...</span>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        reports: [],
        scriptLink: '',
        signupLink: '',
        qaLink: '',
        trainingLockerLink: ''
      };
    },
    mounted() {
      this.loadData();
    },
    methods: {
      loadData() {
        axios.get('https://123avatars.com/v2/sales-team-management?userId=' + localStorage.getItem('userId'))
          .then(response => {
            this.reports = response.data.reports;
            this.scriptLink = response.data.scriptLink;
            this.signupLink = response.data.signupLink;
            this.qaLink = response.data.qaLink;
            this.trainingLockerLink = response.data.trainingLockerLink;
          })
          .catch(error => {
            console.error("There was an error loading the data!", error);
          });
      },
      saveData() {
        const data = {
          userId: localStorage.getItem('userId'),
          reports: this.reports,
          scriptLink: this.scriptLink,
          signupLink: this.signupLink,
          qaLink: this.qaLink ? this.qaLink : null,
          trainingLockerLink: this.trainingLockerLink ? this.trainingLockerLink : null
         };
  
        axios.post('https://123avatars.com/v2/sales-team-management/save', data)
          .then(response => {
            this.showFeedback({ target: document.querySelector('.save-button button') });
          })
          .catch(error => {
            console.error("There was an error saving the data!", error);
          });
      },
      addSalesRep() {
        this.reports.push({
          name: 'New Report',
          link: 'New Link',
          sales_rep: '',
          sales_rep_extension: '',
          assignable: true,
          cell: '',
          email: '',
          cx_password: ''
        });
      },
      refreshLink(report, index) {
        const userId = localStorage.getItem('userId');
        axios.post('https://123avatars.com/v2/sales-team-management/refresh-link', { userId, reportId: report.id })
          .then(response => {
            this.$set(this.reports, index, { ...report, link: response.data.newLink });
            this.showFeedback({ target: event.target });
          })
          .catch(error => {
            console.error("There was an error refreshing the link!", error);
          });
      },
      showFeedback(event) {
        const button = event.target;
        const feedback = button.nextElementSibling;
        feedback.style.display = 'inline';
        feedback.innerHTML = 'Action in progress...';
  
        setTimeout(() => {
          feedback.innerHTML = 'Action completed!';
          setTimeout(() => {
            feedback.style.display = 'none';
          }, 2000);
        }, 2000);
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin: 20px auto;
    width: 90%;
    max-width: 1200px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .report-table th,
  .report-table td {
    position: relative;
  }
  
  .feedback {
    display: none;
    color: #28a745;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
    white-space: nowrap;
  }
  
  .links-container input {
    margin-bottom: 20px;
  }
  
  .save-button .feedback {
    display: none;
  }
  </style>