<template>
<svg viewBox="0 0 22 12">
	<path d="m17.07 9.83004-.54.51996c-.11.11-.24.16-.4.16-.16 0-.29-.05-.4-.16l-4.55-4.54996c-.11-.11-.16-.24-.16-.4 0-.16.05-.29.16-.4l4.55-4.55c.11-.11.24-.16.4-.16.16 0 .29.05.4.16l.54.52c.11.11.16.24.15.4-.01.16-.07.29-.18.4l-2.81 2.7h6.73c.16 0 .29.05.4.16.11.11.16.24.16.4v.75c0 .16-.05.29-.16.4-.11.11-.24.16-.4.16h-6.73l2.81 2.7c.11.11.17.24.18.4.01.16-.04.29-.15.4v-.01ZM11.4399 0c.25 0 .42.12.52.35.1.23.05.44-.12.61l-4.34 4.34v6.14c0 .23-.1.4-.3.5-.2.1-.4.09-.59-.04l-1.88-1.31c-.16-.12-.23-.28-.23-.47V5.29L.1699.96c-.17-.17-.21-.37-.12-.61s.27-.35.52-.35h10.88-.01Z"/>
</svg>
</template>

<script>
export default {
   name: 'CallFunnelInSVG',
}
</script>